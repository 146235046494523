import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import { CadeEvent } from '@versant-monorepo/cade';

interface CadeSummaryAndOpinionInstructionModel {
  title: string;
  instruction: string;
  audioSrc: string[];
  currentStep: number;
  totalSteps: number;
  onEvent: (event: CadeEvent) => void;
}
export function transformJSONToSummaryAndOpinionInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeSummaryAndOpinionInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
    'items.actions.displayParams.display',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const { title, instruction, audioSrc, totalSteps, currentStep } = {
    ...extractCommonItemsForInstruction(json, tocArray),
  };

  return {
    title,
    instruction,
    audioSrc,
    currentStep,
    totalSteps,
    onEvent: (event) => {},
  };
}
