import { CadeEvent, useEndedEvent } from '@utils/events';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import './styles.scss';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerStatus,
} from '@components/base/Timer';
import { useDelay } from '@utils/useDelay';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { TextArea } from '@components/base/TextArea';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  leftLabel: string;
  rightLabel: string;
  count: number;
  total: number;
  timeLeft: number;
  information: string[];
  instruction?: string;
};

export function EmailExercise({
  rightLabel,
  leftLabel,
  timeLeft,
  forceEnd,
  onEvent,
  information,
  count,
  total,
  subTitle,
  instruction,
}: Props) {
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.IN_PROGRESS
  );
  const [currentText, setCurrentText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent<string>(onEvent);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent(currentText);
    }
  }, [forceEnd]);

  const onChangeStatus = useCallback(
    (status: TimerStatus) => {
      setTimerStatus(status);
      if (isTimerFinished(status)) {
        delay.set(() => sendEndEvent(currentText), EXERCISE_AUTO_PLAY_TIMEOUT);
      }
    },
    [currentText]
  );

  return (
    <div className={'email-exercise'}>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level={'small'} style={{ paddingBottom: 20 }}>
          {instruction ?? t('email.exercise.instruction')}
        </Instruction>
      </InstructionContainer>
      <div className={'email-exercise__counter-box'}>
        <QuestionCounter
          count={count}
          total={total}
          title={t('email.exercise.counterTitle')}
        />
        <div className="email-exercise__upper-secetion">
          <div className="email-exercise__timer">
            {timerStatus === TIMER_STATUS.FINISHED && (
              <Paragraph weight={'bold'} className={'email-exercise__red-msg'}>
                {t('timer.timeIsOver')}
              </Paragraph>
            )}
            <Timer
              time={timeLeft}
              onChangeStatus={onChangeStatus}
              timerStatus={timerStatus}
              variant={TIMER_VARIANT.SECONDARY}
            />
          </div>
        </div>
      </div>
      <div className={'email-exercise__email-box'}>
        <div className="email-exercise__email-box--left">
          <Paragraph weight={'bold'} level={'large'}>
            {leftLabel}
          </Paragraph>
          <Box className={'email-exercise__scenario-box'} role={'instruction'}>
            <span className="email-exercise__scenario-badge">
              {t('email.exercise.badge')}
            </span>
            {information.map((el, key) => (
              <div
                style={{ fontSize: '18px' }}
                key={`${el}${key}`}
                dangerouslySetInnerHTML={{ __html: el }}
              ></div>
            ))}
          </Box>
        </div>
        <div className="email-exercise__email-box--right">
          <div className="email-exercise__email-box-header">
            <Paragraph weight={'bold'} level={'large'}>
              {rightLabel}
            </Paragraph>
          </div>
          <div className="email-exercise__textarea-wrapper">
            <TextArea.Field
              disabled={timerStatus === TIMER_STATUS.FINISHED}
              className="email-exercise__textarea-old"
              placeholder={t('email.exercise.input.placeholder')}
              ariaLabel={t('email.exercise.input.aria')}
              onChange={(text) => {
                setCurrentText(text!);
                const countWordInText = currentText
                  .split(/\s+/)
                  .filter((word) => word !== '').length;
                setWordCount(countWordInText);
                return '';
              }}
            />
          </div>
          <div className="email-exercise__word-counter">
            {timerStatus !== TIMER_STATUS.FINISHED && (
              <>
                <span
                  className={'sr-only'}
                  aria-live={'polite'}
                  role={'status'}
                  aria-atomic={'true'}
                >
                  {`${t('email.exercise.wordCount')} ${wordCount}`}
                </span>
                <Paragraph weight={'bold'} style={{ textAlign: 'right' }}>
                  {`${t('email.exercise.wordCount')} ${wordCount}`}
                </Paragraph>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
