import {
  CADE_CONTENT_TITLE_ID,
  CADE_NEXT_BUTTON_ID,
  CADE_SPEAKER_CHECK_TROUBLESHOOTING_LINK_ID,
  SpeakerCheck,
} from '@versant-monorepo/cade';
import { useNavigate } from 'react-router-dom';
import { useCheckProgress } from '../../hooks/useCheckProgress';
import { vfwRoutes } from '../../vfw-routes';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from 'hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { isSpeakingTest } from '../../utils/utils';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { passCheck } from 'utils/prechecks';
import { serializeError } from '../../api/api.utils';

export const SpeakerCheckWrapper = () => {
  const navigate = useNavigate();
  useSetDocumentTitle('Speaker check');
  useCheckProgress('speaker');
  const { pushEvent } = useLogger();
  const { lastEvent, eventHandler } = useHandleAppEvent({
    'cade-ended-event': () => {
      try {
        passCheck('speaker');
        pushEvent({
          item: 'Speaker Check',
          level: LogLevel.INFO,
          message: 'User go next on Speaker check',
        });
        if (isSpeakingTest()) {
          navigate(vfwRoutes.backgroundNoiseCheck);
        } else {
          navigate(vfwRoutes.setupSuccess);
        }
      } catch (e) {
        console.error(e);
        pushEvent({
          level: LogLevel.ERROR,
          message: `Speaker Check error: ${serializeError(e)}`,
          item: 'Speaker Check',
          eventType: 'ERROR',
        });
        navigate(vfwRoutes.serverError);
      }
    },
    'cade-success-event': () => focus(CADE_NEXT_BUTTON_ID),
    'cade-failure-event': () => {
      pushEvent({
        item: 'Speaker Check',
        level: LogLevel.INFO,
        message: 'Speaker check error',
        eventType: 'ERROR',
      });
      focus(CADE_SPEAKER_CHECK_TROUBLESHOOTING_LINK_ID);
    },
  });
  const { focus } = useFocus(CADE_CONTENT_TITLE_ID, []);

  const speakerCheckProps = {
    onEvent: eventHandler,
    audioSrc: require('../../assets/audio/speaker_check.mp3'),
    currentCheckingStep: isSpeakingTest() ? 4 : 3,
    totalCheckingSteps: isSpeakingTest() ? 5 : 3,
  };

  return (
    <>
      <Template
        volumeSlider={true}
        header={<Header onExit={() => eventHandler(exitEvent())} title={''} />}
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <SpeakerCheck {...speakerCheckProps} />
      </Template>
    </>
  );
};
