import React, { createContext, FC, PropsWithChildren, useState } from 'react';

export const MicrophoneContext = createContext<{
  microphoneId?: string;
  stream?: MediaStream;
  setStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
}>({
  microphoneId: undefined,
  setStream: () => {},
});

export const MicrophoneProvider: FC<
  PropsWithChildren<{ microphoneId: string | undefined }>
> = ({ microphoneId, children }) => {
  const [stream, setStream] = useState<MediaStream>();
  return (
    <MicrophoneContext.Provider value={{ microphoneId, stream, setStream }}>
      {children}
    </MicrophoneContext.Provider>
  );
};
