import { useEffect, useState } from 'react';

export const useAudioDuration = (audioSrc: string[]) => {
  const [duration, setDuration] = useState<number>(0);

  if (audioSrc.length === 0) {
    return 0;
  }

  useEffect(() => {
    let audios: HTMLAudioElement[] = [];
    const loadedMetadata = (audio: HTMLAudioElement) =>
      setDuration((previousDuration) => {
        const duration = audio.duration * 1000;

        if (!previousDuration) {
          return duration;
        }

        return previousDuration + duration;
      });
    audioSrc.forEach((audioFile) => {
      const audio = new Audio();
      audio.addEventListener('loadedmetadata', () => loadedMetadata(audio));

      audio.src = audioFile;
      audios.push(audio);
    });

    return () => {
      audios.forEach((audio) =>
        audio.removeEventListener('loadedmetadata', () => loadedMetadata(audio))
      );
    };
  }, []);

  return duration;
};
