import {
  ApiModel,
  AudioResponse,
  LaunchTest,
  ReservationTin,
  TextResponse,
} from './api.interfaces';
import jwt_decode from 'jwt-decode';
import { VfwStorage } from '../utils/Storage';
import { Logger, LogLevel } from './Logger';
import {
  base64ToFile,
  generateSequenceNumber,
  getChecksum,
  sampleWAV,
  validateChecksum,
} from '../utils/utils';
import { serializeError } from './api.utils';

export const COMMON_KEYS_TO_VALIDATE = [
  'items.actions',
  'items.titles',
  'items.itemSequence.count',
  'items.itemSequence.total',
];

export let CURRENT_ITEM_TYPE = '';

export function makeUrl(path: string) {
  return `${process.env.REACT_APP_PROXY_URL}${path}`;
}

function getCurrentItemType(json: ApiModel): string {
  if (json.items[0].itemType === 'instructions') {
    return `${json.items[0].itemType}_${json.items[0].subType}`;
  }

  return json.items[0].itemType;
}

function applyVarsToVFWStorage(
  token: string,
  json: ReservationTin,
  csrfToken: string
) {
  VfwStorage.setItem('csrfToken', csrfToken);
  VfwStorage.setItem('authtoken', token);
  VfwStorage.setItem('reservationTinResponse', json);
  VfwStorage.setItem('testName', json.testName ?? '');
  VfwStorage.setItem('entitlement', json.entitlement as string);
  VfwStorage.setItem('speakingTips', json.speakingTips);
}

const request = async (
  url: string,
  options: any = {},
  retries = 3,
  withContentType = true
) => {
  const fetchWithHeaders = async () => {
    const headers = withContentType
      ? {
          ...options?.headers,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // apply authorization token if we have one
          ...(VfwStorage.getItem('authtoken')
            ? { Authorization: VfwStorage.getItem('authtoken') }
            : {}),
        }
      : {
          ...options?.headers,
          // apply authorization token if we have one
          ...(VfwStorage.getItem('authtoken')
            ? { Authorization: VfwStorage.getItem('authtoken') }
            : {}),
        };
    return await fetch(url, {
      ...options,
      headers,
      credentials: 'include',
    });
  };
  let _retries = retries;
  let timeouts = 1;
  while (retries) {
    try {
      const response = await fetchWithHeaders();
      if (!response.ok) {
        const json = await response.json();
        throw json;
      }
      return response;
    } catch (error: any) {
      _retries--;
      timeouts++;
      if (!_retries) {
        timeouts = 1;
        throw error;
      }
      // retries after 2 seconds, 4 seconds, 6 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000 * timeouts));
    }
  }
};

export const reserveTinLegacyDirect = async (
  tin: number,
  hash: string
): Promise<ReservationTin> => {
  try {
    const response = await request(
      makeUrl('/tin/reservation/legacy'),
      {
        method: 'POST',
        body: JSON.stringify({
          tin,
          hash,
          csrfToken: VfwStorage.getItem('csrfToken'),
        }),
      },
      1
    );
    const data = (response && (await response.json())) ?? '';
    const token = data?.token || '';
    const csrfToken = data?.csrfToken || '';
    const json = jwt_decode(token) as ReservationTin;
    applyVarsToVFWStorage(token, json, csrfToken);
    return json as ReservationTin;
  } catch (error: any) {
    throw error;
  }
};

export const reserveTinDirect = async (
  token: string
): Promise<ReservationTin> => {
  try {
    const response = await request(
      makeUrl('/tin/reservation/direct'),
      {
        method: 'POST',
        body: JSON.stringify({
          token,
          csrfToken: VfwStorage.getItem('csrfToken'),
        }),
      },
      1
    );
    const data = (response && (await response.json())) ?? '';
    const authtoken = data?.token || '';
    const csrfToken = data?.csrfToken || '';
    const json = jwt_decode(authtoken) as ReservationTin;
    applyVarsToVFWStorage(authtoken, json, csrfToken);
    return json as ReservationTin;
  } catch (error: any) {
    throw error;
  }
};

export const reserveTin = async (
  tin: number,
  recaptchaToken?: string
): Promise<ReservationTin> => {
  try {
    const response = await request(
      makeUrl('/tin/reservation'),
      {
        method: 'POST',
        body: JSON.stringify({
          tin,
          recaptchaToken,
          csrfToken: VfwStorage.getItem('csrfToken'),
        }),
      },
      1
    );
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Reservation tin success',
      item: 'Login',
    });
    const data = (response && (await response.json())) ?? '';
    const token = data?.token || '';
    const csrfToken = data?.csrfToken || '';
    const json = jwt_decode(token) as ReservationTin;
    applyVarsToVFWStorage(token, json, csrfToken);
    if (json.resume) {
      // we are launching a test here since looks like test instance id is not refreshed after resume
      // which can produce error during network check on system checks
      await launchTest();
      Logger.getInstance().pushEvent({
        level: LogLevel.INFO,
        message: 'Attempt to launch test after resume',
        item: 'Reservation tin',
      });
    }
    return json;
  } catch (error: any) {
    throw error;
  }
};

export const connectionSpeed = async (startTime: number) =>
  request(makeUrl(`/connectionspeed?nnn=${startTime}`), {}, 1);

export const validateRecording = async (): Promise<boolean> => {
  let blob = sampleWAV();
  // yes System Check item is out of border of responsibility
  // this API function should not know that
  const LOG_ITEM = 'System Check';
  let crc32_client = await getChecksum(blob);
  const url = makeUrl('/storage/validate');

  const fd = new FormData();
  fd.append('file', blob);
  fd.append('crc32', crc32_client.toString());

  try {
    const data = await request(
      url,
      {
        method: 'POST',
        body: fd,
      },
      3,
      false
    );

    const json = await data?.json();

    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: `Validate Recording call finished with result: ${JSON.stringify(
        json
      )}`,
      item: LOG_ITEM,
      eventType: 'SUCCESS',
    });

    const { crc32 } = json;
    const validationResult = validateChecksum(crc32_client, crc32);
    if (validationResult.status) {
      Logger.getInstance().pushEvent({
        level: LogLevel.INFO,
        message: `CRC32 checksum match success`,
        item: LOG_ITEM,
        eventType: 'SUCCESS',
      });
    } else {
      Logger.getInstance().pushEvent({
        level: LogLevel.INFO,
        message: `CRC32 checksum match failure`,
        item: LOG_ITEM,
        eventType: 'ERROR',
      });
    }

    return validationResult.status;
  } catch (e) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Validate Recording finished by error: ${serializeError(e)}`,
      item: LOG_ITEM,
      eventType: 'ERROR',
    });
    return false;
  }
};

export const launchTest = async (): Promise<LaunchTest> => {
  try {
    const tinResponse = VfwStorage.getItem('reservationTinResponse');
    if (!tinResponse) {
      // TODO log this issue
      console.log('No tin data to proceed.');
      return Promise.reject();
    }
    const response = await request(makeUrl('/test/launch'), {
      method: 'POST',
      body: JSON.stringify({
        csrfToken: VfwStorage.getItem('csrfToken'),
      }),
    });
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Launch test success',
      item: 'Test',
    });
    const token = (response && (await response.text())) ?? '';
    // replace the token
    VfwStorage.setItem('authtoken', token);
    const launchTest: LaunchTest = jwt_decode(token);
    VfwStorage.setItem('launchTestResponse', launchTest);
    return launchTest;
  } catch (error: any) {
    throw error;
  }
};

export const uploadAudioResponse = async (
  audioResponse: AudioResponse
): Promise<void> => {
  const fd = new FormData();
  fd.append('csrfToken', VfwStorage.getItem('csrfToken') as string);
  fd.append('audioResponse', audioResponse.response);
  fd.append('responseId', audioResponse.id);
  try {
    const response = await request(
      makeUrl('/v1/upload-audio'),
      {
        method: 'POST',
        body: fd,
      },
      3,
      false
    );
    const json = response && (await response.json());
    return json;
  } catch (error: any) {}
};

export const refreshView = async () => {
  try {
    const response = await request(
      makeUrl('/v1/test/refreshStep'),
      {
        method: 'POST',
        body: JSON.stringify({
          csrfToken: VfwStorage.getItem('csrfToken'),
        }),
      },
      1
    );
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Refresh step success',
      item: 'Test',
    });

    const json = response && (await response.json());
    CURRENT_ITEM_TYPE = getCurrentItemType(json);
    return json;
  } catch (e) {
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Refresh step failure',
      item: 'Test',
    });
  }
};

export const getNextStep = async (
  itemResponses: TextResponse[] | AudioResponse | null = null
) => {
  const launchResponse = VfwStorage.getItem('launchTestResponse');
  if (!launchResponse) {
    // TODO log this issue
    console.log('No launch test data to proceed.');
    return;
  }
  try {
    const fd = new FormData();
    fd.append('csrfToken', VfwStorage.getItem('csrfToken') as string);
    if (itemResponses) {
      if (!Array.isArray(itemResponses)) {
        fd.append('audioResponse', itemResponses.response);
        fd.append('responseId', itemResponses.id);

        Logger.getInstance().pushEvent({
          level: LogLevel.INFO,
          message: 'Next Step contains audio response',
          item: 'Next Step',
        });
      } else {
        fd.append('textResponses', JSON.stringify(itemResponses));

        Logger.getInstance().pushEvent({
          level: LogLevel.INFO,
          message: 'Next Step contains text response',
          item: 'Next Step',
        });
      }
    }

    const response = await request(
      makeUrl('/v1/test/nextstep'),
      {
        method: 'POST',
        body: fd,
      },
      3,
      false
    );
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Next Step executed',
      item: 'Next Step',
    });

    const json = response && (await response.json());
    CURRENT_ITEM_TYPE = getCurrentItemType(json);
    return json;
  } catch (error: any) {
    throw error;
  }
};

export const getConfig = async (): Promise<{
  recaptcha_site_key: string | null;
}> => {
  const response = await request(`${process.env.REACT_APP_PROXY_URL}/config`, {
    method: 'GET',
  });
  return response && (await response.json());
};

export const endTest = async () => {
  const launchResponse = VfwStorage.getItem('launchTestResponse');
  if (!launchResponse) {
    // TODO log this issue
    console.log('No launch test data to proceed.');
    return;
  }
  try {
    const response = await request(makeUrl(`/test/endtest`), {
      method: 'POST',
      body: JSON.stringify({
        testState: 'COMPLETED',
        reason: '',
        csrfToken: VfwStorage.getItem('csrfToken'),
      }),
    });
    const res = response && (await response.json());
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'End test success',
      item: CURRENT_ITEM_TYPE,
    });
    return res;
  } catch (error: any) {}
};

export const validateReferenceFrame = async (base64: string) => {
  const reservationTinResponse = VfwStorage.getItem('reservationTinResponse');
  if (!reservationTinResponse) {
    console.log('No reservation tin data to proceed.');
    return;
  }

  const file = base64ToFile(base64, 'selfie.jpg');

  try {
    const fd = new FormData();
    fd.append('content', file);
    fd.append('csrfToken', VfwStorage.getItem('csrfToken') as string);
    const response = await request(
      makeUrl('/proctoring/frame/validate'),
      {
        method: 'POST',
        body: fd,
      },
      3,
      false
    );
    const res = response && (await response.json());
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Validate selfie',
      item: 'Take a Selfie',
    });
    return res;
  } catch (error: any) {}
};

export const validateReferenceVideo = async (
  file: File,
  recordingTime: string,
  mediaFormat: string,
  mediaPayloadSize: number,
  deviceName: string
) => {
  const reservationTinResponse = VfwStorage.getItem('reservationTinResponse');
  if (!reservationTinResponse) {
    console.log('No reservation tin data to proceed.');
    return;
  }

  try {
    const fd = new FormData();
    fd.append('content', file);
    fd.append('recordingTime', JSON.stringify(recordingTime));
    fd.append('mediaFormat', JSON.stringify(mediaFormat));
    fd.append('mediaPayloadSize', JSON.stringify(mediaPayloadSize));
    fd.append('deviceName', JSON.stringify(deviceName));
    fd.append('csrfToken', VfwStorage.getItem('csrfToken') as string);
    const response = await request(
      makeUrl('/proctoring/video/validate'),
      {
        method: 'POST',
        body: fd,
      },
      3,
      false
    );
    const res = response && (await response.json());
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Validate video',
      item: 'Record a video',
    });
    return res;
  } catch (error: any) {}
};

export const startProctoringSession = async (
  videoUID: string
): Promise<string> => {
  const reservationTinResponse = VfwStorage.getItem('reservationTinResponse');
  if (!reservationTinResponse) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Start proctoring session: No reservation tin data to proceed`,
      item: 'Monitoring',
    });
    throw new Error('No reservation tin data to proceed');
  }

  const referenceFrame = VfwStorage.getItem('referencePhotoBase64');
  if (referenceFrame === null) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Start proctoring session: No reference frame to proceed`,
      item: 'Monitoring',
    });
    throw new Error('No reference frame to proceed');
  }

  try {
    const now = new Date();
    const response = await request(makeUrl(`/proctoring/session/start`), {
      method: 'POST',
      body: JSON.stringify({
        tin: JSON.stringify(reservationTinResponse.tin),
        clientTimePT: now.toISOString(),
        videoUID,
        csrfToken: VfwStorage.getItem('csrfToken'),
      }),
    });
    const res = response && (await response.json());
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Launch proctoring session success',
      item: 'Table of contents',
    });
    const { sessionKey } = res;
    if (referenceFrame) {
      await sendReferenceFrame(sessionKey, referenceFrame);
      Logger.getInstance().pushEvent({
        level: LogLevel.INFO,
        message: `Reference frame successfully sent`,
        item: 'Monitoring',
      });
    }
    return sessionKey;
  } catch (error: any) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Error while starting proctoring session. Error=${serializeError(
        error
      )}`,
      item: 'Monitoring',
    });
    throw new Error('Error while starting proctoring session', error);
  }
};

const sendReferenceFrame = (sessionKey: string, frame: string) =>
  sendFrame(sessionKey, frame, '', true);

export const sendProctoringFrame = (
  sessionKey: string,
  frame: string,
  currentItemId: string
) => sendFrame(sessionKey, frame, currentItemId);

const sendFrame = async (
  sessionKey: string,
  frame: string,
  currentItemId = '',
  reference?: true
) => {
  const reservationTinResponse = VfwStorage.getItem('reservationTinResponse');
  if (!reservationTinResponse) {
    console.log('No reservation tin data to proceed.');
    return;
  }

  const now = new Date();
  const sequenceNumber = reference ? String(1) : generateSequenceNumber();
  const file = base64ToFile(frame, 'frame.jpg');
  const submissionTime = now.toISOString();
  const fileSize = String(file.size);
  // TODO provide test item info

  const fd = new FormData();
  fd.append('content', file);
  fd.append('sessionKey', sessionKey);
  fd.append('submissionTimePT', submissionTime);
  fd.append('sequenceNum', sequenceNumber);
  fd.append('mediaPayloadSize', fileSize);
  fd.append('testItem', currentItemId);
  fd.append('recordingTime', now.toISOString());
  fd.append('csrfToken', VfwStorage.getItem('csrfToken') as string);
  if (reference) {
    fd.append('reference', JSON.stringify(true));
  }

  const response = await request(
    makeUrl('/proctoring/frame'),
    {
      method: 'POST',
      body: fd,
    },
    3,
    false
  );
  const res = response && (await response.json());
  Logger.getInstance().pushEvent({
    level: LogLevel.INFO,
    message: `Sent proctoring frame, sequenceNumber=${sequenceNumber}, sessionKey=${sessionKey}, submissionTime=${submissionTime}, mediaPayloadSize=${fileSize}, testItem=${currentItemId}`,
    item: 'Monitoring',
  });
  return res;
};

export const finalizeProctoringSession = async () => {
  const reservationTinResponse = VfwStorage.getItem('reservationTinResponse');
  if (!reservationTinResponse) {
    console.log('No reservation tin data to proceed.');
    return;
  }

  const sessionKey = VfwStorage.getItem('proctoringSessionKey');
  if (!sessionKey) {
    console.log('No session key to proceed.');
    return;
  }

  try {
    const now = new Date();
    const response = await request(makeUrl(`/proctoring/session/finalize`), {
      method: 'POST',
      body: JSON.stringify({
        tin: JSON.stringify(reservationTinResponse.tin),
        sessionKey,
        submittedFramesCnt: 0,
        clientSessionEndTimePT: now.toISOString(),
        csrfToken: VfwStorage.getItem('csrfToken'),
      }),
    });
    const res = response && (await response.json());
    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: 'Proctoring session successfully finalized',
      item: 'End Test',
    });
    return res;
  } catch (error: any) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Error while finalizing proctoring session: ${serializeError(
        error
      )}}`,
      item: 'End Test',
    });
  }
};

export const sendBackgroundAudio = async (file: File, testItem: string) => {
  const reservationTinResponse = VfwStorage.getItem('reservationTinResponse');
  if (!reservationTinResponse) {
    console.log('No reservation tin data to proceed.');
    return;
  }

  const sessionKey = VfwStorage.getItem('proctoringSessionKey');
  if (!sessionKey) {
    console.log('No session key to proceed.');
    return;
  }

  try {
    const now = new Date();
    const sequenceNumber = generateSequenceNumber();

    const fd = new FormData();
    fd.append('content', file);
    fd.append('testItem', testItem);
    fd.append('sequenceNum', sequenceNumber);
    fd.append('recordingDateTime', now.toISOString());
    fd.append('csrfToken', VfwStorage.getItem('csrfToken') as string);

    const response = await request(
      makeUrl('/proctoring/background-audio'),
      {
        method: 'POST',
        body: fd,
      },
      1,
      false
    );
    const res = response && (await response.json());

    Logger.getInstance().pushEvent({
      level: LogLevel.INFO,
      message: `Sent background audio, sequenceNumber=${sequenceNumber}, mediaPayloadSize=${file.size}, testItem=${CURRENT_ITEM_TYPE}`,
      item: 'Monitoring',
    });
    return res;
  } catch (error: any) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Error while sending background audio: ${serializeError(
        error
      )}}`,
      item: 'Send Background Audio',
    });
  }
};

export const getSessionInfo = async (): Promise<string> => {
  try {
    const response = await request(makeUrl(`/proctoring/tin/info`), {
      method: 'POST',
      body: JSON.stringify({
        csrfToken: VfwStorage.getItem('csrfToken'),
      }),
    });
    const res = response && (await response.json());
    const { sessionKey } = res;
    return sessionKey;
  } catch (error: any) {
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      message: `Error resuming proctoring session, trying to access session info. Error=${serializeError(
        error
      )}`,
      item: 'Monitoring',
    });
    throw new Error('Error while resuming proctoring session', error);
  }
};
