import {
  audioEndedEvent,
  CadeEvent,
  useEndedEvent,
  userResumeSpeakingEvent,
  weCantHearYouEvent,
} from '@utils/events';
import { Box, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import { TextArea } from '@components/base/TextArea';
import { useRecording } from '@components/Recording/useRecording';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import './styles.scss';
import { PlayAudio } from '@components/PlayAudio';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface ReadAloudProps {
  count: number;
  total: number;
  subTitle: string;
  passage: string;
  passageLabel: string;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  instruction?: string;
  audioSrc: string[];
}

export function ReadAloud({
  forceEnd,
  passage,
  passageLabel,
  onEvent,
  subTitle,
  timeouts,
  total,
  count,
  instruction,
  audioSrc,
}: ReadAloudProps) {
  const [warningVisible, setWarningVisible] = useState(false);
  const delay = useDelay();
  const [blob, setBlob] = useState<Blob | null>(null);
  const { sendEndEvent } = useEndedEvent<Blob>(onEvent);
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.INIT
  );
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const { state } = useVolume();
  let voiceLevel = state.microphoneOutputValue;

  const sendBlob = useCallback(
    (b: Blob) => {
      if (b) {
        if (!forceEnd) {
          delay.set(() => {
            sendEndEvent(b);
          });
        } else {
          sendEndEvent(b);
        }
      }
    },
    [forceEnd]
  );

  const { startRecording, stopRecording } = useRecording({
    handleFinishRecording: (b: Blob) => {
      setBlob(b);
      voiceLevel = 0;
      sendBlob(b);
    },
    timeouts: {
      initialSilence: timeouts.speaking,
      endingSilence: timeouts.speaking,
      max: timeouts.speaking,
    },
    onTimeout: (timeoutKind) => {
      switch (timeoutKind) {
        case 'initial_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'cant_hear_you_silence_timeout':
          setWarningVisible(true);
          onEvent(weCantHearYouEvent());
          break;
        case 'ending_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'max_time_timeout':
          stopRecording();
          setWarningVisible(false);
          break;
        case 'cant_hear_you_silence_timeout_canceled':
          setWarningVisible(false);
          onEvent(userResumeSpeakingEvent());
          break;
      }
    },
  });

  const onChangeTimerStatus = useCallback(setTimerStatus, [setTimerStatus]);

  useEffect(() => {
    if (timerStatus === TIMER_STATUS.IN_PROGRESS) {
      startRecording();
    }
  }, [timerStatus]);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      stopRecording();
      if (blob) {
        sendBlob(blob);
      }
    }
  }, [forceEnd, blob]);

  return (
    <div className={'read-aloud-exercise'}>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level={'small'}>
          {instruction ?? t('readAloud.exercise.instruction')}
        </Instruction>
      </InstructionContainer>
      <div className="read-aloud-exercise__upper-section">
        <QuestionCounter
          count={count}
          total={total}
          title={t('readAloud.exercise.questionCountLabel')}
        />
        <div className="read-aloud-exercise__timer">
          <TimerMessage
            show={isTimerFinished(timerStatus)}
            insideClassName="cade-margin-right-3"
          />
          <Timer
            time={timeouts.speaking}
            onChangeStatus={onChangeTimerStatus}
            timerStatus={timerStatus}
          />
        </div>
      </div>
      <Box role="exercise" className="example-box">
        <TextArea.Label
          id="read-aloud-label"
          tabIndex={-1}
          label={passageLabel}
          labelSize="LARGE"
        >
          <TextArea.Field
            ariaLabel={passage}
            disabled={true}
            blockInput={true}
            value={passage}
            className="read-aloud-exercise__text-area"
            onChange={() => {
              return '';
            }}
          />
        </TextArea.Label>
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          weCantHearYou={warningVisible}
          numOfCircles={6}
          listenMode={false}
          currentValue={voiceLevel}
        />
      </div>
      <PlayAudio
        audioSrc={audioSrc}
        autoPlay={true}
        onEnded={() => {
          setTimerStatus(TIMER_STATUS.IN_PROGRESS);
          onEvent(audioEndedEvent());
        }}
      />
    </div>
  );
}
