import './PrimaryButton.scss';
import { Button, ButtonSize } from '../Button';
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

export type PrimaryButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    icon?: string;
    error?: boolean;
    size?: ButtonSize;
  }>;

export function PrimaryButton({
  children,
  error,
  ...props
}: PrimaryButtonProps) {
  return (
    <Button
      {...props}
      className={classNames('cade-primary-button', {
        'cade-primary-button--error': error,
      })}
    >
      {children}
    </Button>
  );
}
