export const vfwRoutes = {
  root: '/',
  login: '/',
  landingPage: '/landing-page',
  thingsYouNeedToKnow: '/things-you-need-to-know',
  systemCheck: '/system-check',
  bandwidthCheck: '/bandwidth-check',
  microphoneCheck: '/microphone-check',
  speakerCheck: '/speaker-check',
  backgroundNoiseCheck: '/background-noise-check',
  setupSuccess: '/setup-success',
  speakingTips: '/speaking-tips',
  test: '/test',
  problemWithConnection: '/problem-with-connection',
  problemWithAudio: '/problem-with-audio',
  weCantHearYou: '/we-cant-hear-you',
  problemWithCamera: '/problem-with-camera',
  browserNotSupported: '/browser-not-supported',
  readyToContinue: '/ready-to-continue',
  accessDenied: '/access-denied',
  serverError: '/error',
  microphoneProblem: '/microphone-problem',
  featureFlagsConfig: '/feature-flags-config',
  lti: '/lti', // deprecated
  directLegacy: '/direct-code',
  direct: '/direct',
  reviewRules: '/review-rules',
  takeASelfie: '/take-a-selfie',
  recordAVideo: '/record-a-video',
  setupSummary: '/setup-summary',
  consentPageBiometricData: '/consent-page-biometric-data',
} as const;
