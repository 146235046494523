import { IParagraph, Paragraph } from '@components/typography/Paragraph';
import './styles.scss';

type IInstruction = IParagraph;

export function Instruction({ children, ...props }: IInstruction) {
  return (
    <Paragraph
      level={'large'}
      className="cade-instruction__paragraph"
      {...props}
    >
      {children}
    </Paragraph>
  );
}
