import { CadeEvent, useEndedEvent } from '@utils/events';
import React, { useContext, useEffect, useState } from 'react';
import { ListeningStatus } from '@components/Listening/commons';
import { useDelay } from '@utils/useDelay';
import { useVolume } from '@context/Volume.context';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { Box, Paragraph, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import { Listening } from '@components/Listening';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import './styles.scss';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  title: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
};

export function SummaryAndOpinionInstruction({
  instruction,
  onEvent,
  forceEnd,
  audioSrc,
  title,
}: Props) {
  const [status, setStatus] = useState<ListeningStatus>('INACTIVE');
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);

  const { state } = useVolume();
  const voiceLevel = state.outputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const [buttonText, setButtonText] = useState(t('buttons.play'));

  useEffect(() => delay.set(onClickPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  function onClickPlay() {
    setStatus('PLAYING');
  }

  function onAudioEnded() {
    delay.set(() => {
      sendEndEvent();
    });
    setButtonText(t('buttons.replay'));
    setStatus('INACTIVE');
  }

  return (
    <div className={'summary-and-opinion-instruction'}>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID} level="large">
          {title}
        </Title>
        <Instruction>{instruction}</Instruction>
      </InstructionContainer>
      <Box role="instruction" className="example-box cade-padding-7">
        <Paragraph level={'large'} weight={'bold'}>
          {t('summaryAndOpinion.instruction.p')}
        </Paragraph>
        <Listening
          role={'instruction'}
          playButtonText={buttonText}
          status={status}
          onClickPlay={() => {
            delay.clear();
            onClickPlay();
          }}
          audioSrc={audioSrc}
          onAudioEnded={onAudioEnded}
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
