import { VfwStorage } from '../../utils/Storage';
import { Navigate } from 'react-router';

export const ProtectedRoute = ({
  children,
}: {
  children: any;
}): JSX.Element => {
  if (!VfwStorage.getItem('authtoken')) {
    return <Navigate to={'/'} />;
  }

  return children;
};
