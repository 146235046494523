import './styles.scss';
import { Space } from '@components/Space';

type IContentExample = {
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
  vertical?: boolean;
};

export function DualPaneLayout({
  leftPane,
  rightPane,
  vertical = false,
}: IContentExample) {
  return (
    <div
      className={`cade-dualpane ${vertical ? 'cade-dualpane__vertical' : ''}`}
    >
      <Space margin={{ right: 3 }} className="cade-dualpane__left">
        {leftPane}
      </Space>
      <Space margin={{ left: 3 }} className="cade-dualpane__right">
        {rightPane}
      </Space>
    </div>
  );
}
