import { Toc, ApiModel } from '../../api.interfaces';
import { extractCommonItemsForExercise } from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import i18n from 'i18next';

interface CadeReadAloudModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  onEvent: (event: CadeEvent) => void;
  count: number;
  total: number;
  subTitle: string;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  passage: string;
  responseId?: string;
  passageLabel: string;
  instruction?: string;
}
export function transformJSONToReadAloud(
  json: ApiModel,
  tocArray: Toc[]
): CadeReadAloudModel {
  const item = json.items[0];
  let passage = '';
  const passageLabel =
    item.microInstructions[1] ?? i18n.t('readAloud.exercise.label');
  const displayAction = item.actions.find((el) => el.actionType === 'display');

  if (displayAction) {
    passage = displayAction.displayParams?.display[0][0] ?? '';
  }

  const commonItems = extractCommonItemsForExercise(json, tocArray);
  const instruction = item.cueInstructions?.at(0);

  return {
    ...commonItems,
    passage,
    onEvent: (event: CadeEvent) => {},
    audioSrc: commonItems.audioSrc,
    passageLabel,
    instruction,
  };
}
