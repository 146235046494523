import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForExercise,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import { VfwStorage } from '../../../utils/Storage';
import i18n from 'i18next';

interface CadeStoryRetellModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  onEvent: (event: CadeEvent) => void;
  count: number;
  total: number;
  subTitle: string;
  audioSrc: any;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  responseId: string;
  leftLabel?: string;
  rightLabel?: string;
  timeLeft?: number;
  instruction: string;
  questionCounterLabel?: string;
}

export function transformJSONToShortStoryRetelling(
  json: ApiModel,
  tocArray: Toc[]
): CadeStoryRetellModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);

  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const instruction =
    json.items[0].cueInstructions?.[0] ??
    i18n.t('storyRetell.exercise.instruction');

  return {
    ...extractCommonItemsForExercise(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    leftLabel: json.items[0].microInstructions[0],
    rightLabel: json.items[0].microInstructions[1]
      ? json.items[0].microInstructions[1]
      : VfwStorage.getItem('reservationTinResponse')?.variantSignature ===
        'veslt'
      ? i18n.t('storyRetell.exercise.labels.right.veslt')
      : i18n.t('storyRetell.exercise.labels.right.default'),

    timeLeft: json.items[0].actions[1].actionDuration,
    instruction,
    questionCounterLabel:
      VfwStorage.getItem('reservationTinResponse')?.variantSignature === 'veslt'
        ? i18n.t('storyRetell.exercise.labels.questionCounter.veslt')
        : i18n.t('storyRetell.exercise.labels.questionCounter.default'),
  };
}
