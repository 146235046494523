import { Feature, FlagsProvider, useFeature } from 'flagged';
import { PropsWithChildren } from 'react';
import { Observable } from './utils';

export const FEATURES_KEYS = {
  USE_NEW_CHECKBOX: 'USE_NEW_CHECKBOX',
} as const;

export type FeaturesKeys = keyof typeof FEATURES_KEYS;

export const DEFAULT_FEATURES = {
  [FEATURES_KEYS.USE_NEW_CHECKBOX]: true,
};

export type Features = Record<FeaturesKeys, boolean>;

export type Props = PropsWithChildren<{
  features?: Features;
}>;

export const parseFeatureFlagsFromEnv = (
  env: NodeJS.ProcessEnv,
  envKey?: string
): Features | undefined => {
  const features = Object.entries(env).reduce((flags, [key, value]) => {
    const isTrue = value === 'true';

    if (!envKey) {
      return {
        ...flags,
        [key]: isTrue,
      };
    }

    if (key.includes(envKey)) {
      return {
        ...flags,
        [key.replace(envKey, '')]: isTrue,
      };
    }

    return { ...flags };
  }, {} as Features);

  return Object.keys(features).length ? features : undefined;
};

const observeFlags = new Observable<Features>();

export const FEATURE_FLAG_STORAGE_NAME = 'FEATURE_FLAGS';

class _FeatureFlagsManager {
  private STORAGE_KEY = FEATURE_FLAG_STORAGE_NAME;

  setFlags(flags: Partial<Features> | undefined) {
    if (!flags) {
      return;
    }

    const allFlags = this.getFlags();
    let prepareFlags = '';

    try {
      prepareFlags = JSON.stringify({ ...allFlags, ...flags });
    } catch (error) {
      console.error(error);
    }

    try {
      window.sessionStorage.setItem(this.STORAGE_KEY, prepareFlags);
    } catch (error) {
      console.error(error);
    }
    observeFlags.notify(this.getFlags());
  }

  getUpdates(callback: (arg: Features) => void) {
    observeFlags.subscribe(callback);
  }

  getFlags() {
    try {
      const allFlags = window.sessionStorage.getItem(this.STORAGE_KEY);

      return allFlags ? JSON.parse(allFlags) : undefined;
    } catch (error) {
      console.error(error);
    }
  }
}

export const FeatureFlagsManager = new _FeatureFlagsManager();

export const FeatureFlags = ({
  children,
  features = DEFAULT_FEATURES,
}: Props) =>
  features ? (
    <FlagsProvider features={features}>{children}</FlagsProvider>
  ) : (
    <>{children}</>
  );

export { useFeature, Feature };
