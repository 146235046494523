import { FC, useContext } from 'react';
import { SoundWave } from './SoundWave';
import './styles.scss';
import { SecondaryButton } from '@components/base/Button';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type Props = {
  onClickPlay?: () => void;
};

export const CADE_LISTENING_BUTTON_ID = 'listening-button';

export const FakeListening: FC<Props> = ({ onClickPlay = () => {} }) => {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  function _onClickPlay() {
    onClickPlay();
  }

  return (
    <div className="listening__container">
      <SoundWave
        duration={0}
        status={'INACTIVE'}
        border={undefined}
        stop={undefined}
        role={'exercise'}
        isLoading={false}
      />
      <div className="listening__button-wrapper">
        <SecondaryButton
          onClick={_onClickPlay}
          size={'normal'}
          id={CADE_LISTENING_BUTTON_ID}
          data-testid="listening-button"
        >
          {t('buttons.play')}
        </SecondaryButton>
      </div>
    </div>
  );
};
