import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import * as process from 'process';
import { getBrowserLanguage } from '../../utils/utils';
import { resources } from '../../i18n/i18n';

// Client-Side Cookie Management: https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?language=en_US
type Props = {
  consentUpdate?: (value: boolean) => void;
};

const language = getBrowserLanguage(Object.keys(resources));
export const CookieBanner = ({ consentUpdate = () => {} }: Props) => {
  useEffect(() => {
    // import style only when component is mounted
    // @ts-ignore
    import('./styles.css');

    window.OptanonWrapper = consentUpdate;
  }, []);

  return (
    <Helmet>
      <html lang={language} />
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={
          process.env.REACT_APP_ONE_TRUST_BANNER_DOMAIN_SCRIPT
        }
        data-document-language="true"
      />
    </Helmet>
  );
};
