import { Box } from '@src/components';
import { PropsWithChildren } from 'react';
import './styles.scss';

type IChecksBox = PropsWithChildren<{}>;

export function ChecksBox({ children }: IChecksBox) {
  return (
    <Box role="instruction" className="cade-checks-box">
      {children}
    </Box>
  );
}
