import React, { useEffect, useState, useContext } from 'react';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { Listening } from '@components/Listening';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import './styles.scss';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  bottomLeftLabel: string;
  bottomRightLabel: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  bottomLeftLabelForBox?: string;
  bottomRightLabelForBox?: string;
};

export const PASSAGE_RECONSTRUCTION_INSTRUCTION_TEST_IDS = {
  CONTAINER: 'cade-passage-reconstruction-instruction-container',
  INSTRUCTION: 'cade-passage-reconstruction-instruction-element',
  SUBTITLE: 'cade-passage-reconstruction-instruction-subtitle',
};

export function PassageReconstructionInstruction({
  subTitle,
  instruction,
  audioSrc,
  bottomLeftLabel,
  bottomRightLabel,
  onEvent,
  forceEnd,
  bottomLeftLabelForBox = 'You read',
  bottomRightLabelForBox = 'You type',
}: Props) {
  const [play, setPlay] = useState(false);
  const [bottomState, setBottomState] = useState<'ACTIVE' | 'INACTIVE'>(
    'INACTIVE'
  );
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const [instructionEnded, setInstructionEnded] = useState(false);
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);
  const textPlay = t('passageReconstruction.instruction.play');
  const textReplay = t('passageReconstruction.instruction.replay');

  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  useEffect(() => delay.set(onClickPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  function onClickPlay() {
    setPlay(true);
    setBottomState('ACTIVE');
  }

  const onAudioEnded = () => {
    delay.set(() => {
      sendEndEvent();
    });
    setInstructionEnded(true);
    setBottomState('INACTIVE');
    setPlay(false);
  };

  return (
    <>
      <div
        className={'passage-reconstruction-instruction-wrapper'}
        data-testid={PASSAGE_RECONSTRUCTION_INSTRUCTION_TEST_IDS.CONTAINER}
      >
        <InstructionContainer>
          <Title
            centered
            id={CADE_CONTENT_TITLE_ID}
            level="large"
            data-testid={PASSAGE_RECONSTRUCTION_INSTRUCTION_TEST_IDS.SUBTITLE}
          >
            {subTitle}
          </Title>
          <Instruction
            data-testid={
              PASSAGE_RECONSTRUCTION_INSTRUCTION_TEST_IDS.INSTRUCTION
            }
          >
            {instruction}
          </Instruction>
        </InstructionContainer>
        <Box role="instruction" className="cade-margin-bottom-5">
          <Paragraph level={'large'} weight={'bold'}>
            {t('passageReconstruction.instruction.p')}
          </Paragraph>
          <div
            className={
              'passage-reconstruction-instruction__listening-container'
            }
          >
            <Listening
              playButtonText={instructionEnded ? textReplay : textPlay}
              status={play ? 'PLAYING' : 'INACTIVE'}
              onClickPlay={() => {
                delay.clear();
                onClickPlay();
              }}
              audioSrc={audioSrc}
              onAudioEnded={onAudioEnded}
              role={'instruction'}
            />
          </div>
        </Box>
        <Box
          role="instruction"
          className="passage-reconstruction-instruction__bottom"
        >
          {bottomState === 'INACTIVE' && (
            <div
              className={'passage-reconstruction-instruction__bottom-overlay'}
            />
          )}
          <DualPaneLayout
            leftPane={
              <div
                aria-hidden={true}
                className={'passage-reconstruction-instruction__bottom-left'}
              >
                <Paragraph level={'large'}>{bottomLeftLabelForBox}</Paragraph>
                <Box withoutShadow={true} role={'instruction'}>
                  {bottomState !== 'INACTIVE' && (
                    <Paragraph level={'normal'}>{bottomLeftLabel}</Paragraph>
                  )}
                </Box>
              </div>
            }
            rightPane={
              <div
                aria-hidden={true}
                className={`passage-reconstruction-instruction__bottom-right ${
                  bottomState === 'INACTIVE' &&
                  'passage-reconstruction-instruction__bottom-right--inactive'
                }`}
              >
                <Paragraph weight={play ? 'bold' : 'normal'} level={'large'}>
                  {bottomRightLabelForBox}
                </Paragraph>
                <Box withoutShadow={true} role={'instruction'}>
                  {bottomState !== 'INACTIVE' && (
                    <Paragraph
                      level={'normal'}
                      weight={play ? 'bold' : 'normal'}
                    >
                      {bottomRightLabel}
                    </Paragraph>
                  )}
                </Box>
              </div>
            }
          />
        </Box>
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            numOfCircles={6}
            currentValue={voiceLevel}
            listenMode={true}
          />
        </div>
      </div>
    </>
  );
}
