import { CADE_CONTENT_TITLE_ID, SpeakingTips } from '@versant-monorepo/cade';
import { Wrapper } from '../Wrapper';
import './SpeakingTipsWrapper.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { VfwStorage } from '../../utils/Storage';
import { vfwRoutes } from '../../vfw-routes';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { audioUrlFromRid, serializeError } from 'api/api.utils';
import { parseSpeakingTipsText } from './parser';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';
import { allChecksPassed } from 'utils/prechecks';
import { useExitTest } from '../../hooks/useExitTest';

export const SpeakingTipsWrapper = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('speakingTipsWrapper.documentTitle'));
  const navigate = useNavigate();
  const exitTest = useExitTest();
  const onlineStatus = useNetwork();
  const [nextEnabled, setNextEnabled] = useState(false);
  const { pushEvent } = useLogger();
  const launchResponse = VfwStorage.getItem('launchTestResponse');
  const tinReservationResponse = VfwStorage.getItem('reservationTinResponse');
  let audioUrl = useMemo(
    () =>
      launchResponse &&
      tinReservationResponse &&
      tinReservationResponse.speakingTips
        ? [
            audioUrlFromRid(
              launchResponse,
              tinReservationResponse.speakingTips.audioRid
            ),
          ]
        : [require('../../assets/audio/speakingTips.mp3')],
    [launchResponse, tinReservationResponse]
  );

  const content = useMemo(() => {
    const defaultContent = {
      title: t('speakingTipsWrapper.content.title'),
      intro: t('speakingTipsWrapper.content.intro'),
      paragraphs: [
        t('speakingTipsWrapper.content.p1'),
        t('speakingTipsWrapper.content.p2'),
        t('speakingTipsWrapper.content.p3'),
      ],
    };
    return (
      (tinReservationResponse &&
        tinReservationResponse.speakingTips &&
        parseSpeakingTipsText(
          tinReservationResponse.speakingTips?.instruction ?? ''
        )) ??
      defaultContent
    );
  }, [tinReservationResponse]);

  useFocus(CADE_CONTENT_TITLE_ID, []);

  useEffect(() => {
    try {
      if (!allChecksPassed()) {
        exitTest();
        return;
      }
    } catch (e) {
      console.error(e);
      pushEvent({
        level: LogLevel.ERROR,
        message: `Speaking Tips error: ${serializeError(e)}`,
        item: 'Speaking Tips Check',
        eventType: 'ERROR',
      });
      navigate(vfwRoutes.serverError);
    }
  }, []);

  const onNext = async () => {
    pushEvent({
      item: 'Speaking Tips',
      level: LogLevel.INFO,
      message: 'User go next on Speaking Tips',
    });
    navigate(vfwRoutes.test);
  };

  (window as any).next = () => onNext;

  const { eventHandler } = useHandleAppEvent({
    'cade-audio-ended-event': () => {
      setNextEnabled(true);
    },
    'cade-ended-event': onNext,
  });

  const speakingTipsProps = {
    title: t('speakingTips.title'),
    onEvent: eventHandler,
    audioSrc: audioUrl,
    content,
  };

  return (
    <Wrapper>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                item: 'Speaking Tips',
                level: LogLevel.INFO,
                message: 'User clicked exit on Speaking Tips',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer
            nextButtonEnabled={nextEnabled}
            onNext={onNext}
            role={'check'}
          />
        }
      >
        <SpeakingTips
          key={networkDependentKey(onlineStatus)}
          {...speakingTipsProps}
        />
      </Template>
    </Wrapper>
  );
};
