import React, { HTMLProps, useCallback, useEffect, useRef } from 'react';
import {
  cancelRepeat,
  updateOutputVolume,
  useVolume,
} from '@context/Volume.context';
import useAudioVolumeAnalyzer from './hooks';
import { SimpleSpread } from '@utils/types';

export type OnTimeUpdateArgs = Pick<
  HTMLAudioElement,
  'currentTime' | 'duration'
>;

export type OnPlayArgsCallback = {
  audioIndex: number;
};
export type OnPlayCallback = (args: OnPlayArgsCallback) => void;

type BaseProps = {
  audioSrc: string[];
  autoPlay?: boolean;
  onSingleEnded?: (index: number) => void;
  onEnded?: () => void;
  onVolumeChange?: (data: number) => void;
  onPlay?: OnPlayCallback;
  onTimeUpdate?: ({ currentTime, duration }: OnTimeUpdateArgs) => void;
  disableAudioVisualAnalysis?: boolean;
  onPause?: () => void;
  pause?: boolean;
  onCanPlayThrough?: (event: Event, audioIndex: number) => void;
};

export interface Props
  extends SimpleSpread<HTMLProps<HTMLAudioElement>, BaseProps> {}

export const AUDIO_TEST_ID = 'cade-audio-player';

export function PlayAudio({
  audioSrc,
  autoPlay,
  onEnded = () => {},
  onVolumeChange = () => {},
  onPlay = () => {},
  onSingleEnded = () => {},
  onTimeUpdate = () => {},
  onPause,
  pause,
  disableAudioVisualAnalysis,
  onCanPlayThrough = () => {},
  ...props
}: Props) {
  const audioIndexRef = useRef<number>(0);
  const [currentAudio] = audioSrc;
  const audioRef = useRef<HTMLAudioElement>(null);
  const isPlayingRef = useRef<boolean>(false);

  useAudioVolumeAnalyzer({
    onVolumeChangeCallback: (data: number) => {
      if (isPlayingRef.current) {
        dispatch(updateOutputVolume(data));
        onVolumeChange(data);
      }
    },
    audioElement: audioRef,
  });

  const { state, dispatch } = useVolume();

  const volume = state.value / 100;
  const repeat = state.isRepeat;

  if (!audioSrc) {
    throw new Error(
      'The audioSrc prop has not been delivered. The prop is mandatory.'
    );
  }

  useEffect(() => {
    if (audioRef.current != null) {
      audioRef.current.volume = volume;
      if (repeat) {
        audioRef.current.currentTime = 0;
        audioRef.current.play().then();
        isPlayingRef.current = true;
        dispatch(cancelRepeat());
      }
    }
  });

  useEffect(() => {
    if (autoPlay) {
      audioRef.current!.play();
      isPlayingRef.current = true;
    } else {
      audioRef.current!.currentTime = 0;
      audioRef.current!.pause();
      isPlayingRef.current = false;
    }
  }, [autoPlay]);

  useEffect(() => {
    if (pause) {
      audioRef.current?.pause();
      isPlayingRef.current = false;
    }
  }, [pause]);

  useEffect(() => {
    const audioInstances = audioSrc.map((audio, index) => {
      const audioInstance = new Audio(audio);
      const canPlayListener = (event: Event) => onCanPlayThrough(event, index);
      audioInstance.addEventListener('canplaythrough', canPlayListener);

      return { audioInstance, canPlayListener };
    });

    return () => {
      audioInstances.forEach(({ audioInstance, canPlayListener }) =>
        audioInstance.removeEventListener('canplaythrough', canPlayListener)
      );
    };
  }, []);

  useEffect(() => {
    return () => {
      isPlayingRef.current = false;
      audioRef.current?.remove();
      dispatch(updateOutputVolume(0));
    };
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const updateProgress = () => {
        const { currentTime, duration } = audioElement;
        onTimeUpdate({ currentTime, duration });
      };

      audioElement.addEventListener('timeupdate', updateProgress);

      return () =>
        audioElement.removeEventListener('timeupdate', updateProgress);
    }
  }, [onTimeUpdate]);

  const onAudioError = useCallback(() => {
    throw new Error('Cannot play audio with url: ' + audioSrc);
  }, []);

  const onAudioEnd = useCallback(() => {
    audioIndexRef.current++;
    onSingleEnded(audioIndexRef.current);

    if (audioSrc.length <= audioIndexRef.current) {
      onEnded();
      isPlayingRef.current = false;
      dispatch(updateOutputVolume(0));
      audioIndexRef.current = 0;
      audioRef.current!.src = audioSrc[0];
    } else {
      audioRef.current!.src = audioSrc[audioIndexRef.current];
      audioRef.current!.play();
      isPlayingRef.current = true;
    }
  }, [audioIndexRef.current, onSingleEnded, onEnded, audioSrc]);

  const _onPlay = useCallback(() => {
    isPlayingRef.current = true;
    onPlay({ audioIndex: audioIndexRef.current });
  }, [audioIndexRef.current]);

  return (
    <audio
      {...props}
      // just for development to avoid CORS issues for MediaSource analyser
      crossOrigin={'anonymous'}
      onEnded={onAudioEnd}
      onPause={onPause}
      onError={onAudioError}
      onPlay={_onPlay}
      ref={audioRef}
      data-testid={AUDIO_TEST_ID}
    >
      <source src={currentAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
}
