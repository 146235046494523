import React, { HTMLProps, PropsWithChildren, useMemo } from 'react';
import classNames from 'classnames';

export type Range = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type Positions = {
  top?: Range;
  right?: Range;
  bottom?: Range;
  left?: Range;
};

export type Spacings = {
  margin?: Positions | Range;
  padding?: Positions | Range;
};

type Props = PropsWithChildren<Spacings & HTMLProps<HTMLDivElement>>;

export const generateClassName = (
  prefix: string,
  values?: Positions | Range
) => {
  if (!values) {
    return '';
  }

  if (typeof values === 'number') {
    return `${prefix}-${values}`;
  }

  return Object.entries(values)
    .map(([key, value]) => `${prefix}-${key}-${value}`)
    .join(' ');
};

export const SPACE_TEST_ID = 'cade-space';

export function Space({
  margin,
  padding,
  children,
  className,
  ...props
}: Props) {
  const marginClasses = useMemo(
    () => generateClassName('cade-margin', margin),
    [margin]
  );
  const paddingClasses = useMemo(
    () => generateClassName('cade-padding', padding),
    [padding]
  );

  const cssClasses = classNames(className, {
    [marginClasses]: margin,
    [paddingClasses]: padding,
  });

  return (
    <div data-testid={SPACE_TEST_ID} className={cssClasses} {...props}>
      {children}
    </div>
  );
}
