import { Modal, Paragraph, SecondaryButton } from '@versant-monorepo/cade';
import { useApi } from '../../hooks/useApi';
import { Template } from '../../components/Template/Template';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import React, { useState } from 'react';
import endTestImg from '../../assets/end-test.svg';
import './styles.scss';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';
import { finalizeProctoringSession } from '../../api/api';
import { VfwStorage } from 'utils/Storage';
import { useExitTest } from '../../hooks/useExitTest';

export const EndTestView = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { endTest } = useApi();
  const { eventHandler, lastEvent } = useHandleAppEvent();
  const { pushEvent } = useLogger();
  const { t } = useTranslation();
  const exitTest = useExitTest();
  useSetDocumentTitle(t('endTestView.documentTitle'));

  const isVideoProctoringEnabled = VfwStorage.getItem('isTestProctored');

  const finishTest = async () => {
    setLoading(true);
    await endTest();
    pushEvent({
      item: 'End Test',
      level: LogLevel.INFO,
      message: 'User successfully end the test',
      eventType: 'ACTION',
    });
    if (isVideoProctoringEnabled) {
      await finalizeProctoringSession();
      pushEvent({
        item: 'End Test',
        level: LogLevel.INFO,
        message: 'Proctoring session successfully finalized',
        eventType: 'ACTION',
      });
    }
    setShowModal(true);
  };

  useFocus(CONTENT_TITLE_ID, [lastEvent]);

  return (
    <>
      <Template
        header={
          <Header
            onExit={() => eventHandler(exitEvent())}
            title={''}
            closeButtonVisible={false}
          />
        }
        footer={
          <Footer
            buttonLabel={t('endTestView.submit')}
            onNext={finishTest}
            role={'check'}
            nextButtonDisableImage={true}
          />
        }
      >
        <div className="end-test">
          {loading && (
            <div
              className={'sr-only'}
              role={'alert'}
              aria-live={'polite'}
              aria-atomic={true}
            >
              Loading
            </div>
          )}
          <div className="end-test--left">
            <Paragraph
              style={{
                fontSize: 30,
                lineHeight: '40px',
                marginTop: '55px',
                color: 'var(--black)',
                marginLeft: '40px',
              }}
            >
              {t('endTestView.p')}
            </Paragraph>
          </div>
          <div className="end-test--right">
            <img src={endTestImg} alt="End test image" aria-hidden={true} />
          </div>
        </div>
      </Template>
      <Modal
        open={showModal}
        footer={
          <SecondaryButton
            onClick={async () => {
              setLoading(false);
              if (VfwStorage.getItem('reservationTinResponse')?.returnUrl) {
                window.location.replace(
                  VfwStorage.getItem('reservationTinResponse')
                    ?.returnUrl as string
                );
              } else {
                exitTest();
              }
            }}
          >
            {t('buttons.exit')}
          </SecondaryButton>
        }
        exitButton={false}
        title={t('endTestView.complete')}
      >
        <Paragraph>{t('endTestView.p2')}</Paragraph>
      </Modal>
    </>
  );
};
