import { CadeEvent } from '@utils/events';
import React, { useContext, useState } from 'react';
import { ListeningStatus } from '@components/Listening/commons';
import { useVolume } from '@context/Volume.context';
import { Box, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@src/itemTypes';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { ListenRecordExerciseContent } from '@itemTypes/components/ListenRecordExerciseContent';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { ConfigContext } from '@context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IOppositesExerciseProps {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: any;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  leftLabel: string;
  rightLabel: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  instruction: string;
}

export const OPPOSITES_EXERCISE_IDS = {
  CONTAINER: 'cade-opposites-exercise-container',
  INSTRUCTION: 'cade-opposites-exercise-instruction',
  SUBTITLE: 'cade-opposites-exercise-subtitle',
};

export function OppositesExercise({
  count,
  total,
  subTitle,
  audioSrc,
  timeouts,
  leftLabel = 'Please listen.',
  rightLabel = 'Say the opposite.',
  onEvent,
  forceEnd = false,
  instruction,
}: IOppositesExerciseProps) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const [warningVisible, setWarningVisible] = useState(false);
  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  return (
    <div data-testid={OPPOSITES_EXERCISE_IDS.CONTAINER}>
      <InstructionContainer>
        <Title
          centered
          id={CADE_CONTENT_TITLE_ID}
          data-testid={OPPOSITES_EXERCISE_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction
          data-testid={OPPOSITES_EXERCISE_IDS.INSTRUCTION}
          level="small"
        >
          {instruction}
        </Instruction>
      </InstructionContainer>
      <QuestionCounter
        title={t('opposites.exercise.questionCountLabel')}
        count={count}
        total={total}
      />
      <Box role="exercise" className="example-box">
        <ListenRecordExerciseContent
          audioSrc={audioSrc}
          timeouts={timeouts}
          onEvent={onEvent}
          listeningStatus={listeningStatus}
          setListeningStatus={setListeningStatus}
          forceEnd={forceEnd}
          setWarningVisible={setWarningVisible}
          leftLabel={leftLabel}
          rightLabel={rightLabel}
          playBeepSound={true}
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          weCantHearYou={warningVisible}
          numOfCircles={6}
          listenMode={listeningStatus !== 'COMPLETED'}
          currentValue={voiceLevel}
        />
      </div>
    </div>
  );
}
