import { ApiModel, Toc } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';

interface CadeStoryRetellInstructionModel {
  title: string;
  instructions: string[];
  audioSrc: string[];
  duration: number;
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
}
export function transformJSONToStoryRetellInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeStoryRetellInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
    'items.actions.displayParams.display',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const { title, instruction, audioSrc, totalSteps, currentStep } = {
    ...extractCommonItemsForInstruction(json, tocArray),
  };

  return {
    title,
    instructions: [instruction],
    audioSrc,
    totalSteps,
    currentStep,
    onEvent: (event: CadeEvent) => {},
    duration: 10000,
  };
}
