import React, { ReactNode, useContext } from 'react';
import './styles.scss';
import { ChecksHeader } from '@components/ChecksHeader';
import { Box, Paragraph } from '@src/components';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Toc = {
  sequenceNum: number;
  sectionId: string;
  itemType: string;
  itemCount: number;
  completedItems: number;
  titles: string[];
};

export type Props = {
  toc: Toc[];
  title: string;
  description: string;
  type: 'toc' | 'resumestatus';
  children?: ReactNode | ReactNode[];
};

export function Toc({ toc, type = 'toc' }: Props) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const renderToc = ({
    titles: [part, task],
    itemCount,
    completedItems,
  }: Toc) => (
    <tr
      key={task}
      className={itemCount === completedItems ? 'toc__table--row-done' : ''}
    >
      <td>{part}</td>
      <td>{task}</td>
      <td>
        {itemCount === completedItems
          ? t('toc.complete')
          : completedItems > 0
          ? `${completedItems}/${itemCount}`
          : itemCount}
      </td>
    </tr>
  );
  return (
    <div className="toc__container">
      <div className="toc">
        <ChecksHeader
          headerText=""
          title={type === 'toc' ? t('toc.overview') : t('toc.welcomeBack')}
          fontLevel="large"
          currentStep={0}
          totalSteps={0}
        />
        <Box role="instruction" className="toc__main-content cade-margin-top-7">
          <Paragraph level="large" className="toc__title cade-margin-bottom-5">
            {type === 'toc' && t('toc.instruction')}
            {type === 'resumestatus' && t('toc.instruction2')}
          </Paragraph>
          <table className="toc__table">
            <thead>
              <tr>
                <th scope="col">{t('toc.part')}</th>
                <th scope="col">{t('toc.task')}</th>
                <th scope="col">{t('toc.questions')}</th>
              </tr>
            </thead>
            <tbody>{toc.map(renderToc)}</tbody>
          </table>
        </Box>
      </div>
    </div>
  );
}
