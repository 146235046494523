import { CadeEvent, useEndedEvent } from '@utils/events';
import './styles.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useAudioDuration } from '@utils/useAudioDuration';
import { useVolume } from '@context/Volume.context';
import { useDelay } from '@utils/useDelay';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { Listening } from '@components/Listening';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export const TYPING_INSTRUCTION_IDS = {
  WRAPPER: 'cade-typing-instruction-wrapper',
  INSTRUCTION: 'cade-typing-instruction-element',
  SUBTITLE: 'cade-typing-instruction-subtitle',
  LEFT_LABEL: 'cade-typing-instruction-left-label',
  LEFT_TEXT: 'cade-typing-instruction-left-text',
  RIGHT_LABEL: 'cade-typing-instruction-right-label',
  RIGHT_TEXT: 'cade-typing-instruction-right-text',
  OVERLAY: 'cade-typing-instruction-overlay',
};

export type Props = {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  leftLabel?: string;
  rightLabel?: string;
  leftText: string;
  rightText: string;
  forceEnd: boolean;
};

export function TypingInstruction({
  instruction,
  audioSrc,
  subTitle,
  onEvent,
  leftLabel,
  forceEnd,
  rightLabel,
  leftText,
  rightText,
}: Props) {
  const [play, setPlay] = useState(false);
  const [instructionEnded, setInstructionEnded] = useState(false);
  const [bottomState, setBottomState] = useState<'ACTIVE' | 'INACTIVE'>(
    'INACTIVE'
  );

  const duration = useAudioDuration(audioSrc);
  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);

  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const _leftLabel = leftLabel ?? t('typing.instruction.leftLabel');
  const _rightLabel = rightLabel ?? t('typing.instruction.rightLabel');
  const textPlay = t('buttons.play');
  const textReplay = t('buttons.replay');

  useEffect(() => delay.set(onClickPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  function onClickPlay() {
    setPlay(true);
    setBottomState('ACTIVE');
  }

  const onAudioEnded = () => {
    delay.set(() => {
      sendEndEvent();
    });
    setInstructionEnded(true);
    setBottomState('INACTIVE');
    setPlay(false);
  };

  return (
    <div
      className={'typing-instruction'}
      data-testid={TYPING_INSTRUCTION_IDS.WRAPPER}
    >
      <InstructionContainer>
        <Title
          centered
          id={CADE_CONTENT_TITLE_ID}
          level={'large'}
          data-testid={TYPING_INSTRUCTION_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction data-testid={TYPING_INSTRUCTION_IDS.INSTRUCTION}>
          {instruction}
        </Instruction>
      </InstructionContainer>
      <Box role="instruction" className="typing-instruction__top">
        <Paragraph level={'large'} weight={'bold'}>
          {t('typing.instruction.p')}
        </Paragraph>
        <div className={'typing-instruction__listening-container'}>
          <Listening
            playButtonText={instructionEnded ? textReplay : textPlay}
            status={play ? 'PLAYING' : 'INACTIVE'}
            onClickPlay={() => {
              delay.clear();
              onClickPlay();
            }}
            audioSrc={audioSrc}
            onAudioEnded={onAudioEnded}
            role={'instruction'}
          />
        </div>
      </Box>
      <Box role={'instruction'} className="typing-instruction__bottom">
        {bottomState === 'INACTIVE' && (
          <div
            className={'overlay'}
            data-testid={TYPING_INSTRUCTION_IDS.OVERLAY}
          />
        )}
        <DualPaneLayout
          leftPane={
            <div className="typing-instruction__bottom-left">
              <Paragraph
                level={'large'}
                data-testid={TYPING_INSTRUCTION_IDS.LEFT_LABEL}
              >
                {_leftLabel}
              </Paragraph>
              <Box role={'instruction'}>
                {bottomState !== 'INACTIVE' && (
                  <Paragraph
                    level={'normal'}
                    data-testid={TYPING_INSTRUCTION_IDS.LEFT_TEXT}
                  >
                    {leftText}
                  </Paragraph>
                )}
              </Box>
            </div>
          }
          rightPane={
            <div className="typing-instruction__bottom-right">
              <Paragraph
                level="large"
                weight={play ? 'bold' : 'normal'}
                data-testid={TYPING_INSTRUCTION_IDS.RIGHT_LABEL}
              >
                {_rightLabel}
              </Paragraph>
              <Box role="instruction">
                {bottomState !== 'INACTIVE' && (
                  <Paragraph
                    level="normal"
                    weight={play ? 'bold' : 'normal'}
                    data-testid={TYPING_INSTRUCTION_IDS.RIGHT_TEXT}
                  >
                    {rightText}
                  </Paragraph>
                )}
              </Box>
            </div>
          }
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
