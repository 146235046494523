export enum ColorPalette {
  DEFAULT_BLUE = '#047A9C',
  HOVER_BLUE = '#356286',
  SELECTED_BLUE = '#002747',
  BLUE_MEDIUM = '#8096B3',
  LIGHT_BLUE = '#D9E6F1',
  BLACK = '#212427',
  MEDIUM_GRAY = '#676767',
  DISABLED_GRAY = '#CCCCCC',
  LIGHT_GRAY = '#EEEEEE',
  WHITE = '#FFFFFF',
  GREEN_ALERT = '#008638',
  LIGHT_GREEN = '#DFF5D5',
  BACKGROUND_GREEN = '#F0F6FF',
  RED_WARNING = '#CA4644',
  DARK_ORANGE = '#A97C0A',
  ORANGE = '#DEA30D',
  LIGHT_YELLOW = '#FAEBC3',
}
