import { parseMarkup } from 'utils/utils';

export function parseSpeakingTipsText(text: string) {
  const title = parseMarkup(/^(.+?)<fmt.p>/i.exec(text)?.[1] ?? '');
  const paragraphs =
    text
      .match(/<fmt\.p>(.+?)<\/fmt\.p>/g)
      ?.map(parseMarkup)
      .map((p) =>
        p.replaceAll('- ', '').replaceAll('<p>', '').replaceAll('</p>', '')
      ) ?? [];

  const ready = /<\/fmt.p>((\s+|\w+|,|'|\.)+)$/g.exec(text)?.at(1);
  return {
    title,
    intro: paragraphs[0],
    paragraphs: paragraphs.slice(1),
    ready,
  };
}
