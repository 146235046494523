import { RepeatInstructionWrapper } from './RepeatInstructionWrapper/RepeatInstructionWrapper';
import { RepeatWrapper } from './RepeatWrapper/RepeatWrapper';
import { QuestionInstructionWrapper } from './QuestionInstructionWrapper/QuestionInstructionWrapper';
import { QuestionWrapper } from './QuestionWrapper/QuestionWrapper';
import { ReadingInstructionWrapper } from './ReadingInstructionWrapper/ReadingInstructionWrapper';
import { SentenceBuildInstructionWrapper } from './SentenceBuildInstructionWrapper/SentenceBuildInstructionWrapper';
import { OpenQuestionInstructionWrapper } from './OpenQuestionInstructionWrapper/OpenQuestionInstructionWrapper';
import { SurveyWrapper } from './SurveyWrapper/SurveyWrapper';
import { SpeechSampleWrapper } from './SpeechSampleWrapper/SpeechSampleWrapper';
import { TocWrapper } from './TocWrapper/TocWrapper';
import { ReadingWrapper } from './ReadingWrapper/ReadingWrapper';
import { SentenceBuildWrapper } from './SentenceBuildWrapper/SentenceBuildWrapper';
import { OpenQuestionWrapper } from './OpenQuestionWrapper/OpenQuestionWrapper';
import { SentenceCompletionInstructionWrapper } from './SentenceCompletionInstructionWrapper/SentenceCompletionInstructionWrapper';
import { SentenceCompletionWrapper } from './SentenceCompletionWrapper/SentenceCompletionWrapper';
import { ConversationInstructionWrapper } from './ConversationInstructionWrapper/ConversationInstructionWrapper';
import { ConversationWrapper } from './ConversationWrapper/ConversationWrapper';
import { PassageComprehensionInstructionWrapper } from './PassageComprehensionInstructionWrapper/PassageComprehensionInstructionWrapper';
import { PassageComprehensionWrapper } from './PassageComprehensionWrapper/PassageComprehensionWrapper';
import { DictationInstructionWrapper } from './DictationInstructionWrapper/DictationInstructionWrapper';
import { DictationWrapper } from './DictationWrapper/DictationWrapper';
import { EndTestView } from './EndTest/EndTestView';
import React, { useEffect, useRef, useState } from 'react';
import { ApiModel } from '../api/api.interfaces';
import { useNavigate } from 'react-router-dom';
import CircleLoader from '../components/CircleLoader/CircleLoader';
import { VfwStorage } from '../utils/Storage';
import { vfwRoutes } from '../vfw-routes';
import { PassageReconstructionInstructionWrapper } from './PassageReconstructionInstructionWrapper/PassageReconstructionInstructionWrapper';
import { PassageReconstructionWrapper } from './PassageReconstructionWrapper/PassageReconstructionWrapper';
import { StoryRetellInstructionWrapper } from './StoryRetellInstructionWrapper/StoryRetellInstructionWrapper';
import { StoryRetellWrapper } from './StoryRetellWrapper/StoryRetellWrapper';
import useMicrophoneStatus from '../hooks/useMicrophoneStatus';
import { useApi } from '../hooks/useApi';
import { isSpeakingTest } from '../utils/utils';
import { allChecksPassed } from '../utils/prechecks';
import { TypingInstructionWrapper } from './TypingInstructionWrapper/TypingInstructionWrapper';
import { TypingWrapper } from './TypingWrapper/TypingWrapper';
import { EmailInstructionWrapper } from './EmailInstructionWrapper/EmailInstructionWrapper';
import { EmailWrapper } from './EmailWrapper/EmailWrapper';
import { ReadAloudInstructionWrapper } from './ReadAloudInstructionWrapper/ReadAloudInstructionWrapper';
import { ReadAloudWrapper } from './ReadAloudWrapper/ReadAloudWrapper';
import { SpeakingSituationsInstructionWrapper } from './SpeakingSituationsInstructionWrapper';
import { SpeakingSituationsExerciseWrapper } from './SpeakingSituationsWrapper';
import { SummaryAndOpinionInstructionWrapper } from './SummaryAndOpinionInstructionWrapper/SummaryAndOpinionInstructionWrapper';
import { SummaryAndOpinionWrapper } from './SummaryAndOpinionWrapper/SummaryAndOpinionWrapper';
import { ResponseSelectionInstructionWrapper } from './ResponseSelectionInstructionWrapper/ResponseSelectionInstructionWrapper';
import { ResponseSelectionWrapper } from './ResponseSelectionWrapper/ResponseSelectionWrapper';
import { ReadingComprehensionInstructionWrapper } from './ReadingComprehensionInstructionWrapper/ReadingComprehensionInstructionWrapper';
import { ReadingComprehensionWrapper } from './ReadingComprehensionWrapper/ReadingComprehensionWrapper';
import { CURRENT_ITEM_TYPE } from '../api/api';
import { OppositesInstructionWrapper } from './OppositesInstructionWrapper/OppositesInstructionWrapper';
import { OppositesWrapper } from './OppositesWrapper/OppositesWrapper';
import { ShortStoryRetellingWrapper } from './ShortStoryRetellingWrapper/ShortStoryRetellingWrapper';
import { ShortStoryRetellingInstructionWrapper } from './ShortStoryRetellingInstructionWrapper/ShortStoryRetellingInstructionWrapper';
import { Logger, LogLevel } from '../api/Logger';
import { useCameraPermissions } from 'hooks/useCameraPermissions';
import MLWebcam from 'components/WebCam/WebCam';
import { useLogger } from 'hooks/useLogger';
import { serializeError } from '../api/api.utils';
import { useExitTest } from '../hooks/useExitTest';

export const TestWrapper = () => {
  const { refreshView } = useApi();
  const navigate = useNavigate();
  const [view, setView] = useState('');
  const [viewLoaded, setViewLoaded] = useState(false);
  const [model, setModel] = useState<ApiModel | undefined>(undefined);
  const microphoneStatus = useMicrophoneStatus();
  const itemId = useRef('');
  const [cameraKey, setCameraKey] = useState(0);
  const exitTest = useExitTest();

  const RefreshDetector = () => {
    useEffect(() => {
      const handleBeforeUnload = () => {
        VfwStorage.setItem('isRefresh', 'true');
        Logger.getInstance().pushEvent({
          level: LogLevel.INFO,
          controller: '',
          message: `The user refreshed the page during the test. - Redirected into ReadyToContinue wrapper.`,
          item: CURRENT_ITEM_TYPE,
        });
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    return <></>;
  };

  const { pushEvent } = useLogger();

  useEffect(() => {
    if (!viewLoaded && view && model) {
      console.log(
        `Page loaded on view: ${view}, item sequence: ${JSON.stringify(
          model.items[0].itemSequence
        )}`
      );
      pushEvent({
        item: view,
        level: LogLevel.INFO,
        message: `Page loaded on view: ${view}, item sequence: ${JSON.stringify(
          model.items[0].itemSequence
        )}`,
      });
      setViewLoaded(true);
    }
  }, [view, viewLoaded, model]);

  useEffect(() => {
    if (
      microphoneStatus === 'off' &&
      isSpeakingTest() &&
      VfwStorage.getItem('isRefresh') === 'false'
    ) {
      navigate(vfwRoutes.weCantHearYou);
    }
  }, [microphoneStatus, navigate]);

  useEffect(() => {
    document.addEventListener('AccessDeniedEvent', () => {
      pushEvent({
        level: LogLevel.WARN,
        message: `Test Wrapper AccessDeniedEvent`,
        item: 'Test Wrapper',
        eventType: 'WARN',
      });
      navigate(vfwRoutes.accessDenied);
    });

    document.addEventListener('ServerErrorEvent', () => {
      pushEvent({
        level: LogLevel.ERROR,
        message: `Test Wrapper ServerErrorEvent`,
        item: 'Test Wrapper',
        eventType: 'ERROR',
      });
      navigate(vfwRoutes.serverError);
    });
  }, []);

  useEffect(() => {
    try {
      if (!allChecksPassed()) {
        exitTest();
        return;
      }
      onRefresh();
    } catch (e) {
      console.error(e);
      pushEvent({
        level: LogLevel.ERROR,
        message: `Test Wrapper error: ${serializeError(e)}`,
        item: 'Test Wrapper',
        eventType: 'ERROR',
      });
      navigate(vfwRoutes.serverError);
    }
  }, []);

  useEffect(() => {
    itemId.current = model?.items?.[0]?.id ?? '';
  }, [model]);

  const cameraPermission = useCameraPermissions();

  const onRefresh = async () => {
    const refreshAttempt = VfwStorage.getItem('isRefresh') === 'true';
    const response = await refreshView();

    if (!refreshAttempt) {
      switchViewHandler(CURRENT_ITEM_TYPE, response);
    } else {
      navigate(vfwRoutes.readyToContinue); // if flag already exists, it means he refreshed the page - navigated to readyToContinue view.
    }
  };

  const switchViewHandler = (view: string, model?: ApiModel) => {
    setView(view);
    if (model) {
      setModel(model);
    }
  };

  const renderSwitch = () => {
    console.log('rendering: ' + view);
    switch (view) {
      case 'instructions_repeat': {
        return (
          <RepeatInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'repeat': {
        return <RepeatWrapper switchView={switchViewHandler} model={model} />;
      }
      case 'instructions_question': {
        return (
          <QuestionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'question': {
        return <QuestionWrapper switchView={switchViewHandler} model={model} />;
      }
      case 'instructions_reading': {
        return (
          <ReadingInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_sentencebuild': {
        return (
          <SentenceBuildInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_openquestion': {
        return (
          <OpenQuestionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'survey': {
        return <SurveyWrapper switchView={switchViewHandler} model={model} />;
      }
      case 'selfintro': {
        return (
          <SpeechSampleWrapper model={model} switchView={switchViewHandler} />
        );
      }
      case 'toc':
      case 'resumestatus': {
        return (
          <TocWrapper
            type={view}
            model={model}
            switchView={switchViewHandler}
          />
        );
      }
      case 'reading': {
        return <ReadingWrapper switchView={switchViewHandler} model={model} />;
      }
      case 'sentencebuild': {
        return (
          <SentenceBuildWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'openquestion': {
        return (
          <OpenQuestionWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'instructions_sentencecompletion': {
        return (
          <SentenceCompletionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'sentencecompletion': {
        return (
          <SentenceCompletionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_conversation': {
        return (
          <ConversationInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'conversation': {
        return (
          <ConversationWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'instructions_passagecomprehension': {
        return (
          <PassageComprehensionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'passagecomprehension': {
        return (
          <PassageComprehensionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_passagereconstruction': {
        return (
          <PassageReconstructionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'passagereconstruction': {
        return (
          <PassageReconstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_storyretelling': {
        return (
          <StoryRetellInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_shortstoryretelling': {
        return (
          <ShortStoryRetellingInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'storyretelling': {
        return (
          <StoryRetellWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'shortstoryretelling': {
        return (
          <ShortStoryRetellingWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_summaryandopinion': {
        return (
          <SummaryAndOpinionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'summaryandopinion': {
        return (
          <SummaryAndOpinionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_dictation': {
        return (
          <DictationInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'dictation': {
        return (
          <DictationWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'instructions_typing': {
        return (
          <TypingInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'typing': {
        return <TypingWrapper switchView={switchViewHandler} model={model} />;
      }
      case 'instructions_email': {
        return (
          <EmailInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'email': {
        return <EmailWrapper switchView={switchViewHandler} model={model} />;
      }
      case 'instructions_readaloud': {
        return (
          <ReadAloudInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'readaloud': {
        return (
          <ReadAloudWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'instructions_readingcomprehension': {
        return (
          <ReadingComprehensionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'readingcomprehension': {
        return (
          <ReadingComprehensionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_speakingsituations': {
        return (
          <SpeakingSituationsInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_responseselection': {
        return (
          <ResponseSelectionInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'speakingsituations': {
        return (
          <SpeakingSituationsExerciseWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'responseselection': {
        return (
          <ResponseSelectionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'instructions_opposite': {
        return (
          <OppositesInstructionWrapper
            switchView={switchViewHandler}
            model={model}
          />
        );
      }
      case 'opposite': {
        return (
          <OppositesWrapper switchView={switchViewHandler} model={model} />
        );
      }
      case 'endoftest': {
        return <EndTestView />;
      }
      default: {
        return <CircleLoader />;
      }
    }
  };

  const isVideoProctoringEnabled = VfwStorage.getItem('isTestProctored');

  const showWebCam =
    view !== 'toc' &&
    view !== 'resumestatus' &&
    view !== 'endoftest' &&
    view !== 'survey' &&
    view !== 'selfintro';

  const { startProctoringSession, getSessionInfo } = useApi();
  const [proctoringSessionState, setProctoringSessionState] = useState<
    'INITIAL' | 'STARTING' | 'STARTED'
  >('INITIAL');

  useEffect(() => {
    if (!isVideoProctoringEnabled) {
      setProctoringSessionState('STARTED');
      return;
    }

    if (proctoringSessionState === 'INITIAL') {
      setProctoringSessionState('STARTING');
      const isTestResumed = !!VfwStorage.getItem('isTestResumed');
      if (isTestResumed) {
        getSessionInfo()
          .then((res) => {
            const sessionKey = res;
            VfwStorage.setItem('proctoringSessionKey', sessionKey);
            setProctoringSessionState('STARTED');
          })
          .catch((e) => {
            pushEvent({
              level: LogLevel.ERROR,
              message: `Test Wrapper resume session error: ${serializeError(
                e
              )}`,
              item: 'Test Wrapper',
              eventType: 'ERROR',
            });
            navigate(vfwRoutes.serverError);
          });
      } else {
        const videoUID = VfwStorage.getItem('videoUID');
        if (videoUID === null) {
          pushEvent({
            level: LogLevel.ERROR,
            message: `Test Wrapper error: videoUID is null`,
            item: 'Test Wrapper',
            eventType: 'ERROR',
          });
          navigate(vfwRoutes.serverError);
          return;
        }
        if (VfwStorage.getItem('proctoringSessionKey') === null) {
          startProctoringSession(videoUID!)
            .then((res) => {
              const sessionKey = res;
              VfwStorage.setItem('proctoringSessionKey', sessionKey);
              setProctoringSessionState('STARTED');
            })
            .catch((e) => {
              pushEvent({
                level: LogLevel.ERROR,
                message: `Test Wrapper Start Proctoring Session Error: ${serializeError(
                  e
                )}`,
                item: 'Test Wrapper',
                eventType: 'ERROR',
              });
              navigate(vfwRoutes.serverError);
            });
        } else {
          setProctoringSessionState('STARTED');
          return;
        }
      }
    }
  }, [
    startProctoringSession,
    setProctoringSessionState,
    proctoringSessionState,
    isVideoProctoringEnabled,
  ]);

  useEffect(() => {
    if (isVideoProctoringEnabled) {
      if (!VfwStorage.getItem('isTestProctored')) {
        return;
      }

      if (cameraPermission === 'denied' || cameraPermission === 'error') {
        navigate(vfwRoutes.problemWithCamera);
      }
    }
  }, [cameraPermission, isVideoProctoringEnabled]);

  return proctoringSessionState === 'STARTED' ? (
    <>
      <RefreshDetector />
      {isVideoProctoringEnabled && showWebCam && (
        <MLWebcam
          key={cameraKey}
          itemId={itemId}
          onLoadingError={() => setCameraKey((prev) => prev + 1)} // Once key is updated due to an error, entire Webcam component will reinitialize.
        />
      )}
      {renderSwitch()}
    </>
  ) : (
    <CircleLoader />
  );
};
