import React from 'react';
import './Stepper.scss';
import { Text } from '@versant-monorepo/cade';
import { Trans } from 'react-i18next';

type Props = {
  currentStep: number;
  totalSteps: number;
};

export const STEPPER_CONTAINER_TEST_ID = 'vfw-stepper-container';
export const STEPPER_TEXT_TEST_ID = 'vfw-stepper-text';

export const Stepper = ({ currentStep, totalSteps }: Props) => {
  return (
    <div className="vfw-stepper" data-testid={STEPPER_CONTAINER_TEST_ID}>
      <Text level="small" weight="semibold" data-testid={STEPPER_TEXT_TEST_ID}>
        <Trans i18nKey="stepper.part" values={{ currentStep, totalSteps }} />
      </Text>
    </div>
  );
};
