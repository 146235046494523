import { SoundWaveData } from '../components/SoundWave/SoundWaveArea';

export const soundWave: SoundWaveData = [
  21,
  21,
  21,
  45,
  45,
  [60, -8],
  [60, 7],
  60,
  [35, 7],
  35,
  21,
  [21, 9],
  21,
  21,
  21,
];

export const SOUND_ANALYSIS_FREQUENCY_MS = 50;
export const MIN_BYTE_VOLUME = 10;
export const MIN_NUMBER_OF_SAMPLES = 14;
export const MIN_PERCENT_OF_VOICE_SAMPLES = 0.6;
export const SUCCESS_TIMEOUT = 3_000;

export const SPECIAL_MIC_IDS = ['communications', 'default'];

export interface Microphone {
  deviceId: string;
  label: string;
}

export type MicrophoneOptions =
  | {
      defaultId: string;
      microphones: Microphone[];
    }
  | { defaultId?: string; microphones: [] };

export const keepOnlyMicrophones = (
  devices: MediaDeviceInfo[]
): MediaDeviceInfo[] => devices.filter((d) => d.kind === 'audioinput');

export const splitIntoGroups = (
  devices: MediaDeviceInfo[]
): Map<string, MediaDeviceInfo[]> => {
  const groups = new Map<string, MediaDeviceInfo[]>();
  devices.forEach((d) =>
    groups.set(d.groupId, [...(groups.get(d.groupId) ?? []), d])
  );
  return groups;
};

export const defaultGroupId = (
  devices: MediaDeviceInfo[]
): string | undefined => {
  const defaultDevices = devices.filter((d) => d.deviceId === 'default');
  if (defaultDevices.length === 1) {
    return defaultDevices[0].groupId;
  }
  return undefined;
};

export const selectPreferredEntryFromGroup = (
  devices: MediaDeviceInfo[]
): MediaDeviceInfo => {
  if (!devices.length) {
    throw new Error(`Unexpected empty list of devices in a group`);
  }
  if (devices.length === 1) {
    return devices[0];
  }
  const filteredDevices = devices.filter(
    (d) => !SPECIAL_MIC_IDS.includes(d.deviceId)
  );
  if (filteredDevices.length) {
    return filteredDevices[0];
  }
  return devices[0];
};

export const microphoneOptionsList = (
  devices: MediaDeviceInfo[]
): MicrophoneOptions => {
  const microphones = keepOnlyMicrophones(devices);
  if (!microphones.length) {
    return { microphones: [] };
  }
  const groups = splitIntoGroups(microphones);
  const defaultGroup = defaultGroupId(microphones);
  const entries = [...groups.values()].map(selectPreferredEntryFromGroup);
  return {
    defaultId: defaultGroup
      ? entries.filter((e) => e.groupId === defaultGroup)[0].deviceId
      : entries[0].deviceId,
    microphones: entries.map(({ deviceId, label }) => ({ deviceId, label })),
  };
};
