import React, { forwardRef, useEffect, useRef } from 'react';
import './styles.scss';
import classNames from 'classnames';

type BaseCheckboxProps = React.InputHTMLAttributes<HTMLInputElement>;
export const CHECKBOX_TEST_ID = 'cade-checkbox';
export const CHECKBOX_LABEL_TEST_ID = 'cade-checkbox-label';

const BaseCheckbox = forwardRef<HTMLInputElement, BaseCheckboxProps>(
  ({ className, tabIndex = -1, ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      data-testid={CHECKBOX_TEST_ID}
      className={classNames('cade-checkbox__input', className)}
      type="checkbox"
      tabIndex={tabIndex}
    />
  )
);

export type CheckboxLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
const Label = forwardRef<HTMLLabelElement, CheckboxLabelProps>(
  ({ className, ...props }, ref) => (
    <label
      {...props}
      ref={ref}
      className={classNames('cade-checkbox__label', className)}
      data-testid={CHECKBOX_LABEL_TEST_ID}
    />
  )
);

export interface CheckboxProps extends BaseCheckboxProps {
  /** Attributes applied to the label element */
  labelProps?: CheckboxLabelProps;
}

export type CheckboxRef = {
  labelRef: HTMLLabelElement | null;
  checkboxRef: HTMLInputElement | null;
};

export const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  ({ children, disabled, labelProps, ...props }, ref) => {
    const labelRef = useRef<HTMLLabelElement>(null);
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (ref) {
        (ref as React.MutableRefObject<CheckboxRef>).current = {
          labelRef: labelRef.current,
          checkboxRef: checkboxRef.current,
        };
      }
    }, []);

    return (
      <Label
        ref={labelRef}
        {...labelProps}
        className={classNames({
          'cade-checkbox__label--disabled': disabled,
        })}
      >
        <BaseCheckbox ref={checkboxRef} {...props} disabled={disabled} />
        {children}
      </Label>
    );
  }
);
