import { ApiModel } from '../../api/api.interfaces';
import {
  CADE_CONTENT_TITLE_ID,
  StoryRetellInstruction,
} from '@versant-monorepo/cade';
import { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToStoryRetellInstruction } from '../../api/parsers/StoryRetell/StoryRetellInstruction';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const StoryRetellInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': () => onEnded(),
  });
  useFocus(CADE_CONTENT_TITLE_ID, [model]);
  const { pushEvent } = useLogger();

  const storyRetellInstructionProps = useParser(
    transformJSONToStoryRetellInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(storyRetellInstructionProps?.title);

  const onEnded = async () => {
    pushEvent({
      item: 'Story Retell',
      level: LogLevel.INFO,
      message: 'User clicked next on Story Retell Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
  };

  return (
    storyRetellInstructionProps && (
      <Template
        volumeSlider={true}
        role={'instruction'}
        footer={
          <Footer
            nextButtonVisible={true}
            onNext={() => setForceEnd(true)}
            role={'instruction'}
          />
        }
        header={
          <Header
            onExit={() => {
              pushEvent({
                item: 'Story Retell Instruction',
                level: LogLevel.INFO,
                message: 'User clicked exit on Story Retell Instruction',
              });
              eventHandler(exitEvent());
            }}
            currentStep={storyRetellInstructionProps.currentStep}
            totalSteps={storyRetellInstructionProps.totalSteps}
            title={storyRetellInstructionProps.title}
            role={'instruction'}
          />
        }
      >
        <div className="vfw-template__content-wrapper">
          <StoryRetellInstruction
            key={networkDependentKey(onlineStatus)}
            {...storyRetellInstructionProps}
            onEvent={eventHandler}
            forceEnd={forceEnd}
          />
        </div>
      </Template>
    )
  );
};
