function generateUniqueId() {
  let counter: number = 0;

  return function (prefix: string = ''): string {
    counter++;
    return prefix + counter;
  };
}

export const uniqueId = generateUniqueId();

export class Observable<T> {
  observers: Array<(data: T) => void> = [];

  subscribe(func: (data: T) => void) {
    this.observers.push(func);
  }

  unsubscribe(func: (data: T) => void) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify(data: T) {
    this.observers.forEach((observer) => observer(data));
  }
}

export function waitForDomElement(
  selector: string
): Promise<HTMLElement | null> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector<HTMLElement>(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector<HTMLElement>(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export const wait = async (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

let audioContextInstance: AudioContext;
export const getAudioContext = () => {
  if (audioContextInstance) {
    return audioContextInstance;
  }

  audioContextInstance = new AudioContext();
  return audioContextInstance;
};

export const testsWaitFor = async (milliseconds: number) =>
  jest.advanceTimersByTime(milliseconds);

// Simply makes timeout a promise
export function delay(
  callback: (resolve: (v?: unknown) => void) => void,
  time: number
) {
  return new Promise((resolve) => setTimeout(() => callback(resolve), time));
}

export function getAudioType() {
  if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
    return {
      fileType: 'audio/webm',
      mimeType: 'audio/webm;codecs=opus',
      extension: 'webm',
    };
  }

  return {
    fileType: 'audio/mp4',
    mimeType: 'audio/mp4',
    extension: 'mp4',
  };
}
