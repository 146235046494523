import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './styles.scss';
import classNames from 'classnames';
import {
  CADE_NEXT_BUTTON_ID,
  NextButton,
  Paragraph,
  SecondaryButton,
} from '@src/components';
import { ChecksHeader } from '@components/ChecksHeader';
import { ColorPalette } from '@src/colorPalette';
import { TroubleshotModal } from './TroubleshotModal';
import { ListeningStatus } from '@components/Listening/commons';
import {
  CadeEvent,
  failureEvent,
  successEvent,
  useEndedEvent,
} from '@utils/events';
import { ChecksBox } from '../components/ChecksBox';
import { Space } from '@components/Space';
import { Listening } from '@components/Listening';
import { CADE_LISTENING_BUTTON_ID } from '@components/Listening/Listening';
import { waitForDomElement } from '@src/utils';
import { Link } from '@components/base/Link';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Options = {
  value: number | string;
  label: string;
};

const YES_VALUE = 1;
const NO_VALUE = 0;

export type Props = {
  audioSrc: string;
  currentCheckingStep: number;
  totalCheckingSteps: number;
  options?: Options[];
  onEvent: (event: CadeEvent) => void;
};

const LABEL_FOR_CHOICES = 'cade-label-for-choices';

type SpeakerCheckStatus =
  | 'IDLE'
  | 'PLAYING'
  | 'CHOICE'
  | 'SUCCESS'
  | 'ERROR'
  | 'IDLE_AFTER_RETRY';

export const CADE_SPEAKER_CHECK_TROUBLESHOOTING_LINK_ID =
  'cade-speaker-check-troubleshooting-link';

export function SpeakerCheck({
  audioSrc,
  currentCheckingStep,
  totalCheckingSteps,
  options,
  onEvent,
}: Props) {
  const [status, setStatus] = useState<SpeakerCheckStatus>('IDLE');
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>();
  const [radioButtonsTouched, setRadioButtonsTouched] = useState(false);
  const [isRetried, setIsRetried] = useState(false);
  const { onClickSendEndEvent } = useEndedEvent(onEvent);
  const {
    i18n: { t, TranslationComponent },
  } = useContext(ConfigContext);

  const defaultOptions = [
    {
      label: t('speakerCheck.radioButtons.yesICan'),
      value: YES_VALUE,
    },
    {
      label: t('speakerCheck.radioButtons.noICant'),
      value: NO_VALUE,
    },
  ];

  const listeningState = useMemo((): ListeningStatus => {
    switch (status) {
      case 'CHOICE':
      case 'IDLE_AFTER_RETRY':
      case 'IDLE': {
        return 'INACTIVE';
      }

      case 'SUCCESS': {
        return 'COMPLETED';
      }

      default: {
        return status;
      }
    }
  }, [status]);

  useEffect(() => {
    if (
      status === 'IDLE_AFTER_RETRY' ||
      (selectedValue === undefined &&
        status === 'CHOICE' &&
        !radioButtonsTouched)
    ) {
      (async () => {
        const buttonId = `#${CADE_LISTENING_BUTTON_ID}`;
        const button = await waitForDomElement(buttonId);
        button?.focus();
      })();
    }
  }, [status, selectedValue, radioButtonsTouched]);

  const onClickLink = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpen(true);
  }, []);

  const onClickRetry = useCallback(() => {
    setStatus('IDLE_AFTER_RETRY');
    setIsRetried(true);
  }, []);

  const getSubtitle = useCallback(() => {
    switch (status) {
      case 'SUCCESS':
        return (
          <Paragraph
            level="large"
            className="cade-speaker-check__color-success"
          >
            {t('speakerCheck.subTitle.success')}
          </Paragraph>
        );
      case 'ERROR':
        return (
          <Paragraph level="large" className="cade-speaker-check__color-fail">
            {t('speakerCheck.subTitle.error.text')}{' '}
            <Link
              onClick={onClickLink}
              id={CADE_SPEAKER_CHECK_TROUBLESHOOTING_LINK_ID}
              className="cade-speaker-check__link"
            >
              {t('speakerCheck.subTitle.error.link')}
            </Link>
          </Paragraph>
        );
      default:
        return (
          <Paragraph level="large" id={LABEL_FOR_CHOICES}>
            <TranslationComponent
              i18nKey="systemCheck.content"
              values={{
                buttonName:
                  status === 'CHOICE' || status === 'IDLE_AFTER_RETRY'
                    ? t('speakerCheck.replay')
                    : t('speakerCheck.play'),
              }}
            />
          </Paragraph>
        );
    }
  }, [status]);

  const onAudioEnded = useCallback(() => {
    setStatus('CHOICE');
  }, []);

  const playAudio = useCallback(() => {
    setStatus('PLAYING');
  }, []);

  const disabled = useMemo(() => {
    if (status === 'CHOICE' || status === 'PLAYING') {
      return false;
    }

    return true;
  }, [status]);

  const radioButtonsOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSelectedValue(Number.parseInt(event.target.value)),
    []
  );
  const radioButtonsOnFocus = useCallback(
    () => setRadioButtonsTouched(true),
    []
  );

  const renderOptions = useCallback(
    ({ value, label }: Options, index: number) => {
      const radioId = `cade-speaker-check-option-${index}`;
      return (
        <div
          className="cade-speaker-check-radio__label"
          key={`${label}_${value}`}
        >
          <input
            onFocus={radioButtonsOnFocus}
            id={radioId}
            className="cade-speaker-check-radio__input-radio"
            onChange={radioButtonsOnChange}
            disabled={status === 'IDLE' || status === 'IDLE_AFTER_RETRY'}
            name="speaker-check"
            type="radio"
            value={value}
            aria-label={label}
          />
          <label
            className={'cade-typography-text__small'}
            htmlFor={radioId}
            aria-hidden={true}
          >
            {label}
          </label>
        </div>
      );
    },
    [disabled, status]
  );

  const onCloseModal = useCallback(() => setOpen(false), []);
  const nextButtonOnClick = useCallback(() => {
    switch (selectedValue) {
      case YES_VALUE: {
        setStatus('SUCCESS');
        onEvent(successEvent());
        break;
      }

      case NO_VALUE: {
        setStatus('ERROR');
        onEvent(failureEvent());
        break;
      }
    }
  }, [selectedValue]);

  const disableNextButton = !(selectedValue !== undefined && status !== 'IDLE');

  const borderColor =
    status === 'SUCCESS' ? ColorPalette.GREEN_ALERT : undefined;
  const speakerCheckContentCssClasses = classNames(
    'cade-speaker-check cade-speaker-check__content',
    {
      'cade-speaker-check__content--failure': status === 'ERROR',
    }
  );

  const panelCssClasses = classNames('cade-speaker-check__panel', {
    'cade-speaker-check__panel--success': status === 'SUCCESS',
    'cade-speaker-check__panel--failure': status === 'ERROR',
  });

  return (
    <div className={'cade-speaker-check__container'}>
      <Space
        margin={{ top: 8, bottom: 8 }}
        className="cade-speaker-check__hgroup"
      >
        <ChecksHeader
          headerText={t('speakerCheck.header.text')}
          title={t('speakerCheck.header.title')}
          currentStep={currentCheckingStep}
          totalSteps={totalCheckingSteps}
        />
      </Space>
      <ChecksBox>
        <div className={speakerCheckContentCssClasses}>
          <div>
            <div
              className="cade-speaker-check__sub-title-wrapper"
              aria-live={status === 'PLAYING' && isRetried ? 'off' : 'polite'}
            >
              {getSubtitle()}
            </div>
            <Space
              margin={{ left: 7 }}
              className="cade-speaker-check__listening-wrapper"
            >
              <Listening
                role="instruction"
                onClickPlay={playAudio}
                playButtonText={
                  status === 'CHOICE' || status === 'IDLE_AFTER_RETRY'
                    ? t('speakerCheck.replay')
                    : t('speakerCheck.play')
                }
                status={listeningState}
                border={borderColor}
                audioSrc={Array.isArray(audioSrc) ? audioSrc : [audioSrc]}
                onAudioEnded={onAudioEnded}
              />
            </Space>
            {status !== 'SUCCESS' && status !== 'ERROR' && (
              <fieldset
                className="cade-speaker-check-radio__container"
                aria-labelledby={LABEL_FOR_CHOICES}
              >
                {(options || defaultOptions).slice(0, 2).map(renderOptions)}
              </fieldset>
            )}
          </div>
          <div>
            {(status === 'SUCCESS' || status === 'ERROR') && (
              <div className={panelCssClasses}>
                {status === 'SUCCESS' ? (
                  <NextButton
                    id={CADE_NEXT_BUTTON_ID}
                    onClick={onClickSendEndEvent}
                  />
                ) : (
                  <SecondaryButton size="large" onClick={onClickRetry}>
                    {t('speakerCheck.replay')}
                  </SecondaryButton>
                )}
              </div>
            )}
          </div>
          {status !== 'SUCCESS' && status !== 'ERROR' && (
            <NextButton
              onClick={nextButtonOnClick}
              disabled={disableNextButton}
            >
              {t('speakerCheck.next')}
            </NextButton>
          )}
        </div>
      </ChecksBox>
      <TroubleshotModal open={open} onClose={onCloseModal} />
    </div>
  );
}
