import React, { useState } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import { ReadCard } from '@components/ReadCard';
import { ScriptLine } from '@utils/types';
import { CadeEvent } from '@utils/events';
import './styles.scss';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { OnPlayArgsCallback } from '@src/components/PlayAudio';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IConversationInstructionProps {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  listenPartDuration: number;
  startActorsAnimationOnAudioIndex?: number;
  scripts: ScriptLine[];
  onEvent: (event: CadeEvent) => void;
  forceEnd: boolean;
}

export const CONVERSATION_INSTRUCTION_TEST_IDS = {
  CONTAINER: 'cade-conversation-instruction-container',
  INSTRUCTION: 'cade-conversation-instruction-element',
  SUBTITLE: 'cade-conversation-instruction-subtitle',
};

export function ConversationInstruction({
  subTitle,
  instruction,
  audioSrc,
  listenPartDuration,
  startActorsAnimationOnAudioIndex = 0,
  scripts,
  onEvent,
  forceEnd,
}: IConversationInstructionProps) {
  const [exampleStatus, setExampleStatus] =
    useState<ListenRecordInstructionStatus>('INIT');
  const [startAnimation, setStartAnimation] = useState(false);

  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  function onPlay({ audioIndex }: OnPlayArgsCallback) {
    if (audioIndex === startActorsAnimationOnAudioIndex) {
      setStartAnimation(true);
    } else {
      setStartAnimation(false);
    }
  }

  return (
    <>
      <div
        className={'conversation-instruction-wrapper'}
        data-testid={CONVERSATION_INSTRUCTION_TEST_IDS.CONTAINER}
      >
        <div>
          <InstructionContainer>
            <Title
              centered
              id={CADE_CONTENT_TITLE_ID}
              level="large"
              data-testid={CONVERSATION_INSTRUCTION_TEST_IDS.SUBTITLE}
            >
              {subTitle}
            </Title>
            <Instruction
              data-testid={CONVERSATION_INSTRUCTION_TEST_IDS.INSTRUCTION}
            >
              {instruction}
            </Instruction>
          </InstructionContainer>
          <Box role="instruction" className="cade-margin-bottom-5">
            <ListenRecordInstructionContent
              status={exampleStatus}
              audioSrc={audioSrc}
              listenPartDuration={listenPartDuration}
              onPlayAudio={onPlay}
              onStatusChange={setExampleStatus}
              onEvent={onEvent}
              forceEnd={forceEnd}
            />
          </Box>
          {scripts && scripts.length > 0 && (
            <Box role="instruction">
              <ReadCard
                status={exampleStatus === 'LISTENING' ? 'ACTIVE' : 'FREEZE'}
                scripts={scripts}
                startAnimation={startAnimation}
                regexForBolding={'[A-Za-z 0-9]+?: '}
              />
            </Box>
          )}
        </div>
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            numOfCircles={6}
            currentValue={voiceLevel}
            listenMode={true}
          />
        </div>
      </div>
    </>
  );
}
