import { ApiModel } from '../../api/api.interfaces';
import {
  CADE_CONTENT_TITLE_ID,
  DictationInstruction,
} from '@versant-monorepo/cade';
import { useState } from 'react';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { transformJSONToDictationInstruction } from '../../api/parsers/Dictation/DictationInstruction';
import { useParser } from '../../hooks/useParser';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const DictationInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState(false);
  const { pushEvent } = useLogger();

  const { eventHandler, lastEvent } = useHandleAppEvent({
    'cade-ended-event': () => onEnded(),
  });
  useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);

  const dictationInstructionProps = useParser(
    transformJSONToDictationInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(dictationInstructionProps?.title);

  const onEnded = async () => {
    pushEvent({
      item: 'Dictation Instruction',
      level: LogLevel.INFO,
      message: 'User clicked next on Dictation Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
  };

  return (
    dictationInstructionProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'instruction'}
          footer={
            <Footer
              nextButtonVisible={true}
              onNext={() => setForceEnd(true)}
              role={'instruction'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Dictation Instruction',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Dictation Instruction',
                });
                eventHandler(exitEvent());
              }}
              currentStep={dictationInstructionProps.currentStep}
              totalSteps={dictationInstructionProps.totalSteps}
              title={dictationInstructionProps.subTitle}
              role={'instruction'}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <DictationInstruction
              key={networkDependentKey(onlineStatus)}
              {...dictationInstructionProps}
              onEvent={eventHandler}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
