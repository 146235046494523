import { VfwStorage } from './Storage';
import { isSpeakingTest } from './utils';

export const generateChecks = () => {
  let checks = [
    { id: 'system', passed: false },
    { id: 'bandwidth', passed: false },
    { id: 'microphone', passed: false },
    { id: 'speaker', passed: false },
    { id: 'backgroundNoise', passed: false },
    { id: 'setupSuccess', passed: false },
  ];

  if (!isSpeakingTest()) {
    checks = checks.filter(
      (check) => check.id !== 'microphone' && check.id !== 'backgroundNoise'
    );
  }

  if (VfwStorage.getItem('isTestProctored')) {
    checks.push(
      { id: 'consentPageBiometricData', passed: false },
      { id: 'takeASelfie', passed: false },
      { id: 'recordAVideo', passed: false },
      { id: 'setupSummary', passed: false }
    );
  }

  if (VfwStorage.getItem('isTestResumed')) {
    const skippedChecksOnResumedTest = [
      'recordAVideo',
      'setupSummary',
      'consentPageBiometricData',
    ];

    checks = checks.filter(
      (check) => !skippedChecksOnResumedTest.includes(check.id)
    );
  }

  VfwStorage.setItem('checks', checks);
};

export const passCheck = (checkId: string) => {
  const checks = VfwStorage.getItem('checks');
  if (!checks)
    throw new Error('Checks not found in Storage, cannot proceed further.');
  checks.map((check) => {
    if (check.id === checkId) {
      check.passed = true;
    }
    return check;
  });

  VfwStorage.setItem('checks', checks);
};

export const isCheckAvailable = (checkId: string): boolean => {
  const checks = VfwStorage.getItem('checks');
  if (!checks)
    throw new Error('Checks not found in Storage, cannot proceed further.');

  let passedFlag = true;

  for (const check of checks) {
    if (check.id === checkId) {
      return passedFlag;
    }

    passedFlag = passedFlag && check.passed;
  }

  return passedFlag;
};

export const allChecksPassed = (): boolean => {
  const checks = VfwStorage.getItem('checks');
  if (!checks)
    throw new Error('Checks not found in Storage, cannot proceed further.');

  return checks.every((check) => check.passed);
};

const isSafari = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.includes('safari') &&
    !userAgent.includes('chrome') &&
    !userAgent.includes('android')
  );
};

export const getVideoMimeType = (): 'video/mp4' | 'video/webm' => {
  return isSafari() ? 'video/mp4' : 'video/webm';
};
