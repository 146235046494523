import { useCallback, useRef } from 'react';
import { useBeep } from './useBeep';

export type CadeEndedEvent<Value> = {
  name: 'cade-ended-event';
  value?: Value;
};

export type CadeRecordingEndedEvent = {
  name: 'cade-recording-ended-event';
  value: Blob;
};

export type CadeNextEvent = {
  name: 'cade-next-event';
  value?:
    | string
    | Blob
    | {
        radioResponse: string;
        radioResponseId: string;
        inputResponse: string;
        inputQuestionResponseId: string;
      };
};

type CadeExitEvent = {
  name: 'cade-exit-event';
};

type CadeWeCantHearYouEvent = {
  name: 'cade-we-cant-hear-you-event';
  value?: string; // for example item type name
};

type CadeUserResumeSpeakingEvent = {
  name: 'cade-user-resume-speaking';
  value?: string; // same as above
};

type CadeClickLinkEvent = {
  name: 'cade-click-link-event';
};

type CadeChangeEvent = {
  name: 'cade-change-event';
  value: string;
};

type CadeTroubleshootClickEvent = {
  name: 'cade-troubleshoot-click-event';
};

type CadeContinueClickEvent = {
  name: 'cade-continue-click-event';
  value?: string;
};

type CadeSuccessEvent = {
  name: 'cade-success-event';
  value?: string;
};

export type CadeFailureEvent<T = any> = {
  name: 'cade-failure-event';
  details?: T;
};

type CadeAudioEndedEvent = {
  name: 'cade-audio-ended-event';
};

type CadeInputFocusEvent = {
  name: 'cade-input-focus-event';
};

type CadeEnableForceEndEvent = {
  name: 'cade-enable-force-end-event';
};

type CadeTimerEndedEvent = {
  name: 'cade-timer-ended-event';
};

export type CadeEvent =
  | CadeEndedEvent<unknown>
  | CadeNextEvent
  | CadeExitEvent
  | CadeWeCantHearYouEvent
  | CadeUserResumeSpeakingEvent
  | CadeClickLinkEvent
  | CadeChangeEvent
  | CadeTroubleshootClickEvent
  | CadeContinueClickEvent
  | CadeSuccessEvent
  | CadeFailureEvent
  | CadeInputFocusEvent
  | CadeAudioEndedEvent
  | CadeEnableForceEndEvent
  | CadeRecordingEndedEvent
  | CadeTimerEndedEvent;

export function audioEndedEvent(): CadeAudioEndedEvent {
  return {
    name: 'cade-audio-ended-event',
  };
}

export function exitEvent(): CadeExitEvent {
  return {
    name: 'cade-exit-event',
  };
}

export function nextEvent(value?: CadeNextEvent['value']): CadeNextEvent {
  const eventBody: CadeNextEvent = {
    name: 'cade-next-event',
  };

  if (value) {
    eventBody.value = value;
  }

  return eventBody;
}

export function endedEvent<V>(value?: V): CadeEndedEvent<V> {
  const eventBody: CadeEndedEvent<V> = {
    name: 'cade-ended-event',
  };

  if (value) {
    eventBody.value = value;
  }

  return eventBody;
}

export function recordingEndedEvent(
  value: CadeRecordingEndedEvent['value']
): CadeRecordingEndedEvent {
  const eventBody: CadeRecordingEndedEvent = {
    name: 'cade-recording-ended-event',
    value: value,
  };

  return eventBody;
}

export function clickLinkEvent(): CadeClickLinkEvent {
  return {
    name: 'cade-click-link-event',
  };
}

export function changeEvent(value: string): CadeChangeEvent {
  return {
    name: 'cade-change-event',
    value,
  };
}

export function continueClickEvent(value?: string): CadeContinueClickEvent {
  return {
    name: 'cade-continue-click-event',
    value,
  };
}

export function troubleshootClickEvent(): CadeTroubleshootClickEvent {
  return {
    name: 'cade-troubleshoot-click-event',
  };
}

export function successEvent(value?: string): CadeSuccessEvent {
  return {
    name: 'cade-success-event',
    value,
  };
}

export function failureEvent<T>(args?: { details?: T }): CadeFailureEvent<T> {
  return {
    name: 'cade-failure-event',
    ...args,
  };
}

export function weCantHearYouEvent(): CadeWeCantHearYouEvent {
  return {
    name: 'cade-we-cant-hear-you-event',
  };
}

export function userResumeSpeakingEvent(): CadeUserResumeSpeakingEvent {
  return {
    name: 'cade-user-resume-speaking',
  };
}

export function cadeInputFocusEvent(): CadeInputFocusEvent {
  return {
    name: 'cade-input-focus-event',
  };
}

export function enableForceEndEvent(): CadeEnableForceEndEvent {
  return {
    name: 'cade-enable-force-end-event',
  };
}

export function timerEndedEvent(): CadeTimerEndedEvent {
  return {
    name: 'cade-timer-ended-event',
  };
}

/**
 * Hook ensures we call ended only once
 * Also it ensures we call beep whenever we send this event
 */
export function useEndedEvent<V = undefined>(onEvent: (e: CadeEvent) => void) {
  const isEnded = useRef(false);
  const beep = useBeep();

  const sendEndEvent = useCallback(
    async (data?: V) => {
      if (isEnded.current) {
        return;
      }
      isEnded.current = true;
      await beep.playNextTone();
      onEvent(endedEvent(data));
    },
    [onEvent, beep]
  );

  const onClickSendEndEvent = useCallback(() => {
    sendEndEvent();
  }, [sendEndEvent]);

  return {
    sendEndEvent,
    onClickSendEndEvent,
  };
}
