import { FC, SVGProps, useEffect, useRef } from 'react';
import {
  SoundWaveArea,
  SoundWaveData,
} from '@components/SoundWave/SoundWaveArea';
import { ColorPalette } from '@src/colorPalette';
import './styles.scss';
import { Paragraph } from '@components/typography/Paragraph';
import { Space } from '@components/Space';

const emptySoundWave: SoundWaveData = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];

export const recordingStates = [
  'EMPTY',
  'ACTIVE',
  'INACTIVE',
  'RECORDING',
  'ERROR',
  'INACTIVE_BUTTON',
  'SUCCESS',
] as const;

export type RecordingState = (typeof recordingStates)[number];

export function isRecordingActive(recordingStatus: RecordingState) {
  const activeStatuses: RecordingState[] = ['ACTIVE', 'RECORDING'];
  return activeStatuses.includes(recordingStatus);
}

export interface RecordingProps extends SVGProps<SVGSVGElement> {
  state: RecordingState;
  soundWaveData: SoundWaveData;
  defaultSoundWaveData: SoundWaveData;
  animate?: boolean;
  animateDuration?: number;
  onBeginCheck?: () => void;
  buttonText?: string;
  /** A focus set on a button */
  focus?: boolean;
}

const barColorsByState = {
  EMPTY: '',
  ACTIVE: ColorPalette.HOVER_BLUE.toString(),
  RECORDING: ColorPalette.HOVER_BLUE.toString(),
  ERROR: ColorPalette.RED_WARNING.toString(),
  INACTIVE: ColorPalette.DISABLED_GRAY.toString(),
  INACTIVE_BUTTON: ColorPalette.DISABLED_GRAY.toString(),
  SUCCESS: ColorPalette.HOVER_BLUE.toString(),
} as const;

const backgroundColorByState = {
  EMPTY: '',
  ACTIVE: ColorPalette.BLUE_MEDIUM.toString(),
  RECORDING: ColorPalette.BLUE_MEDIUM.toString(),
  ERROR: ColorPalette.BLUE_MEDIUM.toString(),
  INACTIVE: ColorPalette.DISABLED_GRAY.toString(),
  INACTIVE_BUTTON: ColorPalette.DISABLED_GRAY.toString(),
  SUCCESS: ColorPalette.GREEN_ALERT.toString(),
} as const;

// width: 427 height: 139
export const SpeakingMeter: FC<RecordingProps> = ({
  state,
  soundWaveData,
  defaultSoundWaveData,
  animate,
  animateDuration = 4_000,
  onBeginCheck = () => {},
  width = 431,
  buttonText = 'Begin check',
  focus,
  ...rest
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const sw = soundWaveData.length
    ? soundWaveData
    : new Array(defaultSoundWaveData?.length).fill(0);

  useEffect(() => {
    if (focus && state === 'INACTIVE_BUTTON') {
      buttonRef.current?.focus();
    }
  }, [buttonRef, state, focus]);

  function getSoundWaveDataByState() {
    switch (state) {
      case 'SUCCESS':
        return defaultSoundWaveData;
      case 'RECORDING':
        return sw;
      case 'EMPTY':
      case 'INACTIVE':
      case 'ACTIVE':
      case 'ERROR':
      case 'INACTIVE_BUTTON':
        return emptySoundWave;
      default:
        return defaultSoundWaveData;
    }
  }

  const content = (
    <div className="recording-container">
      <svg
        aria-hidden={true}
        data-testid="recording-svg"
        viewBox="0 0 427 139"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        {...rest}
      >
        {(state === 'ACTIVE' || state === 'RECORDING' || state === 'ERROR') && (
          <g filter="url(#filter)" id="g346">
            <path
              d="m 388,19.5 v 46.5864 c 0,3.1017 1.439,6.0278 3.896,7.9211 L 417.5,93.7371 H 398 c -5.523,0 -10,4.4772 -10,10 V 120 c 0,5.523 -4.477,10 -10,10 H 19.5 c -5.5228,0 -10,-4.477 -10,-10 V 19.5 c 0,-5.5228 4.4771,-10 10,-10 H 378 c 5.523,0 10,4.4772 10,10 z"
              fill={ColorPalette.SELECTED_BLUE.toString()}
            />
          </g>
        )}
        <path
          d="m 388,19.5 v 46.5864 c 0,3.1 1.439,6 3.896,7.9211 l 20.953,16.1454 c 1.5,1.1651 0.688,3.5842 -1.221,3.5842 H 398 c -5.523,0 -10,4.4772 -10,10 V 120 c 0,5.523 -4.477,10 -10,10 H 19.5 c -5.5228,0 -10,-4.477 -10,-10 V 19.5 c 0,-5.5228 4.4771,-10 10,-10 H 378 c 5.523,0 10,4.4772 10,10 z"
          fill={backgroundColorByState[state]}
        />
        {animate ? (
          <SoundWaveArea
            width={343}
            leftPadding={31.5}
            soundbarColor={barColorsByState[state]}
            left={26.5}
            soundWaveData={getSoundWaveDataByState()}
            animateColor={ColorPalette.DEFAULT_BLUE.toString()}
            animateTimeMs={animateDuration}
            id={'recording'}
          />
        ) : (
          <SoundWaveArea
            width={343}
            leftPadding={31.5}
            color={
              state === 'INACTIVE' || state === 'INACTIVE_BUTTON'
                ? ColorPalette.LIGHT_GRAY
                : ColorPalette.WHITE
            }
            soundbarColor={barColorsByState[state]}
            left={26.5}
            soundWaveData={getSoundWaveDataByState()}
            id={'recording'}
          />
        )}

        <defs>
          <filter
            id="filter"
            x={0.5}
            y={0.5}
            width={426}
            height={138.5}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood
              floodOpacity="0"
              result="BackgroundImageFix"
              id="feFlood356"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={4.5}
              result="effect1_foregroundBlur_6433_49576"
            />
          </filter>
        </defs>
      </svg>
      {state === 'INACTIVE_BUTTON' && (
        <button
          ref={buttonRef}
          onClick={onBeginCheck}
          tabIndex={0}
          onBlur={() => document.getElementById('nextButton')?.focus()}
          data-testid="recording-button"
          className="begin-check-button"
        >
          <Paragraph level="small" weight="bold" className="begin-check-text">
            {buttonText}
          </Paragraph>
        </button>
      )}
    </div>
  );

  const noContent = <Space margin={{ top: 1 }} className="recording-empty" />;

  return <>{state === 'EMPTY' ? noContent : content}</>;
};
