import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  isTimerFinished as isTimerStatusFinished,
  Timer,
  TIMER_STATUS,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import {
  Input,
  INPUT_TEXT_STATES,
  InputTextStates,
} from '@components/base/Input';
import './styles.scss';
import { QuestionCounter } from '@components/QuestionCounter';
import { useFocusNextButton } from '@utils/useFocusNextButton';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { CadeEvent, changeEvent, useEndedEvent } from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Space } from '@src/components';
import { uniqueId } from '@src/utils';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  count: number;
  total: number;
  timeLeft: number;
  subTitle: string;
  text: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd: boolean;
  boxLabel?: string;
  instruction?: string;
};

export const getRegExp = (keys: string[]) => {
  const regexp = keys.map((key) => `{${key}}+?`).join('|');
  return new RegExp(regexp);
};

const replaceJSX = (
  string: string,
  replacement: { [key: string]: ReactNode }
) => {
  const keys = Object.keys(replacement);
  return string
    .split(getRegExp(keys))
    .map((item, i) => [item, replacement[keys[i]]])
    .flat();
};

export function SentenceCompletionExercise({
  count,
  timeLeft,
  total,
  subTitle,
  text,
  onEvent,
  forceEnd,
  boxLabel = 'Complete the sentence.',
  instruction,
}: Props) {
  const [inputState, setInputState] = useState<InputTextStates>(
    INPUT_TEXT_STATES.DEFAULT
  );
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.IN_PROGRESS
  );
  const [currentText, setCurrentText] = useState('');
  const isTimerFinished = isTimerStatusFinished(timerStatus);

  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent<string>(onEvent);

  const { focusNextButton } = useFocusNextButton({
    elementId:
      inputState === 'COMPLETED' || inputState === 'ERROR'
        ? 'instruction'
        : 'input',
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent(currentText);
    }
  }, [forceEnd]);

  const onTimerStatusChange = useCallback(
    (status: TimerStatus) => {
      setTimerStatus(status);
      if (isTimerStatusFinished(status)) {
        setInputState(INPUT_TEXT_STATES.COMPLETED);
        delay.set(() => {
          sendEndEvent(currentText);
        });
        focusNextButton();
      }
    },
    [currentText]
  );

  const onInstructionBlur = () => {
    if (inputState === INPUT_TEXT_STATES.COMPLETED) {
      focusNextButton();
    }
  };

  const inputKey = useMemo(() => uniqueId('textinput-'), []);
  const addInput = (text: string) => {
    const textinput = (
      <>
        <label htmlFor="input" className="sr-only">
          Complete sentence
        </label>
        <Input.Text
          key={inputKey}
          ref={inputRef}
          ariaDescribedby={'inputLabel'}
          state={inputState}
          className={'sentence-completion-exercise__input'}
          id="input"
          disabled={inputState === INPUT_TEXT_STATES.COMPLETED}
          onChange={(evt) => {
            setCurrentText((evt.target as HTMLInputElement).value);
            onEvent(changeEvent((evt.target as HTMLInputElement).value));
          }}
          autoFocus
        />
      </>
    );

    return (
      <div className="sentence-completion-exercise__content-with-input">
        {replaceJSX(text, { textinput })}
      </div>
    );
  };

  return (
    <div className="sentence-build">
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level="small">
          {instruction ?? t('sentenceCompletion.exercise.instruction')}
        </Instruction>
      </InstructionContainer>
      <div className="sentence-completion-exercise__upper-section">
        <QuestionCounter
          count={count}
          total={total}
          title={t('sentenceCompletion.exercise.questionCountLabel')}
        />
        <div className="sentence-completion-exercise__timer">
          <TimerMessage
            show={isTimerFinished}
            insideClassName="cade-margin-right-3"
          />
          <Timer
            time={timeLeft}
            timerStatus={timerStatus}
            onChangeStatus={onTimerStatusChange}
          />
        </div>
      </div>
      <Box role="exercise" className="sentence-build__content">
        <Space
          margin={{ bottom: 2 }}
          className="sentence-build__content-header"
        >
          <Paragraph level={'large'} onBlur={onInstructionBlur}>
            {boxLabel}
          </Paragraph>
        </Space>
        <Space padding={7} className="sentence-completion-exercise__input-box">
          {addInput(text)}
          <span aria-hidden={true} className={'sr-only'} id={'inputLabel'}>
            {text}
          </span>
        </Space>
      </Box>
    </div>
  );
}
