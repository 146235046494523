import { FC, useEffect, useMemo, useRef } from 'react';
import { SoundBar } from './SoundBar';

export type SoundWaveData = (number | [number, number])[];

interface ISoundWaveAreaProps {
  readonly width: number;
  readonly leftPadding: number;
  readonly soundbarColor?: string;
  readonly animateColor?: string;
  readonly animateTimeMs?: number;
  readonly color?: string;
  readonly left: number;
  readonly soundWaveData: SoundWaveData;
  readonly backgroundColor?: string;
  readonly id: string;
  readonly isWaveBar?: boolean;
  readonly onAnimationEnded?: any;
}

const rectanglePath = (left: number, width: number) =>
  `M ${left},35 v 70 c 0,5.5 4.5,10 10,10 h ${
    width - 20
  } c 5.5,0 10,-4.5 10,-10 V 35 c 0,-5.5 -4.5,-10 -10,-10 h ${-(
    width - 20
  )} c -5.5,0 -10,4.5 -10,10 z`;

export const SoundWaveArea: FC<ISoundWaveAreaProps> = ({
  width,
  leftPadding,
  color = '#FFFFFF',
  left,
  soundWaveData,
  soundbarColor,
  animateColor,
  animateTimeMs,
  id,
  onAnimationEnded,
}) => {
  const animate = useRef<SVGAnimateElement>(null);

  useEffect(() => {
    if (animateColor) {
      animate.current?.beginElement?.();
    }

    return () => {
      animate.current?.beginElementAt?.(0);
    };
  }, [animateColor, animate.current]);

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const animationWidth = useMemo(() => {
    const w = (soundWaveData.length - 1) * 18 + 10;
    return w > 0 ? w : 0;
  }, [soundWaveData]);

  return (
    <g>
      {soundbarColor && (
        <rect
          x={left + leftPadding}
          y={31}
          width={animationWidth}
          height={78}
          fill={soundbarColor}
        />
      )}
      {animateTimeMs && (
        <rect
          x={left + leftPadding}
          y={31}
          width={animationWidth}
          height={78}
          fill={animateColor}
        >
          <animate
            ref={animate}
            attributeName="width"
            from={0}
            to={animationWidth}
            // hack for cut a little bit animation time to be synchronized with playing audio
            // in general there is some delay between starting audio and svg animation, and this is strictly related to animation
            // cause difference between playing audio and line 44 in this file is around 2-3ms
            // it is not related to duration itself and moment when duration is calculated because sometimes (without below hack)
            // the same duration gives different results on wave (sometimes animation will end, sometimes not)
            // also added small hack for Safari browser, since this browser play audio with some delay (this is known Safari issue)
            dur={`${animateTimeMs - 250 + (isSafari() ? 500 : 0)}ms`}
            restart="always"
            onEnded={onAnimationEnded?.()}
          />
        </rect>
      )}
      <mask id={`mask${id}`}>
        <path fill="white" d={rectanglePath(left, width)} />
        {soundWaveData
          .map((h) => [h, 0].flat())
          .map(([h, o], i) => (
            <SoundBar
              key={i}
              xPos={left + leftPadding + 5 + i * 18}
              height={h}
              offset={o}
            />
          ))}
      </mask>
      <path
        mask={`url(#mask${id})`}
        fill={color}
        d={rectanglePath(left, width)}
      />
    </g>
  );
};
