import { Modal } from '@components/Modal';
import { Paragraph } from '@components/typography/Paragraph';
import './styles.scss';
import { CircularProgress } from '@components/CircularProgress';
import { Space } from '@components/Space';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { useContext } from 'react';

export function ConnectionModal({ open, ...props }: { open: boolean }) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  return (
    <Modal
      {...props}
      title={t('connectionModal.title')}
      open={open}
      exitButton={false}
    >
      <Space margin={{ top: 2 }} className="connection-modal__container">
        <CircularProgress />
        <Paragraph className="cade-margin-top-6" weight="bold">
          {t('connectionModal.content')}
        </Paragraph>
      </Space>
    </Modal>
  );
}
