import { PrimaryButton } from '@versant-monorepo/cade';
import alertIcon from './warning-icon.svg';
import './styles.scss';
import { VfwStorage } from '../../utils/Storage';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useExitTest } from '../../hooks/useExitTest';

export const AccessDeniedWrapper = () => {
  const { t } = useTranslation();
  const exitTest = useExitTest();
  useSetDocumentTitle(t('accessDeniedWrapper.documentTitle'));

  return (
    <>
      <div className="vfw-error">
        <div className="vfw-error__header"></div>
        <div className="vfw-error__content">
          <h1 className="vfw-error__title">{t('accessDeniedWrapper.h1')}</h1>
          <div className="vfw-error__description">
            <div className="vfw-error__description--left">
              <img src={alertIcon} alt="Alert icon" />
            </div>
            <div className="vfw-error__description--right">
              <p className="vfw-error--paragraph-bold">
                {t('accessDeniedWrapper.p')}
              </p>
              <p>{t('accessDeniedWrapper.p2')}</p>
            </div>
          </div>
        </div>
        <div className="vfw-error__blank"></div>
        <div className="vfw-error__footer">
          <p className="vfw-error--paragraph-bold">
            {VfwStorage.getItem('testName')}
          </p>
          <PrimaryButton
            error={true}
            onClick={() => {
              exitTest();
            }}
          >
            {t('accessDeniedWrapper.exit')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
