import './ExitButton.scss';
import exit_icon from '../icons/exit_button.svg';
import { TextButton } from './TextButton';
import React, { PropsWithChildren } from 'react';

type ExitButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren;

export function ExitButton({ children, ...props }: ExitButtonProps) {
  return (
    <TextButton icon={exit_icon} {...props}>
      {children}
    </TextButton>
  );
}
