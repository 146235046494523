import { ReactNode } from 'react';
import './styles.scss';

interface InstructionContainerProps {
  children: ReactNode;
}

export function InstructionContainer({ children }: InstructionContainerProps) {
  return <div className="instruction__container">{children}</div>;
}
