import { RecordingState, SpeakingMeter } from './SpeakingMeter';
import { useRecording } from './useRecording';
import {
  CadeEvent,
  userResumeSpeakingEvent,
  weCantHearYouEvent,
} from '@utils/events';
import { useEffect, useState } from 'react';

type Props = {
  state: RecordingState;
  width: number;
  timeouts: {
    /** Indicating allowable time for the user when speaking hasn't started */
    initial: number;
    /** Indicating a leftover time when a user has ended speaking to the end of a given exercise */
    ending: number;
    /** Indicating the whole time allowed for speaking by the user */
    speaking: number;
  };
  /** Built-io event system provided by CADE */
  onEvent?: (event: CadeEvent) => void;
  handleFinishRecording?: (recordedData: Blob) => void;
  setWarningVisible?: (warningVisible: boolean) => void;
};

export function Recording({
  state,
  timeouts,
  onEvent = () => {},
  handleFinishRecording = () => {},
  setWarningVisible = () => {},
  width,
}: Props) {
  const { startRecording, stopRecording, soundFrequency } = useRecording({
    handleFinishRecording: (recordedData) => {
      handleFinishRecording(recordedData);
    },
    timeouts: {
      initialSilence: timeouts.initial,
      endingSilence: timeouts.ending,
      max: timeouts.speaking,
    },
    onTimeout: (timeoutKind) => {
      switch (timeoutKind) {
        case 'initial_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'cant_hear_you_silence_timeout':
          setWarningVisible(true);
          onEvent(weCantHearYouEvent());
          break;
        case 'ending_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'max_time_timeout':
          stopRecording();
          setWarningVisible(false);
          break;
        case 'cant_hear_you_silence_timeout_canceled':
          setWarningVisible(false);
          onEvent(userResumeSpeakingEvent());
          break;
      }
    },
  });

  const [innerState, setInnerState] = useState<RecordingState | undefined>(
    undefined
  );

  useEffect(() => {
    if (state === 'RECORDING') {
      startRecording();
    } else {
      setInnerState(undefined);
      stopRecording();
    }
  }, [state]);

  useEffect(() => {
    if (state === 'RECORDING') {
      if (soundFrequency.every((val) => val < 10)) {
        setInnerState('ACTIVE');
      } else {
        setInnerState(undefined);
      }
    }
  }, [soundFrequency]);

  return (
    <SpeakingMeter
      state={innerState || state}
      soundWaveData={soundFrequency}
      defaultSoundWaveData={new Array(15).fill(0)}
      width={width}
    />
  );
}
