import './styles.scss';
import React, { useState } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import { CadeEvent } from '@utils/events';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  listenPartDuration: number;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
};

export const SPEAKING_SITUATIONS_INSTRUCTION_TEST_IDS = {
  WRAPPER: 'cade-speaking-situations-instruction-wrapper',
  SUBTITLE: 'cade-speaking-situations-instruction-subtitle',
  INSTRUCTION: 'cade-speaking-situations-instruction-element',
};

export function SpeakingSituationsInstruction({
  subTitle,
  instruction,
  audioSrc,
  listenPartDuration,
  onEvent,
  forceEnd = false,
}: Props) {
  const [status, setStatus] = useState<ListenRecordInstructionStatus>('INIT');
  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  return (
    <div
      className="speaking-situations-instruction-wrapper"
      data-testid={SPEAKING_SITUATIONS_INSTRUCTION_TEST_IDS.WRAPPER}
    >
      <InstructionContainer>
        <Title
          centered
          id={CADE_CONTENT_TITLE_ID}
          level="large"
          data-testid={SPEAKING_SITUATIONS_INSTRUCTION_TEST_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction
          data-testid={SPEAKING_SITUATIONS_INSTRUCTION_TEST_IDS.INSTRUCTION}
        >
          {instruction}
        </Instruction>
      </InstructionContainer>
      <Box role="instruction" className="cade-margin-bottom-5">
        <ListenRecordInstructionContent
          status={status}
          audioSrc={audioSrc}
          listenPartDuration={listenPartDuration}
          onStatusChange={setStatus}
          onEvent={onEvent}
          forceEnd={forceEnd}
        />
      </Box>
      <div className="voice-level-container">
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
