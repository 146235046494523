type AnyObject = Record<string, any>;

export function keysInFirstNotInSecond(
  obj1: AnyObject,
  obj2: AnyObject,
  parentKey: string = ""
): string[] {
  const differences: string[] = [];

  const keysObj1 = Object.keys(obj1);
  const keysObj2 = new Set(Object.keys(obj2));

  keysObj1.forEach((key) => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (!keysObj2.has(key)) {
      differences.push(fullKey);
    } else if (isObject(obj1[key]) && isObject(obj2[key])) {
      differences.push(
        ...keysInFirstNotInSecond(obj1[key], obj2[key], fullKey)
      );
    } else if (!isEmpty(obj1[key]) && isEmpty(obj2[key])) {
      differences.push(fullKey);
    } else if (typeof obj1[key] !== typeof obj2[key]) {
      differences.push(`${fullKey} (mismatch type)`);
    }
  });

  return differences;
}

function isObject(obj: any): boolean {
  return obj !== null && typeof obj === "object" && !Array.isArray(obj);
}

function isEmpty(value: any): boolean {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0)
  );
}
