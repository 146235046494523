import { DualPaneLayout } from '../base/DualPaneLayout';
import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ReadCardSide, ReadCardSideProps } from './ReadCardSide';
import { ScriptLine } from '@utils/types';

type Props = {
  status: 'FREEZE' | 'ACTIVE';
  scripts: ScriptLine[];
  /** A slot placed on the right side */
  placeholderElement?: React.ReactNode;
  startAnimation?: boolean;
  regexForBolding?: string;
};

export const READ_CARD_CONTAINER_TEST_ID = 'cade-read-card-container';

export function ReadCard({
  status,
  scripts,
  placeholderElement,
  startAnimation = true,
  regexForBolding,
}: Props) {
  const [leftSide, setLeftSide] = useState<ReadCardSideProps>();
  const [rightSide, setRightSide] = useState<ReadCardSideProps | null>();
  const timeouts = useRef<ReturnType<typeof setTimeout>[]>([]);

  function setupForActive() {
    const first = scripts[0];
    setLeftSide({ script: first, status: 'ONLY_HEADER' });

    const second = scripts[1];
    if (second.side === 'right') {
      setRightSide({ script: second, status: 'ONLY_HEADER' });
    } else {
      setRightSide(null);
    }

    if (!startAnimation) {
      return;
    }

    scripts.forEach((scriptLine, index) => {
      timeouts.current.push(
        setTimeout(() => {
          if (scriptLine.side === 'left') {
            setLeftSide({ script: scriptLine, status: 'BOLDED' });
            if (scripts[index + 1].side === 'right') {
              setRightSide({
                script: scripts[index + 1],
                status: 'ONLY_HEADER',
              });
            }
          } else {
            setRightSide({ script: scriptLine, status: 'BOLDED' });
          }
          timeouts.current.push(
            setTimeout(() => {
              scriptLine.side === 'left'
                ? setLeftSide({ script: scriptLine, status: 'NORMAL' })
                : setRightSide({ script: scriptLine, status: 'NORMAL' });
            }, scriptLine.to - scriptLine.from)
          );
        }, scriptLine.from)
      );
    });
  }

  function setupForFreeze() {
    const first = scripts[0];
    setLeftSide({ script: first, status: 'ONLY_HEADER_FREEZE' });

    const second = scripts[1];
    if (second.side === 'right') {
      setRightSide({ script: second, status: 'ONLY_HEADER_FREEZE' });
    } else {
      setRightSide(null);
    }
  }

  useEffect(() => {
    timeouts.current.forEach((t) => clearTimeout(t));
    timeouts.current = [];
    if (status === 'ACTIVE') {
      setupForActive();
    } else if (status === 'FREEZE') {
      setupForFreeze();
    }
  }, [status, startAnimation]);

  return (
    <div
      className="read-card__container"
      aria-hidden={true}
      data-testid={READ_CARD_CONTAINER_TEST_ID}
    >
      <div className="read-card__placeholder">{placeholderElement}</div>
      <DualPaneLayout
        leftPane={
          <ReadCardSide {...leftSide} regexForBolding={regexForBolding} />
        }
        rightPane={<>{rightSide && <ReadCardSide {...rightSide} />}</>}
      />
    </div>
  );
}
