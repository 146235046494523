import React, {
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import FocusTrap from 'focus-trap-react';
import './styles.scss';
import { TagType, Title } from '@components/typography/Title';
import { WarningIcon } from '@icons/WarningIcon';
import ReactDOM from 'react-dom';
import { ExitButton, SecondaryButton } from '../base/Button';
import { ConfigContext } from '@context/CadeConfigProvider';
import { Space } from '@components/Space';
import icon_secondary_default from '../base/Button/icons/icon_secondary_default.svg';

export type Props = PropsWithChildren<{
  onClose?(): void;
  /** The content of the title section  */
  title?: ReactNode[] | ReactNode;
  /** Describes what level of Header should be applied */
  titleType?: TagType;
  open?: boolean;
  /** The content of the footer section  */
  footer?: ReactNode[] | ReactNode;
  /** The position of the modal relative to the browser's window */
  position?: 'center' | 'start';
  /** Using a boolean value causes utilizing the built-in exit button or passing a custom exit button as a node */
  exitButton?: ReactNode[] | ReactNode | boolean;
}>;

export const MODAL_TEST_ID = 'cade-modal';
export const MODAL_TITLE_TEST_ID = 'cade-modal-title';
export const MODAL_CONTENT_TEST_ID = 'cade-modal-content';
export const MODAL_ERROR_CLOSE_BUTTON_TEST_ID = 'cade-button';
export const MODAL_ERROR_TITLE = 'Error headline';
export const MODAL_TROUBLESHOOTING_TITLE = 'Troubleshooting tips';

export function Modal({
  onClose,
  title,
  children,
  open,
  footer,
  position = 'center',
  titleType,
  exitButton,
}: Props) {
  // TODO: temporary fix, this should be configurable by some kind of provider
  const configContext = useContext(ConfigContext);
  const rootId = configContext.rootElementId;
  const stopPropagation = useCallback(
    (event: MouseEvent) => event.stopPropagation(),
    []
  );
  const titleRef = useRef<HTMLHeadingElement>(null);
  const fakeFocusHandlerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const root = document.body.querySelector(
      rootId as keyof HTMLElementTagNameMap
    );

    if (!open) {
      root?.removeAttribute('aria-hidden');
      return;
    }

    root?.setAttribute('aria-hidden', 'true');

    const timeoutId = setTimeout(() => {
      titleRef.current?.focus();
      fakeFocusHandlerRef.current?.setAttribute('tabindex', '-1');
    }, 100);

    return () => {
      root?.removeAttribute('aria-hidden');
      clearTimeout(timeoutId);
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <FocusTrap
      focusTrapOptions={{
        fallbackFocus: rootId,
      }}
    >
      <div
        className={`cade-modal cade-modal--position-${position}`}
        onClick={onClose}
        role="dialog"
        aria-modal={true}
        tabIndex={-1}
        data-testid={MODAL_TEST_ID}
      >
        <Space
          padding={7}
          className="cade-modal__content"
          onClick={stopPropagation}
        >
          <header className="cade-modal__header">
            <Title
              headingType={titleType}
              centered={true}
              className="cade-modal__title"
              level="normal"
              data-testid={MODAL_TITLE_TEST_ID}
              ref={titleRef}
            >
              {title}
            </Title>
            {exitButton ?? <ExitButton aria-label="close" onClick={onClose} />}
            {!exitButton && (
              // setting fake element to take first focus in order to avoid repetition
              // in reading title element
              <span ref={fakeFocusHandlerRef} tabIndex={0}></span>
            )}
          </header>
          <section
            className="cade-modal__body"
            data-testid={MODAL_CONTENT_TEST_ID}
          >
            {children}
          </section>
          {footer && <footer>{footer}</footer>}
        </Space>
      </div>
    </FocusTrap>,
    document.body
  );
}

export type ErrorModalProps = Props & {
  title?: string;
};

const ErrorHeadline = ({ title }: { title?: string }) => (
  <>
    <WarningIcon /> {title}
  </>
);

export function ErrorModal({
  title,
  footer,
  onClose,
  ...props
}: ErrorModalProps) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <Modal
      {...props}
      onClose={onClose}
      titleType="h2"
      title={<ErrorHeadline title={title || t('modal.error.title')} />}
      footer={
        footer === undefined ? (
          <SecondaryButton icon={icon_secondary_default} onClick={onClose}>
            {t('modal.confirmationButton')}
          </SecondaryButton>
        ) : (
          footer
        )
      }
    />
  );
}

export function TroubleshootingTipsModal({
  title,
  footer,
  onClose,
  ...props
}: Props) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <Modal
      {...props}
      onClose={onClose}
      title={title || t('modal.troubleshooting.title')}
      footer={
        footer === undefined ? (
          <SecondaryButton
            data-testid="cade-modal-close-button"
            onClick={onClose}
          >
            {t('modal.confirmationButton')}
          </SecondaryButton>
        ) : (
          footer
        )
      }
    />
  );
}
