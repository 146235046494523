import React, { useState } from 'react';
import { Instruction } from '../../../components/Instruction';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import { Box, Title } from '../../../components';
import { ReadCard } from '../../../components/ReadCard';
import { ScriptLine } from '../../../utils/types';
import { CadeEvent } from '../../../utils/events';
import { VoiceLevelIndicator } from '../../../components/VoiceLevelIndicator';
import { useVolume } from '../../../context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '../../constants';
import { OnPlayArgsCallback } from '../../../components/PlayAudio';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  listenPartDuration: number; // in ms
  startActorsAnimationOnAudioIndex?: number;
  scripts: ScriptLine[];
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
};

export const SHORT_STORY_RETELLING_INSTRUCTION_IDS = {
  CONTAINER: 'cade-short-story-retelling-instruction-container',
  INSTRUCTION: 'cade-short-story-retelling-instruction-element',
  SUBTITLE: 'cade-short-story-retelling-instruction-subtitle',
};

export function ShortStoryRetellingInstruction({
  subTitle,
  instruction,
  audioSrc,
  listenPartDuration,
  startActorsAnimationOnAudioIndex = 0,
  scripts,
  onEvent,
  forceEnd,
}: Props) {
  const [exampleStatus, setExampleStatus] =
    useState<ListenRecordInstructionStatus>('INIT');
  const [startAnimation, setStartAnimation] = useState(false);
  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  function onPlay({ audioIndex }: OnPlayArgsCallback) {
    if (audioIndex === startActorsAnimationOnAudioIndex) {
      setStartAnimation(true);
    } else {
      setStartAnimation(false);
    }
  }

  return (
    <div data-testid={SHORT_STORY_RETELLING_INSTRUCTION_IDS.CONTAINER}>
      <InstructionContainer>
        <Title
          centered
          id={CADE_CONTENT_TITLE_ID}
          level="large"
          data-testid={SHORT_STORY_RETELLING_INSTRUCTION_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction
          data-testid={SHORT_STORY_RETELLING_INSTRUCTION_IDS.INSTRUCTION}
        >
          {instruction}
        </Instruction>
      </InstructionContainer>
      <Box role="instruction" className="cade-margin-bottom-5">
        <ListenRecordInstructionContent
          status={exampleStatus}
          audioSrc={audioSrc}
          listenPartDuration={listenPartDuration}
          onPlayAudio={onPlay}
          onStatusChange={setExampleStatus}
          onEvent={onEvent}
          forceEnd={forceEnd}
        />
      </Box>
      {scripts && scripts.length > 0 && (
        <Box role="instruction">
          <ReadCard
            status={exampleStatus === 'LISTENING' ? 'ACTIVE' : 'FREEZE'}
            scripts={scripts}
            startAnimation={startAnimation}
          />
        </Box>
      )}
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
