import { ApiModel, AudioResponse } from '../../api/api.interfaces';
import {
  CADE_QUESTION_COUNTER_ID,
  getAudioType,
  ShortStoryRetellingExercise,
} from '@versant-monorepo/cade';
import { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToShortStoryRetelling } from '../../api/parsers/ShortStoryRetelling/ShortStoryRetelling';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useNavigate } from 'react-router-dom';
import { useForceEndTrigger } from '../../hooks/useForceEndTrigger';
import { vfwRoutes } from '../../vfw-routes';

export const ShortStoryRetellingWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const navigate = useNavigate();
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const { forceEndTrigger, setForceEnd } = useForceEndTrigger();

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as Blob),
    'cade-audio-ended-event': () => setNextEnabled(true),
    'navigate-we-cant-hear-you-event': () => {
      setForceEnd('we-cant-hear-you');
    },
    'navigate-problem-audio-event': () => {
      setForceEnd('problem-with-audio');
    },
  });
  const { pushEvent } = useLogger();
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);

  const shortStoryRetellingProps = useParser(
    transformJSONToShortStoryRetelling,
    model as ApiModel
  );

  useSetDocumentTitle(shortStoryRetellingProps?.title);

  const onNext = async (responseItem: AudioResponse) => {
    if (forceEndTrigger === 'we-cant-hear-you') {
      navigate(vfwRoutes.weCantHearYou);
      return;
    }

    if (forceEndTrigger === 'problem-with-audio') {
      navigate(vfwRoutes.problemWithAudio);
      return;
    }

    pushEvent({
      item: 'Short Story Retelling Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Short Story Retelling Exercise',
    });

    const response = await getNextStep(responseItem);
    switchView(CURRENT_ITEM_TYPE, response);
    setNextEnabled(false);
    setForceEnd(null);
  };

  const onEnded = async (audio: Blob) => {
    if (!audio.size) {
      pushEvent({
        item: 'Short Story Retelling Exercise',
        level: LogLevel.WARN,
        message: `No audio size for Short Story Retelling Exercise, question ${shortStoryRetellingProps?.count} of ${shortStoryRetellingProps?.total}`,
      });
    }
    const file = new File(
      [audio],
      shortStoryRetellingProps?.responseId as string,
      {
        type: getAudioType().fileType,
      }
    );

    const responseItem = {
      id: shortStoryRetellingProps?.responseId as string,
      response: file,
      type: 'AUDIO',
    } as AudioResponse;

    await onNext(responseItem);
  };

  return (
    shortStoryRetellingProps && (
      <Template
        volumeSlider={true}
        role={'exercise'}
        footer={
          <Footer
            nextButtonEnabled={nextEnabled}
            onNext={() => {
              setForceEnd('next-clicked');
            }}
            role={'exercise'}
          />
        }
        header={
          <Header
            onExit={() => {
              pushEvent({
                item: 'Short Story Retelling Exercise',
                level: LogLevel.INFO,
                message: 'User clicked exit on Short Story Retelling Exercise',
              });
              eventHandler(exitEvent());
            }}
            currentStep={shortStoryRetellingProps.currentStep}
            totalSteps={shortStoryRetellingProps.totalSteps}
            title={shortStoryRetellingProps.subTitle}
          />
        }
      >
        <div className="vfw-template__content-wrapper">
          <ShortStoryRetellingExercise
            key={networkDependentKey(
              onlineStatus,
              shortStoryRetellingProps.responseId
            )}
            {...shortStoryRetellingProps}
            onEvent={eventHandler}
            forceEnd={forceEndTrigger !== null}
          />
        </div>
      </Template>
    )
  );
};
