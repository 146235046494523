export function InternetIcon() {
  return (
    <svg
      width="154"
      height="155"
      viewBox="0 0 154 155"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M76.7834 79.3501C68.2773 79.3501 61.3834 86.2464 61.3834 94.7525C61.3834 103.259 68.2797 110.155 76.7834 110.155C85.2871 110.155 92.1834 103.259 92.1834 94.7525C92.1834 86.2464 85.494 79.3501 76.7834 79.3501ZM77 40.8501C60.0961 40.8501 43.8178 47.0101 31.1609 58.1823C27.9847 61.207 27.6718 66.0676 30.4872 69.2679C33.3265 72.4442 38.1872 72.757 41.3634 69.9417C51.205 61.2551 63.8618 56.4667 77 56.4667C90.1381 56.4667 102.819 61.2503 112.637 69.9417C114.104 71.241 115.933 71.8667 117.738 71.8667C118.83 71.8675 119.91 71.6361 120.905 71.1876C121.901 70.7391 122.79 70.0839 123.513 69.2655C126.328 66.0676 126.039 61.207 122.839 58.3917C110.182 47.2267 93.9159 40.8501 77 40.8501ZM151.642 32.5726C131.453 13.2215 104.961 2.56667 77 2.56667C49.0393 2.56667 22.5441 13.2215 2.36868 32.5726C-0.699287 35.5203 -0.797943 40.3929 2.14298 43.4609C5.08341 46.5361 9.95607 46.6179 13.0313 43.6866C30.1022 26.8939 53.0337 17.9667 77 17.9667C100.966 17.9667 123.465 27.1032 140.958 43.6895C142.474 45.1092 144.375 45.831 146.3 45.831C148.323 45.831 150.345 45.0415 151.856 43.4623C154.794 40.3929 154.698 35.3157 151.642 32.5726Z" />
    </svg>
  );
}
