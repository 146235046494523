export type ListeningStatus =
  | 'DISABLED'
  | 'PLAYING'
  | 'INACTIVE'
  | 'COMPLETED'
  | 'ERROR';

export function isListeningActive(status: ListeningStatus) {
  return status === 'PLAYING';
}
