import {
  Action,
  ApiModel,
  CueInstruction,
  CueInstructionExample,
  DisplayParam,
  Item,
  ItemTypes,
  LaunchTest,
  RecordParam,
  SubTypes,
  TextInputParam,
  Toc,
} from './api.interfaces';
import { VfwStorage } from '../utils/Storage';
import { Logger, LogLevel } from './Logger';
import { CURRENT_ITEM_TYPE } from './api';
import i18n from 'i18next';

export function audioUrlFromRid(launchResponse: LaunchTest, audioRid: string) {
  return launchResponse.baseFetchResourceURL.replace('{1}', audioRid + '.mp3');
}

export function extractAudioResourcesFromResponse(json: ApiModel): string[] {
  const launchResponse = VfwStorage.getItem('launchTestResponse');
  if (!launchResponse) {
    // TODO log this issue
    console.log('No launch test data to proceed.');
    return [''];
  }
  return json.items[0].actions
    .filter((el) => el.actionType === 'play')
    .map((playItem: Action) => {
      if (!playItem.playParams) {
        throw new Error('No play params provided');
      }
      return audioUrlFromRid(launchResponse, playItem.playParams.url);
    });
}

export function extractPictureResourcesFromResponse(json: ApiModel): string[] {
  const launchResponse = VfwStorage.getItem('launchTestResponse');
  if (!launchResponse) {
    // TODO log this issue
    console.log('No launch test data to proceed.');
    return [''];
  }
  return json.items[0].actions
    .filter((el) => el.actionType === 'display')
    .map((playItem: Action) => {
      if (!playItem.displayParams) {
        throw new Error('No play params provided');
      }
      return launchResponse.baseFetchResourceURL.replace(
        '{1}',
        playItem.displayParams.picture[0].url + '.enc.jpg'
      );
    });
}

export function transformFromAlternativeCue(
  alternativeCue: CueInstruction[]
): CueInstruction[] {
  return alternativeCue
    .filter((item) => item.examples)
    .map((item) => {
      const transformedExamples: CueInstructionExample[] = [];
      let currentExampleA: CueInstructionExample = {};
      let currentExampleB: CueInstructionExample = {};

      item.examples.forEach((example: CueInstructionExample) => {
        if (example.label) {
          transformedExamples.push(example);
        } else if (example.prompt) {
          currentExampleA.label = example.prompt;
        } else if (example.promptQuote) {
          currentExampleA.promptQuote = example.promptQuote;
          transformedExamples.push(currentExampleA);
          currentExampleA = {};
        } else if (example.response) {
          currentExampleB.label = example.response;
        } else if (example.respondQuote) {
          currentExampleB.respondQuote = example.respondQuote;
          transformedExamples.push(currentExampleB);
          currentExampleB = {};
        }
      });

      return {
        instructions: item.instructions,
        examples: transformedExamples,
        timestamps: item.timestamps,
      };
    });
}

export function extractActorsData(json: ApiModel): ScriptLine[] {
  let scriptLines: ScriptLine[] = [];
  const displayAction = json.items[0].actions.find(
    (action) => action.actionType === 'display'
  );
  if (
    !displayAction ||
    !displayAction.displayParams ||
    !displayAction.displayParams.cueInstructions
  ) {
    return scriptLines;
  }

  if (!validateCueParams(displayAction.displayParams.cueInstructions)) {
    const transformedCue = transformFromAlternativeCue(
      displayAction.displayParams.cueInstructions
    );
    if (validateCueParams(transformedCue)) {
      displayAction.displayParams.cueInstructions = transformedCue;
    } else {
      return [];
    }
  }

  let timestampIndex = 0;
  for (
    let i = 0;
    i < displayAction.displayParams.cueInstructions[0].examples.length;
    i++
  ) {
    const cueInstruction = displayAction.displayParams.cueInstructions[0];

    if (
      cueInstruction.examples[i].prompt === '...' ||
      cueInstruction.examples[i].label === '...'
    ) {
      const lastElement = scriptLines[scriptLines.length - 1];
      lastElement.content =
        lastElement.content +
        '...' +
        (cueInstruction.examples[i].promptQuote ||
          cueInstruction.examples[i].respondQuote);
      continue;
    }

    const line: ScriptLine = {
      header:
        cueInstruction.examples[i].prompt ||
        cueInstruction.examples[i].label ||
        '',
      content:
        cueInstruction.examples[i].promptQuote ||
        cueInstruction.examples[i].respondQuote ||
        '',
      from: cueInstruction.timestamps[timestampIndex].from,
      to: cueInstruction.timestamps[timestampIndex].to,
      side: cueInstruction.examples[i].promptQuote != null ? 'left' : 'right',
    };

    scriptLines.push(line);
    timestampIndex++;
  }
  return scriptLines;
}

export function extractActorsDataForConversation(json: ApiModel): ScriptLine[] {
  let scriptLines: ScriptLine[] = [];
  const displayAction = json.items[0].actions.find(
    (action) => action.actionType === 'display'
  );
  if (
    !displayAction ||
    !displayAction.displayParams ||
    !displayAction.displayParams.cueInstructions
  ) {
    return scriptLines;
  }

  const cueInstruction = displayAction.displayParams.cueInstructions[0];

  if (!cueInstruction.timestamps) {
    return [];
  }

  for (let i = 0; i < cueInstruction.timestamps.length; i++) {
    let header = '';
    let content = '';
    let side: 'right' | 'left' = 'left';
    if (i === 0) {
      header = cueInstruction.examples[0].prompt || '';
      content =
        '' +
        cueInstruction.examples[1].label +
        ' ' +
        cueInstruction.examples[1].promptQuote +
        '\n' +
        cueInstruction.examples[2].label +
        ' ' +
        cueInstruction.examples[2].promptQuote +
        '\n' +
        cueInstruction.examples[3].label +
        ' ' +
        cueInstruction.examples[3].promptQuote;
      side = 'left';
    } else if (i === 1) {
      header = cueInstruction.examples[0].prompt || '';
      content =
        '' +
        cueInstruction.examples[4].label +
        ' ' +
        cueInstruction.examples[4].promptQuote;
      side = 'left';
    } else if (i === 2) {
      header = cueInstruction.examples[5].response || '';
      content = cueInstruction.examples[6].respondQuote || '';
      side = 'right';
    } else if (i === 3) {
      header = cueInstruction.examples[7].label || '';
      content = cueInstruction.examples[7].respondQuote || '';
      side = 'right';
    }

    const line: ScriptLine = {
      header,
      content,
      from: cueInstruction.timestamps[i].from,
      to: cueInstruction.timestamps[i].to,
      side,
    };

    scriptLines.push(line);
  }

  return scriptLines;
}

export function extractActorsDataForDictation(json: ApiModel): ScriptLine[] {
  let scriptLines: ScriptLine[] = [];
  const displayAction = json.items[0].actions.find(
    (action) => action.actionType === 'display'
  );
  if (
    !displayAction ||
    !displayAction.displayParams ||
    !displayAction.displayParams.cueInstructions
  ) {
    return scriptLines;
  }

  const cueInstruction = displayAction.displayParams.cueInstructions[0];

  if (!cueInstruction.timestamps) {
    return [];
  }

  for (let i = 0; i < cueInstruction.timestamps.length; i++) {
    if (i === 0) {
      const line: ScriptLine = {
        header: cueInstruction.examples[0].prompt || '',
        content: cueInstruction.examples[1].promptText || '',
        from: cueInstruction.timestamps[i].from,
        to: cueInstruction.timestamps[i].to,
        side: 'left',
      };

      scriptLines.push(line);
    }
  }

  const line: ScriptLine = {
    header: cueInstruction.examples[2].response || '',
    content: cueInstruction.examples[3].responseText || '',
    from: cueInstruction.timestamps[1].from,
    to: cueInstruction.timestamps[1].to,
    side: 'right',
  };

  scriptLines.push(line);

  return scriptLines;
}

const isInstructOrExampleActor = (actor: string): boolean => {
  return actor === 'instruct' || actor === 'examples';
};

export function validateCueParams(cueInstructions: CueInstruction[]): boolean {
  if (!cueInstructions || cueInstructions.length === 0) {
    console.warn('Cue instruction cannot be empty!');
    return false;
  }
  if (!cueInstructions[0].examples || !cueInstructions[0].timestamps) {
    console.warn('There is not enough data in cueInstructions');
    return false;
  }

  if (
    !cueInstructions[0].examples ||
    cueInstructions[0].examples.length === 0
  ) {
    console.warn('Examples cannot be empty!');
    return false;
  }
  for (let example of cueInstructions[0].examples) {
    if (
      (!example.prompt && !example.label) ||
      (example.prompt?.length === 0 && example.label?.length === 0)
    ) {
      console.warn(
        'Prompt/Label in every example must exists, check item below!'
      );
      console.warn(example);
      return false;
    }
    if (
      (!example.promptQuote || example.promptQuote.length === 0) &&
      (!example.respondQuote || example.respondQuote.length === 0)
    ) {
      console.warn(
        'promptQuote or respondQuote in every example must exists, check item below!'
      );
      console.warn(example);
      return false;
    }
  }
  if (
    !cueInstructions[0].timestamps ||
    cueInstructions[0].timestamps.length === 0
  ) {
    console.warn('Timestamps cannot be empty!');
    return false;
  }
  for (let timestamp of cueInstructions[0].timestamps) {
    if (
      (!timestamp.from || timestamp.to <= 0) &&
      !isInstructOrExampleActor(timestamp.actor)
    ) {
      console.warn("Every timestamp must have valid 'from', check item below!");
      console.warn(timestamp);
      return false;
    }
    if (
      (!timestamp.to || timestamp.to <= 0) &&
      !isInstructOrExampleActor(timestamp.actor)
    ) {
      console.warn("Every timestamp must have valid 'to', check item below!");
      console.warn(timestamp);
      return false;
    }
    if (!timestamp.actor) {
      console.warn('Every timestamp must have valid actor, check item below!');
      console.warn(timestamp);
      return false;
    }
  }
  return true;
}

export function extractResponseIdFromResponse(
  json: ApiModel
): string | undefined {
  const recordItem = json.items[0].actions.find(
    (el) => el.actionType === 'record'
  );
  if (!recordItem) return undefined;

  return recordItem.recordParams?.responseId;
}

export const getSteps = (
  toc: Toc[],
  item: SubTypes | ItemTypes | undefined
) => ({
  currentStep: toc.findIndex(({ itemType }) => itemType === item) + 1,
  totalSteps: toc.length,
});

export const getAction = (item: Item, type: string) =>
  item.actions.find(({ actionType }) => actionType === type);
export const getTitles = (item: Item) => {
  const [letter, sectionName] = item.titles;

  return i18n.t('part', { letter, sectionName });
};

export const getTimeouts = (item: Item) => {
  const record = getAction(item, 'record');

  return {
    speaking: (record as unknown as Action).actionDuration,
    initial: (record?.recordParams as unknown as RecordParam)
      .initialTimeout as number,
    ending: (record?.recordParams as unknown as RecordParam)
      .endTimeout as number,
  };
};

export const getQuestionsNumber = (item: Item) => {
  const { count, total } = item.itemSequence;
  return { count, total };
};

export const getInstruction = (display: Action | undefined) =>
  (
    (display?.displayParams as DisplayParam).cueInstructions as CueInstruction[]
  )[0].instructions[0] ||
  (display?.displayParams as DisplayParam).display[0][0];

export function extractCommonItemsForInstruction(
  json: ApiModel,
  tocArray: Toc[]
): {
  title: string;
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  totalSteps: number;
  currentStep: number;
} {
  const item = json.items[0];
  const display = getAction(item, 'display');
  const title = getTitles(item);

  return {
    ...getSteps(tocArray, item.subType),
    title,
    subTitle: title,
    instruction: getInstruction(display),
    audioSrc: extractAudioResourcesFromResponse(json),
  };
}

export function extractCommonItemsForExercise(
  json: ApiModel,
  tocArray: Toc[]
): {
  count: number;
  total: number;
  responseId: string;
  title: string;
  subTitle: string;
  audioSrc: string[];
  totalSteps: number;
  currentStep: number;
  timeouts: {
    speaking: number;
    initial: number;
    ending: number;
  };
} {
  const item = json.items[0];
  const title = getTitles(item);

  return {
    ...getSteps(tocArray, item.itemType),
    ...getQuestionsNumber(item),
    title,
    subTitle: title,
    audioSrc: extractAudioResourcesFromResponse(json),
    timeouts: getTimeouts(item),
    responseId: extractResponseIdFromResponse(json) as string,
  };
}

export function checkObjectForKey2(
  objects: object | object[],
  keys: string[]
): string[] {
  if (!Array.isArray(objects)) {
    objects = [objects];
  }

  const keysNotFound: string[] = [...keys];

  const checkForKey = (obj: object, currentKey: string = '') => {
    for (const key in obj) {
      const newKey = currentKey ? `${currentKey}.${key}` : key;
      // @ts-ignore
      if (
        keys.indexOf(newKey) !== -1 &&
        // @ts-ignore
        obj[key] !== undefined &&
        // @ts-ignore
        obj[key] !== null &&
        // @ts-ignore
        !(Array.isArray(obj[key]) && obj[key].length === 0) &&
        // @ts-ignore
        obj[key] !== ''
      ) {
        // Key found and has a truthy value, remove from keysNotFound array
        const index = keysNotFound.indexOf(newKey);
        if (index !== -1) {
          keysNotFound.splice(index, 1);
        }
      }
      // @ts-ignore
      if (
        // @ts-ignore
        typeof obj[key] === 'object' &&
        // @ts-ignore
        obj[key] !== null &&
        // @ts-ignore
        !Array.isArray(obj[key])
      ) {
        // Recursively check for keys in nested objects

        // @ts-ignore
        checkForKey(obj[key], newKey);
      }
      // @ts-ignore
      if (Array.isArray(obj[key])) {
        // Recursively check for keys in nested arrays
        // @ts-ignore
        for (const item of obj[key]) {
          if (typeof item === 'object' && item !== null) {
            checkForKey(item, newKey);
          }
        }
      }
    }
  };

  if (Array.isArray(objects)) {
    for (const obj of objects) {
      checkForKey(obj);
    }
  } else {
    checkForKey(objects);
  }

  return keysNotFound;
}

export function checkPlayParamsUrls(actions: any): boolean {
  const formattedActions: string[] = [];
  actions.forEach((action: any) => {
    if (
      action.actionType === 'play' &&
      (!action.playParams ||
        !action.playParams.url ||
        action.playParams.url === '')
    ) {
      formattedActions.push(`play.actionSequence.${action.actionSequence}`);
    }
  });

  if (formattedActions.length) {
    console.log('playMissingItems', formattedActions);
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      controller: 'validation',
      message: `Validation failed for ${CURRENT_ITEM_TYPE} play params fields, missing fields: ${formattedActions.toString()}`,
      item: CURRENT_ITEM_TYPE,
    });
    return false;
  }

  return true;
}

export function checkTOCArray(tocArray: Toc[]): boolean {
  const validationResults: string[] = [];
  tocArray.forEach((el: Toc) => {
    if (!el.titles || !el.titles.length) {
      validationResults.push(`toc.${el.itemType}.titles`);
    }
    if (!el.itemCount) {
      validationResults.push(`toc.${el.itemType}.itemCount`);
    }
  });

  if (validationResults.length) {
    console.log('missingTocItems', validationResults);
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      controller: 'validation',
      message: `Validation failed for ${CURRENT_ITEM_TYPE} fields, missing fields: ${validationResults.toString()}`,
      item: CURRENT_ITEM_TYPE,
    });
    return false;
  }
  return true;
}

export function checkTextInputParams(
  items: TextInputParam[] | undefined
): boolean {
  if (!items) {
    return false;
  }
  const validationResults: string[] = [];
  items.forEach((el: TextInputParam) => {
    if (!el.responseId) {
      validationResults.push(`textInputParams.responseId`);
    }
    if (!el.prompt || !el.prompt.length) {
      validationResults.push(`textInputParams.prompt`);
    }
  });

  if (validationResults?.length) {
    console.log('missingTextInputParams', validationResults);
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      controller: 'validation',
      message: `Validation failed for ${CURRENT_ITEM_TYPE} textinputparams fields, missing fields: ${validationResults.toString()}`,
      item: CURRENT_ITEM_TYPE,
    });
    return false;
  }

  return true;
}

export function validateWDTResponse(
  json: ApiModel,
  keysToValidate: string[],
  validators: Function[]
): boolean {
  const missingKeys = checkObjectForKey2(json, keysToValidate);

  if (missingKeys.length) {
    console.log('missingKeys', missingKeys);
    Logger.getInstance().pushEvent({
      level: LogLevel.ERROR,
      controller: 'validation',
      message: `Validation failed for ${CURRENT_ITEM_TYPE}, missing fields: ${missingKeys.toString()}`,
      item: CURRENT_ITEM_TYPE,
    });
    return false;
  }

  if (!validators.length) {
    return true;
  }

  return validators.every((validator: Function) => validator());
}

export function extractLabelFromMicroInstructions(json: ApiModel): {
  leftLabel: string;
  rightLabel: string;
} {
  let leftLabel = 'You see:';
  let rightLabel = 'You type:';

  if (json.items[0] && json.items[0].microInstructions) {
    leftLabel = json.items[0].microInstructions[0];
    rightLabel = json.items[0].microInstructions[1];
  }
  return { leftLabel, rightLabel };
}

export type ScriptLine = {
  from: number;
  to: number;
  side: 'left' | 'right';
  header: string;
  content: string;
};

export const serializeError = (error: any) => {
  let serialized = error;
  if (Object.keys(error).length === 0) {
    // Object with non-enumerable properties was returned (like TS Error)
    const nonEnumerableProperties = Object.getOwnPropertyNames(error);
    serialized = nonEnumerableProperties.reduce(
      (accumulator: { [p: string]: string }, current) => {
        accumulator[current] = error[current];
        return accumulator;
      },
      {}
    );
  }

  return JSON.stringify(serialized);
};
