import {
  BackgroundNoiseCheck,
  CADE_CONTENT_TITLE_ID,
  CADE_NEXT_BUTTON_ID,
} from '@versant-monorepo/cade';
import { useNavigate } from 'react-router-dom';
import { useCheckProgress } from '../hooks/useCheckProgress';
import { vfwRoutes } from '../vfw-routes';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { Template } from '../components/Template/Template';
import { exitEvent, useHandleAppEvent } from 'hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useSetDocumentTitle } from '../hooks/useSetDocumentTitle';
import { useLogger } from '../hooks/useLogger';
import { LogLevel } from '../api/Logger';
import { useTranslation } from 'react-i18next';
import { passCheck } from '../utils/prechecks';
import { serializeError } from '../api/api.utils';

export const BackgroundNoiseCheckWrapper = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('backgroundNoiseCheckWrapper.documentTitle'));
  const navigate = useNavigate();
  useCheckProgress('backgroundNoise');
  const { lastEvent, eventHandler } = useHandleAppEvent({
    'cade-ended-event': () => {
      pushEvent({
        level: LogLevel.INFO,
        item: 'Background Noise Check',
        message: 'User clicked next on background noise check',
      });
      try {
        passCheck('backgroundNoise');
        navigate(vfwRoutes.setupSuccess);
      } catch (e) {
        console.error(e);
        pushEvent({
          level: LogLevel.ERROR,
          message: `Background Noise Check error: ${serializeError(e)}`,
          item: 'Background Noise Check',
          eventType: 'ERROR',
        });
        navigate(vfwRoutes.serverError);
      }
    },
    'cade-success-event': () => focus(CADE_NEXT_BUTTON_ID),
  });
  const { focus } = useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);
  const { pushEvent } = useLogger();

  const backgroundNoiseProps = {
    totalCheckingSteps: 5,
    currentCheckingStep: 5,
    onEvent: eventHandler,
    maxAcceptableDecibels: 35,
    warningDecibels: 15,
  };

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                level: LogLevel.INFO,
                item: 'Background Noise Check',
                message: 'User clicked exit test on background noise check',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <BackgroundNoiseCheck {...backgroundNoiseProps} />
      </Template>
    </>
  );
};
