import React from 'react';
import './styles.scss';
import classNames from 'classnames';

type Props = {
  onChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  options: { value: string; label: string; checked?: boolean }[];
  readonly?: boolean;
  state?: 'ACTIVE' | 'DISABLED';
};

export function Selection({
  onChangeValue = () => {},
  name,
  options,
  readonly = false,
  state = 'ACTIVE',
}: Props) {
  return (
    <div
      className={classNames({
        'cade-selection__disabled': state === 'DISABLED',
      })}
      onChange={onChangeValue}
      data-testid="cade-selection-container"
    >
      {options.map((option) => (
        <div key={option.value} className="cade-selection__radio">
          <input
            disabled={readonly}
            checked={option.checked}
            type="radio"
            id={`option_${option.value}`}
            name={name}
            value={option.value}
          />
          <label
            className={'cade-typography-text__normal'}
            htmlFor={`option_${option.value}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}
