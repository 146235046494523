export function HeadsetIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 11.6845C22.7865 12.6506 24 14.5407 24 16.7143V17.2857C24 20.4416 21.4416 23 18.2857 23H18C16.8954 23 16 22.1046 16 21V13C16 11.8954 16.8954 11 18 11H18.2857C18.5276 11 18.766 11.015 19 11.0442V9C19 5.68629 16.3137 3 13 3H11C7.68629 3 5 5.68629 5 9V11.0442C5.234 11.015 5.47239 11 5.71429 11H6C7.10457 11 8 11.8954 8 13V21C8 22.1046 7.10457 23 6 23H5.71429C2.55837 23 0 20.4416 0 17.2857V16.7143C0 14.5407 1.21352 12.6506 3 11.6845V9C3 4.58172 6.58172 1 11 1H13C17.4183 1 21 4.58172 21 9V11.6845ZM18.2857 13H18V21H18.2857C20.3371 21 22 19.3371 22 17.2857V16.7143C22 14.6629 20.3371 13 18.2857 13ZM2 16.7143C2 14.6629 3.66294 13 5.71429 13H6V21H5.71429C3.66294 21 2 19.3371 2 17.2857V16.7143Z"
      />
    </svg>
  );
}
