import {
  AudioResponse,
  ReservationTin,
  TextResponse,
} from '../api/api.interfaces';
import { useContext } from 'react';
import { AppContext } from '../context/App.context';
import { vfwRoutes } from '../vfw-routes';
import { VfwStorage } from '../utils/Storage';
import {
  endTest as endTestApi,
  getConfig as getConfigApi,
  getNextStep as getNextStepApi,
  getSessionInfo as getSessionInfoApi,
  launchTest as launchTestApi,
  refreshView as refreshViewApi,
  reserveTin,
  reserveTinDirect,
  reserveTinLegacyDirect,
  sendBackgroundAudio as sendBackgroundAudioApi,
  sendProctoringFrame as sendProctoringFrameApi,
  startProctoringSession as startProctoringSessionApi,
  validateReferenceFrame as validateReferenceFrameApi,
  validateReferenceVideo as validateReferenceVideoApi,
} from '../api/api';
import { useLogger } from './useLogger';
import { LogLevel } from '../api/Logger';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useApi = () => {
  const { setLoading, setTestStatus } = useContext(AppContext);
  const { pushEvent } = useLogger();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const loginInternal = async (
    restCall: () => Promise<ReservationTin>
  ): Promise<ReservationTin> => {
    setLoading(true);
    try {
      const response = await restCall();
      setTestStatus('TEST_BEGUN');
      VfwStorage.setItem('language', response.locale);
      VfwStorage.setItem(
        'isTestProctored',
        process.env.REACT_APP_ENABLE_VIDEO_PROCTORING === 'true'
          ? response.isProctored
          : false
      );
      if (process.env.REACT_APP_ENABLE_TRANSLATIONS === 'true') {
        await i18n.changeLanguage(response.locale);
      }
      return response;
    } finally {
      setLoading(false);
    }
  };

  const login = async (tin: number, recaptcha?: string) =>
    loginInternal(() => reserveTin(tin, recaptcha));

  const legacyDirectLogin = async (tin: number, hash: string) =>
    loginInternal(() => reserveTinLegacyDirect(tin, hash));

  const directLogin = async (token: string) =>
    loginInternal(() => reserveTinDirect(token));

  const launchTest = async () => {
    setLoading(true);
    try {
      const response = await launchTestApi();
      setLoading(false);
      return response;
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: '',
        message: 'Launch test error: ' + JSON.stringify(error),
      });
      setLoading(false);
      errorNavigation(error.error);
      return new Promise((resolve, reject) => reject());
    }
  };

  const getNextStep = async (
    itemResponses: TextResponse[] | AudioResponse | null = null
  ) => {
    if (
      itemResponses &&
      (itemResponses as AudioResponse).type === 'AUDIO' &&
      (itemResponses as AudioResponse).response.size === 0
    ) {
      navigate(vfwRoutes.weCantHearYou);
    } else {
      setLoading(true);
      try {
        const response = await getNextStepApi(itemResponses);
        const itemType = response.items[0].itemType;
        if (itemType === 'resumestatus' || itemType === 'toc') {
          VfwStorage.setItem('toc', response.items[0].toc);
        }
        setTestStatus(response.testState);
        setLoading(false);
        return response;
      } catch (error: any) {
        pushEvent({
          level: LogLevel.ERROR,
          item: '',
          message: 'Get next step error: ' + JSON.stringify(error),
        });
        setLoading(false);
        errorNavigation(error.error);
      }
    }
  };

  const refreshView = async () => {
    setLoading(true);
    try {
      const response = await refreshViewApi();
      setLoading(false);
      setTestStatus(response.testState);
      return response;
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: '',
        message: 'Get refresh view error: ' + JSON.stringify(error),
      });
      setLoading(false);
      errorNavigation(error.error);
    }
  };

  const getConfig = () => getConfigApi();

  const endTest = async () => {
    setLoading(true);
    try {
      const response = await endTestApi();
      setTestStatus(undefined);
      setLoading(false);
      return response;
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: '',
        message: 'End test error: ' + JSON.stringify(error),
      });
      setLoading(false);
      errorNavigation(error.error);
    }
  };

  const validateReferenceFrame = async (base64: string) => {
    try {
      return await validateReferenceFrameApi(base64);
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: 'Take a selfie',
        message: 'Take a selfie: ' + JSON.stringify(error),
      });
      errorNavigation(error.error);
    }
  };

  const validateReferenceVideo = async (
    file: File,
    recordingTime: string,
    mediaFormat: string,
    mediaPayloadSize: number,
    deviceName: string
  ) => {
    try {
      return await validateReferenceVideoApi(
        file,
        recordingTime,
        mediaFormat,
        mediaPayloadSize,
        deviceName
      );
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: 'Record a video',
        message: 'Record a video: ' + JSON.stringify(error),
      });
      errorNavigation(error.error);
    }
  };

  const errorNavigation = (message: string) => {
    if (message === 'AccessDenied') {
      navigate(vfwRoutes.accessDenied);
    } else {
      if (navigator.onLine) {
        console.log('server error');
        navigate(vfwRoutes.serverError);
      }
    }
  };

  const startProctoringSession = async (videoUID: string) =>
    startProctoringSessionApi(videoUID);

  const sendProctoringFrame = async (
    sessionKey: string,
    frame: string,
    currentItemId: string
  ) => {
    try {
      return await sendProctoringFrameApi(sessionKey, frame, currentItemId);
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: 'Sending proctoring frame',
        message: 'Sending proctoring frame: ' + JSON.stringify(error),
      });
    }
  };

  const sendBackgroundAudio = async (file: File, testItem: string) => {
    try {
      return await sendBackgroundAudioApi(file, testItem);
    } catch (error: any) {
      pushEvent({
        level: LogLevel.ERROR,
        item: 'Sending background audio',
        message: 'Sending background audio: ' + JSON.stringify(error),
      });
    }
  };

  const getSessionInfo = async () => getSessionInfoApi();

  return {
    login,
    launchTest,
    getNextStep,
    refreshView,
    endTest,
    getConfig,
    legacyDirectLogin,
    directLogin,
    validateReferenceFrame,
    validateReferenceVideo,
    startProctoringSession,
    sendProctoringFrame,
    sendBackgroundAudio,
    getSessionInfo,
  };
};
