export function formatTimeWithSpaces(time: number) {
  return (
    <>
      {formatMinutes(time)} : {formatSeconds(time)}
    </>
  );
}

export function formatTime(time: number) {
  return (
    <>
      {formatMinutes(time)}:{formatSeconds(time)}
    </>
  );
}

export function formatMinutes(time: number) {
  return (Math.floor(time / 60) + '').padStart(1, '0');
}

export function formatSeconds(time: number) {
  return ((time % 60) + '').padStart(2, '0');
}
