import React, { createContext } from 'react';

export type ModalType = 'POSTPONE_TEST' | 'PRE_TEST' | 'TEST';
export type ShowModalType =
  | { open: true; type: 'POSTPONE_TEST' | 'PRE_TEST' | 'TEST' }
  | { open: false };
export const openedModal = (type: ModalType) => {
  return {
    open: true as const,
    type,
  };
};
export const closedModal = () => ({ open: false } as const);

export type TestStatus = 'TEST_BEGUN' | 'IN_PROGRESS' | 'COMPLETED' | undefined;

export const AppContext = createContext<{
  visibleModal?: ShowModalType;
  setVisibleModal?: (modal: ShowModalType) => void;
  weCantHearYouCount?: number;
  setWeCantHearYouCount?: any;
  audioTroubleshootingCount: number;
  setAudioTroubleshootingCount: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  setLoading?: any;
  setTestStatus: React.Dispatch<React.SetStateAction<TestStatus>>;
  testStatus: TestStatus;
}>({
  weCantHearYouCount: 0,
  audioTroubleshootingCount: 0,
  setAudioTroubleshootingCount: () => {},
  setTestStatus: () => {},
  testStatus: undefined,
});
