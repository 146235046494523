import { createContext, PropsWithChildren, ReactNode } from 'react';
import { Trans, TransProps } from 'react-i18next';

const TranslationComponent = () => <></>;

export type CadeConfigProviderProps = {
  rootElementId?: string;
  interactionBeginTone: string | null;
  interactionEndTone: string | null;
  errorTone: string | null;
  nextTone: string | null;
  i18n: {
    t: (key: string) => string;
    TranslationComponent: typeof Trans;
  };
};

export const ConfigContext = createContext<CadeConfigProviderProps>({
  i18n: {
    t: (key: string) => key,
    TranslationComponent,
  },
  interactionBeginTone: null,
  interactionEndTone: null,
  nextTone: null,
  errorTone: null,
  rootElementId: '#root',
});
export const CadeConfigProvider = ({
  rootElementId = '#root',
  interactionBeginTone,
  interactionEndTone,
  errorTone,
  nextTone,
  i18n,
  children,
}: PropsWithChildren<CadeConfigProviderProps>) => {
  return (
    <>
      <ConfigContext.Provider
        value={{
          rootElementId,
          interactionBeginTone,
          interactionEndTone,
          nextTone,
          errorTone,
          i18n,
        }}
      >
        {children}
      </ConfigContext.Provider>
    </>
  );
};
