import { VfwStorage } from '../utils/Storage';
import { SEND_LOGS_INTERVAL } from '../constants';

export interface LogEvent {
  level: number;
  timestamp: number;
  message: string;
  item: string; // repeat, sentence completion itd
  embedType: 'iframe' | 'direct';
  controller?: string;
  eventType?: 'ACTION' | 'ERROR' | string;
}

export enum LogLevel {
  FATAL = 1,
  ERROR = 2,
  WARN = 3,
  INFO = 4,
  DEBUG = 5,
  TRACE = 6,
}

export class Logger {
  private static instance: Logger;
  private events: LogEvent[] = [];
  private interval: ReturnType<typeof setInterval> | undefined;
  private SEND_LOG_URL = `${process.env.REACT_APP_PROXY_URL}/log`;

  private constructor() {
    this.interval = setInterval(() => {
      this.sendLogs();
    }, SEND_LOGS_INTERVAL);
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  public pushEvent(event: Omit<LogEvent, 'timestamp' | 'embedType'>) {
    const fullEvent: LogEvent = {
      ...event,
      timestamp: Date.now(),
      embedType: window.top !== window.self ? 'iframe' : 'direct',
    };
    this.events.push(fullEvent);
    // if we have error send logs immediately
    if (event.level === LogLevel.ERROR) {
      this.sendLogs();
    }
  }

  private clearEvents() {
    this.events = [];
  }

  private prepareLog() {
    const tinResponse = VfwStorage.getItem('reservationTinResponse');
    if (!tinResponse) {
      return;
    }
    const header = {
      userAgent: navigator.userAgent,
    };

    return {
      header,
      events: this.events,
    };
  }

  public async sendLogs() {
    if (!this.events.length) return;
    const body = this.prepareLog();

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(VfwStorage.getItem('authtoken')
        ? { Authorization: VfwStorage.getItem('authtoken') }
        : {}),
    } as unknown as Headers;

    try {
      await fetch(this.SEND_LOG_URL, {
        method: 'POST',
        body: JSON.stringify({
          ...body,
          csrfToken: VfwStorage.getItem('csrfToken'),
        }),
        headers: headers,
        credentials: 'include',
      });
      this.clearEvents();
    } catch (e) {}
  }
}
