import { useCallback, useEffect, useState } from 'react';

export const networkDependentKey = (
  status: boolean,
  key: string = 'content'
) => {
  if (status) {
    return `${key}/online`;
  }

  return `${key}/offline`;
};

export const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = useCallback(
    () => setNetwork(window.navigator.onLine),
    [window.navigator.onLine]
  );

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return isOnline;
};
