import { ProblemWithCamera } from '@versant-monorepo/cade';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { Template } from '../components/Template/Template';
import { useHandleAppEvent } from '../hooks/useHandleAppEvent';
import { useSetDocumentTitle } from '../hooks/useSetDocumentTitle';
import { useLogger } from '../hooks/useLogger';
import { LogLevel } from '../api/Logger';
import { useTranslation } from 'react-i18next';
import { useExitTest } from '../hooks/useExitTest';

export function ProblemWithCameraWrapper() {
  const { t } = useTranslation();
  useSetDocumentTitle(t('microphoneProblemWrapper.documentTitle'));
  const { eventHandler } = useHandleAppEvent({
    'cade-exit-event': () => hardExit(),
  });

  const { pushEvent } = useLogger();
  const exitTest = useExitTest();
  async function hardExit() {
    pushEvent({
      item: 'Problem with Camera',
      level: LogLevel.ERROR,
      message: 'There is a problem with a camera',
    });
    exitTest({ hard: true });
  }

  return (
    <>
      <Template
        header={<Header onExit={hardExit} title={''} />}
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <ProblemWithCamera onEvent={eventHandler} />
      </Template>
    </>
  );
}
