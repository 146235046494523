import './styles.scss';
import { useRecording } from '@components/Recording/useRecording';
import React, { useEffect, useState, useContext } from 'react';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { Listening } from '@components/Listening';
import { PlayAudio } from '@components/PlayAudio';
import {
  CadeEvent,
  useEndedEvent,
  userResumeSpeakingEvent,
  weCantHearYouEvent,
} from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { FakeListening } from '@components/Listening/FakeListening';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IReadingExerciseProps {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: string;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  questions: string[];
  currentQuestion: number;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  instruction: string;
}

export const ReadingExercise = ({
  currentQuestion,
  questions,
  total,
  subTitle,
  timeouts,
  count,
  audioSrc,
  onEvent = () => {},
  forceEnd,
  instruction,
}: IReadingExerciseProps) => {
  const [warningVisible, setWarningVisible] = useState(false);
  const [startExercise, setStartExercise] = useState(currentQuestion > 0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isListenMode, setIsListenMode] = useState(true);
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent<Blob>(onEvent);
  const [blob, setBlob] = useState<Blob>();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;

  function playAudio() {
    delay.clear();
    setStartExercise(true);
  }

  useEffect(() => {
    if (!startExercise) {
      delay.set(playAudio, EXERCISE_AUTO_PLAY_TIMEOUT);
    }
  }, [startExercise]);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      if (isRecording) {
        stopRecording();
      } else {
        sendEndEvent(blob);
      }
    }
  }, [forceEnd]);

  useEffect(() => {
    if (startExercise) {
      setIsListenMode(true);
      setIsAudioPlaying(true);
    }
  }, [startExercise]);

  const { startRecording, stopRecording, isRecording } = useRecording({
    handleFinishRecording: (blob: Blob) => {
      setBlob(blob);
      if (count === total) {
        setWarningVisible(false);
        if (!forceEnd) {
          setIsListenMode(true);
          delay.set(() => {
            sendEndEvent(blob);
          });
        } else {
          sendEndEvent(blob);
        }
      } else {
        sendEndEvent(blob);
      }
    },
    timeouts: {
      initialSilence: timeouts.initial,
      endingSilence: timeouts.ending,
      max: timeouts.speaking,
    },
    onTimeout: (timeoutKind) => {
      switch (timeoutKind) {
        case 'initial_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'cant_hear_you_silence_timeout':
          setWarningVisible(true);
          onEvent(weCantHearYouEvent());
          break;
        case 'ending_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'max_time_timeout':
          stopRecording();
          setWarningVisible(false);
          break;
        case 'cant_hear_you_silence_timeout_canceled':
          setWarningVisible(false);
          onEvent(userResumeSpeakingEvent());
          break;
      }
    },
  });

  const onAudioEnded = () => {
    setIsListenMode(false);
    startRecording();
    document.getElementById(`paragraph-${count}`)?.focus();
  };

  return (
    <>
      <div className={'reading-exercise'}>
        <InstructionContainer>
          <Title centered id={CADE_CONTENT_TITLE_ID}>
            {subTitle}
          </Title>
          <Instruction level="small">{instruction}</Instruction>
        </InstructionContainer>
        <Box role={'exercise'}>
          {!startExercise && (
            <div className={'reading-exercise__instruction'}>
              <Paragraph weight={'bold'} level={'large'}>
                {t('reading.exercise.p')}
              </Paragraph>
              <div className={'reading-exercise__listening-container'}>
                <FakeListening onClickPlay={playAudio} />
              </div>
            </div>
          )}
          {startExercise && (
            <div className={'reading-exercise__questions-container'}>
              {questions.map((el: string, index: number) => {
                return (
                  <Paragraph
                    key={'paragraph' + index}
                    id={'paragraph-' + (index + 1)}
                    tabIndex={-1}
                    weight={index === currentQuestion ? 'bold' : 'normal'}
                  >
                    {index + 1}. {el}
                  </Paragraph>
                );
              })}
            </div>
          )}
        </Box>
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            weCantHearYou={warningVisible}
            numOfCircles={6}
            listenMode={isListenMode}
            currentValue={voiceLevel}
          />
        </div>
        {isAudioPlaying ? (
          <PlayAudio
            audioSrc={Array.isArray(audioSrc) ? audioSrc : [audioSrc]}
            autoPlay={true}
            onEnded={() => onAudioEnded()}
          />
        ) : null}
      </div>
    </>
  );
};
