import { CadeEvent } from '@utils/events';
import { Box, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import React, { useState } from 'react';
import './styles.scss';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  instruction: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  audioSrc: string[];
};

export function EmailInstruction({
  instruction,
  subTitle,
  onEvent,
  forceEnd,
  audioSrc,
}: Props) {
  const [exampleStatus, setExampleStatus] =
    useState<ListenRecordInstructionStatus>('INIT');

  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  return (
    <div className={'email-instruction-wrapper'}>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID} level="large">
          {subTitle}
        </Title>
        <Instruction aria-hidden={true} level={'large'}>
          {instruction}
        </Instruction>
      </InstructionContainer>
      <Box role="instruction" className="cade-margin-bottom-5">
        <ListenRecordInstructionContent
          status={exampleStatus}
          audioSrc={audioSrc}
          listenPartDuration={0}
          onStatusChange={setExampleStatus}
          onEvent={onEvent}
          forceEnd={forceEnd}
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
