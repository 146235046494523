import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { ListeningStatus } from '@components/Listening/commons';
import { ListenRecordExerciseContent } from '../../components/ListenRecordExerciseContent';
import { CadeEvent } from '@utils/events';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { ConfigContext } from '@context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface Props {
  count: number;
  total: number;
  subTitle: string;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  passageLabel: string;
  leftLabel: string;
  rightLabel: string;
  forceEnd?: boolean;
  instruction: string;
}

export function PassageComprehensionExercise({
  count,
  total,
  subTitle,
  timeouts,
  audioSrc,
  onEvent,
  passageLabel,
  leftLabel,
  rightLabel,
  forceEnd = false,
  instruction,
}: Props) {
  const [audioIndex, setAudioIndex] = useState(0);
  const mode = useMemo(
    () => (audioIndex > 0 || audioSrc.length === 1 ? 'question' : 'passage'),
    [audioIndex, audioSrc]
  );
  const isQuestionMode = mode === 'question';
  const [listeningStatus, setListeningStatus] = useState<ListeningStatus>(
    isQuestionMode ? 'PLAYING' : 'INACTIVE'
  );
  const [warningVisible, setWarningVisible] = useState(false);

  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;

  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const switchToQuestionMode = useCallback(() => {
    setAudioIndex(1);
    setListeningStatus('PLAYING');
  }, [setAudioIndex, setListeningStatus]);

  const _onEvent = useCallback(
    (event: CadeEvent) => {
      if (event.name === 'cade-audio-ended-event') {
        if (isQuestionMode) {
          onEvent(event);
        } else {
          // passage mode only switch to next audio
          setListeningStatus('COMPLETED');
          switchToQuestionMode();
        }
        return;
      }
      onEvent(event);
    },
    [onEvent, audioIndex]
  );
  return (
    <>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level="small">{instruction}</Instruction>
      </InstructionContainer>
      <QuestionCounter
        title={t('passageComprehension.exercise.questionCountLabel')}
        count={count}
        total={total}
      />
      <Box role="exercise" className="example-box">
        <ListenRecordExerciseContent
          key={audioIndex}
          audioSrc={audioSrc[audioIndex]}
          timeouts={timeouts}
          onEvent={_onEvent}
          listeningStatus={listeningStatus}
          setListeningStatus={setListeningStatus}
          forceEnd={forceEnd}
          setWarningVisible={setWarningVisible}
          rightLabel={rightLabel}
          leftLabel={isQuestionMode ? leftLabel : passageLabel}
          showRightPane={isQuestionMode}
          autoPlay={!isQuestionMode}
          playBeepSound={true}
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          weCantHearYou={warningVisible}
          numOfCircles={6}
          listenMode={listeningStatus !== 'COMPLETED'}
          currentValue={voiceLevel}
        />
      </div>
    </>
  );
}
