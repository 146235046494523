import { LaunchTest, ReservationTin, Toc } from '../api/api.interfaces';

export type StorageKeys = {
  authtoken: string;
  csrfToken: string;
  checks: { id: string; passed: boolean }[];
  launchTestResponse: LaunchTest;
  reservationTinResponse: ReservationTin;
  withoutSecondConfirmation: boolean;
  toc: Toc[];
  microphoneId?: string;
  testName: string;
  entitlement: string;
  language: string;
  speakingTips: {
    audioRid: string;
    instruction: string;
  } | null;
  proctoringSessionKey: string;
  isTestProctored: boolean;
  referencePhotoBase64: string;
  videoUID: string;
  isTestResumed: boolean;
  cameraId: string;
  isRefresh: string;
};

export const encryptionBase64 = {
  encode: (data: string) => btoa(encodeURIComponent(data)),
  decode: (data: string) => decodeURIComponent(atob(data)),
};

const isSessionStorageAvailable = () => {
  try {
    return Boolean(window.sessionStorage);
  } catch (error) {
    console.error(error);
    return false;
  }
};

type ClearAllArgs = { exclude?: string[] };

const clearSessionStorage = (exclude: string[]) => {
  const { length } = window.sessionStorage;

  for (let index = 0; index < length; index++) {
    const key = window.sessionStorage.key(index);

    if (!key) {
      continue;
    }

    if (exclude.indexOf(key) === -1) {
      window.sessionStorage.removeItem(key);
    }
  }
};

abstract class BaseStorage {
  private static encode(data: string) {
    return process.env.REACT_APP_DEV_QUIRKS === 'false'
      ? encryptionBase64.encode(data)
      : data;
  }

  private static decode(data: string) {
    return process.env.REACT_APP_DEV_QUIRKS === 'false'
      ? encryptionBase64.decode(data)
      : data;
  }
  static getItem<K extends keyof StorageKeys>(key: K): StorageKeys[K] | null {
    const encryptedKey = BaseStorage.encode(key);
    const data = BaseStorage.decode(
      window.sessionStorage.getItem(encryptedKey) || ''
    );

    if (!data || typeof data !== 'string') {
      return null;
    }

    return JSON.parse(data as string);
  }

  static setItem<T extends keyof StorageKeys>(key: T, value: StorageKeys[T]) {
    let data;
    try {
      data = JSON.stringify(value);
    } catch (error: any) {
      throw new Error(error);
    }

    data = BaseStorage.encode(data);
    const encrypetedKey = BaseStorage.encode(key);
    window.sessionStorage.setItem(encrypetedKey, data);
  }

  static removeItem(key: keyof StorageKeys) {
    const encrypetedKey = BaseStorage.encode(key);
    window.sessionStorage.removeItem(encrypetedKey);
  }

  static clearAll(args?: ClearAllArgs) {
    if (args?.exclude) {
      const exclude = args?.exclude.map(BaseStorage.encode);
      return clearSessionStorage(exclude);
    }

    window.sessionStorage.clear();
  }
}

class EmptyStorage extends BaseStorage {
  static getItem() {
    return null;
  }

  static setItem() {}

  static removeItem() {}

  static clearAll() {}
}

class Storage extends BaseStorage {}

export const VfwStorage = isSessionStorageAvailable() ? Storage : EmptyStorage;
