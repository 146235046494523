import { ApiModel } from '../../api/api.interfaces';
import {
  CADE_CONTENT_TITLE_ID,
  PassageComprehensionInstruction,
} from '@versant-monorepo/cade';
import { useState } from 'react';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToPassageComprehensionInstruction } from '../../api/parsers/PassageComprehension/PassageComprehensionInstruction';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const PassageComprehensionInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState<boolean>(false);
  const { pushEvent } = useLogger();
  const { eventHandler, lastEvent } = useHandleAppEvent({
    'cade-ended-event': () => onEnded(),
  });
  useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);

  const passageComprehensionInstructionProps = useParser(
    transformJSONToPassageComprehensionInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(passageComprehensionInstructionProps?.title);

  const onEnded = async () => {
    pushEvent({
      item: 'Passage Comprehension Instruction',
      level: LogLevel.INFO,
      message: 'User clicked next on Passage Comprehension Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
  };

  return (
    passageComprehensionInstructionProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'instruction'}
            footer={
              <Footer
                nextButtonVisible={true}
                onNext={() => setForceEnd(true)}
                role={'instruction'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'Passage Comprehension Instruction',
                    level: LogLevel.INFO,
                    message:
                      'User clicked exit on Passage Comprehension Instruction',
                  });
                  eventHandler(exitEvent());
                }}
                currentStep={passageComprehensionInstructionProps.currentStep}
                totalSteps={passageComprehensionInstructionProps.totalSteps}
                title={passageComprehensionInstructionProps.title}
                role={'instruction'}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <PassageComprehensionInstruction
                key={networkDependentKey(onlineStatus)}
                {...passageComprehensionInstructionProps}
                onEvent={eventHandler}
                forceEnd={forceEnd}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
