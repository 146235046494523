import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { useState } from 'react';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import {
  CADE_QUESTION_COUNTER_ID,
  ReadingComprehensionExercise,
} from '@versant-monorepo/cade';
import { transformJSONToReadingComprehension } from '../../api/parsers/ReadingComprehension/ReadingComprehension';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useBackgroundNoiseAudio } from '../../hooks/useBackgroundNoiseAudio';

export const ReadingComprehensionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const [forceEnd, setForceEnd] = useState(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event),
  });
  const { pushEvent } = useLogger();
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);

  const readingComprehensionExerciseProps = useParser(
    transformJSONToReadingComprehension,
    model as ApiModel
  );

  const { stopRecording, isVideoProctoringEnabled } = useBackgroundNoiseAudio(
    readingComprehensionExerciseProps?.questions[0].id
  );

  useSetDocumentTitle(readingComprehensionExerciseProps?.title);

  const onEnded = async (event: any) => {
    if (isVideoProctoringEnabled) {
      stopRecording();
    }
    let values: {
      radioResponse: string;
      radioResponseId: string;
    }[] = event.value;
    const responseItem = values.map((value) => {
      return {
        id: value.radioResponseId,
        response: value.radioResponse || '',
        type: 'TEXT',
      };
    }) as TextResponse[];
    pushEvent({
      item: 'Reading Comprehension Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Reading Comprehension Exercise',
    });
    const response = await getNextStep(responseItem);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
  };

  return (
    readingComprehensionExerciseProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'exercise'}
            footer={
              <Footer
                nextButtonEnabled={true}
                onNext={() => {
                  setForceEnd(true);
                }}
                role={'exercise'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'Reading Comprehension Exercise',
                    level: LogLevel.INFO,
                    message:
                      'User clicked exit on Reading Comprehension Exercise',
                  });
                  eventHandler(exitEvent());
                }}
                currentStep={readingComprehensionExerciseProps.currentStep}
                totalSteps={readingComprehensionExerciseProps.totalSteps}
                title={readingComprehensionExerciseProps.subTitle}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <ReadingComprehensionExercise
                key={readingComprehensionExerciseProps.questions[0].id}
                {...readingComprehensionExerciseProps}
                onEvent={eventHandler}
                forceEnd={forceEnd}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
