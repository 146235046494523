import { useEffect } from 'react';

type FocusNextButtonProps = {
  elementId: string;
};

export const useFocusNextButton = ({ elementId }: FocusNextButtonProps) => {
  const focusNextButton = () => {
    const nextButton = document.getElementById('nextButton');
    if (!nextButton || nextButton.getAttribute('disabled') === 'disabled') {
      document.getElementById('cade-voice-level-indicator_container')?.focus();
      return;
    }

    document.getElementById('nextButton')?.focus();
  };

  return {
    focusNextButton,
  };
};
