import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './WebCam.scss';
import { useMediaQuery } from 'usehooks-ts';
import { useApi } from '../../hooks/useApi';
import { VfwStorage } from '../../utils/Storage';
import { Logger, LogLevel } from '../../api/Logger';
import { FaceDetector, FilesetResolver } from '@mediapipe/tasks-vision';
import { serializeError } from '../../api/api.utils';
import { useCameraPermissions } from '../../hooks/useCameraPermissions';

declare type RunningMode = 'IMAGE' | 'VIDEO';

const runningMode: RunningMode = 'VIDEO';

export type MLWebcamProps = {
  itemId: MutableRefObject<string>;
  onLoadingError: () => void;
};

const DEFAULT_INTERVAL_TIME = 5000;
const BURST_INTERVAL_TIME = 1000;

const MLWebcam = ({ itemId, onLoadingError }: MLWebcamProps): JSX.Element => {
  const api = useApi();
  const [intervalDelay, setIntervalDelay] = useState(DEFAULT_INTERVAL_TIME);
  const matchesLgBreakPoint = useMediaQuery('(min-width: 1512px)');
  const matchesMdBreakPoint = useMediaQuery('(min-width: 1366px)');
  const width = matchesLgBreakPoint ? 245 : matchesMdBreakPoint ? 225 : 205;
  const height = matchesLgBreakPoint ? 153 : matchesMdBreakPoint ? 140 : 128;
  const [alert, setAlert] = useState(false);
  const cameraId = VfwStorage.getItem('cameraId');

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [faceDetector, setFaceDetector] = useState<FaceDetector | null>(null);
  const [facesDetected, setFacesDetected] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isPlaying = useRef(false);
  const cameraPermission = useCameraPermissions();

  const allStreams = useRef<Array<MediaStream>>([]);

  const enableInitialCam = async () => {
    if (videoRef.current) {
      let constraints;
      if (cameraId) {
        constraints = {
          video: {
            deviceId: {
              exact: cameraId,
            },
          },
        };
      } else {
        constraints = {
          video: true,
        };
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          allStreams.current.push(stream);
          allStreams.current.forEach((stream) => {
            const track = stream.getTracks();
            track.forEach((track) => {
              track.onended = () => {
                console.log(
                  `track ended, cameraPermission: ${cameraPermission}`
                );
                Logger.getInstance().pushEvent({
                  level: LogLevel.ERROR,
                  message: `One of the media track has been closed. It happens on enabling initial cam. Camera Permissions: ${cameraPermission} readyState: ${track.readyState}`,
                  item: 'MONITORING',
                });
              };
            });
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error(err);
          Logger.getInstance().pushEvent({
            level: LogLevel.ERROR,
            message: 'Error trying to initialize video monitoring',
            item: 'MONITORING',
          });
        });
    }
  };

  const enableCam = async () => {
    if (videoRef.current) {
      let constraints;
      if (cameraId) {
        constraints = {
          video: {
            deviceId: {
              exact: cameraId,
            },
          },
        };
      } else {
        constraints = {
          video: true,
        };
      }

      cameraCleanup();
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          allStreams.current.push(stream);
          allStreams.current.forEach((stream) => {
            const track = stream.getTracks();
            track.forEach((track) => {
              track.onended = () => {
                console.log(
                  `track ended, cameraPermission: ${cameraPermission}`
                );
                onLoadingError();
                Logger.getInstance().pushEvent({
                  level: LogLevel.ERROR,
                  message: `One of the media track has been closed. Camera component will be reinitialized. Camera Permissions: ${cameraPermission} readyState: ${track.readyState}`,
                  item: 'MONITORING',
                });
              };
            });
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.addEventListener('loadeddata', predictWebcam);
            videoRef.current.addEventListener(
              'playing',
              () => (isPlaying.current = true)
            );
          }
        })
        .catch((err) => {
          console.error(err);
          Logger.getInstance().pushEvent({
            level: LogLevel.ERROR,
            message: 'Error trying to initialize video monitoring',
            item: 'MONITORING',
          });
        });
    }
  };

  const cameraCleanup = () => {
    allStreams.current.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
    allStreams.current = [];
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject = null;
    }
    alertTimeoutIds.forEach((id) => clearTimeout(id));
  };

  useEffect(() => {
    const initializeFaceDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.15/wasm'
      );
      const detector = await FaceDetector.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath:
            'https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite',
          delegate: 'GPU',
        },
        runningMode,
      });
      setIsLoading(false);
      setFaceDetector(detector);
    };
    initializeFaceDetector();

    return () => {
      cameraCleanup();
    };
  }, []);

  useEffect(() => {
    // enable initial camera without face detection until the model is loaded
    if (isLoading) {
      enableInitialCam();
    } else {
      enableCam();
    }
  }, [isLoading]);

  let lastVideoTime = -1;

  const predictWebcam = async () => {
    if (videoRef.current && faceDetector) {
      const startTimeMs = performance.now();
      if (videoRef.current.currentTime !== lastVideoTime) {
        lastVideoTime = videoRef.current.currentTime;
        const detections = await faceDetector.detectForVideo(
          videoRef.current,
          startTimeMs
        );
        setFacesDetected(detections.detections.length);
      }

      window.requestAnimationFrame(predictWebcam);
    }
  };

  const alertTimeoutIds: Array<NodeJS.Timer> = useMemo(() => [], []);

  useEffect(() => {
    if (facesDetected !== 1) {
      alertTimeoutIds.push(
        setTimeout(() => {
          setAlert(true);
        }, 1000)
      );
      setIntervalDelay(BURST_INTERVAL_TIME);
    } else {
      alertTimeoutIds?.forEach((i) => clearTimeout(i));
      setAlert(false);
      setIntervalDelay(DEFAULT_INTERVAL_TIME);
    }
  }, [facesDetected, alertTimeoutIds]);

  const captureScreenshot = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (context) {
        // Set canvas dimensions to match the video
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        // Draw the current frame of the video onto the canvas
        context.drawImage(
          videoRef.current,
          0,
          0,
          videoRef.current.videoWidth,
          videoRef.current.videoHeight
        );

        // Get the image data (base64 string) from the canvas
        const dataUrl = canvas.toDataURL('image/png');
        return dataUrl;
      }
    }
  };

  useEffect(() => {
    const sendFrame = () => {
      if (!isLoading && isPlaying.current) {
        try {
          const screenshot = captureScreenshot();
          if (screenshot && screenshot.length > 100) {
            const sessionKey = VfwStorage.getItem('proctoringSessionKey');
            if (sessionKey) {
              api.sendProctoringFrame(sessionKey, screenshot, itemId.current);
            } else {
              Logger.getInstance().pushEvent({
                level: LogLevel.ERROR,
                message: 'Missing session key. Cannot send proctoring frame',
                item: 'MONITORING',
              });
            }
          }
        } catch (err) {
          Logger.getInstance().pushEvent({
            level: LogLevel.ERROR,
            message: `Error while sending proctoring frame. Error=${serializeError(
              err
            )}`,
            item: 'MONITORING',
          });
        }
      }
    };

    const interval = setInterval(sendFrame, intervalDelay);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalDelay, isLoading]);

  return (
    <div
      className={`vfw-webcam${
        isLoading ? '--loading' : alert ? '--alert' : ''
      }`}
      style={{ width: `${width}px`, height: `${height}px` }}
      data-testid="webcam-container"
    >
      <video
        id="webcam"
        ref={videoRef}
        autoPlay
        muted
        style={{
          width,
          position: 'absolute',
          transform: 'scaleX(-1)',
        }}
      ></video>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default MLWebcam;
