import React, { useCallback, useContext } from 'react';
import { Paragraph } from '../../components/typography/Paragraph';
import { Title } from '../../components/typography/Title';
import './styles.scss';
import { CadeEvent, exitEvent } from '../../utils/events';
import { ConfigContext } from '../../context/CadeConfigProvider';
import { Box, PrimaryButton, Space, TextButton } from '../../components';
import { MicrophoneIcon } from '../../icons/MicrophoneIcon';

type Props = {
  onEvent: (event: CadeEvent) => void;
};

export function ProblemWithCamera({ onEvent }: Props) {
  const {
    i18n: { t, TranslationComponent },
  } = useContext(ConfigContext);

  const onExit = useCallback(() => onEvent(exitEvent()), [onEvent]);

  return (
    <div className="problem-with-microphone__container">
      <div className="problem-with-microphone__title-container">
        <Title
          className="cade-margin-bottom-4 problem-with-microphone__warning"
          level="large"
        >
          {t('problemWithCamera.title')}
        </Title>
        <Paragraph className="cade-margin-bottom-9" level="large">
          <TranslationComponent i18nKey="problemWithCamera.subtitle" />
        </Paragraph>
      </div>
      <Box role="instruction">
        <div className="problem-with-microphone__box-container">
          <Space
            margin={{ top: 11, bottom: 11 }}
            className="problem-with-microphone__warning"
          >
            <MicrophoneIcon />
          </Space>
          <PrimaryButton onClick={onExit}>
            {t('problemWithCamera.buttons.exitTest')}
          </PrimaryButton>
        </div>
      </Box>
    </div>
  );
}
