import './SecondaryButton.scss';
import { Button, ButtonSize } from '../Button';
import React, { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

type SecondaryButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    icon?: string;
    size?: ButtonSize;
    error?: boolean;
  }>;

export const SecondaryButton = forwardRef<
  HTMLButtonElement,
  SecondaryButtonProps
>(function SecondaryButton({ children, error, ...props }, ref) {
  return (
    <Button
      {...props}
      className={classNames('cade-secondary-button', {
        'cade-secondary-button--error': error,
      })}
      ref={ref}
    >
      {children}
    </Button>
  );
});
