import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';
import icon_primary_default from '../icons/icon_primary_default.svg';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { useContext } from 'react';

export const CADE_NEXT_BUTTON_ID = 'cade-next-button';

export function NextButton({
  children,
  onClick = () => {},
  icon = icon_primary_default,
  ...props
}: PrimaryButtonProps) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <PrimaryButton size={'large'} {...props} onClick={onClick} icon={icon}>
      {children || t('buttons.next')}
    </PrimaryButton>
  );
}
