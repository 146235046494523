import React, { useCallback, useContext, useEffect, useState } from 'react';
import './styles.scss';
import { OnTimeUpdateArgs, PlayAudio } from '@components/PlayAudio';

import { PlayIcon } from '@icons/PlayIcon';
import { SecondaryButton } from '@components/base/Button';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Props = {
  audioSource: string | Blob;
};

export default async function getBlobDuration(blob: Blob | string) {
  const tempVideoElement = document.createElement('video');

  const duration = new Promise((resolve, reject) => {
    tempVideoElement.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoElement.duration === Infinity) {
        tempVideoElement.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoElement.ontimeupdate = () => {
          tempVideoElement.ontimeupdate = null;
          resolve(tempVideoElement.duration);
          tempVideoElement.currentTime = 0;
        };
      } else {
        resolve(tempVideoElement.duration);
      }
    });

    tempVideoElement.addEventListener('error', (event) => reject(event.error));
  });

  tempVideoElement.src =
    typeof blob === 'string' ? blob : window.URL.createObjectURL(blob);

  return duration;
}

export const Audio = ({ audioSource }: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const [textButton, setTextButton] = useState(
    t('backgroundNoiseCheck.button.play')
  );

  useEffect(() => {
    if (typeof audioSource === 'string') {
      return;
    }

    getBlobDuration(audioSource).then((value) =>
      setDuration(typeof value === 'number' ? value : 0)
    );
  }, [audioSource]);

  const src =
    typeof audioSource === 'string'
      ? audioSource
      : URL.createObjectURL(audioSource);

  const handlePlay = useCallback(() => setIsPlaying(true), []);

  const onEnded = useCallback(() => {
    setTextButton(t('backgroundNoiseCheck.button.replay'));
    setIsPlaying(false);
    setProgress(0);
  }, []);

  const onPlay = useCallback(() => {
    setTextButton(t('backgroundNoiseCheck.button.play'));
  }, []);

  const onTimeUpdate = useCallback(
    ({ currentTime, duration: _duration }: OnTimeUpdateArgs) => {
      const divider = typeof audioSource === 'string' ? _duration : duration;

      setProgress((currentTime / (isNaN(divider) ? 1 : divider)) * 100);
    },
    [duration, audioSource]
  );

  return (
    <div className="cade-audio">
      <h2 className="cade-typography-text__small cade-audio__header">
        {t('backgroundNoiseCheck.audio.header')}
      </h2>
      <section className="cade-audio__container">
        <div className="cade-audio__icon" onClick={handlePlay}>
          <PlayIcon />
        </div>
        <div className="cade-audio__button">
          {!isPlaying && (
            <SecondaryButton onClick={handlePlay}>{textButton}</SecondaryButton>
          )}
        </div>
        <div className="cade-audio__bar" />
        {isPlaying && progress > 0 && (
          <div
            className="cade-audio__bar--in-progress"
            style={{ width: `${progress}%` }}
          />
        )}
      </section>
      {isPlaying && (
        <PlayAudio
          disableAudioVisualAnalysis={true}
          onTimeUpdate={onTimeUpdate}
          audioSrc={[src]}
          autoPlay={true}
          onEnded={onEnded}
          onPlay={onPlay}
        />
      )}
    </div>
  );
};
