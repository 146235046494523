import { CadeEvent, useEndedEvent } from '@utils/events';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDelay } from '@utils/useDelay';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import {
  isTimerFinished as isTimerStatusFinished,
  Timer,
  TIMER_STATUS,
  TimerStatus,
} from '@components/base/Timer';
import './styles.scss';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { Accordion } from '@components/Accordion';
import { TextArea } from '@components/base/TextArea';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface PassageReconstructionProps {
  count: number;
  total: number;
  title: string;
  onEvent: (event: CadeEvent) => void;
  question1: string;
  question2: string;
  passage: string[];
  instruction: string;
  timeLeft: number;
  forceEnd?: boolean;
  passageLabel?: string;
}

type SummaryAndOpinionEndEventValue = {
  opinion: string;
  summary: string;
};

export function SummaryAndOpinion({
  question2,
  question1,
  passage,
  forceEnd,
  onEvent,
  passageLabel,
  timeLeft,
  instruction,
  total,
  title,
  count,
}: PassageReconstructionProps) {
  const [summary, setSummary] = useState('');
  const [opinion, setOpinion] = useState('');
  const [accordionOpened, setAccordionOpened] = useState<'summary' | 'opinion'>(
    'summary'
  );
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.IN_PROGRESS
  );
  const delay = useDelay();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const { sendEndEvent } =
    useEndedEvent<SummaryAndOpinionEndEventValue>(onEvent);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent({
        opinion,
        summary,
      });
    }
  }, [forceEnd]);

  const onTimerChanged = useCallback(
    (status: TimerStatus) => {
      setTimerStatus(status);
      if (isTimerStatusFinished(status)) {
        delay.set(() => {
          sendEndEvent({
            opinion,
            summary,
          });
        });
      }
    },
    [opinion, summary]
  );

  const calculateWordCount = (text: string): number => {
    return text.split(/\s+/).filter((word) => word !== '').length;
  };

  return (
    <div className={'summary-and-opinion-exercise'}>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {title}
        </Title>
        <Instruction level="small">{instruction}</Instruction>
      </InstructionContainer>
      <div className={'summary-and-opinion-exercise__counter-box'}>
        <QuestionCounter
          count={count}
          total={total}
          title={t('summaryAndOpinion.exercise.counterTitle')}
        />
        <div className={'summary-and-opinion-exercise__timer-container'}>
          {isTimerStatusFinished(timerStatus) && (
            <Paragraph
              weight={'bold'}
              className={'summary-and-opinion-exercise__timer-msg'}
            >
              {t('timer.timeIsOver')}
            </Paragraph>
          )}
          <Timer
            time={timeLeft}
            timerStatus={timerStatus}
            onChangeStatus={onTimerChanged}
          />
        </div>
      </div>
      <Box
        role={'exercise'}
        className={'summary-and-opinion-exercise__content'}
      >
        <DualPaneLayout
          leftPane={
            <div className={'summary-and-opinion-exercise__content--left'}>
              <Paragraph level={'large'} weight={'bold'}>
                {passageLabel}
              </Paragraph>
              <div className={'summary-and-opinion-exercise__content-passage'}>
                {passage.map((el, index) => (
                  <Paragraph
                    key={el + index}
                    level={'small'}
                    onCopy={(event) => {
                      event.preventDefault();
                    }}
                  >
                    {el}
                  </Paragraph>
                ))}
              </div>
            </div>
          }
          rightPane={
            <div className={'summary-and-opinion-exercise__content--right'}>
              <Accordion
                key={'accordion1'}
                onChange={() => setAccordionOpened('summary')}
                id={'summary'}
                expanded={accordionOpened === 'summary'}
                label={question1}
                className={accordionOpened === 'summary' ? 'expanded' : ''}
              >
                <div
                  className={`summary-and-opinion-exercise__textarea-wrapper ${
                    accordionOpened === 'summary' ? 'expanded' : ''
                  }`}
                >
                  <TextArea.Field
                    autoFocus={accordionOpened === 'summary'}
                    disabled={isTimerStatusFinished(timerStatus)}
                    className="summary-and-opinion__textarea"
                    ariaLabel={question1}
                    onChange={(text) => {
                      setSummary(text!);
                      return '';
                    }}
                    onPaste={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>
                <div className={'summary-and-opinion-exercise__word-counter'}>
                  {`${t(
                    'summaryAndOpinion.exercise.wordCount'
                  )} ${calculateWordCount(summary)}`}
                </div>
              </Accordion>
              <Accordion
                key={'accordion2'}
                onChange={() => setAccordionOpened('opinion')}
                id={'opinion'}
                expanded={accordionOpened === 'opinion'}
                label={question2}
                className={accordionOpened === 'opinion' ? 'expanded' : ''}
              >
                <div
                  className={`summary-and-opinion-exercise__textarea-wrapper`}
                >
                  <TextArea.Field
                    autoFocus={accordionOpened === 'opinion'}
                    disabled={isTimerStatusFinished(timerStatus)}
                    className="summary-and-opinion__textarea"
                    ariaLabel={question1}
                    onPaste={(event) => {
                      event.preventDefault();
                    }}
                    onChange={(text) => {
                      setOpinion(text!);
                      return '';
                    }}
                  />
                </div>
                <div className={'summary-and-opinion-exercise__word-counter'}>
                  {`${t(
                    'summaryAndOpinion.exercise.wordCount'
                  )} ${calculateWordCount(opinion)}`}
                </div>
              </Accordion>
            </div>
          }
        />
      </Box>
    </div>
  );
}
