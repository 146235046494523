import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractAudioResourcesFromResponse,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';

interface CadeSpeakingSituationsInstructionModel {
  title: string;
  subTitle: string;
  instruction: string;
  totalSteps: number;
  currentStep: number;
  audioSrc: string[];
  listenPartDuration: number;
  onEvent: (event: CadeEvent) => void;
}
export function transformJSONToSpeakingSituationsInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeSpeakingSituationsInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);

  if (!validateResult) {
    console.error('validation failed');
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const audio = extractAudioResourcesFromResponse(json);

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    audioSrc: audio,
    onEvent: (event: CadeEvent) => {},
    listenPartDuration: 10000,
  };
}
