import React, { useCallback, useState, useContext } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { ListeningStatus } from '@components/Listening/commons';
import { ListenRecordExerciseContent } from '../../components/ListenRecordExerciseContent';
import { CadeEvent } from '@utils/events';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import './styles.scss';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface ISentenceBuildsProps {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: any;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  rightLabel: string;
  forceEnd: boolean;
  instruction?: string;
}

export function SentenceBuildsExercise({
  count,
  total,
  subTitle,
  audioSrc,
  timeouts,
  onEvent,
  leftLabel,
  rightLabel,
  forceEnd,
  instruction,
}: ISentenceBuildsProps) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const [warningVisible, setWarningVisible] = useState(false);

  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;

  const _onEvent = useCallback(
    (event: CadeEvent) => {
      onEvent(event);
    },
    [onEvent]
  );
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  return (
    <>
      <div className="sentence-builds-exercise">
        <InstructionContainer>
          <Title centered id={CADE_CONTENT_TITLE_ID}>
            {subTitle}
          </Title>
          <Instruction level="small">
            {instruction ?? t('sentenceBuilds.exercise.instruction')}
          </Instruction>
        </InstructionContainer>
        <QuestionCounter
          count={count}
          total={total}
          title={t('sentenceBuilds.exercise.questionCountLabel')}
        />
        <Box role="exercise" className="example-box">
          <ListenRecordExerciseContent
            audioSrc={audioSrc}
            timeouts={timeouts}
            onEvent={_onEvent}
            listeningStatus={listeningStatus}
            setListeningStatus={setListeningStatus}
            forceEnd={forceEnd}
            setWarningVisible={setWarningVisible}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            playBeepSound={true}
          />
        </Box>
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            weCantHearYou={warningVisible}
            numOfCircles={6}
            listenMode={listeningStatus !== 'COMPLETED'}
            currentValue={voiceLevel}
          />
        </div>
      </div>
    </>
  );
}
