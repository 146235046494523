import {
  checkPlayParamsUrls,
  extractActorsData,
  extractAudioResourcesFromResponse,
  extractCommonItemsForInstruction,
  ScriptLine,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { ApiModel, Toc } from '../../api.interfaces';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeOppositesInstructionModel {
  title: string;
  subTitle: string;
  instruction: string;
  totalSteps: number;
  currentStep: number;
  audioSrc: string[];
  listenPartDuration: number;
  scripts: ScriptLine[];
  startActorsAnimationOnAudioIndex: number;
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  rightLabel: string;
}

export function transformJSONToOppositesInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeOppositesInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];

  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);

  if (!validateResult) {
    console.error('validation failed');
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const audio = extractAudioResourcesFromResponse(json);
  let leftLabel = i18n.t('opposites.instruction.labels.left');
  let rightLabel = i18n.t('opposites.instruction.labels.right');
  if (
    json.items[0] &&
    json.items[0].actions[0] &&
    json.items[0].actions[0].displayParams
  ) {
    leftLabel = json.items[0].actions[0].displayParams.display[0][1];
    rightLabel = json.items[0].actions[0].displayParams.display[0][3];
  }

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    scripts: extractActorsData(json),
    startActorsAnimationOnAudioIndex: 1,
    audioSrc: audio,
    onEvent: (event: CadeEvent) => {},
    listenPartDuration: 10000,
    leftLabel: leftLabel,
    rightLabel: rightLabel,
  };
}
