import './styles.scss';
import { Title } from '@components/typography/Title';
import { Paragraph } from '@components/typography/Paragraph';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Text } from '@components/typography/Text';
import { Space } from '@components//Space';
import { useContext } from 'react';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export interface ISystemChecksHeaderProps {
  headerText: string;
  title: string;
  successMsg?: string;
  currentStep: number | undefined;
  totalSteps: number | undefined;
  fontLevel?: 'small' | 'normal' | 'large' | undefined;
}

export function ChecksHeader({
  headerText,
  title,
  totalSteps,
  currentStep,
  successMsg,
  fontLevel = 'large',
}: ISystemChecksHeaderProps) {
  const {
    i18n: { TranslationComponent },
  } = useContext(ConfigContext);
  return (
    <Space margin={{ bottom: 2 }} className="system-checks-header">
      <Title
        id={CADE_CONTENT_TITLE_ID}
        level={fontLevel}
        className={'system-checks__header-text-container'}
        centered={true}
      >
        {headerText && (
          <Text level="normal" className="system-checks__header-text">
            {headerText}
          </Text>
        )}
        {title}
      </Title>
      {currentStep && totalSteps ? (
        <Paragraph
          level="smaller"
          weight="bold"
          className="system-checks__steps"
        >
          <TranslationComponent
            i18nKey="checks.steps"
            values={{
              currentStep,
              totalSteps,
            }}
          />
        </Paragraph>
      ) : (
        ''
      )}
      {successMsg && (
        <Paragraph level={'large'} className={'success'}>
          {successMsg}
        </Paragraph>
      )}
    </Space>
  );
}
