import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getVideoMimeType } from '../../utils/prechecks';
import Webcam from 'react-webcam';
import { RecordAVideoStateStatus } from './RecordAVideo';

const MIN_VIDEO_LENGTH_MS = 11000;

type UseDoubleRecorderReturnType = {
  startRecording: () => void;
  stopRecording: () => void;
  stopRecordingDisabled: boolean;
  videoUrl: string | null;
  lowQualityBlob: Blob | null;
  recordDateString: string | undefined;
  videoDuration: number;
};

export const useDoubleRecorder = ({
  webcamRef,
  onStateChange,
}: {
  webcamRef: RefObject<Webcam>;
  onStateChange: (status: RecordAVideoStateStatus) => void;
}): UseDoubleRecorderReturnType => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const lowQualityMediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [lowQualityRecordedChunks, setLowQualityRecordedChunks] = useState([]);
  const [lowQualityBlob, setLowQualityBlob] = useState<Blob | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [stopRecordingDisabled, setStopRecordingDisabled] = useState(false);
  const [recordDateString, setRecordDateString] = useState<string>();
  const [videoStartTime, setVideoStartTime] = useState<Date>();
  const [videoDuration, setVideoDuration] = useState<number>(0);

  useEffect(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: getVideoMimeType(),
      });
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
      setRecordedChunks([]);
    }
    if (lowQualityRecordedChunks.length) {
      const blob = new Blob(lowQualityRecordedChunks, {
        type: getVideoMimeType(),
      });
      setLowQualityBlob(blob);
      setLowQualityRecordedChunks([]);
    }
  }, [recordedChunks, lowQualityRecordedChunks]);

  const startRecording = useCallback(() => {
    onStateChange('RECORDING');
    if (webcamRef.current) {
      mediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream as MediaStream,
        {
          mimeType: getVideoMimeType(),
          videoBitsPerSecond: 3000000, //Increased to 3Mbps to achieve High Definition. Example file should weight 1.5mb
        }
      );
      lowQualityMediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream as MediaStream,
        {
          mimeType: getVideoMimeType(),
          videoBitsPerSecond: 50000,
        }
      );
      mediaRecorderRef.current.addEventListener('dataavailable', (event) =>
        handleDataAvailable(event, setRecordedChunks)
      );
      lowQualityMediaRecorderRef.current.addEventListener(
        'dataavailable',
        (event) => handleDataAvailable(event, setLowQualityRecordedChunks)
      );
      mediaRecorderRef.current.start();
      lowQualityMediaRecorderRef.current.start();
      setVideoStartTime(new Date());
      setStopRecordingDisabled(true);
      setTimeout(() => setStopRecordingDisabled(false), MIN_VIDEO_LENGTH_MS);
    }
  }, [
    webcamRef,
    mediaRecorderRef,
    lowQualityMediaRecorderRef,
    setStopRecordingDisabled,
    setVideoStartTime,
  ]);

  const stopRecording = useCallback(() => {
    mediaRecorderRef.current?.stop();
    lowQualityMediaRecorderRef.current?.stop();
    const now = new Date();
    setVideoDuration(
      videoStartTime ? (now.getTime() - videoStartTime.getTime()) / 1000 : 0
    );
    onStateChange('RECORDED');
    setRecordDateString(now.toISOString());
  }, [
    mediaRecorderRef,
    lowQualityMediaRecorderRef,
    webcamRef,
    recordedChunks,
    setRecordDateString,
    videoStartTime,
    setVideoDuration,
  ]);
  const handleDataAvailable = (
    { data }: { data: any },
    setter: Dispatch<SetStateAction<never[]>>
  ) => {
    if (data.size > 0) {
      setter((prev) => prev.concat(data));
    }
  };

  return {
    startRecording,
    stopRecording,
    stopRecordingDisabled,
    videoUrl,
    lowQualityBlob,
    recordDateString,
    videoDuration,
  };
};
