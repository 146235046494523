import React, { useContext, useEffect } from 'react';
import './VoiceLevelIndicator.scss';
import { HeadsetIcon } from '@icons/HeadsetIcon';
import { MicOnIcon } from '@icons/MicOnIcon';
import { useBeep } from '@utils/useBeep';
import { useVolume } from '@context/Volume.context';
import { Text } from '@components/typography/Text';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Props = {
  currentValue: number;
  numOfCircles?: number;
  listenMode?: boolean;
  weCantHearYou?: boolean;
};

const MAX_OUTPUT_VOLUME_LEVEL = 27;
const MAX_INPUT_VOLUME_LEVEL = 100;

export const VoiceLevelIndicator = ({
  currentValue = 0,
  numOfCircles = 8,
  listenMode = true,
  weCantHearYou = false,
}: Props) => {
  const beep = useBeep();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const { state } = useVolume();
  const globalValue = state.outputValue;

  useEffect(() => {
    if (weCantHearYou) {
      const playBeep = async () => {
        beep && (await beep.playErrorTone());
      };
      playBeep();
    }
  }, [weCantHearYou]);

  function getVolume() {
    if (listenMode) {
      return Math.floor(globalValue / (MAX_OUTPUT_VOLUME_LEVEL / numOfCircles));
    }
    return Math.floor(currentValue / (MAX_INPUT_VOLUME_LEVEL / numOfCircles));
  }

  const generateCircles = (_: any, index: number) => {
    return (
      <span
        key={index}
        className={`circle-unfilled ${
          getVolume() < 1 ? 'circle-disabled' : null
        } ${getVolume() > index ? 'circle-filled' : null}`}
      />
    );
  };

  const circles = Array.from({ length: numOfCircles });

  return (
    <div
      aria-label={'Voice level indicator'}
      id="cade-voice-level-indicator_container"
      role={'status'}
    >
      <div
        role={'alert'}
        aria-live={'polite'}
        aria-atomic={true}
        className={`cade-voice-level-indicator ${
          weCantHearYou ? 'we-cant-hear-you' : null
        }`}
      >
        <div aria-hidden={'true'}>
          {listenMode ? <HeadsetIcon /> : <MicOnIcon />}
        </div>
        <div
          aria-hidden={'true'}
          className="cade-voice-level-indicator__bar"
          data-testid="cade-voice-indicator-circle-container"
        >
          {circles.map(generateCircles)}
        </div>
        {weCantHearYou && (
          <div
            className="cade-voice-level-indicator__error"
            data-testid="cade-voice-indicator-we-cant-hear-you"
          >
            <Text level="smaller" weight="bold">
              {t('voiceLevelIndicator.message')}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
