import { CadeEvent } from '@utils/events';
import React, { useState } from 'react';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import { useVolume } from '@context/Volume.context';
import { Box, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import './styles.scss';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface ReadingComprehensionInstructionProps {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  forceEnd: boolean;
}

export function ReadingComprehensionInstruction({
  forceEnd,
  instruction,
  audioSrc,
  onEvent,
  subTitle,
}: ReadingComprehensionInstructionProps) {
  const [exampleStatus, setExampleStatus] =
    useState<ListenRecordInstructionStatus>('INIT');

  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  return (
    <>
      <div className={'read-aloud-instruction-wrapper'}>
        <div>
          <InstructionContainer>
            <Title centered id={CADE_CONTENT_TITLE_ID} level="large">
              {subTitle}
            </Title>
            <Instruction>{instruction}</Instruction>
          </InstructionContainer>
          <Box role="instruction" className="cade-margin-bottom-5">
            <ListenRecordInstructionContent
              status={exampleStatus}
              audioSrc={audioSrc}
              listenPartDuration={0}
              onStatusChange={setExampleStatus}
              onEvent={onEvent}
              forceEnd={forceEnd}
            />
          </Box>
        </div>
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            numOfCircles={6}
            currentValue={voiceLevel}
            listenMode={true}
          />
        </div>
      </div>
    </>
  );
}
