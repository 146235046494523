import {
  createContext,
  Dispatch,
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
} from 'react';

export interface VolumeState {
  value: number;
  isRepeat: boolean;
  outputValue: number;
  microphoneOutputValue: number;
}

type Action<T, K> = {
  type: T;
  payload: K;
};

export type UpdateVolumeAction = Action<'UPDATE_VOLUME', number>;
export type UpdateOutputVolumeAction = Action<'UPDATE_OUTPUT_VOLUME', number>;
export type CancelRepeatAction = Action<'CANCEL_REPEAT', never>;
export type UpdateMicrophoneOutputValueAction = Action<
  'UPDATE_MICROPHONE_OUTPUT_VALUE',
  number
>;

export type Actions =
  | UpdateVolumeAction
  | UpdateOutputVolumeAction
  | CancelRepeatAction
  | UpdateMicrophoneOutputValueAction;

const initialState: VolumeState = {
  value: 50,
  isRepeat: false,
  outputValue: 0,
  microphoneOutputValue: 0,
};

const VolumeContext = createContext(initialState);
export const VolumeDispatchContext = createContext<Dispatch<Actions>>(() => {});

export function useVolume() {
  const state = useContext(VolumeContext);
  const dispatch = useContext(VolumeDispatchContext);
  return { state, dispatch };
}

export const UPDATE_VOLUME = 'UPDATE_VOLUME';
export const CANCEL_REPEAT = 'CANCEL_REPEAT';
export const UPDATE_OUTPUT_VOLUME = 'UPDATE_OUTPUT_VOLUME';
export const UPDATE_MICROPHONE_OUTPUT_VALUE = 'UPDATE_MICROPHONE_OUTPUT_VALUE';

export const updateVolume = (payload: number) =>
  ({ type: UPDATE_VOLUME, payload } as UpdateVolumeAction);
export const updateOutputVolume = (payload: number) =>
  ({
    type: UPDATE_OUTPUT_VOLUME,
    payload,
  } as UpdateOutputVolumeAction);
export const cancelRepeat = () =>
  ({ type: CANCEL_REPEAT } as CancelRepeatAction);
export const updateMicrophoneOutputValue = (payload: number) =>
  ({
    type: UPDATE_MICROPHONE_OUTPUT_VALUE,
    payload,
  } as UpdateMicrophoneOutputValueAction);

export const volumeReducer: Reducer<VolumeState, Actions> = (
  volume,
  action
) => {
  switch (action.type) {
    case UPDATE_VOLUME: {
      return {
        ...volume,
        value: action.payload,
      };
    }

    case CANCEL_REPEAT: {
      return {
        ...volume,
        isRepeat: false,
      };
    }

    case UPDATE_OUTPUT_VOLUME: {
      return {
        ...volume,
        outputValue: action.payload,
      };
    }

    case UPDATE_MICROPHONE_OUTPUT_VALUE: {
      return {
        ...volume,
        microphoneOutputValue: action.payload,
      };
    }

    default: {
      return { ...volume };
    }
  }
};

export type VolumeProviderProps = PropsWithChildren;

export function VolumeProvider({ children }: VolumeProviderProps) {
  const [volume, dispatch] = useReducer(volumeReducer, initialState);

  return (
    <VolumeContext.Provider value={volume}>
      <VolumeDispatchContext.Provider value={dispatch}>
        {children}
      </VolumeDispatchContext.Provider>
    </VolumeContext.Provider>
  );
}
