import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractActorsDataForConversation,
  extractAudioResourcesFromResponse,
  ScriptLine,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeConversationInstructionModel {
  title: string;
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
  listenPartDuration: number;
  scripts: ScriptLine[];
  startActorsAnimationOnAudioIndex: number;
}
export function transformJSONToConversationInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeConversationInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const display = item.actions.find((el) => el.actionType === 'display');
  const [letter, sectionName] = item.titles;
  const [instructionText] =
    display?.displayParams?.cueInstructions?.[0].instructions ??
    (display?.displayParams?.display[0] as string[]);
  const title = i18n.t('part', { letter, sectionName });
  const totalSteps = tocArray.length;
  const currentStep =
    tocArray.findIndex((el) => el.itemType === item.subType) + 1;
  const audio = extractAudioResourcesFromResponse(json);

  return {
    title,
    subTitle: title,
    instruction: instructionText,
    audioSrc: audio,
    totalSteps,
    currentStep,
    onEvent: (event: CadeEvent) => {},
    listenPartDuration: 10000,
    scripts: extractActorsDataForConversation(json),
    startActorsAnimationOnAudioIndex: 1,
  };
}
