import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadePassageReconstructionInstructionModel {
  title: string;
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
  bottomLeftLabel: string;
  bottomRightLabel: string;
  bottomLeftLabelForBox: string;
  bottomRightLabelForBox: string;
}
export function transformJSONToPassageReconstructionInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadePassageReconstructionInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
    'items.actions.displayParams.display',
    'items.actions.playParams.url',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const display = json.items[0].actions.find(
    (el) => el.actionType === 'display'
  );

  let bottomLeftLabel = i18n.t(
    'passageReconstruction.exercise.labels.bottomLeft'
  );
  let bottomRightLabel = i18n.t(
    'passageReconstruction.exercise.labels.bottomRight'
  );
  let bottomLeftLabelForBox = i18n.t(
    'passageReconstruction.exercise.labels.box.bottomLeft'
  );
  let bottomRightLabelForBox = i18n.t(
    'passageReconstruction.exercise.labels.box.bottomRight'
  );

  if (display) {
    if (
      display.displayParams?.display[0] &&
      display.displayParams?.display[0][1]
    ) {
      bottomLeftLabelForBox = display.displayParams?.display[0][1];
    }
    if (
      display.displayParams?.display[0] &&
      display.displayParams?.display[0][2]
    ) {
      bottomLeftLabel = display.displayParams?.display[0][2];
    }
    if (
      display.displayParams?.display[0] &&
      display.displayParams?.display[0][3]
    ) {
      bottomRightLabelForBox = display.displayParams?.display[0][3];
    }
    if (
      display.displayParams?.display[0] &&
      display.displayParams?.display[0][4]
    ) {
      bottomRightLabel = display.displayParams?.display[0][4];
    }
  }

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    bottomLeftLabel,
    bottomRightLabel,
    bottomLeftLabelForBox,
    bottomRightLabelForBox,
  };
}
