export function BannerAlertIcon() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>""</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6559 1.44613L23.7385 18.633C24.272 19.5425 23.9631 20.7098 23.0485 21.2403C22.7553 21.4104 22.4219 21.5 22.0825 21.5H1.91723C0.858371 21.5 0 20.6465 0 19.5936C0 19.2561 0.0901257 18.9246 0.261167 18.633L10.3438 1.44613C10.8773 0.536679 12.0513 0.229489 12.9659 0.760002C13.2516 0.925704 13.4893 1.16207 13.6559 1.44613ZM11.25 8C10.8358 8 10.5 8.33579 10.5 8.75V13.25C10.5 13.6642 10.8358 14 11.25 14H12.75C13.1642 14 13.5 13.6642 13.5 13.25V8.75C13.5 8.33579 13.1642 8 12.75 8H11.25ZM11.25 15.5C10.8358 15.5 10.5 15.8358 10.5 16.25V17.75C10.5 18.1642 10.8358 18.5 11.25 18.5H12.75C13.1642 18.5 13.5 18.1642 13.5 17.75V16.25C13.5 15.8358 13.1642 15.5 12.75 15.5H11.25Z"
      />
    </svg>
  );
}
