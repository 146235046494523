import {
  CADE_QUESTION_COUNTER_ID,
  SentenceCompletionExercise,
} from '@versant-monorepo/cade';
import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { useState } from 'react';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToSentenceCompletion } from '../../api/parsers/SentenceCompletion/SentenceCompletion';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useBackgroundNoiseAudio } from '../../hooks/useBackgroundNoiseAudio';

export const SentenceCompletionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const [forceEnd, setForceEnd] = useState(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as string),
  });
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);
  const { pushEvent } = useLogger();

  const sentenceCompletionExerciseProps = useParser(
    transformJSONToSentenceCompletion,
    model as ApiModel
  );

  const { stopRecording, isVideoProctoringEnabled } = useBackgroundNoiseAudio(
    sentenceCompletionExerciseProps?.responseId
  );

  useSetDocumentTitle(sentenceCompletionExerciseProps?.title);

  const onEnded = async (value: string) => {
    if (isVideoProctoringEnabled) {
      stopRecording();
    }
    const responseItem = {
      id: sentenceCompletionExerciseProps?.responseId as string,
      response: value || '',
      type: 'TEXT',
    } as TextResponse;
    pushEvent({
      item: 'Sentence Completion Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Sentence Completion Exercise',
    });
    const response = await getNextStep([responseItem]);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
  };

  return (
    sentenceCompletionExerciseProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'exercise'}
            footer={
              <Footer
                nextButtonEnabled={true}
                onNext={() => {
                  setForceEnd(true);
                }}
                role={'exercise'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'Sentence Completion Exercise',
                    level: LogLevel.INFO,
                    message:
                      'User clicked exit on Sentence Completion Exercise',
                  });
                  eventHandler(exitEvent());
                }}
                currentStep={sentenceCompletionExerciseProps.currentStep}
                totalSteps={sentenceCompletionExerciseProps.totalSteps}
                title={sentenceCompletionExerciseProps.subTitle}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <SentenceCompletionExercise
                key={sentenceCompletionExerciseProps.responseId}
                {...sentenceCompletionExerciseProps}
                onEvent={eventHandler}
                forceEnd={forceEnd}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
