import React, { useCallback, useEffect, useState } from 'react';
import { Input, Paragraph, useFeature } from '@versant-monorepo/cade';
import { Link, useNavigate } from 'react-router-dom';
import { vfwRoutes } from '../../vfw-routes';
import { Footer } from '../../components/Footer/Footer';
import icon from '../../assets/ready-to-continue-image.svg';
import { Template } from '../../components/Template/Template';
import './styles.scss';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { ContentTitle } from 'components/ContentTitle/ContentTitle';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useExitModal } from 'hooks/useModal';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import { VfwStorage } from '../../utils/Storage';

export const ReadyToContinueWrapper = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('readyToContinueWrapper.documentTitle'));
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [checked, setChecked] = useState(false);
  const [focus, setFocus] = useState(false);
  const useNewCheckbox = useFeature('USE_NEW_CHECKBOX');
  const { pushEvent } = useLogger();
  useFocus(CONTENT_TITLE_ID, []);
  const exitModal = useExitModal();
  const onLabelKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      (event.currentTarget as HTMLLabelElement).click();
    }
    return;
  };

  const checkboxOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setChecked(event.target.checked),
    []
  );
  const checkboxLabelOnFocus = useCallback(() => setFocus(true), []);
  const checkboxLabelOnBlur = useCallback(() => setFocus(false), []);
  const checkboxLabelOnKeyDown = useCallback(
    (event: any) => onLabelKeyDown(event),
    []
  );
  const postponeTestOnClick = useCallback(
    () => exitModal.show('POSTPONE_TEST'),
    [exitModal]
  );

  const handleReadyToContinue = () => {
    const testRefreshAttempt = VfwStorage.getItem('isRefresh') === 'true';
    navigate(testRefreshAttempt ? vfwRoutes.test : vfwRoutes.systemCheck);
  };

  useEffect(() => {
    return () => VfwStorage.removeItem('isRefresh');
  }, []);

  return (
    <>
      <Template
        footer={
          <Footer
            buttonLabel={t('buttons.start')}
            nextButtonEnabled={checked}
            nextButtonDisableImage={true}
            onNext={() => {
              pushEvent({
                item: 'Ready to Continue',
                level: LogLevel.INFO,
                message: 'User clicked next on Ready to Continue',
              });
              handleReadyToContinue();
            }}
          />
        }
        header={<></>}
      >
        <div className={'ready-to-continue'}>
          <div className="ready-to-continue__top">
            <ContentTitle level={'large'} centered={true}>
              {t('readyToContinueWrapper.title')}
            </ContentTitle>
            <div className="ready-to-continue__content">
              <div className={'ready-to-continue__content-left'}>
                <img
                  src={icon}
                  alt="ready to continue image"
                  aria-hidden={true}
                />
              </div>
              <div className={'ready-to-continue__content-right'}>
                <Paragraph level={'large'} weight={'bold'}>
                  {t('readyToContinueWrapper.p')}
                </Paragraph>
                <div key={`leave`} className="ready-to-continue__options">
                  <label className="ready-to-continue__label">
                    <input
                      className="ready-to-continue__input-radio"
                      name="speaker-check"
                      type="radio"
                      onChange={(event) =>
                        setSelectedOption(event.target.value)
                      }
                      value={'leave'}
                    />
                    {t('readyToContinueWrapper.leave')}
                  </label>
                </div>
                <div key={`technical`} className="ready-to-continue__options">
                  <label className="ready-to-continue__label">
                    <input
                      className="ready-to-continue__input-radio"
                      name="speaker-check"
                      onChange={(event) =>
                        setSelectedOption(event.target.value)
                      }
                      type="radio"
                      value={'technical'}
                    />
                    {t('readyToContinueWrapper.problem')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="ready-to-continue__bottom">
            <Paragraph level={'small'} weight={'bold'}>
              {t('readyToContinueWrapper.p2')}
            </Paragraph>
            <div
              className={'sr-only'}
              role={'status'}
              aria-live={'polite'}
              aria-atomic={true}
            >
              {!useNewCheckbox &&
                focus &&
                !checked &&
                t('readyToContinueWrapper.p3')}
              {!useNewCheckbox &&
                focus &&
                checked &&
                t('readyToContinueWrapper.p4')}
              {useNewCheckbox && focus && t('readyToContinueWrapper.p5')}
            </div>
            <div
              className={`ready-to-continue__check-container ${
                useNewCheckbox ? '' : 'old-checkbox'
              }`}
            >
              {useNewCheckbox ? (
                <Input.Checkbox
                  disabled={!selectedOption}
                  onChange={checkboxOnChange}
                  labelProps={{
                    onFocus: checkboxLabelOnFocus,
                    onBlur: checkboxLabelOnBlur,
                    tabIndex: 0,
                    onKeyDown: checkboxLabelOnKeyDown,
                  }}
                >
                  {t('readyToContinueWrapper.confirm')}
                </Input.Checkbox>
              ) : (
                <>
                  <input
                    disabled={!selectedOption}
                    className={'ready-to-continue__input'}
                    id={'check'}
                    type="checkbox"
                    onChange={(event) => setChecked(event.target.checked)}
                  />
                  <label
                    className={'ready-to-continue__input-label'}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    tabIndex={0}
                    onKeyDown={(event) => onLabelKeyDown(event as any)}
                    htmlFor={'check'}
                  >
                    {t('readyToContinueWrapper.confirm')}
                  </label>
                </>
              )}
            </div>
            <Link
              to="#"
              onClick={postponeTestOnClick}
              className="vfw__blue-link"
            >
              {t('readyToContinueWrapper.later')}
            </Link>
          </div>
        </div>
      </Template>
    </>
  );
};
