import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForExercise,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadePassageComprehensionModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  count: number;
  total: number;
  subTitle: string;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  microphoneId?: string;
  passageLabel: string;
  leftLabel: string;
  rightLabel: string;
  responseId?: string;
  instruction: string;
  questionCount: number;
}
export function transformJSONToPassageComprehension(
  json: ApiModel,
  tocArray: Toc[]
): CadePassageComprehensionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const commonItems = extractCommonItemsForExercise(json, tocArray);
  const itemsInPassage = 3;
  const passageCount = Math.ceil(commonItems.count / itemsInPassage);
  const passageTotal = Math.ceil(commonItems.total / itemsInPassage);
  // which is this question in the passage
  const questionCount = commonItems.count - (passageCount - 1) * itemsInPassage;

  const instruction =
    json.items[0].cueInstructions?.[0] ??
    i18n.t('passageComprehension.exercise.instruction');
  const rightLabel =
    json.items[0].microInstructions?.[1] ??
    i18n.t('passageComprehension.exercise.labels.right');
  return {
    ...commonItems,
    // why - because we have now 6 parts, but every 3 belongs to one passage
    // so instead of (1,2,3) of 6 we want to show 1 of 2
    // and instead of (4,5,6) of 6 we want to show 2 of 2
    count: passageCount,
    total: passageTotal,
    passageLabel: i18n.t('passageComprehension.listenToPassage', {
      passageCount,
    }),
    leftLabel: i18n.t('passageComprehension.listenToQuestion', {
      questionCount,
    }),
    rightLabel: rightLabel,
    onEvent: (event: CadeEvent) => {},
    instruction,
    questionCount,
  };
}
