import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { vfwRoutes } from '../vfw-routes';
import { isCheckAvailable } from '../utils/prechecks';
import { LogLevel } from '../api/Logger';
import { useLogger } from './useLogger';
import { serializeError } from '../api/api.utils';
import { useExitTest } from './useExitTest';

export const useCheckProgress = (checkId: string) => {
  const navigate = useNavigate();
  const { pushEvent } = useLogger();
  const exitTest = useExitTest();

  useEffect(() => {
    try {
      if (!isCheckAvailable(checkId)) {
        exitTest();
        return;
      }
    } catch (e) {
      pushEvent({
        level: LogLevel.ERROR,
        message: `useCheckProgress error: ${serializeError(e)}`,
        item: 'useCheckProgress',
        eventType: 'ERROR',
      });
      navigate(vfwRoutes.serverError);
    }
  }, [checkId]);
};
