import React, { useCallback, useContext, useState } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { ListeningStatus } from '@components/Listening/commons';
import { ListenRecordExerciseContent } from '../../components/ListenRecordExerciseContent';
import { CadeEvent } from '@utils/events';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import {
  isTimerFinished,
  Timer,
  TimerMessage,
  TimerStatus,
  TIMER_STATUS,
} from '@src/components/base/Timer';
import './styles.scss';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IStoryRetellExerciseProps {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: any;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  leftLabel?: string;
  rightLabel?: string;
  onEvent: (event: CadeEvent) => void;
  timeLeft: number;
  forceEnd?: boolean;
  instruction: string;
  questionCounterLabel?: string;
}

export function StoryRetellExercise({
  count,
  total,
  subTitle,
  audioSrc,
  timeouts,
  leftLabel,
  rightLabel,
  onEvent,
  timeLeft,
  forceEnd = false,
  instruction,
  questionCounterLabel,
}: IStoryRetellExerciseProps) {
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const [warningVisible, setWarningVisible] = useState(false);
  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const _leftLabel = leftLabel ?? t('storyRetell.exercise.leftLabel');
  const _rightLabel = rightLabel ?? t('storyRetell.exercise.rightLabel');
  const _questionCounterLabel =
    questionCounterLabel ?? t('storyRetell.exercise.questionCounterLabel');

  const onChangeStatus = useCallback((status: TimerStatus) => {
    setTimerStatus(status);
  }, []);

  return (
    <>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level="small">{instruction}</Instruction>
      </InstructionContainer>
      <div className="story-retell-exercise__upper-section">
        <QuestionCounter
          count={count}
          total={total}
          title={_questionCounterLabel}
        />
        <div className="story-retell-exercise__timer">
          <TimerMessage
            show={isTimerFinished(timerStatus)}
            insideClassName="cade-margin-right-3"
          />
          <Timer
            time={timeLeft}
            timerStatus={timerStatus}
            onChangeStatus={onChangeStatus}
          />
        </div>
      </div>
      <Box role="exercise" className="example-box">
        <ListenRecordExerciseContent
          timeLeft={timeLeft}
          timerStatus={timerStatus}
          setTimerStatus={setTimerStatus}
          audioSrc={audioSrc}
          timeouts={timeouts}
          onEvent={onEvent}
          listeningStatus={listeningStatus}
          setListeningStatus={setListeningStatus}
          forceEnd={forceEnd}
          setWarningVisible={setWarningVisible}
          leftLabel={_leftLabel}
          rightLabel={_rightLabel}
          playBeepSound={true}
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          weCantHearYou={warningVisible}
          numOfCircles={6}
          listenMode={listeningStatus !== 'COMPLETED'}
          currentValue={voiceLevel}
        />
      </div>
    </>
  );
}
