import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForExercise,
  extractLabelFromMicroInstructions,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeConversationModel {
  title: string;
  subTitle: string;
  currentStep: number;
  totalSteps: number;
  onEvent: (event: CadeEvent) => void;
  count: number;
  total: number;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  responseId?: string;
  leftLabel: string;
  rightLabel: string;
  instruction: string;
}
export function transformJSONToConversation(
  json: ApiModel,
  tocArray: Toc[]
): CadeConversationModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);

  if (!validateResult) {
    throw new Error('Validation failed');
  }

  const labels = extractLabelFromMicroInstructions(json);

  const instruction =
    json.items[0].cueInstructions?.[0] ??
    i18n.t('conversation.instruction.content');

  return {
    ...extractCommonItemsForExercise(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    leftLabel: labels.leftLabel,
    rightLabel: labels.rightLabel,
    instruction,
  };
}
