import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import './styles.scss';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { Listening } from '@components/Listening';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { Input } from '@components/base/Input';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  audioSrc: any;
  instruction: string;
  onEvent: (event: CadeEvent) => void;
  leftLabel?: string;
  rightLabel?: string;
  forceEnd: boolean;
  sentenceExample: string;
  inputValue: string;
};

export function SentenceCompletionInstruction({
  instruction,
  audioSrc,
  subTitle,
  onEvent,
  leftLabel = 'You read',
  rightLabel = 'You type',
  forceEnd = false,
  sentenceExample = "It's _____ tonight. Bring your sweater.",
  inputValue = 'cold',
}: Props) {
  const [play, setPlay] = useState(false);
  const [instructionEnded, setInstructionEnded] = useState(false);
  const [bottomState, setBottomState] = useState<'ACTIVE' | 'INACTIVE'>(
    'INACTIVE'
  );
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  const textPlay = t('sentenceCompletion.instruction.play');
  const textReplay = t('sentenceCompletion.instruction.replay');

  const inputRef = useRef<HTMLInputElement>(null);
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);

  useEffect(() => delay.set(onClickPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  const fillInputAndFocus = () => {
    if (inputRef.current) {
      inputRef.current.value = 'cold';
      inputRef.current.classList.add('focus');
      inputRef.current.focus();
    }
  };

  function onClickPlay() {
    setPlay(true);
    setBottomState('ACTIVE');
    fillInputAndFocus();
  }

  const onAudioEnded = () => {
    delay.set(() => {
      sendEndEvent();
    });
    setInstructionEnded(true);
    setBottomState('INACTIVE');
    setPlay(false);
    if (inputRef.current) {
      inputRef.current?.blur();
      inputRef.current.value = '';
      inputRef.current.classList.remove('focus');
    }
  };

  const makeSentenceWithInput = () => {
    const input = (
      <Input.Text
        key={'input'}
        tabIndex={-1}
        value={inputValue}
        readOnly={true}
        ref={inputRef}
      />
    );
    return Array.from(new Set(sentenceExample.split('_'))).map((el) => {
      if (el === '') {
        return input;
      }
      return el;
    });
  };

  return (
    <div className={'sentence-completion-instruction'}>
      <InstructionContainer>
        <Title centered level={'large'} id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level="large">
          {instruction ?? t('sentenceCompletion.instruction.instruction')}
        </Instruction>
      </InstructionContainer>
      <Box role="instruction" className="sentence-completion-instruction__top">
        <Paragraph level={'large'} weight={'bold'}>
          {t('sentenceCompletion.instruction.p')}
        </Paragraph>
        <div className={'sentence-completion-instruction__listening-container'}>
          <Listening
            playButtonText={instructionEnded ? textReplay : textPlay}
            status={play ? 'PLAYING' : 'INACTIVE'}
            onClickPlay={() => {
              delay.clear();
              onClickPlay();
            }}
            audioSrc={audioSrc}
            onAudioEnded={onAudioEnded}
            role={'instruction'}
          />
        </div>
      </Box>
      <Box
        role={'instruction'}
        className="sentence-completion-instruction__bottom"
      >
        {bottomState === 'INACTIVE' && <div className={'overlay'} />}
        <DualPaneLayout
          leftPane={
            <div
              className={'sentence-completion-instruction__bottom-left'}
              aria-hidden={true}
            >
              <Paragraph level={'large'}>{leftLabel}</Paragraph>
              <Box withoutShadow={true} role={'instruction'}>
                {bottomState !== 'INACTIVE' && (
                  <Paragraph level={'normal'}>{sentenceExample}</Paragraph>
                )}
              </Box>
            </div>
          }
          rightPane={
            <div
              className={`sentence-completion-instruction__bottom-right ${
                bottomState === 'INACTIVE' &&
                'sentence-completion-instruction__bottom-right--inactive'
              }`}
              aria-hidden={true}
            >
              <Paragraph weight={play ? 'bold' : 'normal'} level={'large'}>
                {rightLabel}
              </Paragraph>
              <Box withoutShadow={true} role={'instruction'}>
                {bottomState !== 'INACTIVE' && (
                  <div
                    className={`cade-typography-text__normal ${
                      play && 'cade-typography--bold'
                    }`}
                  >
                    {makeSentenceWithInput()}
                  </div>
                )}
              </Box>
            </div>
          }
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
