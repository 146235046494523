import React from 'react';

export function MicrosoftEdgeLogo() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4306_217235)">
        <path
          d="M90.2734 74.4141C88.9324 75.1109 87.5496 75.7241 86.1328 76.25C81.6467 77.9198 76.8961 78.7667 72.1094 78.75C53.6328 78.75 37.5391 66.0547 37.5391 49.7266C37.5631 47.5402 38.1693 45.3996 39.2952 43.5253C40.4212 41.651 42.0263 40.1105 43.9453 39.0625C27.2266 39.7656 22.9297 57.1875 22.9297 67.3828C22.9297 96.2891 49.5313 99.1797 55.2734 99.1797C58.3594 99.1797 63.0078 98.2813 65.8203 97.3828L66.3281 97.2266C77.0914 93.5069 86.267 86.2326 92.3438 76.6016C92.5204 76.3089 92.5961 75.9665 92.5595 75.6267C92.5229 75.2868 92.3758 74.9684 92.1408 74.7202C91.9059 74.4719 91.596 74.3076 91.2587 74.2523C90.9214 74.197 90.5753 74.2538 90.2734 74.4141Z"
          fill="url(#paint0_linear_4306_217235)"
        />
        <path
          opacity="0.35"
          d="M90.2734 74.4141C88.9324 75.1109 87.5496 75.7241 86.1328 76.25C81.6467 77.9198 76.8961 78.7667 72.1094 78.75C53.6328 78.75 37.5391 66.0547 37.5391 49.7266C37.5631 47.5402 38.1693 45.3996 39.2952 43.5253C40.4212 41.651 42.0263 40.1105 43.9453 39.0625C27.2266 39.7656 22.9297 57.1875 22.9297 67.3828C22.9297 96.2891 49.5313 99.1797 55.2734 99.1797C58.3594 99.1797 63.0078 98.2813 65.8203 97.3828L66.3281 97.2266C77.0914 93.5069 86.267 86.2326 92.3438 76.6016C92.5204 76.3089 92.5961 75.9665 92.5595 75.6267C92.5229 75.2868 92.3758 74.9684 92.1408 74.7202C91.9059 74.4719 91.596 74.3076 91.2587 74.2523C90.9214 74.197 90.5753 74.2538 90.2734 74.4141Z"
          fill="url(#paint1_radial_4306_217235)"
        />
        <path
          d="M41.2891 94.2578C37.812 92.0983 34.7981 89.2702 32.4219 85.9375C29.7084 82.2234 27.8405 77.9605 26.9494 73.4478C26.0583 68.9352 26.1656 64.2823 27.2637 59.8155C28.3618 55.3488 30.424 51.1764 33.3058 47.5913C36.1876 44.0062 39.819 41.0952 43.9453 39.0625C45.1953 38.4766 47.2656 37.4609 50.0391 37.5C51.9907 37.5151 53.9123 37.9813 55.6539 38.8623C57.3954 39.7432 58.9096 41.015 60.0781 42.5781C61.6498 44.6917 62.5114 47.249 62.5391 49.8828C62.5391 49.8047 72.1094 18.7891 31.2891 18.7891C14.1406 18.7891 0.0390618 35.0391 0.0390618 49.3359C-0.0291471 56.8881 1.58546 64.3607 4.76562 71.2109C9.97372 82.3009 19.0724 91.0926 30.3345 95.9172C41.5966 100.742 54.2381 101.263 65.8594 97.3828C61.7879 98.6687 57.4858 99.0552 53.2504 98.5157C49.0149 97.9761 44.9471 96.5234 41.3281 94.2578H41.2891Z"
          fill="url(#paint2_linear_4306_217235)"
        />
        <path
          opacity="0.41"
          d="M41.2891 94.2578C37.812 92.0983 34.7981 89.2702 32.4219 85.9375C29.7084 82.2234 27.8405 77.9605 26.9494 73.4478C26.0583 68.9352 26.1656 64.2823 27.2637 59.8155C28.3618 55.3488 30.424 51.1764 33.3058 47.5913C36.1876 44.0062 39.819 41.0952 43.9453 39.0625C45.1953 38.4766 47.2656 37.4609 50.0391 37.5C51.9907 37.5151 53.9123 37.9813 55.6539 38.8623C57.3954 39.7432 58.9096 41.015 60.0781 42.5781C61.6498 44.6917 62.5114 47.249 62.5391 49.8828C62.5391 49.8047 72.1094 18.7891 31.2891 18.7891C14.1406 18.7891 0.0390618 35.0391 0.0390618 49.3359C-0.0291471 56.8881 1.58546 64.3607 4.76562 71.2109C9.97372 82.3009 19.0724 91.0926 30.3345 95.9172C41.5966 100.742 54.2381 101.263 65.8594 97.3828C61.7879 98.6687 57.4858 99.0552 53.2504 98.5157C49.0149 97.9761 44.9471 96.5234 41.3281 94.2578H41.2891Z"
          fill="url(#paint3_radial_4306_217235)"
        />
        <path
          d="M59.5313 58.125C59.1797 58.5156 58.2031 59.1016 58.2031 60.3125C58.2031 61.3281 58.8672 62.3437 60.0781 63.1641C65.6641 67.0703 76.25 66.5234 76.2891 66.5234C80.4533 66.5207 84.5407 65.4011 88.125 63.2812C91.7336 61.1705 94.7278 58.1533 96.8108 54.5286C98.8938 50.9039 99.9932 46.7977 100 42.6172C100.117 33.8672 96.875 28.0469 95.5859 25.4687C87.2656 9.29687 69.4141 -2.32055e-07 50 -2.32055e-07C36.8601 -0.00129953 24.2478 5.16992 14.891 14.3952C5.53408 23.6205 0.18478 36.1583 0 49.2969C0.195313 35.0391 14.375 23.5156 31.25 23.5156C32.6172 23.5156 40.4297 23.6328 47.6563 27.4219C52.7333 29.9447 56.9376 33.9314 59.7266 38.8672C62.1094 43.0078 62.5391 48.2812 62.5391 50.3906C62.5391 52.5 61.4844 55.5859 59.4922 58.1641L59.5313 58.125Z"
          fill="url(#paint4_radial_4306_217235)"
        />
        <path
          d="M59.5313 58.125C59.1797 58.5156 58.2031 59.1016 58.2031 60.3125C58.2031 61.3281 58.8672 62.3437 60.0781 63.1641C65.6641 67.0703 76.25 66.5234 76.2891 66.5234C80.4533 66.5207 84.5407 65.4011 88.125 63.2812C91.7336 61.1705 94.7278 58.1533 96.8108 54.5286C98.8938 50.9039 99.9932 46.7977 100 42.6172C100.117 33.8672 96.875 28.0469 95.5859 25.4687C87.2656 9.29687 69.4141 -2.32055e-07 50 -2.32055e-07C36.8601 -0.00129953 24.2478 5.16992 14.891 14.3952C5.53408 23.6205 0.18478 36.1583 0 49.2969C0.195313 35.0391 14.375 23.5156 31.25 23.5156C32.6172 23.5156 40.4297 23.6328 47.6563 27.4219C52.7333 29.9447 56.9376 33.9314 59.7266 38.8672C62.1094 43.0078 62.5391 48.2812 62.5391 50.3906C62.5391 52.5 61.4844 55.5859 59.4922 58.1641L59.5313 58.125Z"
          fill="url(#paint5_radial_4306_217235)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4306_217235"
          x1="22.9297"
          y1="69.1406"
          x2="92.6172"
          y2="69.1406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C59A4" />
          <stop offset="1" stopColor="#114A8B" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_4306_217235"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(61.4063 69.666) scale(37.2656 35.4023)"
        >
          <stop offset="0.7" stopOpacity="0" />
          <stop offset="0.9" stopOpacity="0.5" />
          <stop offset="1" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_4306_217235"
          x1="59.6484"
          y1="38.9062"
          x2="16.1719"
          y2="86.2891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="0.2" stopColor="#1595DF" />
          <stop offset="0.7" stopColor="#0680D7" />
          <stop offset="1" stopColor="#0078D4" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_4306_217235"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.5605 77.7098) rotate(-81.3844) scale(56.0102 45.2506)"
        >
          <stop offset="0.8" stopOpacity="0" />
          <stop offset="0.9" stopOpacity="0.5" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_4306_217235"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.1133 18.4828) rotate(92.2906) scale(79.1257 168.521)"
        >
          <stop stopColor="#35C1F1" />
          <stop offset="0.1" stopColor="#34C1ED" />
          <stop offset="0.2" stopColor="#2FC2DF" />
          <stop offset="0.3" stopColor="#2BC3D2" />
          <stop offset="0.7" stopColor="#36C752" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_4306_217235"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(93.7734 30.1953) rotate(73.7398) scale(38.0078 30.908)"
        >
          <stop stopColor="#66EB6E" />
          <stop offset="1" stopColor="#66EB6E" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_4306_217235">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
