import { useContext, useEffect, useRef } from 'react';
import { ConfigContext } from '../context/CadeConfigProvider';
import { useVolume } from '../context/Volume.context';

export const useBeep = () => {
  const configContext = useContext(ConfigContext);
  const interactionBeginToneRef = useRef<HTMLAudioElement | null>(null);
  const interactionEndToneRef = useRef<HTMLAudioElement | null>(null);
  const nextToneRef = useRef<HTMLAudioElement | null>(null);
  const errorToneRef = useRef<HTMLAudioElement | null>(null);
  const { state } = useVolume();

  useEffect(() => {
    interactionBeginToneRef.current = new Audio(
      configContext.interactionBeginTone ?? undefined
    );
    interactionEndToneRef.current = new Audio(
      configContext.interactionEndTone ?? undefined
    );
    nextToneRef.current = new Audio(configContext.nextTone ?? undefined);
    errorToneRef.current = new Audio(configContext.errorTone ?? undefined);
  }, [configContext]);

  useEffect(() => {
    if (interactionBeginToneRef.current instanceof HTMLAudioElement) {
      interactionBeginToneRef.current.volume = state.value / 100;
    }
    if (interactionEndToneRef.current instanceof HTMLAudioElement) {
      interactionEndToneRef.current.volume = state.value / 100;
    }
    if (nextToneRef.current instanceof HTMLAudioElement) {
      nextToneRef.current.volume = state.value / 100;
    }
    if (errorToneRef.current instanceof HTMLAudioElement) {
      errorToneRef.current.volume = state.value / 100;
    }
  }, [state]);

  const playAudio = (audio: HTMLAudioElement) => {
    return new Promise((res) => {
      audio.play();
      audio.onended = res;
    });
  };

  const playInteractionBeginTone = async () => {
    await playAudio(interactionBeginToneRef.current as HTMLAudioElement);
  };

  const playInteractionEndTone = async () => {
    await playAudio(interactionEndToneRef.current as HTMLAudioElement);
  };

  const playNextTone = async () => {
    await playAudio(nextToneRef.current as HTMLAudioElement);
  };

  const playErrorTone = async () => {
    await playAudio(errorToneRef.current as HTMLAudioElement);
  };

  return {
    playInteractionBeginTone,
    playInteractionEndTone,
    playNextTone,
    playErrorTone,
  };
};
