import React, { useCallback, useEffect, useState, useContext } from 'react';
import './styles.scss';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { CadeEvent, timerEndedEvent, useEndedEvent } from '@utils/events';
import {
  isTimerFinished,
  isTimerFinished as isTimerStatusFinished,
  Timer,
  TIMER_STATUS,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import { TextArea } from '@components/base/TextArea';
import { useDelay } from '@utils/useDelay';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IPassageReconstructionProps {
  count: number;
  total: number;
  subTitle: string;
  passageToRead: string;
  timeToRead: number;
  timeToWrite: number;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  readingStatus?: boolean;
  instruction?: string;
}

export function PassageReconstruction({
  count,
  total,
  subTitle,
  passageToRead,
  timeToRead,
  timeToWrite,
  onEvent,
  forceEnd = false,
  readingStatus = false,
  instruction,
}: IPassageReconstructionProps) {
  const [currentText, setCurrentText] = useState('');
  const [readingTimerStatus, setReadingTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.IN_PROGRESS
  );
  const [writingTimerStatus, setWritingTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.IN_PROGRESS
  );
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent<string>(onEvent);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent(currentText);
    }
  }, [forceEnd, currentText]);

  const onReadingTimerStatusChange = useCallback((status: TimerStatus) => {
    setReadingTimerStatus(status);
    isTimerStatusFinished(status) && onEvent(timerEndedEvent());
  }, []);

  const onWritingTimerStatusChange = useCallback(
    (status: TimerStatus) => {
      setWritingTimerStatus(status);
      if (isTimerStatusFinished(status)) {
        delay.set(() => {
          sendEndEvent(currentText);
        });
      }
    },
    [currentText]
  );
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  return (
    <div className="passage-reconstruction-exercise">
      <div className="passage-reconstruction-exercise__content_container">
        <InstructionContainer>
          <Title centered id={CADE_CONTENT_TITLE_ID}>
            {subTitle}
          </Title>
          <Instruction level="small">
            {instruction ?? t('passageReconstruction.exercise.instruction')}
          </Instruction>
        </InstructionContainer>
        <div className="passage-reconstruction-exercise__upper-section">
          <QuestionCounter
            count={count}
            total={total}
            title={t('passageReconstruction.exercise.questionCountLabel')}
          />
          <div className="passage-reconstruction-exercise__timer">
            <TimerMessage
              show={isTimerFinished(writingTimerStatus)}
              insideClassName="cade-margin-right-3"
            />
            {readingStatus && (
              <Timer
                time={timeToRead}
                timerStatus={readingTimerStatus}
                timeIsUpMessageForReader={''}
                onChangeStatus={onReadingTimerStatusChange}
              />
            )}
            {!readingStatus && (
              <Timer
                time={timeToWrite}
                timerStatus={writingTimerStatus}
                onChangeStatus={onWritingTimerStatusChange}
              />
            )}
          </div>
        </div>
        <Box role="exercise" className="example-box">
          {readingStatus && (
            <TextArea.Label
              id="passage-reconstruction-label"
              tabIndex={-1}
              label={t('passageReconstruction.exercise.readingLabel')}
              labelSize="LARGE"
            >
              <TextArea.ReadBox state={'COMPLETED'} tabIndex={0}>
                <Paragraph
                  onCopy={(event) => {
                    event.preventDefault();
                  }}
                >
                  {passageToRead}
                </Paragraph>
              </TextArea.ReadBox>
            </TextArea.Label>
          )}
          {!readingStatus && (
            <TextArea.Label
              label={t('passageReconstruction.exercise.rewriteLabel')}
              id="passage-reconstruction-label"
              tabIndex={-1}
              labelSize="NORMAL"
            >
              <TextArea.Field
                autoFocus={true}
                disabled={isTimerFinished(writingTimerStatus)}
                className="passage-reconstruction-exercise__text-area"
                ariaLabel={t('passageReconstruction.exercise.rewriteAria')}
                onChange={(text) => {
                  setCurrentText(text!);
                  return '';
                }}
                onPaste={(event) => {
                  event.preventDefault();
                }}
              />
            </TextArea.Label>
          )}
        </Box>
      </div>
    </div>
  );
}
