import { ApiModel } from '../../api/api.interfaces';
import { Wrapper } from '../Wrapper';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Template } from '../../components/Template/Template';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { useCallback, useState } from 'react';
import {
  CADE_CONTENT_TITLE_ID,
  ReadingComprehensionInstruction,
} from '@versant-monorepo/cade';
import { transformJSONToReadingComprehensionInstruction } from '../../api/parsers/ReadingComprehension/ReadingComprehensionInstruction';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const ReadingComprehensionInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': () => onEnded(),
  });
  const { pushEvent } = useLogger();
  useFocus(CADE_CONTENT_TITLE_ID, [model]);
  const [forceEnd, setForceEnd] = useState<boolean>(false);
  const onlineStatus = useNetwork();

  const readingComprehensionInstructionProps = useParser(
    transformJSONToReadingComprehensionInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(readingComprehensionInstructionProps?.title);

  const onEnded = useCallback(async () => {
    pushEvent({
      item: 'Reading Comprehension Instruction',
      level: LogLevel.INFO,
      message: 'User clicked next on Reading Comprehension Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
  }, [getNextStep, switchView]);

  const onNext = useCallback(() => setForceEnd(true), []);
  const onExit = useCallback(() => {
    eventHandler(exitEvent());
    pushEvent({
      item: 'Reading Comprehension Instruction',
      level: LogLevel.INFO,
      message: 'User clicked exit on Reading Comprehension Instruction',
    });
  }, [eventHandler]);

  return (
    readingComprehensionInstructionProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role="instruction"
          footer={
            <Footer
              nextButtonVisible={true}
              onNext={onNext}
              role="instruction"
            />
          }
          header={
            <Header
              onExit={onExit}
              currentStep={readingComprehensionInstructionProps.currentStep}
              totalSteps={readingComprehensionInstructionProps.totalSteps}
              title={readingComprehensionInstructionProps.subTitle}
              role={'instruction'}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <ReadingComprehensionInstruction
              {...readingComprehensionInstructionProps}
              key={networkDependentKey(onlineStatus)}
              onEvent={eventHandler}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
