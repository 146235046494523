import React, { useEffect } from 'react';
import './styles.scss';
import {
  BannerSuccessIcon,
  INSTRUCTION_AUTO_PLAY_TIMEOUT,
  NextButton,
  Paragraph,
  Space,
  useDelay,
} from '@versant-monorepo/cade';
import { VfwStorage } from '../../utils/Storage';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { CookieBanner } from '../../components/CookieBanner';
import { vfwRoutes } from '../../vfw-routes';
import { useNavigate } from 'react-router-dom';
import { LoginFooter } from '../../components/LoginFooter/LoginFooter';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { ContentTitle } from 'components/ContentTitle/ContentTitle';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';

export const LandingPage = () => {
  const navigate = useNavigate();
  const { pushEvent } = useLogger();
  const delay = useDelay();
  const { t } = useTranslation();
  useFocus(CONTENT_TITLE_ID, []);
  useSetDocumentTitle('');

  useEffect(() => delay.set(moveForward, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);
  function moveForward() {
    pushEvent({
      level: LogLevel.INFO,
      item: 'Landing Page',
      message: 'Next clicked on landing page',
    });
    navigate(vfwRoutes.thingsYouNeedToKnow);
  }

  return (
    <>
      <Template
        footer={<LoginFooter nextButtonVisible={false} />}
        header={
          <Header
            role={'instruction'}
            closeButtonVisible={false}
            title={''}
            onExit={() => {}}
          />
        }
      >
        <div className="landing">
          <div className={'landing__title-wrapper'}>
            <ContentTitle centered={true} level={'large'}>
              {VfwStorage.getItem('testName')}
              <Space
                className={'landing__icon'}
                margin={{ top: 5 }}
                aria-hidden={true}
              >
                <BannerSuccessIcon
                  width={'48'}
                  height={'48'}
                ></BannerSuccessIcon>
              </Space>
            </ContentTitle>
          </div>
          <div>
            <div className="landing__content">
              <Paragraph level={'large'} className={'cade-typography--bold'}>
                {t('landingPage.p')}
              </Paragraph>
              <Space margin={{ left: 5 }}>
                <NextButton onClick={moveForward}>
                  {t('landingPage.start')}
                </NextButton>
              </Space>
            </div>
          </div>
        </div>
        <CookieBanner />
      </Template>
    </>
  );
};
