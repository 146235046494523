import './TextButton.scss';
import React, { PropsWithChildren } from 'react';
import { Button } from '../Button';

type TextButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    icon?: string;
  }>;

export function TextButton({ children, ...props }: TextButtonProps) {
  return (
    <Button {...props} className="cade-text-button" hideBorder={true}>
      {children}
    </Button>
  );
}
