export const countries: { label: string; value: string }[] = [
  { label: 'Afrikaans', value: 'Afrikaans' },
  { label: 'Albanian - shqip', value: 'Albanian - shqip' },
  { label: 'Amharic - አማርኛ', value: 'Amharic - አማርኛ' },
  { label: 'Arabic - العربية', value: 'Arabic - العربية' },
  { label: 'Aragonese - aragonés', value: 'Aragonese - aragonés' },
  { label: 'Armenian - հայերեն', value: 'Armenian - հայերեն' },
  { label: 'Asturian - asturianu', value: 'Asturian - asturianu' },
  {
    label: 'Azerbaijani - azərbaycan dili',
    value: 'Azerbaijani - azərbaycan dili',
  },
  { label: 'Basque - euskara', value: 'Basque - euskara' },
  { label: 'Belarusian - беларуская', value: 'Belarusian - беларуская' },
  { label: 'Bengali - বাংলা', value: 'Bengali - বাংলা' },
  { label: 'Bosnian - bosanski', value: 'Bosnian - bosanski' },
  { label: 'Breton - brezhoneg', value: 'Breton - brezhoneg' },
  { label: 'Bulgarian - български', value: 'Bulgarian - български' },
  { label: 'Catalan - català', value: 'Catalan - català' },
  {
    label: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)',
    value: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)',
  },
  { label: 'Chinese - 中文', value: 'Chinese - 中文' },
  {
    label: 'Chinese (Hong Kong) - 中文（香港）',
    value: 'Chinese (Hong Kong) - 中文（香港）',
  },
  {
    label: 'Chinese (Simplified) - 中文（简体）',
    value: 'Chinese (Simplified) - 中文（简体）',
  },
  {
    label: 'Chinese (Traditional) - 中文（繁體）',
    value: 'Chinese (Traditional) - 中文（繁體）',
  },
  { label: 'Corsican', value: 'Corsican' },
  { label: 'Croatian - hrvatski', value: 'Croatian - hrvatski' },
  { label: 'Czech - čeština', value: 'Czech - čeština' },
  { label: 'Danish - dansk', value: 'Danish - dansk' },
  { label: 'Dutch - Nederlands', value: 'Dutch - Nederlands' },
  { label: 'English', value: 'English' },
  { label: 'English (Australia)', value: 'English (Australia)' },
  { label: 'English (Canada)', value: 'English (Canada)' },
  { label: 'English (India)', value: 'English (India)' },
  { label: 'English (New Zealand)', value: 'English (New Zealand)' },
  { label: 'English (South Africa)', value: 'English (South Africa)' },
  { label: 'English (United Kingdom)', value: 'English (United Kingdom)' },
  { label: 'English (United States)', value: 'English (United States)' },
  { label: 'Esperanto - esperanto', value: 'Esperanto - esperanto' },
  { label: 'Estonian - eesti', value: 'Estonian - eesti' },
  { label: 'Faroese - føroyskt', value: 'Faroese - føroyskt' },
  { label: 'Filipino', value: 'Filipino' },
  { label: 'Finnish - suomi', value: 'Finnish - suomi' },
  { label: 'French - français', value: 'French - français' },
  {
    label: 'French (Canada) - français (Canada)',
    value: 'French (Canada) - français (Canada)',
  },
  {
    label: 'French (France) - français (France)',
    value: 'French (France) - français (France)',
  },
  {
    label: 'French (Switzerland) - français (Suisse)',
    value: 'French (Switzerland) - français (Suisse)',
  },
  { label: 'Galician - galego', value: 'Galician - galego' },
  { label: 'Georgian - ქართული', value: 'Georgian - ქართული' },
  { label: 'German - Deutsch', value: 'German - Deutsch' },
  {
    label: 'German (Austria) - Deutsch (Österreich)',
    value: 'German (Austria) - Deutsch (Österreich)',
  },
  {
    label: 'German (Germany) - Deutsch (Deutschland)',
    value: 'German (Germany) - Deutsch (Deutschland)',
  },
  {
    label: 'German (Liechtenstein) - Deutsch (Liechtenstein)',
    value: 'German (Liechtenstein) - Deutsch (Liechtenstein)',
  },
  {
    label: 'German (Switzerland) - Deutsch (Schweiz)',
    value: 'German (Switzerland) - Deutsch (Schweiz)',
  },
  { label: 'Greek - Ελληνικά', value: 'Greek - Ελληνικά' },
  { label: 'Guarani', value: 'Guarani' },
  { label: 'Gujarati - ગુજરાતી', value: 'Gujarati - ગુજરાતી' },
  { label: 'Hausa', value: 'Hausa' },
  { label: 'Hawaiian - ʻŌlelo Hawaiʻi', value: 'Hawaiian - ʻŌlelo Hawaiʻi' },
  { label: 'Hebrew - עברית', value: 'Hebrew - עברית' },
  { label: 'Hindi - हिन्दी', value: 'Hindi - हिन्दी' },
  { label: 'Hungarian - magyar', value: 'Hungarian - magyar' },
  { label: 'Icelandic - íslenska', value: 'Icelandic - íslenska' },
  { label: 'Indonesian - Indonesia', value: 'Indonesian - Indonesia' },
  { label: 'Interlingua', value: 'Interlingua' },
  { label: 'Irish - Gaeilge', value: 'Irish - Gaeilge' },
  { label: 'Italian - italiano', value: 'Italian - italiano' },
  {
    label: 'Italian (Italy) - italiano (Italia)',
    value: 'Italian (Italy) - italiano (Italia)',
  },
  {
    label: 'Italian (Switzerland) - italiano (Svizzera)',
    value: 'Italian (Switzerland) - italiano (Svizzera)',
  },
  { label: 'Japanese - 日本語', value: 'Japanese - 日本語' },
  { label: 'Kannada - ಕನ್ನಡ', value: 'Kannada - ಕನ್ನಡ' },
  { label: 'Kazakh - қазақ тілі', value: 'Kazakh - қазақ тілі' },
  { label: 'Khmer - ខ្មែរ', value: 'Khmer - ខ្មែរ' },
  { label: 'Korean - 한국어', value: 'Korean - 한국어' },
  { label: 'Kurdish - Kurdî', value: 'Kurdish - Kurdî' },
  { label: 'Kyrgyz - кыргызча', value: 'Kyrgyz - кыргызча' },
  { label: 'Lao - ລາວ', value: 'Lao - ລາວ' },
  { label: 'Latin', value: 'Latin' },
  { label: 'Latvian - latviešu', value: 'Latvian - latviešu' },
  { label: 'Lingala - lingála', value: 'Lingala - lingála' },
  { label: 'Lithuanian - lietuvių', value: 'Lithuanian - lietuvių' },
  { label: 'Macedonian - македонски', value: 'Macedonian - македонски' },
  { label: 'Malay - Bahasa Melayu', value: 'Malay - Bahasa Melayu' },
  { label: 'Malayalam - മലയാളം', value: 'Malayalam - മലയാളം' },
  { label: 'Maltese - Malti', value: 'Maltese - Malti' },
  { label: 'Marathi - मराठी', value: 'Marathi - मराठी' },
  { label: 'Mongolian - монгол', value: 'Mongolian - монгол' },
  { label: 'Nepali - नेपाली', value: 'Nepali - नेपाली' },
  {
    label: 'Norwegian (Bokmål) - norsk (bokmål)',
    value: 'Norwegian (Bokmål) - norsk (bokmål)',
  },
  {
    label: 'Norwegian (Nynorsk) - norsk (nynorsk)',
    value: 'Norwegian (Nynorsk) - norsk (nynorsk)',
  },
  { label: 'Occitan', value: 'Occitan' },
  { label: 'Pashto - پښتو', value: 'Pashto - پښتو' },
  { label: 'Persian - فارسی', value: 'Persian - فارسی' },
  { label: 'Polish - polski', value: 'Polish - polski' },
  { label: 'Portuguese - português', value: 'Portuguese - português' },
  {
    label: 'Portuguese (Brazil) - português (Brasil)',
    value: 'Portuguese (Brazil) - português (Brasil)',
  },
  {
    label: 'Portuguese (Portugal) - português (Portugal)',
    value: 'Portuguese (Portugal) - português (Portugal)',
  },
  { label: 'Punjabi - ਪੰਜਾਬੀ', value: 'Punjabi - ਪੰਜਾਬੀ' },
  { label: 'Quechua', value: 'Quechua' },
  { label: 'Romanian - română', value: 'Romanian - română' },
  { label: 'Romansh - rumantsch', value: 'Romansh - rumantsch' },
  { label: 'Russian - русский', value: 'Russian - русский' },
  { label: 'Samoan - gagana faa Samoa', value: 'Samoan - gagana faa Samoa' },
  { label: 'Sardinian - sardu', value: 'Sardinian - sardu' },
  {
    label: 'Scottish Gaelic - Gàidhlig',
    value: 'Scottish Gaelic - Gàidhlig',
  },
  { label: 'Serbian - српски', value: 'Serbian - српски' },
  {
    label: 'Serbian (Latin) - srpski (latinica)',
    value: 'Serbian (Latin) - srpski (latinica)',
  },
  { label: 'Shona - chiShona', value: 'Shona - chiShona' },
  { label: 'Sindhi', value: 'Sindhi' },
  { label: 'Sinhala - සිංහල', value: 'Sinhala - සිංහල' },
  { label: 'Slovak - slovenčina', value: 'Slovak - slovenčina' },
  { label: 'Slovenian - slovenščina', value: 'Slovenian - slovenščina' },
  { label: 'Somali - Soomaali', value: 'Somali - Soomaali' },
  { label: 'Spanish - español', value: 'Spanish - español' },
  {
    label: 'Spanish (Argentina) - español (Argentina)',
    value: 'Spanish (Argentina) - español (Argentina)',
  },
  {
    label: 'Spanish (Chile) - español (Chile)',
    value: 'Spanish (Chile) - español (Chile)',
  },
  {
    label: 'Spanish (Colombia) - español (Colombia)',
    value: 'Spanish (Colombia) - español (Colombia)',
  },
  {
    label: 'Spanish (Costa Rica) - español (Costa Rica)',
    value: 'Spanish (Costa Rica) - español (Costa Rica)',
  },
  {
    label: 'Spanish (Dominican Republic) - español (República Dominicana)',
    value: 'Spanish (Dominican Republic) - español (República Dominicana)',
  },
  {
    label: 'Spanish (Ecuador) - español (Ecuador)',
    value: 'Spanish (Ecuador) - español (Ecuador)',
  },
  {
    label: 'Spanish (El Salvador) - español (El Salvador)',
    value: 'Spanish (El Salvador) - español (El Salvador)',
  },
  {
    label: 'Spanish (Guatemala) - español (Guatemala)',
    value: 'Spanish (Guatemala) - español (Guatemala)',
  },
  {
    label: 'Spanish (Honduras) - español (Honduras)',
    value: 'Spanish (Honduras) - español (Honduras)',
  },
  {
    label: 'Spanish (Mexico) - español (México)',
    value: 'Spanish (Mexico) - español (México)',
  },
  {
    label: 'Spanish (Nicaragua) - español (Nicaragua)',
    value: 'Spanish (Nicaragua) - español (Nicaragua)',
  },
  {
    label: 'Spanish (Panama) - español (Panamá)',
    value: 'Spanish (Panama) - español (Panamá)',
  },
  {
    label: 'Spanish (Paraguay) - español (Paraguay)',
    value: 'Spanish (Paraguay) - español (Paraguay)',
  },
  {
    label: 'Spanish (Peru) - español (Perú)',
    value: 'Spanish (Peru) - español (Perú)',
  },
  {
    label: 'Spanish (Puerto Rico) - español (Puerto Rico)',
    value: 'Spanish (Puerto Rico) - español (Puerto Rico)',
  },
  {
    label: 'Spanish (Spain) - español (España)',
    value: 'Spanish (Spain) - español (España)',
  },
  {
    label: 'Spanish (United States) - español (Estados Unidos)',
    value: 'Spanish (United States) - español (Estados Unidos)',
  },
  {
    label: 'Spanish (Uruguay) - español (Uruguay)',
    value: 'Spanish (Uruguay) - español (Uruguay)',
  },
  {
    label: 'Spanish (Venezuela) - español (Venezuela)',
    value: 'Spanish (Venezuela) - español (Venezuela)',
  },
  { label: 'Sundanese - Basa Sunda', value: 'Sundanese - Basa Sunda' },
  { label: 'Swahili - Kiswahili', value: 'Swahili - Kiswahili' },
  { label: 'Swedish - svenska', value: 'Swedish - svenska' },
  {
    label: 'Swedish (Sweden) - svenska (Sverige)',
    value: 'Swedish (Sweden) - svenska (Sverige)',
  },
  { label: 'Tajik - тоҷикӣ', value: 'Tajik - тоҷикӣ' },
  { label: 'Tamil - தமிழ்', value: 'Tamil - தமிழ்' },
  { label: 'Tatar', value: 'Tatar' },
  { label: 'Telugu - తెలుగు', value: 'Telugu - తెలుగు' },
  { label: 'Thai - ไทย', value: 'Thai - ไทย' },
  { label: 'Tibetan - བོད་སྐད་', value: 'Tibetan - བོད་སྐད་' },
  { label: 'Turkish - Türkçe', value: 'Turkish - Türkçe' },
  { label: 'Turkmen', value: 'Turkmen' },
  { label: 'Ukrainian - українська', value: 'Ukrainian - українська' },
  { label: 'Urdu - اردو', value: 'Urdu - اردو' },
  { label: 'Uyghur', value: 'Uyghur' },
  { label: 'Uzbek - o‘zbek', value: 'Uzbek - o‘zbek' },
  { label: 'Vietnamese - Tiếng Việt', value: 'Vietnamese - Tiếng Việt' },
  { label: 'Welsh - Cymraeg', value: 'Welsh - Cymraeg' },
  { label: 'Wolof - Wollof', value: 'Wolof - Wollof' },
  { label: 'Xhosa - isiXhosa', value: 'Xhosa - isiXhosa' },
  { label: 'Yiddish - ייִדיש', value: 'Yiddish - ייִדיש' },
  { label: 'Yoruba - Yorùbá', value: 'Yoruba - Yorùbá' },
  { label: 'Zulu - isiZulu', value: 'Zulu - isiZulu' },
];
