import React, { ElementType, useEffect } from 'react';
import './styles.scss';
import { Paragraph, Title } from '@versant-monorepo/cade';
import { GoogleChromeLogo } from './icons/GoogleChromeLogo';
import { FirefoxLogo } from './icons/FirefoxLogo';
import { MicrosoftEdgeLogo } from './icons/MicrosoftEdgeLogo';
import { Template } from '../../components/Template/Template';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { AppleSafariLogo } from './icons/AppleSafariLogo';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';
import { useExitTest } from '../../hooks/useExitTest';

export type Browser = {
  Icon: ElementType;
  link: string;
  name: string;
};

export const browsers = [
  {
    Icon: GoogleChromeLogo,
    link: 'https://www.google.com/chrome/',
    name: 'Google Chrome',
  },
  {
    Icon: FirefoxLogo,
    link: 'https://www.mozilla.org/en-US/firefox/new/',
    name: 'Mozilla Firefox',
  },
  {
    Icon: MicrosoftEdgeLogo,
    link: 'https://www.microsoft.com/en-us/edge',
    name: 'Microsoft Edge',
  },
  {
    Icon: AppleSafariLogo,
    link: 'https://www.apple.com/safari/',
    name: 'Apple Safari',
  },
];

const renderBrowsers = ({ link, Icon, name }: Browser) => (
  <a key={name} href={link} className="browser-not-supported__link">
    <Icon />
    <Paragraph weight="bold">{name}</Paragraph>
  </a>
);

export function BrowserNotSupported() {
  const { pushEvent } = useLogger();
  const exitTest = useExitTest();
  const { t } = useTranslation();
  useSetDocumentTitle(t('browserNotSupported.documentTitle'));

  useEffect(() => {
    pushEvent({
      level: LogLevel.WARN,
      item: '',
      message: 'User tries to use browser which is not supported',
    });
  }, []);

  return (
    <Template
      footer={
        <Footer
          buttonLabel={t('browserNotSupported.endTest')}
          onNext={async () => {
            exitTest();
          }}
        />
      }
      header={
        <Header closeButtonVisible={false} title={''} onExit={() => {}} />
      }
    >
      <div className="browser-not-supported__content">
        <section className="browser-not-supported__info cade-padding-top-8 cade-padding-left-12 cade-padding-right-12">
          <hgroup className="browser-not-supported__header">
            <Title level="large">{t('browserNotSupported.title')}</Title>
          </hgroup>
          <section className="cade-margin-top-10">
            <Paragraph weight="bold">{t('browserNotSupported.p')}</Paragraph>
            <br />
            <br />
            <Paragraph>{t('browserNotSupported.p2')}</Paragraph>
            <div className="browser-not-supported__links cade-margin-top-6">
              {browsers.map(renderBrowsers)}
            </div>
          </section>
        </section>
        <Paragraph className="cade-margin-top-6 cade-margin-left-12">
          {t('browserNotSupported.p3')}
        </Paragraph>
      </div>
    </Template>
  );
}
