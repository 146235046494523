import { ApiModel, AudioResponse } from '../../api/api.interfaces';
import {
  CADE_QUESTION_COUNTER_ID,
  getAudioType,
  SpeakingSituationsExercise,
} from '@versant-monorepo/cade';
import { useCallback, useState } from 'react';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { transformJSONToSpeakingSituations } from '../../api/parsers/SpeakingSituations/SpeakingSituations';
import { useParser } from '../../hooks/useParser';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useNavigate } from 'react-router-dom';
import { useForceEndTrigger } from '../../hooks/useForceEndTrigger';
import { vfwRoutes } from '../../vfw-routes';

export const SpeakingSituationsExerciseWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const navigate = useNavigate();
  const { switchView, model } = props;
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const { forceEndTrigger, setForceEnd } = useForceEndTrigger();
  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as Blob),
    'cade-audio-ended-event': () => {},
    'cade-next-event': () => {
      setNextEnabled(true);
    },
    'navigate-we-cant-hear-you-event': () => {
      setForceEnd('we-cant-hear-you');
    },
    'navigate-problem-audio-event': () => {
      setForceEnd('problem-with-audio');
    },
  });
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);
  const { pushEvent } = useLogger();

  const speakingSituationsExerciseProps = useParser(
    transformJSONToSpeakingSituations,
    model as ApiModel
  );

  useSetDocumentTitle(speakingSituationsExerciseProps?.title);

  const onNext = useCallback(
    async (responseItem: AudioResponse) => {
      if (forceEndTrigger === 'we-cant-hear-you') {
        navigate(vfwRoutes.weCantHearYou);
        return;
      }

      if (forceEndTrigger === 'problem-with-audio') {
        navigate(vfwRoutes.problemWithAudio);
        return;
      }

      pushEvent({
        item: 'Speaking Situations Exercise',
        level: LogLevel.INFO,
        message: 'User go next on Speaking Situations Exercise',
      });

      const response = await getNextStep(responseItem);
      switchView(CURRENT_ITEM_TYPE, response);
      setNextEnabled(false);
      setForceEnd(null);
    },
    [getNextStep, switchView]
  );

  const onEnded = useCallback(
    async (audio: Blob) => {
      if (!audio.size) {
        pushEvent({
          item: 'Speaking Situations Exercise',
          level: LogLevel.WARN,
          message: `No audio size for Speaking Situations Exercise, question ${speakingSituationsExerciseProps?.count} of ${speakingSituationsExerciseProps?.total}`,
        });
      }
      const file = new File(
        [audio],
        speakingSituationsExerciseProps?.responseId as string,
        {
          type: getAudioType().fileType,
        }
      );

      const responseItem = {
        id: speakingSituationsExerciseProps?.responseId as string,
        response: file,
        type: 'AUDIO',
      } as AudioResponse;

      await onNext(responseItem);
    },
    [onNext, speakingSituationsExerciseProps?.responseId]
  );

  const onExit = useCallback(() => {
    pushEvent({
      item: 'Speaking Situations Exercise',
      level: LogLevel.INFO,
      message: 'User clicked exit on Speaking Situations Exercise',
    });
    eventHandler(exitEvent());
  }, [eventHandler]);

  const onNextFooter = useCallback(() => setForceEnd('next-clicked'), []);

  return (
    speakingSituationsExerciseProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role="exercise"
          footer={
            <Footer
              nextButtonEnabled={nextEnabled}
              onNext={onNextFooter}
              role="exercise"
            />
          }
          header={
            <Header
              onExit={onExit}
              currentStep={speakingSituationsExerciseProps.currentStep}
              totalSteps={speakingSituationsExerciseProps.totalSteps}
              title={speakingSituationsExerciseProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <SpeakingSituationsExercise
              key={networkDependentKey(
                onlineStatus,
                speakingSituationsExerciseProps.responseId
              )}
              {...speakingSituationsExerciseProps}
              onEvent={eventHandler}
              forceEnd={forceEndTrigger !== null}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
