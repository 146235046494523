import CircleLoader from 'components/CircleLoader/CircleLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { vfwRoutes } from 'vfw-routes';
import { useEffect } from 'react';
import { useApi } from '../../hooks/useApi';
import { generateChecks } from '../../utils/prechecks';
import { VfwStorage } from '../../utils/Storage';
import { launchTest } from '../../api/api';

export const LegacyDirect = () => {
  const { legacyDirectLogin } = useApi();
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  const tin = urlParams.get('tin');
  const hash = urlParams.get('code');

  useEffect(() => {
    if (!tin) {
      navigate(vfwRoutes.login);
      return;
    }
    if (!hash) {
      // redirect to login with tin set
      navigate({
        pathname: vfwRoutes.login,
        search: `?tin=${tin}`,
      });
      return;
    }
    // try to start the test
    (async () => {
      try {
        const reservation = await legacyDirectLogin(Number(tin), hash);
        VfwStorage.setItem('isTestResumed', !!reservation?.resume);
        generateChecks();
        if (reservation.resume) {
          await launchTest();
          navigate(vfwRoutes.readyToContinue);
        } else {
          navigate(vfwRoutes.landingPage);
        }
      } catch (e: any) {
        navigate({
          pathname: vfwRoutes.login,
          search: `?tin=${tin}`,
        });
      }
    })();
  }, [tin, hash]);

  return <CircleLoader />;
};
