import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ConnectionModal } from '@versant-monorepo/cade';
import { vfwRoutes } from '../../vfw-routes';
import './styles.scss';

export function ConnectionModalWrapper() {
  const navigate = useNavigate();
  const [openConnectionModal, setOpenConnectionModal] = useState(false);
  const problemWithConnection = useRef<boolean>(false);
  const MODAL_TIMEOUT = 2_000;
  const PROBLEM_WITH_CONNECTION_TIMEOUT = 15_000;
  let openModalTimeout: number;
  let moveToProblemWithConnectionTimeout: number;

  function handleOnline() {
    setOpenConnectionModal(false);
    clearTimeout(openModalTimeout);
    clearTimeout(moveToProblemWithConnectionTimeout);
  }

  function handleOffline() {
    if (problemWithConnection.current) {
      return;
    }
    openModalTimeout = window.setTimeout(
      () => setOpenConnectionModal(true),
      MODAL_TIMEOUT
    );
    moveToProblemWithConnectionTimeout = window.setTimeout(() => {
      problemWithConnection.current = true;
      setOpenConnectionModal(false);
      navigate(vfwRoutes.problemWithConnection);
    }, PROBLEM_WITH_CONNECTION_TIMEOUT);
  }

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  }, [navigate]);

  useEffect(() => {
    return () => {
      clearTimeout(openModalTimeout);
      clearTimeout(moveToProblemWithConnectionTimeout);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>{openConnectionModal && <ConnectionModal open={openConnectionModal} />}</>
  );
}
