import { FC, useEffect, useRef } from 'react';
import {
  SoundWaveArea,
  SoundWaveData,
} from '@components/SoundWave/SoundWaveArea';
import { Role } from '../commons';
import { ColorPalette } from '@src/colorPalette';
import { ListeningStatus } from './commons';
import { ListeningLoader } from './ListeningLoader';

const SOUND_WAVE_DATA: SoundWaveData = [
  21,
  21,
  21,
  45,
  45,
  [60, -8],
  [60, 7],
  60,
  [35, 7],
  35,
  21,
  [21, 9],
  21,
  21,
  21,
];

const soundbarColorByStatus = {
  PLAYING: ColorPalette.DISABLED_GRAY,
  INACTIVE: ColorPalette.DISABLED_GRAY,
  DISABLED: ColorPalette.DISABLED_GRAY,
  COMPLETED: ColorPalette.DISABLED_GRAY,
  ERROR: ColorPalette.RED_WARNING,
} as const;

const backgroundColorByStatus = {
  instruction: {
    PLAYING: ColorPalette.BACKGROUND_GREEN,
    INACTIVE: ColorPalette.LIGHT_GRAY,
    DISABLED: ColorPalette.LIGHT_GRAY,
    COMPLETED: ColorPalette.WHITE,
    ERROR: ColorPalette.WHITE,
  },
  exercise: {
    PLAYING: ColorPalette.WHITE,
    INACTIVE: ColorPalette.LIGHT_GRAY,
    DISABLED: ColorPalette.LIGHT_GRAY,
    COMPLETED: ColorPalette.BACKGROUND_GREEN,
    ERROR: ColorPalette.LIGHT_GRAY,
  },
} as const;

type Props =
  | {
      status: ListeningStatus;
      role: Role;
      onPlayClick?: () => void;
      animationTimeMs?: number;
      duration?: number;
      width?: number | string;
      border?: string;
      stop?: boolean;
      onAnimationEnded?: any;
      isLoading?: boolean;
    }
  | {
      status: 'PLAYING';
      role: Role;
      animationTimeMs: number;
      onPlayClick: () => void;
      duration: number;
      width?: number | string;
      border?: string;
      stop?: boolean;
      onAnimationEnded?: any;
      isLoading?: never;
    };

export const SoundWave: FC<Props> = ({
  status: state,
  role,
  duration,
  width,
  border,
  stop,
  onAnimationEnded,
  isLoading,
}) => {
  const svgAnimation = useRef<SVGSVGElement>(null);

  useEffect(() => {
    stop && svgAnimation.current?.pauseAnimations();
  }, [stop]);

  return (
    <div style={{ position: 'relative', width }} aria-hidden={true}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 427 139"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgAnimation}
      >
        <g filter="url(#filter0_f_6688_68196)" id="g5801">
          <path
            d="m 38.5,19 v 46.6 c 0,3.1017 -1.4393,6 -3.9,7.9 L 9,93.2371 h 19.5 c 5.5,0 10,4.5 10,10 V 119.5 c 0,5.5 4.5,10 10,10 H 407 c 5.5,0 10,-4.5 10,-10 V 19 C 417,13.5 412.523,9 407,9 H 48.5 c -5.5,0 -10,4.5 -10,10 z"
            fill={border ?? '#AAAAAA'}
          />
        </g>
        <path
          d="m 38.5,19 v 46.6 c 0,3.1 -1.44,6 -3.9,7.9 l -20.95,16.15 c -1.5,1.15 -0.7,3.6 1.2,3.6 h 13.6 c 5.5,0 10,4.5 10,10 v 16.25 c 0,5.5 4.5,10 10,10 h 358.5 c 5.5,0 10,-4.5 10,-10 v -100.5 c 0,-5.5 -4.5,-10 -10,-10 h -358.5 c -5.5,0 -10,4.5 -10,10 z"
          fill={backgroundColorByStatus[role][state]}
        />
        <SoundWaveArea
          soundbarColor={soundbarColorByStatus[state]}
          animateColor={
            state === 'PLAYING' ? ColorPalette.HOVER_BLUE : undefined
          }
          animateTimeMs={duration || 3000}
          left={71}
          soundWaveData={SOUND_WAVE_DATA}
          backgroundColor={backgroundColorByStatus[role][state]}
          color={backgroundColorByStatus[role][state]}
          id={'listening'}
          leftPadding={25}
          width={315}
          onAnimationEnded={onAnimationEnded?.()}
        />
        <defs id="defs5810">
          <filter
            id="filter0_f_6688_68196"
            x="0"
            y="0"
            width="426"
            height="138.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="4.5"
              result="effect1_foregroundBlur_6688_68196"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
