import { Toc, ApiModel } from '../../api.interfaces';
import { extractCommonItemsForExercise } from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';

interface CadeReadingModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  onEvent: (event: CadeEvent) => void;
  count: number;
  total: number;
  subTitle: string;
  audioSrc: string;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  questions: string[];
  currentQuestion: number;
  responseId?: string;
  instruction: string;
}
export function transformJSONToReading(
  json: ApiModel,
  tocArray: Toc[]
): CadeReadingModel {
  const item = json.items[0];
  let questions: string[] = [];

  const displayAction = item.actions.find((el) => el.actionType === 'display');

  if (displayAction) {
    displayAction.displayParams?.display.forEach((el) => {
      el.forEach((question) => questions.push(question));
    });
  }

  const instruction = item.cueInstructions?.at(0) ?? item.microInstructions[0];

  const commonItems = extractCommonItemsForExercise(json, tocArray);

  const currentQuestion = commonItems.count - 1;

  return {
    ...commonItems,
    onEvent: (event: CadeEvent) => {},
    questions,
    currentQuestion,
    audioSrc: commonItems.audioSrc[0],
    instruction,
  };
}
