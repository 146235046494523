import { CADE_CONTENT_TITLE_ID, SetupSuccess } from '@versant-monorepo/cade';
import { useNavigate } from 'react-router-dom';
import { VfwStorage } from '../utils/Storage';
import { vfwRoutes } from '../vfw-routes';
import { useApi } from '../hooks/useApi';
import { Template } from '../components/Template/Template';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { exitEvent, useHandleAppEvent } from 'hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { isSpeakingTest } from '../utils/utils';
import { useSetDocumentTitle } from '../hooks/useSetDocumentTitle';
import { LogLevel } from '../api/Logger';
import { useLogger } from '../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import { useCheckProgress } from '../hooks/useCheckProgress';
import { allChecksPassed, passCheck } from 'utils/prechecks';
import { serializeError } from '../api/api.utils';

export const SetupSuccessWrapper = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('setupSuccessWrapper.documentTitle'));
  const { launchTest, getNextStep } = useApi();
  const { pushEvent } = useLogger();
  const navigate = useNavigate();
  const isVideoProctoringEnabled = VfwStorage.getItem('isTestProctored');
  const { lastEvent, eventHandler } = useHandleAppEvent({
    'cade-ended-event': async () => {
      pushEvent({
        level: LogLevel.INFO,
        item: 'Setup Success',
        message: 'User clicked next on setup success',
      });
      try {
        passCheck('setupSuccess');
        if (allChecksPassed() && !VfwStorage.getItem('launchTestResponse')) {
          await launchTest();
          await getNextStep();
        }
        if (isVideoProctoringEnabled && !VfwStorage.getItem('isTestResumed')) {
          navigate(vfwRoutes.consentPageBiometricData);
        } else if (
          isVideoProctoringEnabled &&
          VfwStorage.getItem('isTestResumed')
        ) {
          navigate(vfwRoutes.takeASelfie);
        } else {
          if (isSpeakingTest()) {
            navigate(vfwRoutes.speakingTips);
          } else {
            navigate(vfwRoutes.test);
          }
        }
      } catch (e) {
        console.error(e);
        pushEvent({
          level: LogLevel.ERROR,
          message: `Setup Success error: ${serializeError(e)}`,
          item: 'Setup Success Check',
          eventType: 'ERROR',
        });
        navigate(vfwRoutes.serverError);
      }
    },
  });
  useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);

  useCheckProgress('setupSuccess');

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                level: LogLevel.INFO,
                item: 'Setup success',
                message: 'User clicked exit test on setup success',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <SetupSuccess
          onEvent={eventHandler}
          isSpeakingTest={isSpeakingTest()}
        />
      </Template>
    </>
  );
};
