import React, { FC } from 'react';
import { VfwStorage } from '../../utils/Storage';
import { NextButton } from '@versant-monorepo/cade';
import { useTranslation } from 'react-i18next';

export const FOOTER_TEST_ID = 'vfw-footer';
export const FOOTER_NEXT_BUTTON_TEST_ID = 'vfw-footer-next-button';

export const Footer: FC<{
  role?: 'static' | 'instruction' | 'check' | 'exercise';
  onNext: () => void;
  nextButtonVisible?: boolean;
  nextButtonEnabled?: boolean;
  buttonLabel?: string;
  nextButtonDisableImage?: boolean;
}> = ({
  role = 'static',
  onNext,
  nextButtonVisible = true,
  nextButtonEnabled = true,
  buttonLabel,
  nextButtonDisableImage = false,
}) => {
  const { t } = useTranslation();

  return (
    <footer
      className={`vfw-template__footer vfw-template__footer--${role}`}
      data-testid={FOOTER_TEST_ID}
    >
      {nextButtonVisible && (
        <div className="vfw-template__footer--right">
          <NextButton
            data-testid={FOOTER_NEXT_BUTTON_TEST_ID}
            disabled={!nextButtonEnabled}
            {...(nextButtonDisableImage ? { icon: '' } : [])}
            onClick={onNext}
          >
            {buttonLabel || t('footer.nextButton')}
          </NextButton>
        </div>
      )}
      <div className={'vfw-template__footer--left'} data-testid={'test-name'}>
        <p>{VfwStorage.getItem('testName') ?? undefined}</p>
      </div>
    </footer>
  );
};
