import React, { PropsWithChildren } from 'react';
import { VolumeProvider } from './Volume.context';
import { MicrophoneProvider } from './Microphone.context';
import {
  CadeConfigProvider,
  CadeConfigProviderProps,
} from './CadeConfigProvider';

export const CadeProvider = ({
  microphoneId,
  config: {
    interactionBeginTone,
    interactionEndTone,
    rootElementId,
    nextTone,
    errorTone,
    i18n,
  },
  children,
}: PropsWithChildren<{
  microphoneId?: string | null;
  config: CadeConfigProviderProps;
}>) => {
  return (
    <>
      <CadeConfigProvider
        interactionBeginTone={interactionBeginTone}
        interactionEndTone={interactionEndTone}
        errorTone={errorTone}
        nextTone={nextTone}
        rootElementId={rootElementId}
        i18n={i18n}
      >
        <VolumeProvider>
          <MicrophoneProvider microphoneId={microphoneId ?? undefined}>
            {children}
          </MicrophoneProvider>
        </VolumeProvider>
      </CadeConfigProvider>
    </>
  );
};
