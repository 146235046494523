import { exitTest, ExitTestArgs } from '../utils/utils';
import { useContext } from 'react';
import { AppContext } from '../context/App.context';

export const useExitTest = () => {
  const { testStatus: _testStatus } = useContext(AppContext);

  return ({
    testStatus = undefined,
    hard = false,
  }: Partial<ExitTestArgs> = {}) =>
    exitTest({ testStatus: testStatus ?? _testStatus, hard });
};
