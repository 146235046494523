import { ApiModel } from '../../api/api.interfaces';
import { CADE_CONTENT_TITLE_ID, Toc } from '@versant-monorepo/cade';
import { Wrapper } from '../Wrapper';
import { useExitModal } from '../../hooks/useModal';
import { useApi } from '../../hooks/useApi';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { Template } from '../../components/Template/Template';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToTOC } from '../../api/parsers/Toc/Toc';
import './styles.scss';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';
import { VfwStorage } from '../../utils/Storage';

export const TocWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
  type: 'toc' | 'resumestatus';
}) => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('tocWrapper.documentTitle'));
  const { switchView, model, type } = props;
  const { getNextStep } = useApi();
  const exitModal = useExitModal();
  const { pushEvent } = useLogger();

  const response = useParser(transformJSONToTOC, model as ApiModel);

  const onNext = async () => {
    pushEvent({
      item: 'Table of Content',
      level: LogLevel.INFO,
      message: 'User go next on Table of Content',
    });
    let response = await getNextStep();

    /*
    flow resume: launch -> resume (toc resume) -> next (toc resume)
    flow resume: launch -> next (toc resume) -> refresh (1st instruction) -> next (1st instruction)

    Tt's a hacky solution to avoid an issue on WDT side.
    Without this conditional, the resumestatus view (ToC resume) is returned twice due to flow mentioned above.
    */
    const itemType = response.items[0].itemType;
    if (itemType === 'resumestatus') {
      response = await getNextStep();
    }

    switchView(CURRENT_ITEM_TYPE, response);
  };

  useFocus(CADE_CONTENT_TITLE_ID, []);

  return (
    response && (
      <>
        <div className={'toc-wrapper'}>
          <Wrapper>
            <Template
              role={type === 'resumestatus' ? 'instruction' : 'exercise'}
              header={
                <Header
                  onExit={() => {
                    pushEvent({
                      item: 'Table of Content',
                      level: LogLevel.INFO,
                      message: 'User clicked exit on Table of Content',
                    });
                    exitModal.show();
                  }}
                  title={''}
                />
              }
              footer={
                <Footer
                  nextButtonVisible={true}
                  buttonLabel={
                    type === 'resumestatus'
                      ? t('buttons.continue')
                      : t('buttons.next')
                  }
                  onNext={onNext}
                  role={'exercise'}
                />
              }
            >
              <Toc
                type={type}
                title={t('tocWrapper.overview')}
                description={t('tocWrapper.desc')}
                toc={response.toc as any[]}
              />
              <div style={{ height: '100px', background: 'white' }} />
            </Template>
          </Wrapper>
        </div>
      </>
    )
  );
};
