import { useState } from 'react';

type ForceEndTrigger =
  | 'we-cant-hear-you'
  | 'problem-with-audio'
  | 'next-clicked'
  | null;
export const useForceEndTrigger = () => {
  const [forceEndTrigger, setForceEndTrigger] = useState<ForceEndTrigger>(null);

  const setForceEnd = (val: ForceEndTrigger) => setForceEndTrigger(val);

  return {
    forceEndTrigger,
    setForceEnd,
  };
};
