import { useState, useEffect, useRef } from 'react';
import { VfwStorage } from 'utils/Storage';
import { Logger, LogLevel } from '../api/Logger';

type PermissionState = 'granted' | 'denied' | 'prompt' | 'error';
const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

const isPermissionError = (error: unknown): error is DOMException => {
  return (
    error instanceof DOMException &&
    (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError')
  );
};

export const useCameraPermissions = (intervalMs: number = 5000) => {
  const isVideoProctoringEnabled = VfwStorage.getItem('isTestProctored');
  const [permissionState, setPermissionState] =
    useState<PermissionState>('prompt');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const forceToLaunchCamera = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getTracks().forEach((track) => {
      track.onended = () => {
        console.log(
          `Track onended method invoked because of capturing failed. Possible place to reinitialize. [cameraPermissions], readyState: ${track.readyState}`
        );
        Logger.getInstance().pushEvent({
          level: LogLevel.INFO,
          message: `One of the media track has been closed. [CameraPermissions], ${track.readyState}`,
          item: 'MONITORING',
        });
      };
    });
    setPermissionState('granted');
    stream.getTracks().forEach((track) => track.stop());
  };

  const checkCameraPermission = async () => {
    try {
      if (isFirefox) {
        await forceToLaunchCamera();
      } else {
        const permission: PermissionStatus = await navigator.permissions.query({
          name: 'camera' as PermissionName,
        });
        setPermissionState(permission.state);

        if (permission.state !== 'granted') {
          await forceToLaunchCamera();
        }
      }
    } catch (error) {
      if (isPermissionError(error)) {
        if (
          error.name === 'NotAllowedError' ||
          error.name === 'PermissionDeniedError'
        ) {
          setPermissionState('denied');
        }
      } else {
        console.error('Error accessing camera:', error);
        setPermissionState('error');
      }
    }
  };

  useEffect(() => {
    if (isVideoProctoringEnabled) {
      intervalRef.current = setInterval(checkCameraPermission, intervalMs);
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [intervalMs, isVideoProctoringEnabled]);

  return permissionState;
};
