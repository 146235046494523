import { useNavigate } from 'react-router-dom';
import {
  CADE_CONTENT_TITLE_ID,
  CADE_NEXT_BUTTON_ID,
  WeCantHearYou,
} from '@versant-monorepo/cade';
import { vfwRoutes } from '../vfw-routes';
import { useWeCantHearYou } from '../hooks/useWeCantHearYou';
import { VfwStorage } from '../utils/Storage';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { Template } from '../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../hooks/useHandleAppEvent';
import { useContext, useEffect } from 'react';
import { AppContext } from '../context/App.context';
import { useFocus } from '../hooks/useFocus';
import { useSetDocumentTitle } from '../hooks/useSetDocumentTitle';
import { useLogger } from '../hooks/useLogger';
import { LogLevel } from '../api/Logger';
import { useTranslation } from 'react-i18next';

export const WeCantHearYouWrapper = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('weCantHearYouWrapper.documentTitle'));
  const navigate = useNavigate();
  const { canHearYou } = useWeCantHearYou();
  const { pushEvent } = useLogger();
  const { setAudioTroubleshootingCount } = useContext(AppContext);

  const { eventHandler } = useHandleAppEvent({
    'cade-continue-click-event': (event) => {
      VfwStorage.setItem('microphoneId', event.value);
      pushEvent({
        level: LogLevel.INFO,
        item: 'We cant hear you',
        message: 'User will continue the test from we cant hear you page',
      });
      canHearYou();
      navigate(vfwRoutes.test);
    },
    'cade-failure-event': () => {
      pushEvent({
        level: LogLevel.INFO,
        item: 'We cant hear you',
        message: 'Cannot hear user, move to microphone problem page',
      });
      navigate(vfwRoutes.microphoneProblem);
    },
    'cade-success-event': () => focus(CADE_NEXT_BUTTON_ID),
  });

  const { focus } = useFocus(CADE_CONTENT_TITLE_ID);

  useEffect(() => {
    setAudioTroubleshootingCount((val) => val + 1);
  }, []);

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                level: LogLevel.INFO,
                item: 'We cant hear you',
                message: 'User clicked exit test on we cant hear you check',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <WeCantHearYou onEvent={eventHandler} />
      </Template>
    </>
  );
};
