import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { useApi } from '../../hooks/useApi';
import { useState } from 'react';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useParser } from '../../hooks/useParser';
import { transformJSONToSummaryAndOpinion } from '../../api/parsers/SummaryAndOpinion/SummaryAndOpinion';
import { Wrapper } from '../Wrapper';
import { Template } from '../../components/Template/Template';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { SummaryAndOpinion } from '@versant-monorepo/cade';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useBackgroundNoiseAudio } from '../../hooks/useBackgroundNoiseAudio';

export const SummaryAndOpinionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();

  const [forceEnd, setForceEnd] = useState<boolean>(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) =>
      onEnded(event.value as { summary: string; opinion: string }),
  });
  const { pushEvent } = useLogger();

  const summaryAndOpinionProps = useParser(
    transformJSONToSummaryAndOpinion,
    model as ApiModel
  );

  const { stopRecording, isVideoProctoringEnabled } = useBackgroundNoiseAudio(
    summaryAndOpinionProps?.responseId1
  );

  useSetDocumentTitle(summaryAndOpinionProps?.title);

  const onEnded = async (value: { summary: string; opinion: string }) => {
    if (isVideoProctoringEnabled) {
      stopRecording();
    }
    const summaryItem = {
      id: summaryAndOpinionProps?.responseId1,
      response: value.summary,
      type: 'TEXT',
    } as TextResponse;

    const opinionItem = {
      id: summaryAndOpinionProps?.responseId2,
      response: value.opinion,
      type: 'TEXT',
    } as TextResponse;
    pushEvent({
      item: 'Summary and Opinion Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Summary and Opinion Exercise',
    });
    const response = await getNextStep([summaryItem, opinionItem]);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
  };

  return (
    summaryAndOpinionProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'exercise'}
            footer={
              <Footer
                nextButtonEnabled={true}
                onNext={() => setForceEnd(true)}
                role={'exercise'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'Summary and Opinion Exercise',
                    level: LogLevel.INFO,
                    message:
                      'User clicked exit on Summary and Opinion Exercise',
                  });
                  eventHandler(exitEvent());
                }}
                currentStep={summaryAndOpinionProps.currentStep}
                totalSteps={summaryAndOpinionProps.totalSteps}
                title={summaryAndOpinionProps.title}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <SummaryAndOpinion
                key={summaryAndOpinionProps.responseId1}
                {...summaryAndOpinionProps}
                onEvent={eventHandler}
                forceEnd={forceEnd}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
