import React, { useCallback, useState } from 'react';
import {
  FeatureFlagsManager,
  Features,
  FeaturesKeys,
  parseFeatureFlagsFromEnv,
} from '@versant-monorepo/cade';
import { Entries } from '../utils/types';
import { vfwRoutes } from '../vfw-routes';
import { FEATURE_FLAG_ENV_PREFIX } from '../constants';

type Option = {
  name: FeaturesKeys;
  value: boolean;
};

const parseFeatureFlagsForCheckboxes = (flags: Features) => {
  return (Object.entries(flags || {}) as Entries<Features>).reduce(
    (featureFlags, [name, value]) => [...featureFlags, { name, value }],
    [] as Option[]
  );
};

const flags = parseFeatureFlagsForCheckboxes(
  FeatureFlagsManager.getFlags() ||
    parseFeatureFlagsFromEnv(process.env, FEATURE_FLAG_ENV_PREFIX)
);

export const FeatureFlagConfig = () => {
  const [options, setOptions] = useState<Option[]>(flags);

  const goToHomePage = useCallback(
    () => (window.location.href = vfwRoutes.root),
    []
  );

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked: value } = event.target;
    setOptions((previousOptions) => {
      const options = previousOptions.map((option) =>
        option.name === name
          ? {
              ...option,
              value,
            }
          : option
      );

      const preparedFlags = options.reduce(
        (all, { name, value }) => ({ ...all, [name]: value }),
        {}
      );

      FeatureFlagsManager.setFlags(preparedFlags);

      return options;
    });
  }, []);

  const renderOptions = useCallback(
    ({ name, value }: Option) => (
      <React.Fragment key={name}>
        <label>
          {name}
          <input
            type="checkbox"
            defaultChecked={value}
            name={name}
            onChange={onChange}
          />
        </label>
        <br />
      </React.Fragment>
    ),
    [onChange]
  );

  return (
    <>
      {options.map(renderOptions)}
      <button onClick={goToHomePage}>Go Home</button>
    </>
  );
};
