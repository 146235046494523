import React, { FC } from 'react';
import { NextButton, Paragraph } from '@versant-monorepo/cade';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export const LoginFooter: FC<{
  onNext?: () => void;
  nextButtonVisible?: boolean;
  nextDisabled?: boolean;
}> = ({
  onNext = () => {},
  nextButtonVisible = true,
  nextDisabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <footer className={`vfw-template__footer vfw-template__footer--static`}>
      <div className={'vfw-template__footer--left'}>
        <div className={`vfw-template__footer-consent`}>
          <Paragraph level={'smaller'}>
            By signing in and using this Versant service, you agree and consent
            to the&nbsp;
            <a
              href={
                'https://www.pearson.com/legal-information/terms-of-use.html'
              }
              target={'_blank'}
            >
              Terms of Use
            </a>{' '}
            and&nbsp;
            <a
              href={
                'https://www.versanttest.com/scorekeeper/legal/privacy-policy'
              }
              target={'_blank'}
            >
              Privacy Policy
            </a>
            <span className={'footer-right-text'}>
              &copy; {new Date().getFullYear()} Pearson Education, Inc. or its
              affiliate(s). All rights reserved.
            </span>
          </Paragraph>
        </div>
      </div>
      <div className="vfw-template__footer--right">
        {nextButtonVisible && (
          <NextButton onClick={onNext} disabled={nextDisabled} />
        )}
      </div>
    </footer>
  );
};
