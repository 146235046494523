import { Template } from '../../components/Template/Template';
import { Box, Paragraph, PrimaryButton } from '@versant-monorepo/cade';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { InternetIcon } from '../../components/InternetIcon/InternetIcon';
import './styles.scss';
import { ContentTitle } from 'components/ContentTitle/ContentTitle';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';
import { useExitTest } from '../../hooks/useExitTest';

export function ProblemWithConnectionWrapper() {
  useFocus(CONTENT_TITLE_ID, []);
  const exitTest = useExitTest();
  const { t } = useTranslation();
  useSetDocumentTitle(t('problemWithConnectionWrapper.documentTitle'));
  const { pushEvent } = useLogger();

  async function hardExit() {
    pushEvent({
      item: 'Problem with connection',
      level: LogLevel.INFO,
      message: 'There is problem with connection',
      eventType: 'ERROR',
    });
    exitTest({ hard: true });
  }

  return (
    <Template
      header={<Header onExit={hardExit} title={''} />}
      footer={
        <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
      }
    >
      <div className="problem-with-connection__container">
        <div className="problem-with-connection__title-container">
          <ContentTitle
            className="cade-margin-bottom-4 problem-with-connection__red"
            level="large"
          >
            {t('problemWithConnectionWrapper.title')}
          </ContentTitle>
          <Paragraph className="cade-margin-bottom-6" level="large">
            {t('problemWithConnectionWrapper.p')}
            <br />
            {t('problemWithConnectionWrapper.p2')}
          </Paragraph>
        </div>
        <Box role="instruction">
          <div className="problem-with-connection__box-container">
            <Paragraph
              className="cade-margin-bottom-9 problem-with-connection__red"
              level="large"
            >
              {t('problemWithConnectionWrapper.p3')}
            </Paragraph>
            <div className="cade-margin-bottom-11 problem-with-connection__red">
              <InternetIcon />
            </div>
            <PrimaryButton onClick={hardExit}>
              {t('problemWithConnectionWrapper.endTest')}
            </PrimaryButton>
          </div>
        </Box>
      </div>
    </Template>
  );
}
