import { RefObject, useEffect } from 'react';
import { getAudioContext } from '../../utils';

type AudioVisualizationHookProps = {
  onVolumeChangeCallback: (data: number) => void;
  audioElement: RefObject<HTMLAudioElement>;
};

const useAudioVolumeAnalyzer = ({
  onVolumeChangeCallback,
  audioElement,
}: AudioVisualizationHookProps) => {
  useEffect(() => {
    let sourceNode: MediaElementAudioSourceNode | null;
    let analyzerNode: AnalyserNode | null;

    const handleFileLoad = async () => {
      const audioContext = getAudioContext();
      const audio = audioElement.current!;
      sourceNode = audioContext.createMediaElementSource(audio);
      analyzerNode = audioContext.createAnalyser();
      analyzerNode.fftSize = 256;
      sourceNode.connect(analyzerNode);
      analyzerNode.connect(audioContext.destination);
    };

    const updateAudioData = () => {
      if (!analyzerNode) {
        return;
      }

      const dataArray = new Uint8Array(analyzerNode.frequencyBinCount);
      analyzerNode.getByteFrequencyData(dataArray);

      const volume = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
      onVolumeChangeCallback(volume);
    };

    handleFileLoad();

    const intervalId = setInterval(updateAudioData, 50);

    return () => {
      clearInterval(intervalId);
      sourceNode?.disconnect();
      analyzerNode?.disconnect();
    };
  }, []);
};

export default useAudioVolumeAnalyzer;
