import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractActorsData,
  extractAudioResourcesFromResponse,
  extractCommonItemsForInstruction,
  ScriptLine,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeShortAnswerQuestionInstructionModel {
  title: string;
  subTitle: string;
  instruction: string;
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
  audioSrc: string[];
  listenPartDuration: number;
  scripts: ScriptLine[];
  leftLabel: string;
  rightLabel: string;
  startActorsAnimationOnAudioIndex: number;
}
export function transformJSONToQuestionInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeShortAnswerQuestionInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const audio = extractAudioResourcesFromResponse(json);

  const display = json.items[0].actions.find(
    (el) => el.actionType === 'display'
  )?.displayParams?.display;

  let leftLabel = i18n.t('question.instruction.labels.left');
  let rightLabel = i18n.t('question.instruction.labels.right');

  if (display) {
    if (display[0] && display[0][1]) {
      leftLabel = display[0][1];
    }
    if (display[0] && display[0][3]) {
      rightLabel = display[0][3];
    }
  }

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    scripts: extractActorsData(json),
    startActorsAnimationOnAudioIndex: 1,
    audioSrc: audio,
    onEvent: (event: CadeEvent) => {},
    listenPartDuration: 10000,
    leftLabel: leftLabel,
    rightLabel: rightLabel,
  };
}
