import { Template } from '../../components/Template/Template';
import { Header } from '../../components/Header/Header';
import { LogLevel } from '../../api/Logger';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { Footer } from '../../components/Footer/Footer';
import { useLogger } from '../../hooks/useLogger';
import {
  Box,
  Modal,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
} from '@versant-monorepo/cade';
import { ContentTitle } from '../../components/ContentTitle/ContentTitle';
import './styles.scss';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Webcam from 'react-webcam';
import useCameras from '../../hooks/useCameras';
import { useTranslation } from 'react-i18next';
import cameraOff from './icons/camera-off.svg';
import alertIcon from './icons/alert.svg';
import successIcon from './icons/success.svg';
import { VfwStorage } from '../../utils/Storage';
import { vfwRoutes } from '../../vfw-routes';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import CircleLoader from '../../components/CircleLoader/CircleLoader';
import { useCheckProgress } from '../../hooks/useCheckProgress';
import { passCheck } from '../../utils/prechecks';
import { serializeError } from '../../api/api.utils';

export function TakeASelfie() {
  const [state, setState] = useState<
    | 'INITIAL'
    | 'ACCESS_GRANTED'
    | 'PHOTO_TAKEN'
    | 'PHOTO_UPLOADING'
    | 'SUCCESS'
    | 'ERROR'
  >('INITIAL');
  const [photo, setPhoto] = useState<string | null | undefined>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { eventHandler } = useHandleAppEvent();
  const { pushEvent } = useLogger();
  const { cameraState } = useCameras();
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { validateReferenceFrame } = useApi();
  const [currentCameraDevice, setCurrentCameraDevice] = useState<string>();

  useCheckProgress('takeASelfie');

  useEffect(() => {
    if (cameraState.status === 'ERROR') {
      setState('ERROR');
      setError(cameraState.message);
    }

    if (cameraState.status === 'SUCCESS') {
      setState('ACCESS_GRANTED');
      setCurrentCameraDevice(cameraState.data[0].deviceId);
    }
  }, [cameraState]);

  useEffect(() => {
    if (currentCameraDevice) {
      VfwStorage.setItem('cameraId', currentCameraDevice);
    }
  }, [currentCameraDevice]);

  const photoVisible = useMemo(() => {
    return (
      state === 'PHOTO_TAKEN' ||
      state === 'PHOTO_UPLOADING' ||
      state === 'SUCCESS'
    );
  }, [state]);

  const initialMessage = useMemo(() => {
    return state === 'INITIAL' || state === 'ACCESS_GRANTED';
  }, [state]);

  const sendPhoto = useCallback(async () => {
    if (photo) {
      setLoading(true);
      const res: { acceptable: boolean; details: string } =
        await validateReferenceFrame(photo);
      if (!res.acceptable) {
        setState('ERROR');
        // ugly solution for showing different messages based on VRM response
        // will be fixed later with new VRM
        if (res.details.startsWith('Only one face')) {
          setError(t('remoteMonitoring.takeASelfie.onlyOneFace'));
        } else if (res.details.startsWith('Image capture is too dark')) {
          setError(t('remoteMonitoring.takeASelfie.tooDark'));
        } else if (res.details.startsWith('Image capture is too blur')) {
          setError(t('remoteMonitoring.takeASelfie.tooBlur'));
        } else if (res.details.startsWith('Face Pose incorrect')) {
          setError(t('remoteMonitoring.takeASelfie.poseIncorrect'));
        } else if (res.details.startsWith('Face is not centered')) {
          setError(t('remoteMonitoring.takeASelfie.faceNotCentered'));
        }
      } else {
        setState('SUCCESS');
        VfwStorage.setItem('referencePhotoBase64', photo);
      }
      setLoading(false);
    }
  }, [photo]);

  const handleSuccessClick = () => {
    try {
      passCheck('takeASelfie');
      if (VfwStorage.getItem('isTestResumed')) {
        navigate(vfwRoutes.reviewRules);
      } else {
        navigate(vfwRoutes.recordAVideo);
      }
    } catch (e) {
      console.error(e);
      pushEvent({
        level: LogLevel.ERROR,
        message: `Take Selfie error: ${serializeError(e)}`,
        item: 'Take Selfie',
        eventType: 'ERROR',
      });
      navigate(vfwRoutes.serverError);
    }
  };

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                item: 'Take a Selfie',
                level: LogLevel.INFO,
                message: 'User clicked exit on Take a Selfie',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <div className={'take-a-selfie'}>
          <div className={'take-a-selfie__header'}>
            <Paragraph>{t('remoteMonitoring.takeASelfie.setup')}</Paragraph>
            <ContentTitle level={'large'}>
              {t('remoteMonitoring.takeASelfie.header')}
            </ContentTitle>
            <Paragraph
              className={'take-a-selfie__stepper'}
              level={'smaller'}
              weight={'semibold'}
            >
              {t('remoteMonitoring.takeASelfie.step')} 1{' '}
              {t('remoteMonitoring.takeASelfie.of')}{' '}
              {`${VfwStorage.getItem('isTestResumed') ? '1' : '2'}`}
            </Paragraph>
          </div>
          <Box role={'instruction'} className={'take-a-selfie__box'}>
            <div className={'take-a-selfie__box-header'}>
              {initialMessage && (
                <Paragraph level={'small'}>
                  {t('remoteMonitoring.takeASelfie.chooseCamera')}
                </Paragraph>
              )}
              {photoVisible && state !== 'SUCCESS' && (
                <Paragraph level={'small'}>
                  {t('remoteMonitoring.takeASelfie.clearPhoto')}
                </Paragraph>
              )}
              {state === 'SUCCESS' && (
                <Paragraph level={'small'}>
                  {t('remoteMonitoring.takeASelfie.imageOK')}
                </Paragraph>
              )}
            </div>
            {state === 'INITIAL' && (
              <div className={'take-a-selfie__box-camera-initial'}></div>
            )}
            {state === 'ACCESS_GRANTED' && (
              <div className={'take-a-selfie__box-camera'}>
                <Webcam
                  ref={webcamRef}
                  style={{ width: 345, transform: 'translate(0, -20px)' }}
                  screenshotFormat={'image/jpeg'}
                  videoConstraints={{
                    facingMode: currentCameraDevice ? undefined : 'user',
                    deviceId: currentCameraDevice,
                  }}
                  mirrored
                />
              </div>
            )}
            {photoVisible && (
              <div className="take-a-selfie__photo-container">
                <div className={'take-a-selfie__box-picture'}>
                  {loading && (
                    <div className={'take-a-selfie__overlay'}>
                      <CircleLoader />
                    </div>
                  )}
                  <img src={photo as string} alt="selfie" />
                </div>
                {state === 'SUCCESS' && (
                  <img
                    className={'take-a-selfie__success-icon'}
                    src={successIcon}
                    alt="success icon"
                  />
                )}
              </div>
            )}
            {state === 'ERROR' && (
              <>
                {!photo && (
                  <>
                    <div
                      className={'take-a-selfie__error'}
                      dangerouslySetInnerHTML={{ __html: error as string }}
                    />
                    <div className={'take-a-selfie__error'}>
                      <Paragraph level={'small'}>
                        {t('remoteMonitoring.takeASelfie.follow')}{' '}
                        <a
                          onClick={() => {
                            setModalOpen(true);
                          }}
                          className={'take-a-selfie__troubleshooting-link'}
                        >
                          {t(
                            'remoteMonitoring.takeASelfie.troubleshootingGuide'
                          )}
                        </a>
                        .
                      </Paragraph>
                    </div>
                  </>
                )}
                {photo && (
                  <div className="take-a-selfie__photo-container">
                    <div
                      className={'take-a-selfie__error'}
                      dangerouslySetInnerHTML={{ __html: error as string }}
                    ></div>
                    <div
                      className={'take-a-selfie__error-image'}
                      style={{ background: 'none' }}
                    >
                      <img
                        style={{ width: 345 }}
                        src={photo as string}
                        alt="selfie"
                      />
                    </div>
                    <img
                      src={alertIcon}
                      alt="Alert icon"
                      className={'take-a-selfie__alert-icon'}
                    />
                  </div>
                )}
                {cameraState.status === 'ERROR' && (
                  <div className="take-a-selfie__photo-container">
                    <div className={'take-a-selfie__error-image'}>
                      <img src={cameraOff} alt="Camera off" />
                      <img
                        src={alertIcon}
                        alt="Alert icon"
                        className={'take-a-selfie__alert-icon'}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={'take-a-selfie__box-select'}>
              <label
                className={'take-a-selfie__camera-label'}
                htmlFor={'chooseCamera'}
              >
                Camera
              </label>
              <select
                id={'chooseCamera'}
                className={'take-a-selfie__select'}
                onChange={(e) => {
                  setCurrentCameraDevice(e.target.value);
                }}
              >
                {cameraState.status === 'SUCCESS' &&
                  cameraState.data.map((camera, index) => (
                    <option key={camera.deviceId} value={camera.deviceId}>
                      {camera.label || `Camera ${index + 1}`}
                    </option>
                  ))}
              </select>
            </div>
            <div className={'take-a-selfie__box-buttons'}>
              {state === 'INITIAL' ||
                (state === 'ACCESS_GRANTED' && (
                  <SecondaryButton
                    data-testid={'capturePhoto'}
                    onClick={() => {
                      const src = webcamRef.current?.getScreenshot();
                      setPhoto(src);
                      setState('PHOTO_TAKEN');
                    }}
                  >
                    {t('remoteMonitoring.takeASelfie.capturePhoto')}
                  </SecondaryButton>
                ))}
              {state === 'PHOTO_TAKEN' && (
                <>
                  <PrimaryButton onClick={sendPhoto} data-testid={'usePhoto'}>
                    {t('remoteMonitoring.takeASelfie.useThisPhoto')}
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={() => {
                      setState('ACCESS_GRANTED');
                      setPhoto(null);
                    }}
                  >
                    {t('remoteMonitoring.takeASelfie.tryAgain')}
                  </SecondaryButton>
                </>
              )}
              {state === 'ERROR' && photo && (
                <>
                  <SecondaryButton
                    onClick={() => {
                      setState('ACCESS_GRANTED');
                      setPhoto(null);
                    }}
                  >
                    {t('remoteMonitoring.takeASelfie.retry')}
                  </SecondaryButton>
                </>
              )}
              {state === 'SUCCESS' && (
                <PrimaryButton onClick={handleSuccessClick}>
                  {t('remoteMonitoring.takeASelfie.next')}
                </PrimaryButton>
              )}
              {cameraState.status === 'ERROR' && (
                <SecondaryButton onClick={() => window.location.reload()}>
                  {t('remoteMonitoring.takeASelfie.refresh')}
                </SecondaryButton>
              )}
            </div>
          </Box>
        </div>
      </Template>

      <Modal
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        titleType={'h2'}
        title={t('remoteMonitoring.takeASelfie.troubleshootingTips')}
        footer={
          <div className={'take-a-selfie__modal-footer'}>
            <SecondaryButton
              onClick={() => setModalOpen(false)}
              style={{ minWidth: 172 }}
            >
              {t('remoteMonitoring.takeASelfie.ok')}
            </SecondaryButton>
          </div>
        }
      >
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t('remoteMonitoring.takeASelfie.modalTip1'),
          }}
        />
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t('remoteMonitoring.takeASelfie.modalTip2'),
          }}
        />
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t('remoteMonitoring.takeASelfie.modalTip3'),
          }}
        />
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t('remoteMonitoring.takeASelfie.modalTip4'),
          }}
        />
      </Modal>
    </>
  );
}
