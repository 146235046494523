import './style.scss';
import { ChecksHeader } from '@components/ChecksHeader';
import React, { useContext } from 'react';
import { SystemCheckCell } from '../Checks/SystemCheck/SystemCheckCell';
import { Box, NextButton } from '@src/components';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { Space } from '@components/Space';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export interface SetupSuccessProps {
  subTitle?: string;
  onEvent: (event: CadeEvent) => void;
  isSpeakingTest: boolean;
}

export const SetupSuccess = ({
  subTitle,
  onEvent,
  isSpeakingTest,
}: SetupSuccessProps) => {
  const { onClickSendEndEvent } = useEndedEvent(onEvent);
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const speakingSystemChecks = [
    { id: 1, label: t('setupSuccess.system'), state: 'ok' },
    { id: 2, label: t('setupSuccess.bandwidth'), state: 'ok' },
    { id: 3, label: t('setupSuccess.microphone'), state: 'ok' },
    { id: 4, label: t('setupSuccess.speaker'), state: 'ok' },
    { id: 5, label: t('setupSuccess.backgroundNoise'), state: 'ok' },
  ];

  const nonSpeakingSystemChecks = [
    { id: 1, label: t('setupSuccess.system'), state: 'ok' },
    { id: 2, label: t('setupSuccess.bandwidth'), state: 'ok' },
    { id: 3, label: t('setupSuccess.speaker'), state: 'ok' },
  ];

  const initSystemChecks = isSpeakingTest
    ? speakingSystemChecks
    : nonSpeakingSystemChecks;

  return (
    <>
      <div className={'cade-setup-success__container'}>
        <div className={'cade-setup-success'}>
          <ChecksHeader
            headerText={''}
            title={subTitle || t('setupSuccess.subTitle')}
            currentStep={0}
            totalSteps={0}
            successMsg={t('setupSuccess.successMsg')}
          />
          <Box role={'instruction'} className="cade-setup-success__box">
            <div
              className="cade-setup-success__content"
              aria-live={'polite'}
              role={'status'}
              aria-atomic={true}
            >
              <Space
                role={'list'}
                margin={{ bottom: 5 }}
                className="cade-setup-success__checks-list"
              >
                {initSystemChecks.map((sc) => (
                  <SystemCheckCell
                    key={sc.id.toString()}
                    label={sc.label}
                    state={sc.state}
                  />
                ))}
              </Space>
            </div>
            <div className={'cade-setup-success__footer'}>
              <NextButton onClick={onClickSendEndEvent} />
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};
