import React, { useContext } from 'react';
import { osName } from 'react-device-detect';
import {
  TroubleshootingTipsModal,
  Props as ModalProps,
} from '@components/Modal';
import { MicrophoneCheckState } from './index';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Props = {
  state?: MicrophoneCheckState;
  micsWereNotDetected?: boolean;
} & ModalProps;

const i18nComponents = {
  ul: <ul />,
  li: <li />,
  b: <b />,
};

export function TroubleshotModal({ open, onClose, state }: Props) {
  const {
    i18n: { TranslationComponent },
  } = useContext(ConfigContext);
  return (
    <TroubleshootingTipsModal open={open} onClose={onClose}>
      {(state === 'ERROR' || state === 'ERROR_WE_CANT_HEAR_YOU') && (
        <>
          {osName.includes('Mac OS') && (
            <TranslationComponent
              i18nKey="microphoneCheck.troubleshotModal.macos.weCantHearYou"
              components={i18nComponents}
            />
          )}
          {osName.includes('Windows') && (
            <TranslationComponent
              i18nKey="microphoneCheck.troubleshotModal.win.weCantHearYou"
              components={i18nComponents}
            />
          )}
        </>
      )}
      {(state === 'ERROR_MICROPHONE' ||
        state === 'ERROR_MICROPHONE_WE_CANT_HEAR_YOU') && (
        <>
          {osName.includes('Mac OS') && (
            <TranslationComponent
              i18nKey="microphoneCheck.troubleshotModal.macos.errorMicrophone"
              components={i18nComponents}
            />
          )}
          {osName.includes('Windows') && (
            <TranslationComponent
              i18nKey="microphoneCheck.troubleshotModal.win.errorMicrophone"
              components={i18nComponents}
            />
          )}
        </>
      )}
    </TroubleshootingTipsModal>
  );
}
