import { Box, Paragraph, PrimaryButton, Title } from '@src/components';
import { MicrophoneIcon } from '@icons/MicrophoneIcon';
import React, { useContext } from 'react';
import './styles.scss';
import { CadeEvent, exitEvent } from '@utils/events';
import { Space } from '@components/Space';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type Props = {
  onEvent: (event: CadeEvent) => void;
};

export function MicrophoneProblem({ onEvent }: Props) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <>
      <div className="microphone-problem__container">
        <div className="microphone-problem__title-container">
          <Title
            className="cade-margin-bottom-4 microphone-problem__red"
            level="large"
          >
            {t('microphoneProblem.title')}
          </Title>
          <Paragraph className="cade-margin-bottom-9" level="large">
            {t('microphoneProblem.p')}
          </Paragraph>
        </div>
        <Box role="instruction">
          <div className="microphone-problem__box-container">
            <Space
              margin={{ top: 11, bottom: 11 }}
              className="microphone-problem__red"
            >
              <MicrophoneIcon />
            </Space>
            <PrimaryButton onClick={() => onEvent(exitEvent())}>
              {t('microphoneProblem.button')}
            </PrimaryButton>
          </div>
        </Box>
      </div>
    </>
  );
}
