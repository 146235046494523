import {
  CADE_CONTENT_TITLE_ID,
  CADE_NEXT_BUTTON_ID,
  CADE_SYSTEM_CHECK_TROUBLESHOOTING_LINK_ID,
  CadeFailureEvent,
  SYSTEM_CHECK_ID,
  SystemCheck,
  SystemCheckElement,
} from '@versant-monorepo/cade';
import { useNavigate } from 'react-router-dom';
import { vfwRoutes } from '../vfw-routes';
import { useEffect, useRef } from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { Template } from '../components/Template/Template';
import { useFocus } from 'hooks/useFocus';
import { exitEvent, useHandleAppEvent } from 'hooks/useHandleAppEvent';
import { isSpeakingTest } from '../utils/utils';
import { useSetDocumentTitle } from '../hooks/useSetDocumentTitle';
import { LogLevel } from '../api/Logger';
import { useLogger } from '../hooks/useLogger';
import { validateRecording } from '../api/api';
import { useTranslation } from 'react-i18next';
import { passCheck } from 'utils/prechecks';
import { serializeError } from '../api/api.utils';

export const SystemCheckWrapper = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('systemCheckWrapper.documentTitle'));
  const { pushEvent } = useLogger();
  const navigate = useNavigate();
  const browserErrorsRef = useRef(false);

  useEffect(() => {
    pushEvent({
      level: LogLevel.INFO,
      item: 'System Check',
      message: 'Start of System Check',
      eventType: 'START',
    });
  }, []);

  const { eventHandler } = useHandleAppEvent({
    'cade-failure-event': (event: CadeFailureEvent<SystemCheckElement[]>) => {
      const hasAnyBrowserErrors =
        event.details?.filter(
          ({ id }: SystemCheckElement) =>
            id === SYSTEM_CHECK_ID.BROWSER_VERSION ||
            id === SYSTEM_CHECK_ID.BROWSER_COMPATIBILITY
        )?.length ?? 0;
      browserErrorsRef.current = hasAnyBrowserErrors > 0;

      event.details?.forEach((error: SystemCheckElement) => {
        pushEvent({
          level: LogLevel.INFO,
          item: 'System Check',
          message: `${error.id}`,
          eventType: 'ERROR',
        });
      });
      focus(CADE_SYSTEM_CHECK_TROUBLESHOOTING_LINK_ID);
    },
    'cade-ended-event': () => {
      // For browser errors we have different flow
      if (browserErrorsRef.current) {
        pushEvent({
          level: LogLevel.INFO,
          item: 'System Check',
          message: 'Browser error. Navigates to not supported browser page.',
          eventType: 'ERROR',
        });
        navigate(vfwRoutes.browserNotSupported);
        return;
      }
      try {
        passCheck('system');
        pushEvent({
          level: LogLevel.INFO,
          item: 'System Check',
          message: 'User go next on system check',
          eventType: 'SUCCESS',
        });
        navigate(vfwRoutes.bandwidthCheck);
      } catch (e) {
        console.error(e);
        pushEvent({
          level: LogLevel.ERROR,
          message: `System Check error: ${serializeError(e)}`,
          item: 'System Check',
          eventType: 'ERROR',
        });
        navigate(vfwRoutes.serverError);
      }
    },
    'cade-success-event': async () => {
      pushEvent({
        level: LogLevel.INFO,
        item: 'System Check',
        message: 'All system checked passed',
        eventType: 'SUCCESS',
      });
      focus(CADE_NEXT_BUTTON_ID);
    },
  });
  const { focus } = useFocus(CADE_CONTENT_TITLE_ID, []);

  const networkCheckHandler = async (): Promise<boolean> => {
    return validateRecording();
  };

  const systemCheckProps = {
    count: 1,
    total: isSpeakingTest() ? 5 : 3,
    currentCheckingStep: 1,
    totalCheckingSteps: isSpeakingTest() ? 5 : 3,
    tooltip: 'Tooltip Content',
    onEvent: eventHandler,
    networkCheck: networkCheckHandler,
  };

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                level: LogLevel.INFO,
                item: 'System Check',
                message: 'User clicked exit test on system check',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <SystemCheck {...systemCheckProps} />
      </Template>
    </>
  );
};
