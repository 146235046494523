import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import classNames from 'classnames';
import {
  CADE_NEXT_BUTTON_ID,
  NextButton,
  Paragraph,
  SecondaryButton,
  Space,
} from '@src/components';
import { Meter, MicrophoneMeter, Status } from './MicrophoneMeter';
import { ChecksHeader } from '@components/ChecksHeader';
import { ColorPalette } from '@src/colorPalette';
import { useRecording } from '@components/Recording/useRecording';
import { Audio } from './Audio';
import { CadeEvent, successEvent, useEndedEvent } from '@utils/events';
import { ChecksBox } from '../components/ChecksBox';
import { useDelay } from '@utils/useDelay';
import { useBeep } from '@utils/useBeep';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type Props = {
  currentCheckingStep: number;
  totalCheckingSteps: number;
  warningDecibels: number;
  maxAcceptableDecibels: number;
  onEvent: (event: CadeEvent) => void;
};

const getColor = (status: Status) => {
  switch (status) {
    default:
    case 'INIT': {
      return ColorPalette.BLACK;
    }

    case 'WARNING':
    case 'SUCCESS': {
      return ColorPalette.GREEN_ALERT;
    }

    case 'FAIL': {
      return ColorPalette.RED_WARNING;
    }
  }
};

const AUTORUN_CHECK_TIME = 10_000;

export const BackgroundNoiseCheck = ({
  totalCheckingSteps,
  currentCheckingStep,
  maxAcceptableDecibels,
  warningDecibels,
  onEvent,
}: Props) => {
  const [status, setStatus] = useState<Status>('DISABLED');
  const [refresh, setRefresh] = useState(0);
  const failedTriesRef = useRef(0);
  const { onClickSendEndEvent } = useEndedEvent(onEvent);

  const { startRecording, stopRecording, recordedData } = useRecording({});

  const stopRecordingCallback = useCallback(() => {
    stopRecording();
    beep.playInteractionEndTone();
  }, []);
  const delay = useDelay();
  const beep = useBeep();
  const {
    i18n: { t, TranslationComponent },
  } = useContext(ConfigContext);

  const title = <>{t('backgroundNoiseCheck.title.initial')}</>;

  const successTitle = (
    <span aria-label={t('backgroundNoiseCheck.title.success')}>
      {t('backgroundNoiseCheck.title.success')}
    </span>
  );

  const failMessage = `backgroundNoiseCheck.title.${
    failedTriesRef.current >= 3 ? 'retried' : 'fail'
  }`;

  const failTitle = (
    <span aria-label={t(`${failMessage}.a11y`)}>
      <TranslationComponent i18nKey={`${failMessage}.value`} />
    </span>
  );

  const getText = (status: Status) => {
    switch (status) {
      default:
      case 'INIT': {
        return title;
      }

      case 'WARNING':
      case 'SUCCESS': {
        return successTitle;
      }

      case 'FAIL': {
        return failTitle;
      }
    }
  };

  useEffect(() => delay.set(runCheck, AUTORUN_CHECK_TIME), []);

  useEffect(() => {
    if (status !== 'INIT') {
      return;
    }
    startRecording();
  }, [status]);

  const runCheck = useCallback(async () => {
    delay.clear();
    await beep.playInteractionBeginTone();
    setStatus('INIT');
  }, []);

  const backgroundNoiseContentCssClasses = classNames(
    'cade-background-noise-check cade-background-noise-check__content override title',
    {
      success: status === 'SUCCESS' || status === 'WARNING',
      fail: status === 'FAIL',
    }
  );

  useEffect(() => {
    status === 'INIT' && refresh === 1 && setRefresh(0);

    if (status === 'FAIL') {
      failedTriesRef.current += 1;
    }
  }, [status, refresh]);

  const onRetry = async () => {
    await beep.playInteractionBeginTone();
    setStatus('INIT');
    setRefresh(1);
  };

  const setStatusWrapper = useCallback((value: Status) => {
    setStatus(value);
    if (value === 'SUCCESS') {
      onEvent(successEvent());
    }
  }, []);

  const goNextAfterFailure = useCallback(() => {
    onEvent(successEvent());
    onClickSendEndEvent();
  }, []);

  return (
    <div className="cade-background-noise-check">
      <Space
        margin={{ top: 8, bottom: 8 }}
        className="cade-background-noise-check__hgroup"
      >
        <ChecksHeader
          headerText={t('backgroundNoiseCheck.header.text')}
          title={t('backgroundNoiseCheck.header.title')}
          currentStep={currentCheckingStep}
          totalSteps={totalCheckingSteps}
        />
      </Space>
      <div className="cade-background-noise-check__content-container">
        <ChecksBox>
          <div className={backgroundNoiseContentCssClasses}>
            <Paragraph
              className="cade-background-noise-check__message"
              level="large"
              style={{ color: getColor(status) }}
              aria-live="polite"
              role="status"
            >
              {getText(status)}
            </Paragraph>

            <Space
              className={'cade-background-noise__meter-container'}
              margin={{ top: 6 }}
            >
              {status === 'DISABLED' ? (
                <Meter statusMeter="DISABLED" />
              ) : (
                <MicrophoneMeter
                  key={refresh}
                  onEnd={stopRecordingCallback}
                  status={setStatusWrapper}
                  maxAcceptableDecibels={maxAcceptableDecibels}
                  warningDecibels={warningDecibels}
                />
              )}
            </Space>
            {status !== 'INIT' && (
              <Space
                margin={{ top: 5 }}
                className="cade-background-noise-check__actions"
              >
                {(status === 'SUCCESS' || status === 'WARNING') && (
                  <div className="cade-background-noise-check__actions--success">
                    <NextButton
                      id={CADE_NEXT_BUTTON_ID}
                      onClick={onClickSendEndEvent}
                    />
                  </div>
                )}
                {status === 'DISABLED' && (
                  <Space margin={{ top: 11 }}>
                    <SecondaryButton size="large" onClick={runCheck}>
                      {t('backgroundNoiseCheck.button.start')}
                    </SecondaryButton>
                  </Space>
                )}
                {status === 'FAIL' && (
                  <>
                    {recordedData && <Audio audioSource={recordedData} />}
                    <Space
                      margin={{ top: 8 }}
                      className={
                        'cade-background-noise-check__retry-btn-container'
                      }
                    >
                      <SecondaryButton size="large" onClick={onRetry}>
                        {t('backgroundNoiseCheck.button.retry')}
                      </SecondaryButton>
                      {failedTriesRef.current >= 3 && (
                        <NextButton
                          id={CADE_NEXT_BUTTON_ID}
                          onClick={goNextAfterFailure}
                        />
                      )}
                    </Space>
                  </>
                )}
              </Space>
            )}
          </div>
        </ChecksBox>
      </div>
    </div>
  );
};
