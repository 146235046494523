import { PropsWithChildren } from 'react';
import './styles.scss';
import { Role } from '../../commons';
import { Space } from '@components/Space';
import classNames from 'classnames';

type IBox = PropsWithChildren<{
  role: Role;
  className?: string;
  withoutShadow?: boolean;
  ariaHidden?: boolean;
}>;

export function Box({
  children,
  role,
  withoutShadow = false,
  className,
  ariaHidden,
}: IBox) {
  const cssClasses = classNames(className, 'box', {
    [`box--${role}`]: role,
    'box--without-shadow': withoutShadow,
  });

  return (
    <Space
      data-testid="box"
      padding={6}
      className={cssClasses}
      aria-hidden={ariaHidden}
    >
      {children}
    </Space>
  );
}
