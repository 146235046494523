import { useCallback, useEffect, useRef } from 'react';
import { sendBackgroundAudio } from '../api/api';
import { VfwStorage } from '../utils/Storage';
import { getAudioType } from '@versant-monorepo/cade';
import { Logger, LogLevel } from '../api/Logger';
import { serializeError } from '../api/api.utils';

export const useBackgroundNoiseAudio = (
  testItem: string | null = null,
  chunkDuration = 15
) => {
  const chunksRef = useRef<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const isVideoProctoringEnabled = VfwStorage.getItem('isTestProctored');

  useEffect(() => {
    if (isVideoProctoringEnabled && testItem) {
      setTimeout(() => {
        startRecording(testItem as string);
      }, 100);
    }
  }, [testItem]);

  const startRecording = useCallback(async (testItem: string) => {
    try {
      const constraints: MediaStreamConstraints = {
        audio: VfwStorage.getItem('microphoneId')
          ? {
              deviceId: { exact: VfwStorage.getItem('microphoneId') as string },
            }
          : true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      mediaStreamRef.current = stream;

      const recorder = new MediaRecorder(stream, {
        mimeType: getAudioType().mimeType,
      });
      mediaRecorderRef.current = recorder;

      recorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          chunksRef.current = event.data;
        }
      };

      recorder.onstop = async () => {
        if (chunksRef.current) {
          const blob = new Blob([chunksRef.current], {
            type: getAudioType().fileType,
          });
          chunksRef.current = null;
          sendToServer(blob, testItem);
        }
      };

      recorder.start();

      intervalRef.current = setInterval(() => {
        if (recorder.state !== 'inactive') {
          recorder.stop();
          setTimeout(() => {
            try {
              recorder.start();
            } catch (err) {
              Logger.getInstance().pushEvent({
                level: LogLevel.ERROR,
                message: `MediaRecorder error (BGAudio) on chunk of ${testItem}. ${serializeError(
                  err
                )}`,
                item: 'Test',
              });
            }
          }, 100);
        }
      }, chunkDuration * 1000);
    } catch (err) {
      console.error('Error accessing media devices.', err);
      Logger.getInstance().pushEvent({
        level: LogLevel.ERROR,
        message: `MediaRecorder error (BGAudio) on ${testItem}. ${serializeError(
          err
        )}`,
        item: 'Test',
      });
    }
  }, []);

  const stopRecording = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      mediaRecorderRef.current.stop();
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
  }, []);

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, [stopRecording]);

  const sendToServer = async (audioBlob: Blob, testItem: string) => {
    const file = new File(
      [audioBlob],
      `background-noise.${getAudioType().extension}`,
      {
        type: getAudioType().fileType,
      }
    );
    sendBackgroundAudio(file, testItem);
  };

  return { startRecording, stopRecording, isVideoProctoringEnabled };
};
