import { ApiModel, TextResponse } from '../../api/api.interfaces';
import {
  CADE_QUESTION_COUNTER_ID,
  DictationExercise,
} from '@versant-monorepo/cade';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { useState } from 'react';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { transformJSONToDictation } from '../../api/parsers/Dictation/Dictation';
import { useParser } from '../../hooks/useParser';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useBackgroundNoiseAudio } from '../../hooks/useBackgroundNoiseAudio';

export const DictationWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState(false);
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as string),
    'cade-audio-ended-event': () => setNextEnabled(true),
  });
  const { pushEvent } = useLogger();
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);

  const dictationProps = useParser(transformJSONToDictation, model as ApiModel);
  useSetDocumentTitle(dictationProps?.title);

  const { stopRecording, isVideoProctoringEnabled } = useBackgroundNoiseAudio(
    dictationProps?.responseId
  );

  const onEnded = async (value: string) => {
    if (isVideoProctoringEnabled) {
      stopRecording();
    }
    pushEvent({
      item: 'Dictation Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Dictation Exercise',
    });
    const responseItem = {
      id: dictationProps?.responseId as string,
      response: value || '',
      type: 'TEXT',
    } as TextResponse;

    const response = await getNextStep([responseItem]);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
    setNextEnabled(false);
  };

  return (
    dictationProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'exercise'}
          footer={
            <Footer
              nextButtonEnabled={nextEnabled}
              onNext={() => {
                setForceEnd(true);
              }}
              role={'exercise'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Dictation Exercise',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Dictation Exercise',
                });
                eventHandler(exitEvent());
              }}
              currentStep={dictationProps.currentStep}
              totalSteps={dictationProps.totalSteps}
              title={dictationProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <DictationExercise
              key={networkDependentKey(onlineStatus, dictationProps.responseId)}
              {...dictationProps}
              onEvent={eventHandler}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
