import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import './styles.scss';
import { INPUT_TEXT_STATES, InputTextStates } from './types';
import { Text } from '@src/components';

export type ExtraProps = {
  ariaDescribedby?: string;
};

export interface Props extends React.HTMLProps<HTMLInputElement>, ExtraProps {
  /** The state prop defines how the InputText looks like. */
  state?: InputTextStates;
  /** It works only with the ERROR state and shows the message under the input */
  errorMessage?: string;
  /** The keyValue prop uses a native prop key under the hood and causes refreshing the whole state of the InputText if it gets changed */
  keyValue?: string;
  /** This props is responsible to run input in strict mode. Will be harder for user to paste items or use autocompletion. */
  strictMode?: boolean;
}

export const INPUT_TEXT_TEST_ID = 'cade-input-text';
export const INPUT_TEXT_ERROR_MESSAGE_TEST_ID = 'cade-input-text-error-message';

export const InputText = React.forwardRef(
  (
    {
      state = INPUT_TEXT_STATES.DEFAULT,
      errorMessage,
      ariaDescribedby,
      className,
      keyValue,
      onChange = () => {},
      value,
      strictMode = true,
      ...props
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [internalValue, setInternalValue] = useState(value ?? '');
    const [eventValue, setEventValue] =
      useState<React.ChangeEvent<HTMLInputElement>>();
    const inputCssClasses = classNames(
      'cade-input-text cade-typography-text__normal',
      className,
      {
        'cade-input-text--default': state === INPUT_TEXT_STATES.DEFAULT,
        'cade-input-text--completed': state === INPUT_TEXT_STATES.COMPLETED,
        'cade-input-text--error': state === INPUT_TEXT_STATES.ERROR,
      }
    );

    // This useEffect is needed to avoid React errors (Cannot update a component...)
    useEffect(() => {
      if (eventValue) {
        onChange(eventValue);
      }
    }, [eventValue]);

    const _onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const nativeEvent = event.nativeEvent as Event & {
          inputType: string;
          data: string;
        };

        setInternalValue((previousState) => {
          switch (nativeEvent.inputType) {
            case 'deleteWordBackward':
            case 'deleteContentBackward': {
              setEventValue(event);
              return target.value;
            }
          }

          if (nativeEvent.data && nativeEvent.data.length > 1 && strictMode) {
            const value = previousState
              .toString()
              .replace(nativeEvent.data, '');
            event.target.value = value;
            setEventValue(event);
            return value;
          }
          setEventValue(event);
          return target.value;
        });
      },
      [setEventValue]
    );

    return (
      <div className="cade-input-text__container" key={keyValue}>
        <input
          {...props}
          aria-describedby={ariaDescribedby}
          data-testid={INPUT_TEXT_TEST_ID}
          ref={ref}
          onChange={_onChange}
          className={inputCssClasses}
          data-gramm="false"
          data-enable-grammarly="false"
          data-gramm_editor="false"
          autoComplete={'off'}
          spellCheck="false"
          value={internalValue}
        />
        {state === INPUT_TEXT_STATES.ERROR && (
          <Text
            level="small"
            className="cade-input-text__message--error"
            data-testid={INPUT_TEXT_ERROR_MESSAGE_TEST_ID}
          >
            {errorMessage}
          </Text>
        )}
      </div>
    );
  }
);
