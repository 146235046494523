export function WarningIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5595 1.91845L23.7769 20.3591C24.2534 21.2191 23.9414 22.3019 23.0801 22.7776C22.816 22.9235 22.5191 23 22.2173 23H1.78236C0.797988 23 0 22.2033 0 21.2205C0 20.9192 0.0766396 20.6228 0.222734 20.3591L10.4402 1.91845C10.9167 1.05847 12.0013 0.74697 12.8626 1.22271C13.1558 1.38462 13.3973 1.62575 13.5595 1.91845ZM11.5 9C11.2239 9 11 9.22386 11 9.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V9.5C13 9.22386 12.7761 9 12.5 9H11.5ZM11.5 18C11.2239 18 11 18.2239 11 18.5V19.5C11 19.7761 11.2239 20 11.5 20H12.5C12.7761 20 13 19.7761 13 19.5V18.5C13 18.2239 12.7761 18 12.5 18H11.5Z"
        fill="#CA4644"
      />
    </svg>
  );
}
