import { ApiModel } from '../../api/api.interfaces';
import { useApi } from '../../hooks/useApi';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from '../../hooks/useFocus';
import {
  CADE_CONTENT_TITLE_ID,
  ShortStoryRetellingInstruction,
} from '@versant-monorepo/cade';
import { useState } from 'react';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { useLogger } from '../../hooks/useLogger';
import { useParser } from '../../hooks/useParser';
import { transformJSONToShortStoryRetellingInstruction } from '../../api/parsers/ShortStoryRetelling/ShortStoryRetellingInstruction';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { LogLevel } from '../../api/Logger';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { Wrapper } from '../Wrapper';
import { Template } from '../../components/Template/Template';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';

export const ShortStoryRetellingInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': () => onEnded(),
  });
  useFocus(CADE_CONTENT_TITLE_ID, [model]);
  const [forceEnd, setForceEnd] = useState<boolean>(false);
  const onlineStatus = useNetwork();
  const { pushEvent } = useLogger();

  const oppositesInstructionProps = useParser(
    transformJSONToShortStoryRetellingInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(oppositesInstructionProps?.title);

  const onEnded = async () => {
    pushEvent({
      item: 'ShortStoryRetelling Instruction',
      level: LogLevel.INFO,
      message: 'User clicked next on ShortStoryRetelling Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
  };

  return (
    oppositesInstructionProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'instruction'}
            footer={
              <Footer
                nextButtonVisible={true}
                onNext={() => setForceEnd(true)}
                role={'instruction'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'ShortStoryRetelling Instruction',
                    level: LogLevel.INFO,
                    message:
                      'User clicked exit on ShortStoryRetelling Instruction',
                  });
                  eventHandler(exitEvent());
                }}
                currentStep={oppositesInstructionProps.currentStep}
                totalSteps={oppositesInstructionProps.totalSteps}
                title={oppositesInstructionProps.subTitle}
                role={'instruction'}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <ShortStoryRetellingInstruction
                {...oppositesInstructionProps}
                key={networkDependentKey(onlineStatus)}
                onEvent={eventHandler}
                forceEnd={forceEnd}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
