import {
  Modal,
  ModalProps,
  Paragraph,
  SecondaryButton,
} from '@versant-monorepo/cade';
import React from 'react';
import './AudioHelpModal.scss';
import AudioErrorPopupImg from './AudioErrorPopup 2.svg';
import { useTranslation } from 'react-i18next';

export function AudioHelpModal({ open, onClose }: ModalProps) {
  const Divider = () => <div className="audio-help-modal__divider" />;
  const { t } = useTranslation();

  const footer = (
    <SecondaryButton onClick={onClose}>
      {t('audioHelpModal.ok')}
    </SecondaryButton>
  );

  return (
    <Modal
      title={t('audioHelpModal.title')}
      footer={footer}
      open={open}
      onClose={onClose}
      exitButton={false}
    >
      <div className="audio-help-modal__container">
        <Paragraph level="large" weight="semibold">
          {t('audioHelpModal.p')}
        </Paragraph>
        <ol>
          <li>{t('audioHelpModal.li')}</li>
          <Divider />
          <li>
            {t('audioHelpModal.li2')}
            <div className="audio-help-modal__os-instruction">
              <b>{t('audioHelpModal.li3')}</b>
              <ul className="audio-help-modal__os-instruction-list">
                <li>{t('audioHelpModal.li4')}</li>
                <li>{t('audioHelpModal.li5')}</li>
              </ul>
            </div>
            <div className="audio-help-modal__os-instruction">
              <b>{t('audioHelpModal.li6')}</b>
              <ul className="audio-help-modal__os-instruction-list">
                <li>{t('audioHelpModal.li7')}</li>
              </ul>
            </div>
          </li>
          <Divider />
          <li>{t('audioHelpModal.li8')}</li>
          <Divider />
          <li>
            <div>{t('audioHelpModal.li9')}</div>
            <img
              className="audio-help-modal__img"
              src={AudioErrorPopupImg}
              alt="Audio Error Popup"
            />
            <div>
              <b>{t('audioHelpModal.li10')}</b>
              {t('audioHelpModal.li11')}
            </div>
          </li>
        </ol>
      </div>
    </Modal>
  );
}
