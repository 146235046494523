import React, { useContext, useState } from 'react';
import { Paragraph } from '@components/typography/Paragraph';
import { Title } from '@components/typography/Title';
import './styles.scss';
import {
  MicrophoneCheckComponent,
  MicrophoneCheckState,
} from '../components/MicrophoneCheck';
import { TroubleshotModal } from '../components/MicrophoneCheck/TroubleshotModal';
import {
  CadeEvent,
  continueClickEvent,
  failureEvent,
  troubleshootClickEvent,
} from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { useBeep } from '@utils/useBeep';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type Props = {
  onEvent: (event: CadeEvent) => void;
};

export function WeCantHearYou({ onEvent }: Props) {
  const [state, setState] = useState<MicrophoneCheckState>('READY');
  const [open, setOpen] = useState(false);

  const delay = useDelay();
  const beep = useBeep();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const onTroubleshootClickCallback = () => {
    onEvent(troubleshootClickEvent());
    setOpen(true);
  };

  const topParagraph = (
    <Paragraph level="large">{t('weCantHearYou.top')}</Paragraph>
  );

  const manageState = (state: MicrophoneCheckState) => {
    switch (state) {
      case 'ERROR':
        setState('ERROR_WE_CANT_HEAR_YOU');
        break;
      case 'ERROR_MICROPHONE':
        setState('ERROR_MICROPHONE_WE_CANT_HEAR_YOU');
        break;
      default:
        setState(state);
    }
  };

  return (
    <>
      <div className="we-cant-hear-you__container">
        <Title
          id={CADE_CONTENT_TITLE_ID}
          level="large"
          className={`cade-margin-bottom-4 ${
            (state === 'READY' || state.includes('ERROR')) && 'red-title'
          }`}
        >
          {t('weCantHearYou.title')}
        </Title>
        <Paragraph className="cade-margin-bottom-7">
          {state === 'READY'
            ? t('weCantHearYou.subTitle.ready')
            : t('weCantHearYou.subTitle.error')}
        </Paragraph>
        <MicrophoneCheckComponent
          state={state}
          onSetState={manageState}
          onClickTroubleshooting={onTroubleshootClickCallback}
          onFail={() => onEvent(failureEvent())}
          onFinish={(micId) => {
            delay.clear();
            onEvent(continueClickEvent(micId));
          }}
          successButtonText={t('weCantHearYou.successButton')}
          bottomParagraphText={t('weCantHearYou.bottomParagraph')}
          topParagraph={topParagraph}
          allowMicrophoneOnInit={true}
          onEvent={(event) => {
            if (event.name === 'cade-success-event') {
              delay.set(async () => {
                await beep.playNextTone();
                onEvent(continueClickEvent(event.value));
              });
            }
            onEvent(event);
          }}
        />
      </div>
      <TroubleshotModal
        open={open}
        onClose={() => setOpen(false)}
        state={state}
      />
    </>
  );
}
