import { Box, Paragraph, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import React, { useState } from 'react';
import { CadeEvent } from '@utils/events';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import './styles.scss';
import { Selection } from '@components/Selection';
import classNames from 'classnames';
import { InstructionContainer } from '@src/components/InstructionContainer';

type Props = {
  subTitle: string;
  instruction: string;
  exampleText: string;
  audioSrc: string[];
  listenPartDuration: number;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  leftLabel: string;
  rightLabel: string;
};

export function ResponseSelectionInstruction({
  subTitle,
  instruction,
  exampleText,
  audioSrc,
  listenPartDuration,
  onEvent,
  forceEnd,
  rightLabel,
  leftLabel,
}: Props) {
  const [exampleStatus, setExampleStatus] =
    useState<ListenRecordInstructionStatus>('INIT');
  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  const options = [
    { value: 'a', label: 'A' },
    { value: 'b', label: 'B' },
    { value: 'c', label: 'C', checked: exampleStatus === 'LISTENING' },
  ];

  return (
    <>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID} level="large">
          {subTitle}
        </Title>
        <Instruction>{instruction}</Instruction>
      </InstructionContainer>
      <Box role="instruction" className="cade-margin-bottom-5">
        <ListenRecordInstructionContent
          status={exampleStatus}
          audioSrc={audioSrc}
          listenPartDuration={listenPartDuration}
          onStatusChange={setExampleStatus}
          onEvent={onEvent}
          forceEnd={forceEnd}
        />
      </Box>
      <Box
        className={classNames({
          'response-selection__disabled-example': exampleStatus !== 'LISTENING',
        })}
        role="instruction"
      >
        <DualPaneLayout
          leftPane={
            <>
              <Paragraph
                className={`cade-margin-bottom-2 read-card-side__header`}
                level="large"
                weight={exampleStatus === 'LISTENING' ? 'bold' : 'normal'}
              >
                {leftLabel}
              </Paragraph>
              <Box
                className="response-selection__example-box response-selection__example-box--left"
                withoutShadow={true}
                role="instruction"
              >
                {exampleStatus === 'LISTENING' && (
                  <Paragraph
                    weight="bold"
                    className="response-selection__example-text"
                  >
                    {exampleText}
                  </Paragraph>
                )}
              </Box>
            </>
          }
          rightPane={
            <>
              <Paragraph
                className={`cade-margin-bottom-2 read-card-side__header`}
                level="large"
                weight={'normal'}
              >
                {rightLabel}
              </Paragraph>
              <Box
                className="response-selection__example-box response-selection__example-box--right"
                withoutShadow={true}
                role="instruction"
              >
                {exampleStatus === 'LISTENING' && (
                  <Selection
                    readonly={true}
                    name="responseselection"
                    options={options}
                    state="ACTIVE"
                  />
                )}
              </Box>
            </>
          }
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </>
  );
}
