import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import './styles.scss';
import icon_word_counter from './assets/icon_word_counter.svg';
import icon_word_counter_completed from './assets/icon_word_counter_completed.svg';
import { SimpleSpread } from '../../../utils/types';
import { Text } from '../../typography/Text';
import { Space } from '../../Space';

export const countWords = (value: string | null | undefined) => {
  const REGEX_EXCLUDED_SIGNS = /[\t\n\r$&+,:;=?@#|'<>.^*()%!\-"\\\/]/gm;
  if (!value) {
    return 0;
  }

  return value
    .replace(REGEX_EXCLUDED_SIGNS, ' ')
    .split(' ')
    .filter((str) => str.trim().length > 0).length;
};

export const TEXT_AREA_LABEL_SIZE = {
  TINY: 'TINY',
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
  LARGE: 'LARGE',
} as const;

export type TextAreaLabelSize = keyof typeof TEXT_AREA_LABEL_SIZE;
export type Value = string | number | undefined | null;

export const TEXT_AREA_INPUT_ID = 'cade-text-area-input';
export const TEXT_AREA_FIELD_TEST_ID = 'cade-text-area-field';
export const TEXT_AREA_WORD_COUNTER_ID = 'cade-text-area-word-counter';

type TextAreaWordCounterProps = {
  maxWordsLimit?: number;
  wordsNumber?: number;
};

const TextAreaWordCounter = ({
  maxWordsLimit,
  wordsNumber,
}: TextAreaWordCounterProps) => {
  if (!maxWordsLimit) {
    return null;
  }

  return (
    <Text level="small" weight="bold" className="cade-textarea-word-counter">
      <img
        src={
          wordsNumber === maxWordsLimit
            ? icon_word_counter_completed
            : icon_word_counter
        }
        data-testid={
          wordsNumber === maxWordsLimit
            ? 'completed-word-counter-icon'
            : 'word-counter-icon'
        }
        className="cade-textarea-word-counter__icon"
        alt="Word's counter icon"
      />
      <Text level="small" data-testid={TEXT_AREA_WORD_COUNTER_ID}>
        {wordsNumber}/{maxWordsLimit} words
      </Text>
    </Text>
  );
};

type ExtraBaseTextAreaProps = {
  disabled?: boolean;
  value: Value;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  ariaLabel?: string;
};

interface BaseTextAreaProps
  extends SimpleSpread<
    React.HTMLProps<HTMLTextAreaElement>,
    ExtraBaseTextAreaProps
  > {}

const BaseTextArea = forwardRef<HTMLTextAreaElement, BaseTextAreaProps>(
  function BaseTexArea({ disabled, value, id, ...props }, ref) {
    return (
      <textarea
        {...props}
        data-testid={TEXT_AREA_FIELD_TEST_ID}
        onCopy={disabled ? (event) => event.preventDefault() : () => {}}
        tabIndex={0}
        id={id ?? TEXT_AREA_INPUT_ID}
        spellCheck={false}
        readOnly={disabled}
        value={value === null ? '' : value}
        ref={ref}
      />
    );
  }
);

type ExtraTextAreaLabelProps = PropsWithChildren<{
  label?: string;
  labelSize?: TextAreaLabelSize;
  children: ReactNode | ReactNode[];
  labelSlot?: ReactNode | ReactNode[];
}>;

export interface TextAreaLabelProps
  extends SimpleSpread<HTMLFormElement, ExtraTextAreaLabelProps> {}

const TextAreaLabel = ({
  children,
  label,
  labelSlot,
  labelSize,
  htmlFor,
  className,
  ...props
}: TextAreaLabelProps) => {
  const cssClassesForLabel = classNames(className, 'cade-textarea__title', {
    'cade-typography-text__tiny': labelSize === TEXT_AREA_LABEL_SIZE.TINY,
    'cade-typography-text__small': labelSize === TEXT_AREA_LABEL_SIZE.SMALL,
    'cade-typography-text__normal': labelSize === TEXT_AREA_LABEL_SIZE.NORMAL,
    'cade-typography-text__large': labelSize === TEXT_AREA_LABEL_SIZE.LARGE,
  });

  return (
    <>
      <Space margin={{ bottom: 3 }} className="cade-textarea__label">
        <label
          {...props}
          htmlFor={htmlFor ?? TEXT_AREA_INPUT_ID}
          className={cssClassesForLabel}
        >
          {label ?? ''}
        </label>
        {labelSlot}
      </Space>
      <div className="cade-textarea__container">{children}</div>
    </>
  );
};

export type ExtraTextAreaFieldProps = {
  value?: string;
  onChange(value: string | undefined): Value;
  maxWordsLimit?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  blockInput?: boolean;
  className?: string;
  ariaLabel?: string;
  label?: string;
  message?: ReactNode | ReactNode[];
};

export interface TextAreaFieldProps
  extends SimpleSpread<
    React.HTMLProps<HTMLTextAreaElement>,
    ExtraTextAreaFieldProps
  > {}

function TextAreaField({
  value,
  onChange,
  label,
  maxWordsLimit,
  autoFocus,
  blockInput,
  className,
  ariaLabel,
  message,
  ...props
}: TextAreaFieldProps) {
  // hacky solution
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
  useEffect(() => forceUpdate(), []);
  const [inputValue, setValue] = useState<string>(value || '');
  const [wordsNumber, setWordsNumber] = useState(0);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoFocus) {
      textAreaRef.current?.focus();
    }
  }, [autoFocus, textAreaRef]);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.target?.value;
    const words = maxWordsLimit ? countWords(value) : 0;
    const nativeEvent = event.nativeEvent as Event & {
      inputType: string;
      data: string;
    };

    const isValueInvalid = nativeEvent.data && nativeEvent.data.length > 1;

    if (maxWordsLimit && words > maxWordsLimit) {
      return;
    }

    !isValueInvalid && maxWordsLimit && setWordsNumber(words);

    setValue((previousValue) => {
      if (isValueInvalid) {
        value = (previousValue || value)
          .toString()
          .replace(nativeEvent.data, '');
      }

      if (maxWordsLimit === words) {
        const ALLOWED_SIGNS = /^[a-zA-Z0-9.]+$/;
        const stringLength = value?.length;
        const finalValue = ALLOWED_SIGNS.test(value?.charAt(stringLength - 1))
          ? value
          : value?.slice(0, -1);

        return finalValue;
      }

      return value;
    });
  };

  const textAreaClasses = classNames(
    className,
    ['cade-textarea', 'cade-typography-text__normal'],
    {
      'cade-textarea--block': blockInput,
    }
  );

  return (
    <div className="cade-textarea__wrapper">
      <BaseTextArea
        {...props}
        value={inputValue}
        className={textAreaClasses}
        ref={textAreaRef}
        onChange={handleChange}
        aria-label={ariaLabel || label}
      />
      {maxWordsLimit && (
        <TextAreaWordCounter
          maxWordsLimit={maxWordsLimit}
          wordsNumber={wordsNumber}
        />
      )}
      {message}
    </div>
  );
}

const TextAreaReadBox = ({
  children,
  state = 'ACTIVE',
  ...props
}: React.HTMLProps<HTMLDivElement> & {
  state?: 'DISABLED' | 'ACTIVE' | 'WAITING' | 'COMPLETED';
}) => {
  return (
    <div
      className={classNames('cade-textarea-read-box', {
        'cade-textarea-read-box--disabled': state === 'DISABLED',
        'cade-textarea-read-box--active': state === 'ACTIVE',
        'cade-textarea-read-box--focus':
          state === 'ACTIVE' || state === 'WAITING',
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export const TextArea = {
  Field: TextAreaField,
  Label: TextAreaLabel,
  ReadBox: TextAreaReadBox,
};
