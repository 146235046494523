export * from "./utils";

import english from "./english.json";
import german from "./german.json";
import french from "./french.json";
import portuguese from "./portuguese.json";
import spanish from "./spanish.json";

export const translationEnglish = english;
export const translationGerman = german;
export const translationFrench = french;
export const translationPortuguese = portuguese;
export const translationSpanish = spanish;
