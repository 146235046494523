import { RecordingState } from '@components/Recording/SpeakingMeter';
import { Listening } from '@components/Listening';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './styles.scss';
import { Instruction } from '@components/Instruction';
import { Box, Paragraph, Space, Title } from '@src/components';
import { DualPaneLayout } from '@components/base/DualPaneLayout';

import { QuestionCounter } from '@components/QuestionCounter';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import { ListeningStatus } from '@components/Listening/commons';
import { audioEndedEvent, CadeEvent, useEndedEvent } from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useBeep } from '@utils/useBeep';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { Recording } from '@components/Recording/Recording';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface Props {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  rightLabel: string;
  forceEnd?: boolean;
  instruction: string;
}

// TODO PB All magic strings like "ACTIVE", "STANDBY" should be moved into an enum or something similar.

export function OpenQuestionsExercise({
  count,
  total,
  subTitle,
  audioSrc,
  timeouts,
  onEvent = () => {},
  leftLabel,
  rightLabel,
  forceEnd = false,
  instruction,
}: Props) {
  const [listeningState, setListeningState] =
    useState<ListeningStatus>('INACTIVE');
  const [recordingState, setRecordingState] = useState<RecordingState>('EMPTY');
  const [nextEnabled, setNextEnabled] = useState(false);
  const [listenMode, setListenMode] = useState(true);
  const [warningVisible, setWarningVisible] = useState(false);
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );
  const [leftHeaderHeight, setLeftHeaderHeight] = useState<number>(0);
  const { sendEndEvent } = useEndedEvent<Blob>(onEvent);

  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;

  const delay = useDelay();
  const beep = useBeep();

  useEffect(() => delay.set(playAudio, EXERCISE_AUTO_PLAY_TIMEOUT), []);

  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (forceEnd) {
      setRecordingState('SUCCESS');
    }
  }, [forceEnd]);

  const handleFinishRecording = (recordedData: Blob) => {
    if (forceEnd) {
      sendEndEvent(recordedData);
      delay.clear();
    } else {
      delay.set(() => {
        sendEndEvent(recordedData);
      });
    }
  };

  useEffect(() => {
    if (!listenMode) {
      if (isTimerFinished(timerStatus) || forceEnd) {
        setRecordingState('INACTIVE');
        return;
      }
    }
  }, [timerStatus, forceEnd]);

  async function onAudioEnded() {
    setListeningState('COMPLETED');
    await beep.playInteractionBeginTone();

    setRecordingState('RECORDING');
    setNextEnabled(true);
    setListenMode(false);
    setTimerStatus(TIMER_STATUS.IN_PROGRESS);
    onEvent(audioEndedEvent());
  }

  const onChangeTimerStatus = useCallback(setTimerStatus, [setTimerStatus]);

  function playAudio() {
    delay.clear();
    setListeningState('PLAYING');
  }

  const rightHeaderDivRef = useCallback(
    (node: HTMLDivElement | null) => {
      setLeftHeaderHeight(node?.clientHeight ?? 0);
    },
    [recordingState]
  );

  const leftParagraphBolded = useMemo(() => {
    return recordingState !== 'RECORDING' && listeningState !== 'COMPLETED';
  }, [recordingState, listeningState]);

  return (
    <>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level="small">{instruction}</Instruction>
      </InstructionContainer>
      <div className="cade-open-questions-exercise__upper-section">
        <QuestionCounter
          count={count}
          total={total}
          title={t('openQuestions.exercise.questionCountLabel')}
        />
        <div className="cade-open-questions-exercise__timer">
          <TimerMessage
            show={isTimerFinished(timerStatus)}
            insideClassName="cade-margin-right-3"
          />
          <Timer
            time={timeouts.speaking}
            onChangeStatus={onChangeTimerStatus}
            timerStatus={timerStatus}
            variant={TIMER_VARIANT.SECONDARY}
          />
        </div>
      </div>
      <Box role="exercise">
        <DualPaneLayout
          leftPane={
            <div>
              <Paragraph
                style={{ minHeight: leftHeaderHeight }}
                level={'large'}
                weight={leftParagraphBolded ? 'bold' : 'normal'}
              >
                {leftLabel}
              </Paragraph>
              <Listening
                audioSrc={audioSrc}
                role="exercise"
                onClickPlay={playAudio}
                status={listeningState}
                onAudioEnded={onAudioEnded}
              />
            </div>
          }
          rightPane={
            <div>
              <Space
                padding={{ left: 3, right: 5 }}
                className="cade-open-questions-exercise__right-pane-title"
              >
                <div
                  ref={rightHeaderDivRef}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: leftHeaderHeight > 30 ? 'flex-start' : 'center',
                  }}
                >
                  <Paragraph
                    level={'large'}
                    {...(nextEnabled && { weight: 'bold' })}
                  >
                    {rightLabel}
                  </Paragraph>
                </div>
              </Space>
              <Recording
                state={recordingState}
                width={431}
                timeouts={timeouts}
                handleFinishRecording={handleFinishRecording}
                setWarningVisible={setWarningVisible}
                onEvent={onEvent}
              />
            </div>
          }
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          weCantHearYou={warningVisible}
          numOfCircles={6}
          listenMode={listenMode}
          currentValue={voiceLevel}
        />
      </div>
    </>
  );
}
