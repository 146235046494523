import { Paragraph } from '@src/components';
import {
  RecordingState,
  SpeakingMeter,
} from '@components/Recording/SpeakingMeter';
import {
  MicrophoneOptions,
  microphoneOptionsList,
  MIN_BYTE_VOLUME,
  MIN_NUMBER_OF_SAMPLES,
  MIN_PERCENT_OF_VOICE_SAMPLES,
  SOUND_ANALYSIS_FREQUENCY_MS,
  soundWave,
  SUCCESS_TIMEOUT,
} from '@utils/microphone';
import { MicrophoneSelector } from './MicrophoneSelector';
import React, {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSoundFrequency } from '@utils/useSoundFrequency';
import './styles.scss';
import { ErrorModal, ErrorModalProps } from '@components/Modal';
import { ChecksBox } from '../ChecksBox';
import {
  CADE_NEXT_BUTTON_ID,
  NextButton,
  PrimaryButton,
  SecondaryButton,
} from '@components/base/Button';
import { useBeep } from '@utils/useBeep';
import { CadeEvent, failureEvent, successEvent } from '@utils/events';
import { MicrophoneContext } from '@context/Microphone.context';
import { Link } from '@components/base/Link';
import { ConfigContext } from '@src/context/CadeConfigProvider';

function ModalWrapper({
  onClose,
  ...props
}: Pick<ErrorModalProps, 'open' | 'onClose'>) {
  const {
    i18n: { t, TranslationComponent },
  } = useContext(ConfigContext);
  return (
    <ErrorModal
      {...props}
      onClose={onClose}
      exitButton={false}
      title={t('microphoneCheck.modal.title')}
      footer={
        <PrimaryButton onClick={onClose}>
          {t('modal.confirmationButton')}
        </PrimaryButton>
      }
    >
      <TranslationComponent
        i18nKey={'microphoneCheck.modal.content'}
        components={{ b: <br /> }}
      />
    </ErrorModal>
  );
}

export const microphoneCheckStates = [
  'ALLOW',
  'READY',
  'RECORDING',
  'ERROR',
  'ERROR_WE_CANT_HEAR_YOU',
  'ERROR_MICROPHONE',
  'ERROR_MICROPHONE_WE_CANT_HEAR_YOU',
  'SUCCESS',
] as const;

const OPEN_ERROR_MODAL_TIMEOUT = 1_000;

export type MicrophoneCheckState = (typeof microphoneCheckStates)[number];

type Props = {
  onFinish: (micId: string) => void;
  onSetState?: (state: MicrophoneCheckState) => void;
  onExit?: () => void;
  onClickTroubleshooting: () => void;
  onFail?: () => void;
  bottomParagraphText?: string;
  successButtonText: string;
  topParagraph?: ReactNode;
  allowMicrophoneOnInit?: boolean;
  state: MicrophoneCheckState;
  focusMicrophoneSelector?: boolean;
  onEvent?: (event: CadeEvent) => void;
};

export const CADE_MICROPHONE_CHECK_TROUBLESHOOTING_LINK_ID =
  'cade-microphone-check-troubleshooting-link';

export function MicrophoneCheckComponent({
  onFinish,
  onSetState = () => {},
  onClickTroubleshooting,
  onExit,
  onFail = () => {},
  bottomParagraphText = '',
  successButtonText,
  topParagraph,
  allowMicrophoneOnInit = false,
  state,
  focusMicrophoneSelector,
  onEvent,
}: Props) {
  const [microphoneId, setMicrophoneId] = useState<string>('');
  const [recordingState, setRecordingState] =
    useState<RecordingState>('INACTIVE');
  const { stream, setStream } = useContext(MicrophoneContext);
  const [freqData, setFreqData] = useState<number[]>([]);
  const volumeData = useRef<number[]>([]);
  const stopHandling = useRef<boolean>(false);
  const [microphoneOptions, setMicrophoneOptions] = useState<MicrophoneOptions>(
    {
      microphones: [],
    }
  );
  const [disallowMicrophone, setDisallowMicrophone] = useState<boolean>(false);
  const attemptNumber = useRef<number>(0);
  const NUMBER_OF_ALLOWED_ATTEMPTS = 2;
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const timeoutSuccessRef = useRef<number | null>(null);
  const beep = useBeep();
  const microphoneSelectorRef = useRef<HTMLSelectElement>(null);
  const {
    i18n: { t, TranslationComponent },
  } = useContext(ConfigContext);

  useEffect(() => {
    switch (state) {
      case 'ALLOW':
        setRecordingState('INACTIVE');
        break;
      case 'READY':
        setRecordingState('INACTIVE_BUTTON');
        break;
      case 'RECORDING':
        setRecordingState('RECORDING');
        beep.playInteractionBeginTone();
        break;
      case 'ERROR':
        stopHandling.current = false;
        volumeData.current = [];
        setRecordingState('ERROR');
        timeoutSuccessRef.current && clearTimeout(timeoutSuccessRef.current);
        onEvent?.(failureEvent());
        break;
      case 'ERROR_WE_CANT_HEAR_YOU':
        stopAnalyzing();
        stopHandling.current = false;
        volumeData.current = [];
        if (attemptNumber.current >= NUMBER_OF_ALLOWED_ATTEMPTS) {
          onFail();
          break;
        }
        setRecordingState('INACTIVE_BUTTON');
        break;
      case 'ERROR_MICROPHONE':
        stopHandling.current = false;
        volumeData.current = [];
        setRecordingState('INACTIVE');
        timeoutRef.current = window.setTimeout(
          () => setOpenErrorModal(true),
          OPEN_ERROR_MODAL_TIMEOUT
        );
        break;
      case 'ERROR_MICROPHONE_WE_CANT_HEAR_YOU':
        stopAnalyzing();
        stopHandling.current = false;
        volumeData.current = [];
        if (attemptNumber.current >= NUMBER_OF_ALLOWED_ATTEMPTS) {
          onFail();
          break;
        }
        setRecordingState('INACTIVE_BUTTON');
        break;
      case 'SUCCESS':
        setRecordingState('SUCCESS');
        onEvent?.(successEvent(microphoneId));
        break;
    }
  }, [state]);

  useEffect(() => {
    loadMicrophone();
    if (allowMicrophoneOnInit) {
      allowMicrophone();
    }

    return () => {
      onEnd();
    };
  }, []);

  useEffect(() => {
    if (microphoneId != null && state !== 'ALLOW') {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: microphoneId } })
        .then((m) => {
          setStream(m);
          loadMicrophone();
        })
        .catch(() => {
          onSetState('ERROR_MICROPHONE');
        });
    }
  }, [microphoneId]);

  const handleVolume = useCallback(
    (n: number) => {
      volumeData.current.push(n);
      if (
        !stopHandling.current &&
        volumeData.current.length >= MIN_NUMBER_OF_SAMPLES &&
        volumeData.current
          .slice(-MIN_NUMBER_OF_SAMPLES)
          .filter((v) => v > MIN_BYTE_VOLUME).length >=
          MIN_PERCENT_OF_VOICE_SAMPLES * MIN_NUMBER_OF_SAMPLES
        // We take some number of last voice samples,
        // filter out only those which are higher than threshold,
        // and check if enough percent of them meets our criteria
      ) {
        stopHandling.current = true;
        timeoutSuccessRef.current = window.setTimeout(() => {
          beep.playInteractionEndTone();
          onSetState('SUCCESS');
          stopAnalyzing();
        }, SUCCESS_TIMEOUT); // We do timeout, to make transition to success more smooth
      } else if (volumeData.current.length > 200) {
        onSetState('ERROR');
        stream?.getTracks().forEach((track) => track.stop());
        stopAnalyzing();
        beep.playInteractionEndTone();
      }
    },
    [volumeData]
  );

  const onClickLink = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClickTroubleshooting();
  }, []);

  const errorParagraph = (
    <Paragraph level="large" className="red-paragraph">
      {<TranslationComponent i18nKey="microphoneCheck.content.error.text" />}
      <Link
        id={CADE_MICROPHONE_CHECK_TROUBLESHOOTING_LINK_ID}
        onClick={onClickLink}
      >
        {t('microphoneCheck.content.error.link')}
      </Link>
    </Paragraph>
  );

  const [startAnalyzing, stopAnalyzing] = useSoundFrequency(
    15,
    setFreqData,
    handleVolume,
    stream,
    SOUND_ANALYSIS_FREQUENCY_MS
  );

  function onBeginCheck() {
    startAnalyzing();
    attemptNumber.current += 1;
    onSetState('RECORDING');
  }

  function onMicrophoneChange(e: ChangeEvent<HTMLSelectElement>) {
    stopAnalyzing();
    setMicrophoneId(e.target.value as string);
    onSetState('READY');
  }

  function onEnd() {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    stopAnalyzing();
  }

  function onRetry() {
    allowMicrophone().then(() => {
      stopAnalyzing();
      volumeData.current = [];
      onSetState('READY');
    });
  }

  const getTopParagraph = useCallback(() => {
    if (topParagraph) {
      return topParagraph;
    }

    switch (state) {
      case 'ALLOW':
        return (
          <Paragraph level="large">
            {t('microphoneCheck.content.allow')}
          </Paragraph>
        );
      case 'SUCCESS':
        return (
          <Paragraph level="large" className="green-paragraph">
            {t('microphoneCheck.content.success')}
          </Paragraph>
        );
      case 'ERROR':
        return errorParagraph;
      case 'ERROR_MICROPHONE':
        return (
          <Paragraph level="large" className="red-paragraph">
            {t('microphoneCheck.content.errorMicrophone')}
          </Paragraph>
        );
      default:
        return (
          <Paragraph level="large">
            {t('microphoneCheck.content.initial')}
          </Paragraph>
        );
    }
  }, [topParagraph, state]);

  const loadMicrophone = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const options = microphoneOptionsList(devices);
      setMicrophoneOptions(options);
      if (!options.microphones.length) {
        if (state !== 'ALLOW') {
          onSetState('ERROR_MICROPHONE');
        }
        return;
      }
      if (!options.microphones[0].deviceId) {
        if (state !== 'ALLOW') {
          onSetState('ERROR_MICROPHONE');
        }
        setDisallowMicrophone(true);
        return;
      }
      if (!microphoneId && options.defaultId) {
        setMicrophoneId(options.defaultId);
        setDisallowMicrophone(false);
      }
    });
  };

  const allowMicrophone = useCallback(
    () =>
      new Promise(async (resolve, reject) => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          setStream(stream);
          onSetState('READY');
          loadMicrophone();
          resolve(true);
        } catch (error) {
          onSetState('ERROR_MICROPHONE');
          reject(false);
        }
      }),

    []
  );

  const onErrorModalClose = useCallback(() => setOpenErrorModal(false), []);

  function getBottomSection() {
    switch (state) {
      case 'ALLOW':
        return (
          <SecondaryButton onClick={allowMicrophone} size="large">
            {t('microphoneCheck.bottomSection.allow')}
          </SecondaryButton>
        );
      case 'READY':
      case 'RECORDING':
        return <Paragraph level={'large'}>{bottomParagraphText}</Paragraph>;
      case 'ERROR':
        return (
          <SecondaryButton onClick={allowMicrophone} size="large">
            {t('microphoneCheck.bottomSection.error')}
          </SecondaryButton>
        );
      case 'ERROR_WE_CANT_HEAR_YOU':
        return errorParagraph;
      case 'ERROR_MICROPHONE':
        return (
          <>
            <SecondaryButton onClick={onRetry} size="large">
              {t('microphoneCheck.bottomSection.errorMicrophoneButtons.retry')}
            </SecondaryButton>
            <SecondaryButton error={true} onClick={onExit} size="large">
              {t('microphoneCheck.bottomSection.errorMicrophoneButtons.exit')}
            </SecondaryButton>
          </>
        );
      case 'ERROR_MICROPHONE_WE_CANT_HEAR_YOU':
        return (
          <Paragraph level="large" className="red-paragraph">
            {
              <TranslationComponent i18nKey="microphoneCheck.bottomSection.errorMicrophoneWeCantHearYou.text" />
            }
            <a
              onClick={onClickTroubleshooting}
              className="microphone-check__link"
            >
              {t(
                'microphoneCheck.bottomSection.errorMicrophoneWeCantHearYou.link'
              )}
            </a>
          </Paragraph>
        );
      case 'SUCCESS':
        return (
          <NextButton
            id={CADE_NEXT_BUTTON_ID}
            onClick={() => onFinish(microphoneId)}
          >
            {successButtonText}
          </NextButton>
        );
    }
  }

  useEffect(() => {
    if (focusMicrophoneSelector && state === 'READY') {
      microphoneSelectorRef.current?.focus();
    }
  }, [focusMicrophoneSelector, state]);

  const selectedMicrophoneName = useMemo(
    () =>
      microphoneOptions.microphones.find(
        (microphone) => microphone.deviceId === microphoneId
      ),
    [microphoneId, microphoneOptions]
  );

  return (
    <div className="microphone-check__container">
      <ChecksBox>
        <div className="microphone-check__box-container">
          <div className="microphone-check__top-section">
            <div
              className="microphone-check__top-paragraph"
              role="status"
              aria-atomic={true}
              aria-live="polite"
            >
              {getTopParagraph()}
            </div>
            <div className="microphone-check__content">
              {recordingState !== 'SUCCESS' && state !== 'ERROR_MICROPHONE' && (
                <div className="microphone-check__microphone-selector">
                  <MicrophoneSelector
                    disabled={state === 'ALLOW' || state === 'RECORDING'}
                    onChange={onMicrophoneChange}
                    microphoneOptions={microphoneOptions}
                    microphoneId={microphoneId}
                    disallowMicrophone={disallowMicrophone}
                    ref={microphoneSelectorRef}
                  />
                  <span className="sr-only" aria-live="polite">
                    {selectedMicrophoneName?.label
                      ? `Selected microphone: ${selectedMicrophoneName.label}`
                      : ''}
                  </span>
                </div>
              )}
              <div className="microphone-check__speaking-meter">
                <SpeakingMeter
                  state={recordingState}
                  buttonText={t('microphoneCheck.bottomSection.buttonText')}
                  onBeginCheck={onBeginCheck}
                  width={415}
                  soundWaveData={freqData}
                  defaultSoundWaveData={soundWave}
                />
              </div>
            </div>
          </div>
          <div className="microphone-check__buttons">{getBottomSection()}</div>
        </div>
      </ChecksBox>
      <ModalWrapper open={openErrorModal} onClose={onErrorModalClose} />
    </div>
  );
}
