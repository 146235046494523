import { ApiModel, AudioResponse } from '../../api/api.interfaces';
import { useState } from 'react';
import {
  CADE_QUESTION_COUNTER_ID,
  getAudioType,
  SentenceBuildsExercise,
} from '@versant-monorepo/cade';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToSentenceBuild } from '../../api/parsers/SentenceBuild/SentenceBuild';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const SentenceBuildWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState<boolean>(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as Blob),
    'cade-audio-ended-event': () => setNextEnabled(true),
  });
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);
  const { pushEvent } = useLogger();

  const sentenceBuildsProps = useParser(
    transformJSONToSentenceBuild,
    model as ApiModel
  );

  useSetDocumentTitle(sentenceBuildsProps?.title);

  const onNext = async (responseItem: AudioResponse) => {
    pushEvent({
      item: 'Sentence Build Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Sentence Build Exercise',
    });

    const response = await getNextStep(responseItem);
    switchView(CURRENT_ITEM_TYPE, response);
    setNextEnabled(false);
    setForceEnd(false);
  };

  const onEnded = async (audio: Blob) => {
    if (!audio.size) {
      pushEvent({
        item: 'Sentence Build Exercise',
        level: LogLevel.WARN,
        message: `No audio size for Sentence Build Exercise, question ${sentenceBuildsProps?.count} of ${sentenceBuildsProps?.total}`,
      });
    }
    const file = new File([audio], sentenceBuildsProps?.responseId as string, {
      type: getAudioType().fileType,
    });

    const responseItem = {
      id: sentenceBuildsProps?.responseId as string,
      response: file,
      type: 'AUDIO',
    } as AudioResponse;

    await onNext(responseItem);
  };

  return (
    sentenceBuildsProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'exercise'}
          footer={
            <Footer
              nextButtonEnabled={nextEnabled}
              onNext={() => {
                setForceEnd(true);
              }}
              role={'exercise'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Sentence Build Exercise',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Sentence Build Exercise',
                });
                eventHandler(exitEvent());
              }}
              currentStep={sentenceBuildsProps.currentStep}
              totalSteps={sentenceBuildsProps.totalSteps}
              title={sentenceBuildsProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <SentenceBuildsExercise
              key={networkDependentKey(
                onlineStatus,
                sentenceBuildsProps.responseId
              )}
              {...sentenceBuildsProps}
              onEvent={eventHandler}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
