import React, { forwardRef, PropsWithChildren } from 'react';
import './Button.scss';
import classNames from 'classnames';
import { Text } from '@components/typography/Text';

export type ButtonSize = 'normal' | 'large' | 'extra-large';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    /** Hiding a border around the button */
    hideBorder?: boolean;
    icon?: string;
    size?: ButtonSize;
  }>;

export const BUTTON_TEST_ID = 'cade-base-button';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    { children, size = 'normal', className, icon, hideBorder, ...props },
    ref
  ) {
    const buttonCssClasses = classNames(
      className,
      `cade-button--${size}`,
      'cade-button',
      {
        'cade-button--no-border': hideBorder,
      }
    );

    return (
      <div className="cade-base-button-wrapper">
        <button
          data-testid={BUTTON_TEST_ID}
          {...props}
          className={buttonCssClasses}
          tabIndex={0}
          ref={ref}
        >
          {children && (
            <Text level="small" weight="bold" data-testid="cade-button-text">
              {children}
            </Text>
          )}
          {icon && (
            <img
              className="cade-button__icon"
              data-testid="cade-default-button-icon"
              src={icon}
              aria-hidden={true}
            />
          )}
        </button>
      </div>
    );
  }
);
