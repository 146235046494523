import { PropsWithChildren } from 'react';
import './styles.scss';
import classNames from 'classnames';

export type Props = PropsWithChildren<{
  level?: 'normal' | 'small' | 'smaller' | 'tiny' | 'large'; // needed to use "level" as "size" is exitng <p> prop
  weight?: 'normal' | 'bold' | 'semibold';
}> &
  React.HTMLProps<HTMLSpanElement>;

export function Text({
  children,
  level = 'normal',
  weight = 'normal',
  className,
  ...props
}: Props) {
  return (
    <span
      {...props}
      className={classNames(
        className,
        `cade-typography-text__${level} cade-typography--${weight}`
      )}
    >
      {children}
    </span>
  );
}
