import { CadeEvent } from '@versant-monorepo/cade';
import { useExitModal } from './useModal';
import { useWeCantHearYou } from './useWeCantHearYou';
import { useState } from 'react';

type NavigateWeCantHearYouEvent = {
  name: 'navigate-we-cant-hear-you-event';
};
type NavigateProblemAudioEvent = {
  name: 'navigate-problem-audio-event';
};
type ExitEvent = {
  name: 'exit-event';
};
type AppEvent =
  | CadeEvent
  | ExitEvent
  | NavigateWeCantHearYouEvent
  | NavigateProblemAudioEvent;
export function navigateWeCantHearYouEvent(): NavigateWeCantHearYouEvent {
  return {
    name: 'navigate-we-cant-hear-you-event',
  };
}
export function navigateProblemAudioEvent(): NavigateProblemAudioEvent {
  return {
    name: 'navigate-problem-audio-event',
  };
}
export function exitEvent(): ExitEvent {
  return {
    name: 'exit-event',
  };
}
type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<
  K,
  V
>
  ? (a: T) => void
  : never;

type MapDiscriminatedUnion<T extends Record<K, string>, K extends keyof T> = {
  [V in T[K]]: DiscriminateUnion<T, K, V>;
};

type EventMap = Partial<MapDiscriminatedUnion<AppEvent, 'name'>>;

const eventLogger = (event: AppEvent) =>
  process.env.REACT_APP_DEV_QUIRKS === 'true' &&
  console.log('%cEvent:', 'color: green; background: yellow', event);

export const useHandleAppEvent = (customHandlerMap: EventMap = {}) => {
  const eventHandler = (event: AppEvent) => {
    eventLogger(event);
    setLastEvent(event);
    const handler = handlerMap[event['name']];
    if (handler) {
      // TS cannot allow on this
      handler(event as any);
    }
  };

  const exitModal = useExitModal();
  const { cannotHearYou, canHearYou } = useWeCantHearYou(eventHandler);
  const [lastEvent, setLastEvent] = useState<AppEvent | null>(null);

  const handlerMap: EventMap = {
    'exit-event': () => exitModal.show(),
    'cade-exit-event': () => exitModal.show(),
    'cade-we-cant-hear-you-event': () => cannotHearYou(),
    'cade-user-resume-speaking': () => canHearYou(),
    ...customHandlerMap,
  };
  return {
    lastEvent,
    eventHandler,
  };
};
