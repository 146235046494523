import {
  Checkbox,
  CheckboxLabelProps,
  CheckboxProps,
  CheckboxRef,
} from './Checkbox';
import { InputText } from './Text';

export * from './Text/types';

export { CheckboxProps, CheckboxLabelProps, Checkbox, CheckboxRef };

export const Input = {
  Checkbox,
  Text: InputText,
};
