import {
  Context,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SoundWave } from './SoundWave';
import './styles.scss';
import { Role } from '../commons';
import { ListeningStatus } from './commons';
import { SecondaryButton } from '@components/base/Button';
import { OnPlayCallback, PlayAudio } from '@components/PlayAudio';
import { useAudioDuration } from '@utils/useAudioDuration';
import { ButtonSize } from '@components/base/Button/Button';
import { ListeningLoader } from '@components/Listening/ListeningLoader';
import {
  CadeConfigProviderProps,
  ConfigContext,
} from '@src/context/CadeConfigProvider';

type Props = {
  audioSrc: string[];
  /** The role is related to the status props, and it impacts the look of the component depending on the chosen status   */
  role: Role;
  status?: ListeningStatus;
  onClickPlay?: () => void;
  onAudioEnded?: () => void;
  onSingleAudioEnded?: (index: number) => void;
  playButtonText?: string;
  playButtonSize?: ButtonSize;
  border?: string;
  stop?: boolean;
  onPlay?: OnPlayCallback;
};

export const CADE_LISTENING_BUTTON_ID = 'listening-button';

export const Listening: FC<Props> = ({
  audioSrc,
  status = 'INACTIVE',
  onClickPlay = () => {},
  playButtonText,
  playButtonSize,
  border,
  stop,
  role,
  onAudioEnded = () => {},
  onSingleAudioEnded,
  onPlay = () => {},
}) => {
  const duration = useAudioDuration(audioSrc);
  const [currentStatus, setListeningStatus] = useState(status);
  const [isAbleToPlay, setIsAbleToPlay] = useState(false);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const _onClickPlay = useCallback(() => {
    if (isAbleToPlay) {
      setListeningStatus('PLAYING');
      onClickPlay();
    }
  }, [isAbleToPlay]);

  function _onAudioEnded() {
    setListeningStatus('COMPLETED');
    onAudioEnded();
  }

  const onCanPlayThrough = useCallback(
    (_: Event, index: number) => {
      if (index === audioSrc.length - 1) {
        setIsAbleToPlay(true);
        return;
      }
    },
    [audioSrc]
  );

  useEffect(() => {
    if (status === 'PLAYING' && isAbleToPlay) {
      setListeningStatus('PLAYING');
      return;
    }

    setListeningStatus(status);
  }, [status, isAbleToPlay]);

  return (
    <div className="listening__container">
      <SoundWave
        duration={duration}
        status={isAbleToPlay ? currentStatus : 'INACTIVE'}
        border={border}
        stop={stop}
        role={role}
        isLoading={!isAbleToPlay}
      />
      {isAbleToPlay && currentStatus === 'INACTIVE' && (
        <div className="listening__button-wrapper">
          <SecondaryButton
            onClick={_onClickPlay}
            size={playButtonSize}
            id={CADE_LISTENING_BUTTON_ID}
            data-testid="listening-button"
          >
            {playButtonText ?? t('buttons.play')}
          </SecondaryButton>
        </div>
      )}
      {!isAbleToPlay && (
        <div className={'listening__button-wrapper'}>
          <ListeningLoader />
        </div>
      )}
      {audioSrc && (
        <PlayAudio
          onCanPlayThrough={onCanPlayThrough}
          audioSrc={audioSrc}
          onPlay={onPlay}
          autoPlay={isAbleToPlay && currentStatus === 'PLAYING'}
          onEnded={_onAudioEnded}
          onSingleEnded={onSingleAudioEnded}
        />
      )}
    </div>
  );
};
