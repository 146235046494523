import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { useNavigate } from 'react-router-dom';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { LogLevel } from '../../api/Logger';
import { allChecksPassed, passCheck } from '../../utils/prechecks';
import { vfwRoutes } from '../../vfw-routes';
import { useFocus } from '../../hooks/useFocus';
import {
  Box,
  CADE_CONTENT_TITLE_ID,
  Paragraph,
  PrimaryButton,
  Space,
  SystemCheckCell,
} from '@versant-monorepo/cade';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { Template } from '../../components/Template/Template';
import { ContentTitle } from '../../components/ContentTitle/ContentTitle';
import React from 'react';
import './styles.scss';
import { useCheckProgress } from '../../hooks/useCheckProgress';
import { useApi } from '../../hooks/useApi';
import { serializeError } from '../../api/api.utils';

export const SetupSummary = () => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('remoteMonitoring.setupSummary.documentTitle'));
  const { pushEvent } = useLogger();
  const { getNextStep, launchTest } = useApi();
  const navigate = useNavigate();
  const { lastEvent, eventHandler } = useHandleAppEvent({
    'cade-ended-event': async () => {
      pushEvent({
        level: LogLevel.INFO,
        item: 'Setup Summary',
        message: 'User clicked next on setup summary',
      });
    },
  });

  useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);

  useCheckProgress('setupSummary');

  const listItems = [
    { id: 1, label: t('remoteMonitoring.setupSummary.listItem1'), state: 'ok' },
    { id: 2, label: t('remoteMonitoring.setupSummary.listItem2'), state: 'ok' },
  ];

  return (
    <Template
      volumeSlider={true}
      header={
        <Header
          onExit={() => {
            pushEvent({
              level: LogLevel.INFO,
              item: 'Setup summary',
              message: 'User clicked exit test on setup summary',
            });
            eventHandler(exitEvent());
          }}
          title={''}
        />
      }
      footer={
        <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
      }
    >
      <div className={'setup-summary'}>
        <div className="setup-summary__header">
          <ContentTitle level={'large'}>
            {t('remoteMonitoring.setupSummary.header')}
          </ContentTitle>
          <Paragraph className={'setup-summary__header-subtitle'}>
            {t('remoteMonitoring.setupSummary.subTitle')}
          </Paragraph>
        </div>

        <Box role={'instruction'} className={'setup-summary__box'}>
          <Space
            role={'list'}
            margin={{ bottom: 5 }}
            padding={{ top: 6 }}
            className="setup-summary__checks-list"
          >
            {listItems.map((sc) => (
              <SystemCheckCell
                key={sc.id.toString()}
                label={sc.label}
                state={sc.state}
              />
            ))}
          </Space>
          <div className="setup-summary__footer">
            <PrimaryButton
              onClick={async () => {
                try {
                  passCheck('setupSummary');
                  if (allChecksPassed()) {
                    await launchTest();
                    await getNextStep();
                  }
                  navigate(vfwRoutes.reviewRules);
                } catch (e) {
                  console.error(e);
                  pushEvent({
                    level: LogLevel.ERROR,
                    message: `Setup Summary error: ${serializeError(e)}`,
                    item: 'Setup Summary',
                    eventType: 'ERROR',
                  });
                  navigate(vfwRoutes.serverError);
                }
              }}
            >
              {t('remoteMonitoring.setupSummary.nextButtonLabel')}
            </PrimaryButton>
          </div>
        </Box>
      </div>
    </Template>
  );
};
