import { VfwStorage } from './Storage';
import { vfwRoutes } from '../vfw-routes';
import { parseFeatureFlagsFromEnv } from '@versant-monorepo/cade';
import { FEATURE_FLAG_ENV_PREFIX } from '../constants';
import { getNextStep, launchTest } from '../api/api';
import { allChecksPassed } from './prechecks';
import { z } from 'zod';
import { Logger } from '../api/Logger';
import { TestStatus } from '../context/App.context';

export function isSpeakingTest() {
  return (
    VfwStorage.getItem('reservationTinResponse')?.hasSpeakingItems ?? false
  );
}

export const parseMarkup = (markup: string) =>
  markup
    .replaceAll('<fmt.p>', '<p>')
    .replaceAll('</fmt.p>', '</p>')
    .replaceAll('<fmt.b>', '<b>')
    .replaceAll('</fmt.b>', '</b>')
    .replaceAll('<fmt.br/>', '<br/>')
    .replaceAll('</fmt.ul>', '</ul>')
    .replaceAll('</fmt.li>', '</li>')
    .replaceAll('<fmt.ul>', '<ul>')
    .replaceAll('<fmt.li>', '<li>');

const cancelEvent = (event: Event): boolean => {
  event.preventDefault();
  event.stopPropagation();
  return false;
};

export const disablePasteEvent = () => {
  document
    .querySelector('input, textarea')
    ?.addEventListener('paste', cancelEvent);
};

export const disableDropEvent = () => {
  document.querySelector('html')?.addEventListener('drop', cancelEvent);
};

const handleCtrlAltCmd = (event: KeyboardEvent) => {
  if (event.ctrlKey || event.altKey || event.metaKey) {
    event.preventDefault();
  }
};

export const disableCtrlAltCmd = () => {
  document.addEventListener('keydown', handleCtrlAltCmd, false);
};

const handleCtrlA = (event: KeyboardEvent) => {
  if (
    (event.ctrlKey || event.metaKey) &&
    String.fromCharCode(event.which).toLowerCase() === 'a'
  ) {
    event.preventDefault();
  }
};
export const disableCtrlA = () => {
  document.addEventListener('keydown', handleCtrlA, false);
};

const handleF5 = (event: KeyboardEvent) => {
  if (event.code === 'F5') {
    event.preventDefault();
  }
};

export const disableF5 = () => {
  document.addEventListener('keydown', handleF5, false);
};

export const handleContextMenu = () => {
  document.addEventListener('contextmenu', cancelEvent, false);
};

export const confirmBeforeCloseApp = () => {
  window.onbeforeunload = (e: BeforeUnloadEvent) => {
    if (e) {
      e.returnValue = 'Are you sure that you want to leave?';
    }

    return 'Are you sure that you want to leave?';
  };
};

export const clearEvents = () => {
  document.removeEventListener('paste', cancelEvent);
  document.removeEventListener('drop', cancelEvent);
  document.removeEventListener('keydown', handleCtrlAltCmd);
  document.removeEventListener('keydown', handleCtrlA);
  document.removeEventListener('keydown', handleF5);
  document.removeEventListener('contextmenu', handleContextMenu);
  window.onbeforeunload = () => {};
};

export type ExitTestArgs = {
  hard?: boolean;
  testStatus: TestStatus;
};

export const exitTest = (
  { hard, testStatus }: ExitTestArgs = { hard: false, testStatus: undefined }
) => {
  const ltiReturnUrl = VfwStorage.getItem('reservationTinResponse')?.returnUrl;

  if (hard) {
    window.onbeforeunload = () => {};
  }

  // send logs before redirect
  Logger.getInstance().sendLogs();

  if (!ltiReturnUrl) {
    window.location.href = '/';
    return;
  }

  switch (testStatus) {
    case 'TEST_BEGUN': {
      window.location.href = `${ltiReturnUrl}?lti_errorlog=LAUNCH_UNSUCCESSFUL`;
      break;
    }

    case 'IN_PROGRESS': {
      window.location.href = `${ltiReturnUrl}?lti_errorlog=DELIVERY_INTERRUPTED`;
      break;
    }

    default:
    case 'COMPLETED': {
      window.location.href = ltiReturnUrl;
    }
  }
};

export const closeWindow = () => window.close();

/**
 * UTILITY FOR DEV/TEST APPLICATION
 */
function quirks() {
  if (!process.env.REACT_APP_DEV_QUIRKS) {
    return {
      goToTest: () => {},
    };
  }

  async function goToTest() {
    // set that all checks has passed
    const tinResponse = VfwStorage.getItem('reservationTinResponse');
    if (!tinResponse) {
      console.error('Please use goToTest() quirk only after entering the tin.');
      return;
    }
    let checks = VfwStorage.getItem('checks');
    if (checks) {
      checks = checks.map((check) => {
        check.passed = true;
        return check;
      });
      VfwStorage.setItem('checks', checks);
    }
    try {
      if (allChecksPassed()) {
        await launchTest();
        const response = await getNextStep();
        const itemType = response.items[0].itemType;
        if (itemType === 'resumestatus' || itemType === 'toc') {
          VfwStorage.setItem('toc', response.items[0].toc);
        }
      }
      window.location.replace(vfwRoutes.test);
    } catch (e) {
      console.error(e);
    }
  }

  async function skipExercises(amount: number) {
    // amount -1 as last reload() will also make the call
    for (let i = 0; i < amount; i++) {
      const response = await getNextStep();
      const itemType = response.items[0].itemType;
      if (itemType === 'resumestatus' || itemType === 'toc') {
        VfwStorage.setItem('toc', response.items[0].toc);
      }
    }
    window.location.reload();
  }

  return {
    goToTest,
    skipExercises,
    featureFlags: parseFeatureFlagsFromEnv(
      process.env,
      FEATURE_FLAG_ENV_PREFIX
    ),
  };
}

(window as any).quirks = quirks;

function toUnsignedInt32(number: number) {
  if (number >= 0) {
    return number;
  }
  return 0xffffffff - number * -1 + 1;
}

const lookupTable = [
  0, 1996959894, 3993919788, 2567524794, 124634137, 1886057615, 3915621685,
  2657392035, 249268274, 2044508324, 3772115230, 2547177864, 162941995,
  2125561021, 3887607047, 2428444049, 498536548, 1789927666, 4089016648,
  2227061214, 450548861, 1843258603, 4107580753, 2211677639, 325883990,
  1684777152, 4251122042, 2321926636, 335633487, 1661365465, 4195302755,
  2366115317, 997073096, 1281953886, 3579855332, 2724688242, 1006888145,
  1258607687, 3524101629, 2768942443, 901097722, 1119000684, 3686517206,
  2898065728, 853044451, 1172266101, 3705015759, 2882616665, 651767980,
  1373503546, 3369554304, 3218104598, 565507253, 1454621731, 3485111705,
  3099436303, 671266974, 1594198024, 3322730930, 2970347812, 795835527,
  1483230225, 3244367275, 3060149565, 1994146192, 31158534, 2563907772,
  4023717930, 1907459465, 112637215, 2680153253, 3904427059, 2013776290,
  251722036, 2517215374, 3775830040, 2137656763, 141376813, 2439277719,
  3865271297, 1802195444, 476864866, 2238001368, 4066508878, 1812370925,
  453092731, 2181625025, 4111451223, 1706088902, 314042704, 2344532202,
  4240017532, 1658658271, 366619977, 2362670323, 4224994405, 1303535960,
  984961486, 2747007092, 3569037538, 1256170817, 1037604311, 2765210733,
  3554079995, 1131014506, 879679996, 2909243462, 3663771856, 1141124467,
  855842277, 2852801631, 3708648649, 1342533948, 654459306, 3188396048,
  3373015174, 1466479909, 544179635, 3110523913, 3462522015, 1591671054,
  702138776, 2966460450, 3352799412, 1504918807, 783551873, 3082640443,
  3233442989, 3988292384, 2596254646, 62317068, 1957810842, 3939845945,
  2647816111, 81470997, 1943803523, 3814918930, 2489596804, 225274430,
  2053790376, 3826175755, 2466906013, 167816743, 2097651377, 4027552580,
  2265490386, 503444072, 1762050814, 4150417245, 2154129355, 426522225,
  1852507879, 4275313526, 2312317920, 282753626, 1742555852, 4189708143,
  2394877945, 397917763, 1622183637, 3604390888, 2714866558, 953729732,
  1340076626, 3518719985, 2797360999, 1068828381, 1219638859, 3624741850,
  2936675148, 906185462, 1090812512, 3747672003, 2825379669, 829329135,
  1181335161, 3412177804, 3160834842, 628085408, 1382605366, 3423369109,
  3138078467, 570562233, 1426400815, 3317316542, 2998733608, 733239954,
  1555261956, 3268935591, 3050360625, 752459403, 1541320221, 2607071920,
  3965973030, 1969922972, 40735498, 2617837225, 3943577151, 1913087877,
  83908371, 2512341634, 3803740692, 2075208622, 213261112, 2463272603,
  3855990285, 2094854071, 198958881, 2262029012, 4057260610, 1759359992,
  534414190, 2176718541, 4139329115, 1873836001, 414664567, 2282248934,
  4279200368, 1711684554, 285281116, 2405801727, 4167216745, 1634467795,
  376229701, 2685067896, 3608007406, 1308918612, 956543938, 2808555105,
  3495958263, 1231636301, 1047427035, 2932959818, 3654703836, 1088359270,
  936918000, 2847714899, 3736837829, 1202900863, 817233897, 3183342108,
  3401237130, 1404277552, 615818150, 3134207493, 3453421203, 1423857449,
  601450431, 3009837614, 3294710456, 1567103746, 711928724, 3020668471,
  3272380065, 1510334235, 755167117,
];

function crc32(blob: any) {
  return new Promise<number>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const buffer = event.target?.result;
      // @ts-ignore
      const view = new DataView(buffer);
      let divisor = 0xffffffff;
      for (let i = 0; i < view.buffer.byteLength; i++) {
        let byte = view.getUint8(i);
        const tableIndex = (divisor ^ byte) & 0xff;
        const tableVal = lookupTable[tableIndex];
        if (tableVal === undefined)
          throw new Error('tableIndex out of range 0-255');
        divisor = (divisor >>> 8) ^ tableVal;
      }
      let crc = toUnsignedInt32(divisor ^ 0xffffffff);
      resolve(crc);
    };
    fileReader.onerror = (event) => {
      fileReader.abort();
      reject(fileReader.error);
    };
    fileReader.readAsArrayBuffer(blob);
  });
}

export const getChecksum = (blob: any) => crc32(blob);
//// end * utility to generate checksum for given blob ////

//// start * utility to validate client checksum against server checksum ////
export const validateChecksum = function (
  crc32_client: any,
  crc32_server: any
) {
  // ignore validation if no checksum was provided in the upload request to the server
  if (crc32_client == null) {
    return {
      status: true,
      message: 'client checksum is null, ignoring validation',
    };
  }

  // check if server returns the checksum it has validated against
  try {
    if (crc32_server == crc32_client) {
      return { status: true, message: 'checksum validation successful' };
    }

    console.error('failure: checksum mismatch');
    return {
      status: false,
      message:
        'checksum mismatch crc32_client=' +
        crc32_client +
        ' crc32_server=' +
        crc32_server,
    };
  } catch (error) {
    console.warn(
      'ignored: checksum validation not possible due to error=' + error
    );
    return {
      status: false,
      message: 'checksum validation not possible due to error=' + error,
    };
  }
};
//// end * utility to validate client checksum against server checksum ////

//// start * utility to export sample WAV file ////
function sampleBytes(length: any) {
  const result = Array(length);
  for (let i = 0; i < length; ++i) {
    result[i] = Math.floor(256 * Math.random());
  }
  return result;
}
function floatTo16BitPCM(output: any, offset: any, input: any) {
  for (let i = 0; i < input.length; i++, offset += 2) {
    let s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}
function writeString(view: any, offset: any, string: any) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}
export const sampleWAV = function () {
  let sample = sampleBytes(1024);
  let rate = 8000;
  let bufferData = new Uint8Array(44 + sample.length * 2);
  let view = new DataView(bufferData.buffer);

  writeString(view, 0, 'RIFF'); /* RIFF identifier */
  view.setUint32(4, 36 + sample.length * 2, true); /* RIFF chunk length */
  writeString(view, 8, 'WAVE'); /* RIFF type */
  writeString(view, 12, 'fmt '); /* format chunk identifier */
  view.setUint32(16, 16, true); /* format chunk length */
  view.setUint16(20, 1, true); /* sample format (raw) */
  view.setUint16(22, 1, true); /* channel count */
  view.setUint32(24, rate, true); /* sample rate */
  view.setUint32(
    28,
    rate * 2,
    true
  ); /* byte rate (sample rate * block align) */
  view.setUint16(
    32,
    2,
    true
  ); /* block align (channel count * bytes per sample) */
  view.setUint16(34, 16, true); /* bits per sample */
  writeString(view, 36, 'data'); /* data chunk identifier */
  view.setUint32(40, sample.length * 2, true); /* data chunk length */
  floatTo16BitPCM(view, 44, sample);

  return new Blob([view], { type: 'audio/wav' });
};
//// end * utility to export sample WAV file ////

export const extractBasicLangCode = (input: string) => {
  if (!input) {
    return 'en';
  }

  const regex = /^([a-z]{2})(?:-[A-Z]{2})?$/i;
  const match = input.toLowerCase().match(regex);
  return match ? match[1] : 'en';
};

export const getBrowserLanguage = (avaiableLangauges: string[]) =>
  navigator.languages
    .map(extractBasicLangCode)
    .find((lang) => avaiableLangauges.includes(lang)) ?? 'en';

export function base64ToFile(base64String: string, fileName: string): File {
  // Extract the base64 part of the data URL
  const [header, base64Data] = base64String.split(',');

  // Get the MIME type from the header (e.g., 'image/png' or 'image/jpeg')
  const mimeType = header.match(/:(.*?);/)?.[1] || 'image/jpeg';

  // Convert base64 string to binary data
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the binary data
  const blob = new Blob([byteArray], { type: mimeType });

  // Create a File from the Blob
  const file = new File([blob], fileName, { type: mimeType });

  return file;
}

export const generateSequenceNumber = (): string => {
  const now = new Date();
  return String(now.getTime() % 1_000_000_000);
};

const extractReactAppEnvVars = (
  obj: Record<string, string | undefined> = process.env
) =>
  Object.keys(obj)
    .filter((key) => key.startsWith('REACT_APP_'))
    .reduce(
      (result, key) => ({ ...result, [key]: obj[key] as string }),
      {} as Record<string, string>
    );

const envVarsSchema = z.object({
  REACT_APP_PROXY_URL: z.string().url(),
  REACT_APP_RECAPTCHA_SITE_KEY: z.string(),
  REACT_APP_DEV_QUIRKS: z
    .enum(['true', 'false'])
    .transform((value) => value === 'true'),
  REACT_APP_ENABLE_VIDEO_PROCTORING: z
    .enum(['true', 'false'])
    .transform((value) => value === 'true'),
  REACT_APP_ENABLE_TRANSLATIONS: z
    .enum(['true', 'false'])
    .transform((value) => value === 'true'),
  // TODO PB: Add recognition of the environment in order to validade if the key should have a "-test" suffix. For the prod env there shouldn't be a suffix "-test"
  REACT_APP_ONE_TRUST_BANNER_DOMAIN_SCRIPT: z.string(),
});

export const checkEnvVars = () => {
  const envs = extractReactAppEnvVars();
  // TODO PB: Please use parsed envs instead of referring to process,env.REACT_APP_XXX
  return envVarsSchema.parse(envs);
};
