import React, { useContext, useState } from 'react';
import './styles.scss';
import { Box, Paragraph } from '@src/components';
import { ListeningStatus } from '@components/Listening/commons';
import { ListenRecordExerciseContent } from '../../components/ListenRecordExerciseContent';
import { ChecksHeader } from '@components/ChecksHeader';
import { CadeEvent } from '@utils/events';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export interface Props {
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  question: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  title: string;
}

export function SpeechSample({
  question,
  audioSrc,
  timeouts,
  onEvent,
  forceEnd = false,
  title,
}: Props) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const [warningVisible, setWarningVisible] = useState(false);

  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  return (
    <div className={'speech-sample-exercise-container'}>
      <div className="cade-speaker-check__hgroup">
        <ChecksHeader
          headerText={''}
          title={title || t('speechSample.exercise.question')}
          fontLevel={'large'}
          currentStep={undefined}
          totalSteps={undefined}
        />
      </div>
      <Box role="exercise" className="example-box">
        <Paragraph
          className={'speech-sample-exercise__question'}
          weight={
            listeningStatus === 'PLAYING' || listeningStatus === 'INACTIVE'
              ? 'bold'
              : 'normal'
          }
          level="large"
          dangerouslySetInnerHTML={{ __html: question }}
        />
        <br />
        <ListenRecordExerciseContent
          audioSrc={audioSrc}
          timeouts={timeouts}
          onEvent={onEvent}
          listeningStatus={listeningStatus}
          setListeningStatus={setListeningStatus}
          forceEnd={forceEnd}
          setWarningVisible={setWarningVisible}
          verticalMode={true}
          leftLabel={''}
          rightLabel={t('speechSample.exercise.rightLabel')}
          recordingFinishStatus="SUCCESS"
          playButtonText={t('speechSample.exercise.button')}
          playButtonSize="large"
          role={'instruction'}
          playBeepSound={true}
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          weCantHearYou={warningVisible}
          listenMode={listeningStatus !== 'COMPLETED'}
        />
      </div>
    </div>
  );
}
