import { ApiModel } from '../../api.interfaces';
import { CadeEvent } from '@versant-monorepo/cade';
import { extractAudioResourcesFromResponse } from '../../api.utils';
import i18n from 'i18next';

interface CadeSurveyModel {
  title: string;
  onEvent: (event: CadeEvent) => void;
  cueInstructions?: string[];
  radioOptions?: string[];
  radioQuestion?: string;
  radioQuestionResponseId?: string;
  inputQuestion?: string;
  inputQuestionResponseId?: string;
  audioSrc: string[];
}
export function transformJSONToSurvey(json: ApiModel): CadeSurveyModel {
  const item = json.items[0];
  const title = item.titles[0];
  let cueInstructions = item.cueInstructions;
  const formInputAction = item.actions.find(
    (el) => el.actionType === 'forminput'
  );
  let radioQuestion = '';
  let radioOptions: string[] | undefined = [];
  let radioQuestionResponseId = '';

  if (formInputAction && formInputAction.radioInputParams) {
    radioQuestion = i18n.t('surveyForm.radioQuestion');
    radioOptions = [
      i18n.t('surveyForm.radioAnswer1'),
      i18n.t('surveyForm.radioAnswer2'),
      i18n.t('surveyForm.radioAnswer3'),
    ];
    radioQuestionResponseId = formInputAction.radioInputParams[0].responseId;
  }

  let inputQuestion = '';
  let inputQuestionResponseId = '';

  if (formInputAction && formInputAction.textInputParams) {
    inputQuestion = i18n.t('surveyForm.inputQuestion');
    inputQuestionResponseId = formInputAction.textInputParams[0].responseId;
  }

  return {
    title,
    cueInstructions,
    radioOptions,
    radioQuestion,
    radioQuestionResponseId,
    inputQuestion,
    inputQuestionResponseId,
    onEvent: (event: CadeEvent) => {},
    audioSrc: extractAudioResourcesFromResponse(json),
  };
}
