import { ApiModel, AudioResponse } from '../../api/api.interfaces';
import {
  CADE_QUESTION_COUNTER_ID,
  getAudioType,
  ReadingExercise,
} from '@versant-monorepo/cade';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Wrapper } from '../Wrapper';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { transformJSONToReading } from '../../api/parsers/Reading/Reading';
import { useParser } from '../../hooks/useParser';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useNavigate } from 'react-router-dom';
import { useForceEndTrigger } from '../../hooks/useForceEndTrigger';
import { vfwRoutes } from '../../vfw-routes';

export const ReadingWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const navigate = useNavigate();
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const { forceEndTrigger, setForceEnd } = useForceEndTrigger();

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as Blob),
    'navigate-we-cant-hear-you-event': () => {
      setForceEnd('we-cant-hear-you');
    },
    'navigate-problem-audio-event': () => {
      setForceEnd('problem-with-audio');
    },
  });
  const { pushEvent } = useLogger();
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);

  const readingExerciseProps = useParser(
    transformJSONToReading,
    model as ApiModel
  );

  useSetDocumentTitle(readingExerciseProps?.title);

  const onNext = async (responseItem: AudioResponse) => {
    if (forceEndTrigger === 'we-cant-hear-you') {
      navigate(vfwRoutes.weCantHearYou);
      return;
    }

    if (forceEndTrigger === 'problem-with-audio') {
      navigate(vfwRoutes.problemWithAudio);
      return;
    }

    pushEvent({
      item: 'Reading Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Reading Exercise',
    });

    const response = await getNextStep(responseItem);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(null);
  };

  const onEnded = async (audio?: Blob) => {
    if (audio) {
      if (!audio.size) {
        pushEvent({
          item: 'Reading Exercise',
          level: LogLevel.WARN,
          message: `No audio size for Reading Exercise, question ${readingExerciseProps?.count} of ${readingExerciseProps?.total}`,
        });
      }
      const file = new File(
        [audio],
        readingExerciseProps?.responseId as string,
        {
          type: getAudioType().fileType,
        }
      );

      const responseItem = {
        id: readingExerciseProps?.responseId as string,
        response: file,
        type: 'AUDIO',
      } as AudioResponse;

      await onNext(responseItem);
    }
  };

  return (
    readingExerciseProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'exercise'}
          footer={
            <Footer
              nextButtonEnabled={
                readingExerciseProps.count === readingExerciseProps.total
              }
              onNext={() => setForceEnd('next-clicked')}
              role={'exercise'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Reading Exercise',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Reading Exercise',
                });
                eventHandler(exitEvent());
              }}
              currentStep={readingExerciseProps.currentStep}
              totalSteps={readingExerciseProps.totalSteps}
              title={readingExerciseProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <ReadingExercise
              key={networkDependentKey(
                onlineStatus,
                readingExerciseProps.responseId
              )}
              {...readingExerciseProps}
              onEvent={eventHandler}
              forceEnd={forceEndTrigger !== null}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
