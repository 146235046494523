import React, { useEffect, useState } from 'react';
import { VfwStorage } from '../utils/Storage';
import { Logger, LogLevel } from '../api/Logger';

type MicrophoneStatus = 'on' | 'off' | 'unknown';

const useMicrophoneStatus = (): MicrophoneStatus => {
  const [microphoneStatus, setMicrophoneStatus] =
    useState<MicrophoneStatus>('unknown');

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.addEventListener) {
      const handleDeviceChange = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const selectedMicrophoneId = VfwStorage.getItem('microphoneId');

        const microphone = devices.find(
          (device) => device.deviceId === selectedMicrophoneId
        );

        if (!microphone) {
          Logger.getInstance().pushEvent({
            level: LogLevel.ERROR,
            message: `Stored microphoneId was not found. Test taker must have disconnected one's headphones`,
            item: '',
            eventType: 'ERROR',
          });
          setMicrophoneStatus('off');
        } else {
          setMicrophoneStatus('on');
        }
      };

      navigator.mediaDevices.addEventListener(
        'devicechange',
        handleDeviceChange
      );

      handleDeviceChange();

      return () => {
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          handleDeviceChange
        );
      };
    }
  }, []);

  return microphoneStatus;
};

export default useMicrophoneStatus;
