import './SystemCheckCell.scss';
import { BannerAlertIcon } from '@icons/BannerAlertIcon';
import { BannerSuccessIcon } from '@icons/BannerSuccessIcon';
import { Paragraph } from '@src/components';
import { Text } from '@components/typography/Text';
import { Space } from '@components/Space';

interface ISystemCheckCellProperties {
  label: string;
  info?: string;
  state: string;
}

export function SystemCheckCell({
  label,
  info,
  state,
}: ISystemCheckCellProperties) {
  function getIcon(state: string) {
    switch (state) {
      case 'error':
        return (
          <div className="icon-error">
            <BannerAlertIcon />
          </div>
        );
      case 'ok':
        return (
          <div className="icon-ok" data-testid={'check-ok'}>
            <BannerSuccessIcon />
          </div>
        );
      case 'inactive':
        return (
          <div className="icon-inactive">
            <BannerSuccessIcon />
          </div>
        );
    }
  }

  return (
    <div className="system-check-cell" role={'listitem'}>
      <div className={'sr-only'}>
        {state === 'ok' ? label + ' success' : ''}
      </div>
      <div aria-hidden={true} className={`system-check-bar ${state}`}>
        <Text role="text" level="normal" weight="bold">
          {label}
        </Text>
        {info && (
          <Space padding={{ left: 2, right: 2 }} className="system-check-info">
            <Paragraph level="smaller">{info}</Paragraph>
          </Space>
        )}
      </div>
      <div aria-hidden={true}>{getIcon(state)}</div>
    </div>
  );
}
