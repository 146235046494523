import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useLogger } from '../../hooks/useLogger';
import useCameras from '../../hooks/useCameras';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VfwStorage } from '../../utils/Storage';
import { vfwRoutes } from '../../vfw-routes';
import { Header } from '../../components/Header/Header';
import { LogLevel } from '../../api/Logger';
import { Footer } from '../../components/Footer/Footer';
import { Template } from '../../components/Template/Template';
import { ContentTitle } from '../../components/ContentTitle/ContentTitle';
import {
  Box,
  Modal,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
} from '@versant-monorepo/cade';
import './styles.scss';
import { useApi } from '../../hooks/useApi';
import CircleLoader from '../../components/CircleLoader/CircleLoader';
import alertIcon from './icons/alert.svg';
import successIcon from './icons/success.svg';
import playIcon from './icons/play.svg';
import pauseIcon from './icons/pause.svg';
import { useCheckProgress } from '../../hooks/useCheckProgress';
import { passCheck } from '../../utils/prechecks';
import { useDoubleRecorder } from './useDoubleRecorder';
import { serializeError } from '../../api/api.utils';

export type RecordAVideoStateStatus =
  | 'INITIAL'
  | 'ACCESS_GRANTED'
  | 'RECORDING'
  | 'RECORDED'
  | 'VIDEO_UPLOADING'
  | 'SUCCESS'
  | 'ERROR';

const STOP_RECORDING_MAX_TIME = 15_000;

export function RecordAVideo() {
  const [state, setState] = useState<RecordAVideoStateStatus>('INITIAL');
  const { validateReferenceVideo } = useApi();
  const [error, setError] = useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { eventHandler } = useHandleAppEvent();
  const { pushEvent } = useLogger();
  const { cameraState } = useCameras();
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [progress, setProgress] = useState(0);
  const autoStopRecordingTimeoutRef = useRef<NodeJS.Timeout>();
  const onStateChange = (status: RecordAVideoStateStatus) => setState(status);

  const {
    startRecording,
    stopRecording: _stopRecording,
    stopRecordingDisabled,
    videoUrl,
    lowQualityBlob,
    recordDateString,
    videoDuration,
  } = useDoubleRecorder({ webcamRef, onStateChange });

  useCheckProgress('recordAVideo');

  const stopRecording = useCallback(() => {
    clearTimeout(autoStopRecordingTimeoutRef.current);
    _stopRecording();
  }, [_stopRecording]);

  useEffect(() => {
    if (state === 'RECORDING') {
      autoStopRecordingTimeoutRef.current = setTimeout(
        stopRecording,
        STOP_RECORDING_MAX_TIME
      );
    }
    return () => clearTimeout(autoStopRecordingTimeoutRef.current);
  }, [state, stopRecording]);

  useEffect(() => {
    if (cameraState.status === 'ERROR') {
      setState('ERROR');
      setError('cameraAccess');
    }

    if (cameraState.status === 'SUCCESS') {
      setState('ACCESS_GRANTED');
      setError(undefined);
    }
  }, [cameraState]);

  const initialMessage = useMemo(() => {
    return (
      state === 'INITIAL' || state === 'ACCESS_GRANTED' || state === 'RECORDING'
    );
  }, [state]);

  const showCamera = useMemo(() => {
    return state === 'ACCESS_GRANTED' || state === 'RECORDING';
  }, [state]);

  const resetVideo = useCallback(() => {
    clearTimeout(autoStopRecordingTimeoutRef.current);
    setVideoPlaying(false);
    videoRef?.current?.pause();
    videoRef?.current?.load();
    setProgress(0);
  }, []);

  const handleTryAgain = useCallback(() => {
    setState('ACCESS_GRANTED');
    setError(undefined);
    resetVideo();
  }, []);

  const sendVideo = useCallback(async () => {
    if (lowQualityBlob) {
      setLoading(true);
      const file = new File([lowQualityBlob], 'video.mp4', {
        type: lowQualityBlob.type,
      });
      if (file) {
        const now = new Date();
        const recordedAt = recordDateString ?? now.toISOString();
        const res: { acceptable: boolean; details: string; videoUID: string } =
          await validateReferenceVideo(
            file,
            recordedAt,
            'video/mp4',
            file.size,
            'web'
          );
        if (!res.acceptable) {
          setState('ERROR');
          if (res.details === 'SV Reply:NoHumanVoice') {
            setError('noPerson');
          } else if (res.details === 'SV Reply:LowVolume') {
            setError('lowVolume');
          } else if (res.details === 'SV Reply:Clipped') {
            setError('clipped');
          } else if (res.details === 'SV Reply:SpeechUnclear') {
            setError('speechUnclear');
          } else if (res.details === 'SV Reply:TooNoisy') {
            setError('tooNoisy');
          } else if (res.details === 'SV Reply:TooSilent') {
            setError('tooSilent');
          } else if (res.details === 'SV Reply:TooShort') {
            setError('tooShort');
          } else {
            setError('speakNaturally');
          }
        } else {
          resetVideo();
          setState('SUCCESS');
          VfwStorage.setItem('videoUID', res.videoUID);
        }
      } else {
        setError('noSpace');
        setState('ERROR');
      }
      setLoading(false);
    }
  }, [lowQualityBlob, recordDateString]);

  const isSVError = useMemo(() => {
    if (error) {
      const statuses = [
        'noPerson',
        'lowVolume',
        'clipped',
        'speechUnclear',
        'tooNoisy',
        'tooSilent',
        'tooShort',
        'speakNaturally',
      ];
      return statuses.includes(error);
    }
    return false;
  }, [error]);

  const ProgressBar = (props: { value: number; max: number }) => {
    return (
      <div className="record-a-video__progress-bar-container">
        <div
          className="record-a-video__progress-bar-indicator"
          style={{
            width: `${(props.value / props.max) * 100}%`,
          }}
        />
      </div>
    );
  };

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const progressPercentage = (currentTime / videoDuration) * 100;
      setProgress(progressPercentage);
    }
  }, [videoDuration, videoRef, setProgress]);

  const handlePlayVideo = useCallback(() => {
    videoRef.current?.play();
    setVideoPlaying(true);
  }, [videoRef, setVideoPlaying]);

  const handlePauseVideo = useCallback(() => {
    videoRef.current?.pause();
    setVideoPlaying(false);
  }, [videoRef, setVideoPlaying]);

  const handleEndVideo = useCallback(() => {
    handlePauseVideo();
    setProgress(0);
  }, [handlePauseVideo, setProgress]);

  const cameraId = VfwStorage.getItem('cameraId');

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                item: 'Record a video',
                level: LogLevel.INFO,
                message: 'User clicked exit on Record a video',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
        }
      >
        <div className={'record-a-video'}>
          <div className={'record-a-video__header'}>
            <Paragraph>{t('remoteMonitoring.recordAVideo.setup')}</Paragraph>
            <ContentTitle level={'large'}>
              {t('remoteMonitoring.recordAVideo.header')}
            </ContentTitle>
            <Paragraph
              className={'record-a-video__stepper'}
              level={'smaller'}
              weight={'semibold'}
            >
              {t('remoteMonitoring.recordAVideo.step', {
                stepCount: 2,
                outOf: 2,
              })}
            </Paragraph>
          </div>
          <Box role={'instruction'} className={'record-a-video__box'}>
            <div className={'record-a-video__box-header'}>
              {initialMessage && (
                <Paragraph level={'small'}>
                  {t('remoteMonitoring.recordAVideo.readShortPassage')}
                </Paragraph>
              )}
              {state === 'ERROR' && error && (
                <>
                  <Paragraph
                    level={'normal'}
                    weight="bold"
                    className={'record-a-video__error-header'}
                  >
                    {t(`remoteMonitoring.recordAVideo.error.${error}.h`)}
                  </Paragraph>
                  <Paragraph
                    level={'small'}
                    className={'record-a-video__error-paragraph'}
                    style={{
                      cursor: isSVError ? 'pointer' : 'default',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `remoteMonitoring.recordAVideo.error.${error}.p`
                      ),
                    }}
                    onClick={isSVError ? () => setModalOpen(true) : () => {}}
                  />
                </>
              )}
              {state === 'RECORDED' && (
                <Paragraph level={'small'}>
                  {t('remoteMonitoring.recordAVideo.recordedMsg')}
                </Paragraph>
              )}
              {state === 'SUCCESS' && (
                <Paragraph level={'small'}>
                  {t('remoteMonitoring.recordAVideo.successMsg')}
                </Paragraph>
              )}
            </div>
            {(state === 'RECORDED' ||
              state === 'SUCCESS' ||
              state === 'ERROR') && (
              <div className="record-a-video__recorded-container">
                <div className="record-a-video__recorded-inner-container">
                  {videoUrl && (
                    <video
                      className="record-a-video__recorded-video"
                      src={videoUrl}
                      ref={videoRef}
                      onTimeUpdate={handleTimeUpdate}
                      onEnded={handleEndVideo}
                    />
                  )}
                  <div className="record-a-video__recorded-video-controls">
                    {videoPlaying ? (
                      <img
                        src={pauseIcon}
                        alt="Pause"
                        onClick={handlePauseVideo}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <img
                        src={playIcon}
                        alt="Play"
                        onClick={handlePlayVideo}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <ProgressBar value={progress} max={100} />
                  </div>
                </div>
                {loading && <CircleLoader />}
                {error && (
                  <img
                    src={alertIcon}
                    alt="Alert icon"
                    className={'record-a-video__alert-icon'}
                  />
                )}
                {state === 'SUCCESS' && (
                  <img
                    src={successIcon}
                    alt="Success icon"
                    className={'record-a-video__success-icon'}
                  />
                )}
              </div>
            )}
            {state !== 'RECORDED' &&
              state !== 'SUCCESS' &&
              state !== 'ERROR' && (
                <div className="record-a-video__recording-container">
                  <div className="record-a-video__passage-container">
                    <div className="record-a-video__passage">
                      <Paragraph level="small">
                        {t('remoteMonitoring.recordAVideo.passage')}
                      </Paragraph>
                    </div>
                  </div>
                  <div className="record-a-video__webcam-container">
                    {state === 'INITIAL' && (
                      <div
                        className={'record-a-video__box-camera-initial'}
                      ></div>
                    )}
                    {showCamera && (
                      <div className={'record-a-video__box-camera'}>
                        <Webcam
                          data-testid="record-a-video-webcam"
                          audio={true}
                          muted={true}
                          ref={webcamRef}
                          mirrored
                          style={{
                            width: 345,
                            height: 220,
                            borderRadius: '8px',
                          }}
                          screenshotFormat={'image/jpeg'}
                          // this logic is correct and fixes the issue on FF: https://bitbucket.pearson.com/projects/VERSANT-ENG/repos/vfw2/pull-requests/819/overview
                          videoConstraints={{
                            facingMode: cameraId ? undefined : 'user',
                            width: 345,
                            height: 220,
                            aspectRatio: 1.5681818182,
                            deviceId: cameraId || undefined,
                          }}
                        />
                        {state === 'RECORDING' && (
                          <div className={'record-a-video__recording-badge'}>
                            <div
                              className={
                                'record-a-video__recording-badge-circle'
                              }
                            ></div>
                            <Paragraph>
                              {t('remoteMonitoring.recordAVideo.recording')}
                            </Paragraph>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            <div className={'record-a-video__box-buttons'}>
              {state === 'ACCESS_GRANTED' && (
                <PrimaryButton
                  onClick={startRecording}
                  data-testid="start-recording-button"
                >
                  {t('remoteMonitoring.recordAVideo.startRecording')}
                </PrimaryButton>
              )}
              {state === 'RECORDING' && (
                <PrimaryButton
                  onClick={stopRecording}
                  disabled={stopRecordingDisabled}
                  data-testid="stop-recording-button"
                >
                  {t('remoteMonitoring.recordAVideo.stopRecording')}
                </PrimaryButton>
              )}
              {state === 'RECORDED' && (
                <>
                  <PrimaryButton
                    onClick={sendVideo}
                    data-testid="send-video-button"
                  >
                    {t('remoteMonitoring.recordAVideo.confirm')}
                  </PrimaryButton>
                  <SecondaryButton
                    data-testid="deny-video-button"
                    onClick={handleTryAgain}
                  >
                    {t('remoteMonitoring.recordAVideo.deny')}
                  </SecondaryButton>
                </>
              )}
              {state === 'ERROR' && (
                <SecondaryButton onClick={handleTryAgain}>
                  {t('remoteMonitoring.recordAVideo.retry')}
                </SecondaryButton>
              )}
              {state === 'SUCCESS' && (
                <>
                  <PrimaryButton
                    onClick={() => {
                      try {
                        passCheck('recordAVideo');
                        navigate(vfwRoutes.setupSummary);
                      } catch (e) {
                        console.error(e);
                        pushEvent({
                          level: LogLevel.ERROR,
                          message: `Record Video error: ${serializeError(e)}`,
                          item: 'Record Video',
                          eventType: 'ERROR',
                        });
                        navigate(vfwRoutes.serverError);
                      }
                    }}
                    data-testid="success-state-button"
                  >
                    {t('remoteMonitoring.recordAVideo.next')}
                  </PrimaryButton>
                </>
              )}
            </div>
          </Box>
        </div>
      </Template>

      <Modal
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        titleType={'h2'}
        title={t('remoteMonitoring.recordAVideo.troubleshootModal.header')}
        footer={
          <div className={'take-a-selfie__modal-footer'}>
            <SecondaryButton
              onClick={() => setModalOpen(false)}
              style={{ minWidth: 172 }}
            >
              {t('remoteMonitoring.recordAVideo.troubleshootModal.confirm')}
            </SecondaryButton>
          </div>
        }
      >
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t(
              'remoteMonitoring.recordAVideo.troubleshootModal.content.header'
            ),
          }}
        />
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t(
              'remoteMonitoring.recordAVideo.troubleshootModal.content.p'
            ),
          }}
        />
      </Modal>
    </>
  );
}
