import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import { Box, Paragraph, SecondaryButton } from '@src/components';
import { ChecksHeader } from '@components/ChecksHeader';
import { PlayAudio } from '@components/PlayAudio';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import {
  AUTOMOVE_TIMEOUT,
  INSTRUCTION_AUTO_PLAY_TIMEOUT,
} from '@utils/constants';
import { useDelay } from '@utils/useDelay';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type Props = {
  audioSrc: string[];
  onEnded: () => void;
  instruction?: string;
  title: string;
};

export function SpeechSampleInstruction({
  audioSrc,
  onEnded,
  instruction,
  title,
}: Props) {
  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;
  const [audioPlaying, setAudioPlaying] = useState(false);
  const delay = useDelay();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => delay.set(playAudio, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  function playAudio() {
    delay.clear();
    setAudioPlaying(true);
  }

  return (
    <div className="speech-sample-instruction-container">
      <div className="cade-speaker-check__hgroup">
        <ChecksHeader
          headerText={''}
          title={title}
          currentStep={undefined}
          totalSteps={undefined}
          fontLevel={'large'}
        />
      </div>
      <Box role="exercise" className="example-box">
        <Paragraph
          level="large"
          className="speech-sample-instruction__text"
          aria-hidden={true}
        >
          {instruction || t('speechSample.instruction.content')}
        </Paragraph>
        <SecondaryButton size="large" onClick={playAudio}>
          {t('speechSample.instruction.button')}
        </SecondaryButton>
        {audioPlaying && (
          <PlayAudio
            audioSrc={audioSrc}
            autoPlay={true}
            onEnded={() => {
              setAudioPlaying(false);
              delay.set(onEnded, AUTOMOVE_TIMEOUT);
            }}
          />
        )}
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
