import React, { useContext } from 'react';
import {
  TroubleshootingTipsModal,
  Props as ModalProps,
} from '@components/Modal';
import { Paragraph } from '@components/typography/Paragraph';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export function TroubleshotModal({ open, onClose }: ModalProps) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <TroubleshootingTipsModal open={open} onClose={onClose}>
      <Paragraph level="normal">{t('speakerCheck.modal.content')}</Paragraph>
    </TroubleshootingTipsModal>
  );
}
