import React, { useState } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import {
  ListenRecordInstructionContent,
  ListenRecordInstructionStatus,
} from '../../components/ListenRecordInstructionContent';
import { ReadCard } from '@components/ReadCard';
import { ScriptLine } from '@utils/types';
import { CadeEvent } from '@utils/events';
import './styles.scss';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { OnPlayArgsCallback } from '@src/components/PlayAudio';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  listenPartDuration: number;
  startActorsAnimationOnAudioIndex?: number;
  scripts: ScriptLine[];
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
};

export const PASSAGE_COMPREHENSION_INSTRUCTION_TEST_IDS = {
  CONTAINER: 'cade-passage-comprehension-instruction-container',
  INSTRUCTION: 'cade-passage-comprehension-instruction-element',
  SUBTITLE: 'cade-passage-comprehension-instruction-subtitle',
};

export function PassageComprehensionInstruction({
  subTitle,
  instruction,
  audioSrc,
  listenPartDuration,
  startActorsAnimationOnAudioIndex = 0,
  scripts,
  onEvent,
  forceEnd = false,
}: Props) {
  const [exampleStatus, setExampleStatus] =
    useState<ListenRecordInstructionStatus>('INIT');
  const [startAnimation, setStartAnimation] = useState(false);

  const { state } = useVolume();
  const voiceLevel = state.outputValue;

  function onPlay({ audioIndex }: OnPlayArgsCallback) {
    if (audioIndex === startActorsAnimationOnAudioIndex) {
      setStartAnimation(true);
    } else {
      setStartAnimation(false);
    }
  }

  return (
    <>
      <div
        className={'passage-comprehension-instruction-wrapper'}
        data-testid={PASSAGE_COMPREHENSION_INSTRUCTION_TEST_IDS.CONTAINER}
      >
        <InstructionContainer>
          <Title
            centered
            id={CADE_CONTENT_TITLE_ID}
            level="large"
            data-testid={PASSAGE_COMPREHENSION_INSTRUCTION_TEST_IDS.SUBTITLE}
          >
            {subTitle}
          </Title>
          <Instruction
            data-testid={PASSAGE_COMPREHENSION_INSTRUCTION_TEST_IDS.INSTRUCTION}
          >
            {instruction}
          </Instruction>
        </InstructionContainer>
        <Box role="instruction" className="cade-margin-bottom-5">
          <ListenRecordInstructionContent
            status={exampleStatus}
            audioSrc={audioSrc}
            listenPartDuration={listenPartDuration}
            onPlayAudio={onPlay}
            onStatusChange={setExampleStatus}
            onEvent={onEvent}
            forceEnd={forceEnd}
          />
        </Box>
        {scripts && scripts.length > 0 && (
          <Box role="instruction">
            <ReadCard
              status={exampleStatus === 'LISTENING' ? 'ACTIVE' : 'FREEZE'}
              scripts={scripts}
              startAnimation={startAnimation}
            />
          </Box>
        )}
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            numOfCircles={6}
            currentValue={voiceLevel}
            listenMode={true}
          />
        </div>
      </div>
    </>
  );
}
