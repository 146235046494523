import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import { Instruction } from '@components/Instruction';
import { Box, Paragraph, SecondaryButton, Title } from '@src/components';
import { QuestionCounter } from '@components/QuestionCounter';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import {
  audioEndedEvent,
  CadeEvent,
  nextEvent,
  useEndedEvent,
  userResumeSpeakingEvent,
  weCantHearYouEvent,
} from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useBeep } from '@utils/useBeep';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { PlayAudio } from '@components/PlayAudio';
import { useRecording } from '@components/Recording/useRecording';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { useAudioDuration } from '@utils/useAudioDuration';
import { TextArea } from '@components/base/TextArea';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

type Step = {
  label: string;
  description?: string;
  time?: number;
};

type Steps = 'first' | 'second' | 'third';
type StepStates = 'DISABLED' | 'ACTIVE' | 'WAITING' | 'COMPLETED';

export interface Props {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  steps: Record<Steps, Step>;
  instruction?: string;
}

const STEPS: Record<Steps, StepStates>[] = [
  {
    first: 'DISABLED',
    second: 'DISABLED',
    third: 'DISABLED',
  },
  {
    first: 'ACTIVE',
    second: 'DISABLED',
    third: 'DISABLED',
  },
  {
    third: 'WAITING',
    second: 'ACTIVE',
    first: 'COMPLETED',
  },
  {
    first: 'COMPLETED',
    second: 'COMPLETED',
    third: 'ACTIVE',
  },
];

export const SPEAKING_SITUATIONS_EXERCISE_TEST_IDS = {
  PLAY_BUTTON: 'cade-speaking-situations-exercise-play-button',
  SUBTITLE: 'cade-speaking-situations-exercise-subtitle',
  INSTRUCTION: 'cade-speaking-situations-exercise-instruction',
  STEP: {
    FIRST: {
      LABEL: 'cade-speaking-situations-exercise-step-first-label',
      DESCRIPTION: 'cade-speaking-situations-exercise-step-first-description',
    },
    SECOND: {
      LABEL: 'cade-speaking-situations-exercise-step-second-label',
    },
    THIRD: {
      LABEL: 'cade-speaking-situations-exercise-step-third-label',
      DESCRIPTION: 'cade-speaking-situations-exercise-step-third-description',
    },
  },
};

export function SpeakingSituationsExercise({
  count,
  total,
  subTitle,
  audioSrc,
  timeouts,
  onEvent = () => {},
  forceEnd = false,
  steps,
  instruction,
}: Props) {
  const [listenMode, setListenMode] = useState(true);
  const [warningVisible, setWarningVisible] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);
  const secondLabelRef = useRef<HTMLParagraphElement>(null);
  const thirdLabelRef = useRef<HTMLParagraphElement>(null);
  const thirdDescriptionRef = useRef<HTMLParagraphElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [pause, setPause] = useState(false);

  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );

  const { sendEndEvent } = useEndedEvent<Blob>(onEvent);
  const delayAutoPlaying = useDelay();
  const delayAutoFocusingThirdDescription = useDelay();
  const delaySendingEndEvent = useDelay();
  const stopPlayingAfter = useDelay();
  const beep = useBeep();
  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;
  const audioDuration = useAudioDuration(audioSrc);

  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const INSTRUCTION = t('speakingSituations.exercise.instruction');

  const { startRecording, stopRecording } = useRecording({
    handleFinishRecording(data) {
      if (forceEnd) {
        return sendEndEvent(data);
      }

      delaySendingEndEvent.set(() => sendEndEvent(data));
    },
    timeouts: {
      initialSilence: timeouts.initial,
      endingSilence: timeouts.ending,
      max: timeouts.speaking,
    },
    onTimeout: (timeoutKind) => {
      switch (timeoutKind) {
        case 'initial_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'cant_hear_you_silence_timeout':
          setWarningVisible(true);
          onEvent(weCantHearYouEvent());
          break;
        case 'ending_silence_timeout':
          setWarningVisible(false);
          stopRecording();
          break;
        case 'max_time_timeout':
          stopRecording();
          setWarningVisible(false);
          break;
        case 'cant_hear_you_silence_timeout_canceled':
          setWarningVisible(false);
          onEvent(userResumeSpeakingEvent());
          break;
      }
    },
  });

  useEffect(
    () => delayAutoPlaying.set(runAudio, EXERCISE_AUTO_PLAY_TIMEOUT),
    []
  );

  useEffect(() => {
    if (!listenMode && forceEnd) {
      stopRecording();
      return;
    }
  }, [forceEnd]);

  useEffect(() => {
    if (STEPS[currentStepIndex].third === 'ACTIVE') {
      onEvent(nextEvent());
    }
  }, [currentStepIndex]);

  const nextStep = useCallback(
    () =>
      setCurrentStepIndex((prevIndex) => {
        if (prevIndex > 3) {
          return 3;
        }

        return prevIndex + 1;
      }),
    []
  );

  const runAudio = useCallback(() => {
    delayAutoPlaying.clear();
    setPlayAudio(true);
    nextStep();
  }, []);

  const onPlay = useCallback(
    () =>
      audioDuration &&
      stopPlayingAfter.set(() => setPause(true), audioDuration - 10_000),
    [audioDuration]
  );

  const onAudioEnded = useCallback(async () => {
    await beep.playInteractionBeginTone();
    nextStep();
    setTimerStatus(TIMER_STATUS.IN_PROGRESS);
    secondLabelRef.current?.focus();
    onEvent(audioEndedEvent());
  }, []);

  const getTimerStatusFromSecondStep = useCallback((status: TimerStatus) => {
    if (isTimerFinished(status)) {
      nextStep();
      setListenMode(false);
      startRecording();
      thirdLabelRef.current?.focus();
    }
  }, []);

  const getTimerStatusFromThirdStep = useCallback((status: TimerStatus) => {
    setTimerStatus(status);
    if (status === TIMER_STATUS.IN_PROGRESS) {
      delayAutoFocusingThirdDescription.set(
        () => thirdDescriptionRef.current?.focus(),
        2000
      );
    }
    if (isTimerFinished(status)) {
      stopRecording();
      delayAutoFocusingThirdDescription.clear();
    }
  }, []);

  return (
    <>
      <InstructionContainer>
        <Title
          centered
          id={CADE_CONTENT_TITLE_ID}
          data-testid={SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction
          level="small"
          data-testid={SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.INSTRUCTION}
        >
          {instruction ?? INSTRUCTION}
        </Instruction>
      </InstructionContainer>
      <QuestionCounter
        count={count}
        total={total}
        title={t('speakingSituations.exercise.questionCountLabel')}
      />
      <div className="speaking-situations-exercise__container">
        {STEPS[currentStepIndex].first !== 'COMPLETED' && (
          <Box role="exercise">
            <Paragraph
              data-testid={
                SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.STEP.FIRST.LABEL
              }
              tabIndex={0}
              level="large"
              weight="bold"
              className="cade-padding-bottom-3"
            >
              {steps.first.label}
            </Paragraph>
            <div className="speaking-situations-exercise-content-box__wrapper">
              <div className="speaking-situations-exercise-content-box__play-button">
                {STEPS[currentStepIndex].first === 'DISABLED' && (
                  <SecondaryButton
                    onClick={runAudio}
                    data-testid={
                      SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.PLAY_BUTTON
                    }
                  >
                    {t('buttons.play')}
                  </SecondaryButton>
                )}
              </div>
              <div className="speaking-situations-exercise-content-box">
                <TextArea.ReadBox state={STEPS[currentStepIndex].first}>
                  <Paragraph
                    level="normal"
                    data-testid={
                      SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.STEP.FIRST
                        .DESCRIPTION
                    }
                    aria-hidden={true}
                    weight={
                      STEPS[currentStepIndex].first === 'ACTIVE'
                        ? 'semibold'
                        : 'normal'
                    }
                  >
                    {steps.first.description}
                  </Paragraph>
                </TextArea.ReadBox>
              </div>
            </div>
            {playAudio && (
              <PlayAudio
                onPlay={onPlay}
                autoPlay={true}
                onPause={onAudioEnded}
                pause={pause}
                audioSrc={audioSrc}
              />
            )}
          </Box>
        )}
        {STEPS[currentStepIndex].second !== 'COMPLETED' && (
          <Box
            role="exercise"
            ariaHidden={STEPS[currentStepIndex].second === 'DISABLED'}
            className="speaking-situations-exercise__second-step"
          >
            <Paragraph
              data-testid={
                SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.STEP.SECOND.LABEL
              }
              tabIndex={0}
              ref={secondLabelRef}
              level="large"
              weight={
                STEPS[currentStepIndex].second === 'ACTIVE' ? 'bold' : 'normal'
              }
            >
              {steps.second.label}
            </Paragraph>
            <Timer
              time={steps.second.time || 20}
              timeIsUpMessageForReader={''}
              timerStatus={timerStatus}
              onChangeStatus={getTimerStatusFromSecondStep}
            />
          </Box>
        )}
        {STEPS[currentStepIndex].third !== 'COMPLETED' && (
          <Box
            role="exercise"
            ariaHidden={STEPS[currentStepIndex].third === 'DISABLED'}
          >
            <div className="speaking-situations-exercise-content-box__label">
              <Paragraph
                data-testid={
                  SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.STEP.THIRD.LABEL
                }
                ref={thirdLabelRef}
                tabIndex={0}
                level="large"
                weight={
                  STEPS[currentStepIndex].third === 'ACTIVE' ? 'bold' : 'normal'
                }
                className="cade-padding-bottom-3"
              >
                {steps.third.label}
              </Paragraph>
              <Timer
                onChangeStatus={getTimerStatusFromThirdStep}
                time={steps.third.time || 60}
                timerStatus={
                  STEPS[currentStepIndex].third === 'ACTIVE'
                    ? timerStatus
                    : TIMER_STATUS.DISABLED
                }
              />
            </div>
            <div className="speaking-situations-exercise-content-box__wrapper">
              <div className="speaking-situations-exercise-content-box">
                <TextArea.ReadBox state={STEPS[currentStepIndex].third}>
                  <Paragraph
                    data-testid={
                      SPEAKING_SITUATIONS_EXERCISE_TEST_IDS.STEP.THIRD
                        .DESCRIPTION
                    }
                    tabIndex={0}
                    ref={thirdDescriptionRef}
                    level="normal"
                    weight={
                      STEPS[currentStepIndex].third === 'ACTIVE'
                        ? 'semibold'
                        : 'normal'
                    }
                  >
                    {steps.third.description}
                  </Paragraph>
                </TextArea.ReadBox>
                <div className="cade-margin-top-4" aria-hidden={true}>
                  <TimerMessage
                    aria-live="polite"
                    show={isTimerFinished(timerStatus)}
                  />
                </div>
              </div>
            </div>
          </Box>
        )}
        <div className="voice-level-container">
          <VoiceLevelIndicator
            weCantHearYou={warningVisible}
            numOfCircles={6}
            listenMode={listenMode}
            currentValue={voiceLevel}
          />
        </div>
      </div>
    </>
  );
}
