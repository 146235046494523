import { Toc, ApiModel } from '../../api.interfaces';
import { validateWDTResponse } from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeSummaryAndOpinionModel {
  title: string;
  instruction: string;
  timeLeft: number;
  count: number;
  total: number;
  passage: string[];
  question1: string;
  question2: string;
  responseId1: string;
  responseId2: string;
  passageLabel: string;
  onEvent: (event: CadeEvent) => void;
  currentStep: number;
  totalSteps: number;
}
export function transformJSONToSummaryAndOpinion(
  json: ApiModel,
  tocArray: Toc[]
): CadeSummaryAndOpinionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.textInputParams.responseId',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, []);

  if (!validateResult) {
    throw new Error('Validation failed');
  }

  const item = json.items[0];
  const [letter, sectionName] = item.titles;
  const title = i18n.t('part', { letter, sectionName });
  const { count, total } = item.itemSequence;
  const totalSteps = tocArray.length;
  const currentStep =
    tocArray.findIndex((el) => el.itemType === item.itemType) + 1;
  const passageLabel =
    item.microInstructions[0] ||
    i18n.t('summaryAndOpinion.exercise.passageLabel');
  const instruction =
    item.cueInstructions?.at(0) ?? item.microInstructions.slice(1).join(' ');
  const display = item.actions.find((el) => el.actionType === 'display');
  const passage = display?.displayParams?.display[0] ?? [];
  const textInput = item.actions.find((el) => el.actionType === 'textinput');

  const firstMicroinstruction = item.microInstructions?.at(1);
  const question1 = firstMicroinstruction
    ? firstMicroinstruction
    : textInput?.textInputParams
    ? textInput.textInputParams[0].prompt[0]
    : '';

  const secondMicroinstruction = item.microInstructions?.at(2);
  const question2 = secondMicroinstruction
    ? secondMicroinstruction
    : textInput?.textInputParams
    ? textInput.textInputParams[1].prompt[0]
    : '';

  const responseId1 = textInput?.textInputParams
    ? textInput.textInputParams[0].responseId
    : '';
  const responseId2 = textInput?.textInputParams
    ? textInput.textInputParams[1].responseId
    : '';
  const timeLeft = textInput?.actionDuration ?? 0;

  return {
    title,
    count,
    total,
    currentStep,
    onEvent: (event: CadeEvent) => {},
    question2,
    question1,
    instruction,
    passage,
    responseId1,
    responseId2,
    totalSteps,
    timeLeft,
    passageLabel,
  };
}
