import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export const newRelicInit = () => {
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
      applicationID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
      sa: 1,
    },
    loader_config: {
      accountID: '3336676',
      trustKey: '691807',
      agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
      licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
      applicationID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
    },
  };

  new BrowserAgent(options);
};
