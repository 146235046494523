import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractActorsDataForDictation,
  extractCommonItemsForInstruction,
  ScriptLine,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';

interface CadeDictationInstructionModel {
  title: string;
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
  listenPartDuration: number;
  scripts: ScriptLine[];
  startActorsAnimationOnAudioIndex: number;
}
export function transformJSONToDictationInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeDictationInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
    'items.actions.displayParams.display',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    scripts: extractActorsDataForDictation(json),
    startActorsAnimationOnAudioIndex: 1,
    onEvent: (event: CadeEvent) => {},
    listenPartDuration: 10000,
  };
}
