import { useEffect, useRef } from 'react';

const DEFAULT_TIME = 5_000;

export const useDelay = () => {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  const clear = () => {
    clearTimeout(timeoutRef.current);
  };

  const set = (callback: Function, time = DEFAULT_TIME) => {
    timeoutRef.current = setTimeout(() => {
      callback();
      clear();
    }, time);
  };

  return { set, clear };
};
