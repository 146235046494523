import { forwardRef, PropsWithChildren, RefObject } from 'react';
import './styles.scss';

export type IParagraph = PropsWithChildren<{
  level?: 'normal' | 'small' | 'smaller' | 'tiny' | 'large'; // needed to use "level" as "size" is exitng <p> prop
  weight?: 'normal' | 'bold' | 'semibold';
  ref?: RefObject<HTMLParagraphElement>;
}> &
  React.HTMLProps<HTMLParagraphElement>;

export const Paragraph = forwardRef<HTMLParagraphElement, IParagraph>(
  function Paragraph(
    { children, level = 'normal', weight = 'normal', className, ...props },
    ref
  ) {
    return (
      <p
        {...props}
        ref={ref}
        className={`cade-paragraph cade-typography-text__${level} cade-typography--${weight} ${className}`}
      >
        {children}
      </p>
    );
  }
);
