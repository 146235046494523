import React from 'react';

export function GoogleChromeLogo() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4306_217228)">
        <path
          d="M50.001 74.9989C63.8089 74.9989 75.0021 63.8052 75.0021 49.9974C75.0021 36.1895 63.8089 24.9958 50.001 24.9958C36.1932 24.9958 25 36.1895 25 49.9974C25 63.8052 36.1932 74.9989 50.001 74.9989Z"
          fill="white"
        />
        <path
          d="M28.3491 62.5039L6.70079 25.0079C2.31129 32.6084 0 41.2304 0 50.0079C0 58.7853 2.31024 67.4073 6.69921 75.0079C11.0882 82.6084 17.401 88.9202 25.0026 93.3071C32.6042 97.694 41.2273 100.003 50.0042 100L71.6525 62.5039V62.4982C69.4598 66.3008 66.3045 69.4588 62.5039 71.6546C58.7034 73.8503 54.3918 75.0066 50.0026 75.0073C45.6134 75.0079 41.3014 73.8529 37.5003 71.6583C33.6992 69.4635 30.543 66.3066 28.3491 62.505V62.5039Z"
          fill="#229342"
        />
        <path
          d="M71.6509 62.5024L50.0031 99.9984C58.78 100 67.4026 97.6898 75.0037 93.3018C82.6047 88.9139 88.9165 82.6016 93.3044 75C97.6921 67.3985 100.001 58.7759 100 49.9989C99.9984 41.222 97.6861 32.6 93.2955 25H49.9995L49.9942 25.0037C54.3834 25.0016 58.6957 26.1552 62.4976 28.3486C66.2997 30.5423 69.4567 33.6976 71.6519 37.4987C73.8471 41.2996 75.0028 45.6116 75.0027 50.0009C75.0026 54.3902 73.8467 58.702 71.6514 62.5029L71.6509 62.5024Z"
          fill="#FBC116"
        />
        <path
          d="M50.001 69.7958C60.9323 69.7958 69.7937 60.9344 69.7937 50.0026C69.7937 39.0709 60.9323 30.21 50.001 30.21C39.0698 30.21 30.2084 39.0714 30.2084 50.0026C30.2084 60.9339 39.0698 69.7958 50.001 69.7958Z"
          fill="#1A73E8"
        />
        <path
          d="M50.001 25.0016H93.2971C88.9097 17.4 82.5984 11.0871 74.9974 6.69817C67.3965 2.30949 58.7742 -0.000666182 49.9974 1.44102e-07C41.2205 0.000525079 32.5984 2.31183 24.9984 6.70185C17.3981 11.0916 11.0876 17.405 6.70129 25.0073L28.3496 62.5034L28.3554 62.5066C26.1589 58.7064 25.0018 54.3948 25.0004 50.0055C24.9991 45.6162 26.1534 41.304 28.3475 37.5024C30.5412 33.7008 33.6981 30.5438 37.4992 28.3496C41.3002 26.1554 45.6126 25 50.0021 25.0011L50.001 25.0016Z"
          fill="#E33B2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_4306_217228">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
