import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Text } from '../../../components';
import { useMicrophoneStream } from '../../../utils/useMicrophoneStream';
import { getAudioContext } from '../../../utils';
import { ConfigContext } from '@src/context/CadeConfigProvider';

let ids: number[] = []; //TODO remove it from here
let id: any;

function useCheckingBackgroundNoise({
  offset = 30,
  refreshRate = 500,
  callback = (value: number) => {},
} = {}) {
  const micStream = useMicrophoneStream();

  useEffect(() => {
    let dbValues: number[] = [];
    let average = 0;

    let source: MediaStreamAudioSourceNode;
    let processor: ScriptProcessorNode;
    let analyser: AnalyserNode;

    const updateDb = () => {
      if (dbValues) {
        let volume = Math.round(
          dbValues.reduce((a, b) => a + b, 0) / dbValues.length
        );
        if (!isFinite(volume)) {
          volume = 0;
        }
        dbValues = [];
        callback(volume);
      }
    };

    if (micStream) {
      const audioContext = getAudioContext();
      source = audioContext.createMediaStreamSource(micStream);
      processor = audioContext.createScriptProcessor(2048, 1, 1);
      analyser = audioContext.createAnalyser();

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 256;

      source.connect(analyser);
      analyser.connect(processor);
      processor.connect(audioContext.destination);

      processor.onaudioprocess = function () {
        const data = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(data);
        let values = 0;

        for (let index = 0; index < data.length; index++) {
          values += data[index];
        }

        average = 20 * Math.log10(values / data.length) + offset;
        dbValues.push(average);
      };

      id = setInterval(updateDb, refreshRate);
      ids.push(id);
    }

    return () => {
      analyser?.disconnect();
      processor?.disconnect();
      source?.disconnect();
    };
  }, [micStream]);

  return {
    stop() {
      ids.forEach(clearInterval);
      ids = [];
    },
  };
}

export type Status = 'DISABLED' | 'INIT' | 'SUCCESS' | 'WARNING' | 'FAIL';

type MeterProps =
  | {
      statusMeter: 'DISABLED';
      decibels?: never;
      warningDecibels?: never;
      maxAcceptableDecibels?: never;
    }
  | {
      decibels: MutableRefObject<number>;
      warningDecibels: number;
      maxAcceptableDecibels: number;
      statusMeter: Status;
    };

type MeterLabelsProps = {
  variant?: Status;
};
const MeterLabels = ({ variant }: MeterLabelsProps) => {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <div
      className={classNames('cade-microphone-meter__labels', {
        [`cade-microphone-meter__labels--${variant?.toLowerCase()}`]: variant,
      })}
      aria-hidden={true}
    >
      <Text level="small">{t('backgroundNoiseCheck.meter.quited')}</Text>
      <Text level="small">{t('backgroundNoiseCheck.meter.tooLoud')}</Text>
    </div>
  );
};

export const Meter = ({
  decibels,
  warningDecibels,
  maxAcceptableDecibels,
  statusMeter,
}: MeterProps) => {
  if (statusMeter === 'DISABLED') {
    return (
      <>
        <MeterLabels variant="DISABLED" />
        <div
          className="cade-microphone-meter cade-microphone-meter--disabled"
          aria-hidden={true}
        />
      </>
    );
  }

  const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
  useEffect(() => {
    const interval = setInterval(() => forceUpdate(), 50);
    return () => clearInterval(interval);
  }, []);

  const quietState =
    decibels.current === 0 || decibels.current < warningDecibels;
  const loudState =
    decibels.current >= warningDecibels &&
    decibels.current < maxAcceptableDecibels;
  const tooLoudState = decibels.current >= maxAcceptableDecibels;

  const cssClassesMeter = classNames('cade-microphone-meter', {
    'cade-microphone-meter--quiet':
      statusMeter !== 'INIT' && (quietState || statusMeter === 'SUCCESS'),
    'cade-microphone-meter--loud':
      statusMeter !== 'INIT' && (loudState || statusMeter === 'WARNING'),
    'cade-microphone-meter--too-loud':
      statusMeter !== 'INIT' && (tooLoudState || statusMeter === 'FAIL'),
  });

  const cssClassesElement = classNames('cade-microphone-meter__element', {
    'cade-microphone-meter__element--quiet':
      quietState || statusMeter === 'SUCCESS',
    'cade-microphone-meter__element--loud':
      loudState || statusMeter === 'WARNING',
    'cade-microphone-meter__element--too-loud':
      tooLoudState || statusMeter === 'FAIL',
  });

  return (
    <div className={cssClassesMeter}>
      <div
        className={cssClassesElement}
        style={{ width: `${(decibels.current * 10) / 5}%` }}
      />
    </div>
  );
};

export type MicrophoneMeterProps = {
  status: (status: Status) => void;
  maxAcceptableDecibels: number;
  warningDecibels: number;
  onEnd?: () => void;
};

function MicrophoneMeterComponent({
  status,
  maxAcceptableDecibels,
  warningDecibels,
  onEnd = () => {},
}: MicrophoneMeterProps) {
  const decibelValue = useRef(0);
  const averageDecibelsValue = useRef(0);
  const decibels = useRef<number[]>([]);
  const [statusMeter, setStatusMeter] = useState<Status>('INIT');
  const { stop } = useCheckingBackgroundNoise({
    callback: (value) => {
      decibels.current.push(value);
      decibelValue.current = value;
    },
    refreshRate: 50,
    offset: 1,
  });

  useEffect(() => {
    if (statusMeter !== 'INIT') {
      stop();
    }
  }, [statusMeter]);

  useEffect(() => {
    let sumOfDecibels = 0;
    let amountOfSamples = 1;
    let intervalHandle = setInterval(() => {
      const currentDecibels =
        decibels.current.reduce((a, b) => a + b, 0) / decibels.current.length;
      decibels.current = [];
      if (currentDecibels > 0) {
        sumOfDecibels += currentDecibels;
        amountOfSamples++;
      }
    }, 1000);

    let id = setTimeout(() => {
      let decibelsStatus: Status;
      const averageDecibels = sumOfDecibels / amountOfSamples;
      if (averageDecibels < warningDecibels) {
        decibelsStatus = 'SUCCESS';
      } else if (
        averageDecibels >= warningDecibels &&
        averageDecibels < maxAcceptableDecibels
      ) {
        decibelsStatus = 'WARNING';
      } else {
        decibelsStatus = 'FAIL';
      }

      onEnd();
      averageDecibelsValue.current = averageDecibels;
      status(decibelsStatus);
      setStatusMeter(decibelsStatus);
      clearTimeout(id);
      clearInterval(intervalHandle);
    }, 10000);

    return () => clearTimeout(id);
  }, []);

  const decibelsValue =
    statusMeter !== 'INIT' ? averageDecibelsValue : decibelValue;

  return (
    <div className="cade-microphone-meter__container">
      <MeterLabels />
      <Meter
        decibels={decibelsValue}
        statusMeter={statusMeter}
        maxAcceptableDecibels={maxAcceptableDecibels}
        warningDecibels={warningDecibels}
      />
    </div>
  );
}

/*
  We need to use a `memo` functionality for now because without there were bugs related to the rendering cycle
  and the component worked in an unexpected way.
   In the future, we should take a look closer at this component and refactor it.
 */
export const MicrophoneMeter = React.memo(MicrophoneMeterComponent);
