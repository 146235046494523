import { FC, PropsWithChildren, useEffect } from 'react';
import {
  clearEvents,
  confirmBeforeCloseApp,
  disableCtrlA,
  disableCtrlAltCmd,
  disableDropEvent,
  disableF5,
  disablePasteEvent,
} from '../utils/utils';

export const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    disablePasteEvent();
    disableDropEvent();
    disableCtrlAltCmd();
    disableCtrlA();
    disableF5();
    confirmBeforeCloseApp();

    return () => {
      clearEvents();
    };
  }, []);

  return <>{children}</>;
};
