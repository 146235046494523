import { useNavigate } from 'react-router-dom';
import { vfwRoutes } from '../../vfw-routes';
import { Template } from '../../components/Template/Template';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import React, { useCallback, useRef, useState } from 'react';
import { CheckboxRef, Input, Paragraph } from '@versant-monorepo/cade';
import './styles.scss';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { ContentTitle } from 'components/ContentTitle/ContentTitle';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useExitModal } from '../../hooks/useModal';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { Trans, useTranslation } from 'react-i18next';
import { useCheckProgress } from 'hooks/useCheckProgress';
import { passCheck } from 'utils/prechecks';

const i18nComponents = {
  br: <br />,
  a: <a />,
};

export const ConsentPageBiometricData = () => {
  const inputRef = useRef<CheckboxRef>(null);
  const navigate = useNavigate();
  const { pushEvent } = useLogger();
  const exitModal = useExitModal();

  useFocus(CONTENT_TITLE_ID);
  const { t } = useTranslation();
  useSetDocumentTitle(t('consentPageBiometricData.documentTitle'));
  useCheckProgress('consentPageBiometricData');

  const [checked, setChecked] = useState(false);

  const onExit = useCallback(() => {
    pushEvent({
      level: LogLevel.INFO,
      item: 'Consent Page Biometric Data Page',
      message: 'Exit button clicked on Consent Page Biometric Data',
    });
    exitModal.show('PRE_TEST');
  }, [exitModal, pushEvent]);

  const onNext = useCallback(() => {
    pushEvent({
      level: LogLevel.INFO,
      item: 'Consent Page Biometric Data Page',
      message: 'Next clicked on Consent Page Biometric Data',
    });
    passCheck('consentPageBiometricData');
    navigate(vfwRoutes.takeASelfie);
  }, [navigate, pushEvent]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setChecked(event.target.checked),
    []
  );

  const onBlur = useCallback(() => inputRef?.current?.checkboxRef?.focus(), []);

  return (
    <>
      <Template
        header={<Header onExit={onExit} title={''} />}
        footer={
          <Footer
            nextButtonEnabled={checked}
            onNext={onNext}
            role="check"
            buttonLabel={t('consentPageBiometricData.button')}
          />
        }
      >
        <div className="consent-page-biometric-data">
          <ContentTitle centered={true} level="large" onBlur={onBlur}>
            {t('consentPageBiometricData.title')}
          </ContentTitle>
          <div className="consent-page-biometric-data__box-container">
            <div className="consent-page-biometric-data__content">
              <Paragraph level="normal" weight={'bold'}>
                {t('consentPageBiometricData.subtitle1')}
              </Paragraph>
              <br />
              <Paragraph level="small">
                {t('consentPageBiometricData.content1')}
              </Paragraph>
              <br />
              <Paragraph level="normal" weight="bold">
                {t('consentPageBiometricData.subtitle2')}
              </Paragraph>
              <br />
              <div className="consent-page-biometric-data__box">
                <Paragraph level="small">
                  <Trans
                    i18nKey="consentPageBiometricData.content2"
                    components={i18nComponents}
                  />
                </Paragraph>
              </div>
              <div className="consent-page-biometric-data__confirm">
                <Input.Checkbox
                  ref={inputRef}
                  onChange={onChange}
                  className="consent-page-biometric-data__checkbox"
                >
                  {t('consentPageBiometricData.confirmation')}
                </Input.Checkbox>
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
};
