import { Template } from '../../components/Template/Template';
import {
  Box,
  Paragraph,
  PrimaryButton,
  TextButton,
} from '@versant-monorepo/cade';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import './styles.scss';
import { MicrophoneIcon } from '../../components/MicrophoneIcon/MicrophoneIcon';
import { ContentTitle } from 'components/ContentTitle/ContentTitle';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { useState } from 'react';
import { AudioHelpModal } from './AudioHelpModal';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import { useExitTest } from '../../hooks/useExitTest';

export function ProblemWithAudioWrapper() {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const exitTest = useExitTest();
  useSetDocumentTitle(t('problemWithAudioWrapper.documentTitle'));
  useFocus(CONTENT_TITLE_ID, []);

  const { pushEvent } = useLogger();

  async function hardExit() {
    pushEvent({
      item: 'Problem with audio',
      level: LogLevel.INFO,
      message: 'There is problem with audio',
      eventType: 'ERROR',
    });
    exitTest({ hard: true });
  }

  return (
    <Template
      header={<Header onExit={hardExit} title={''} />}
      footer={
        <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
      }
    >
      <div className="problem-with-audio__container">
        <div className="problem-with-audio__title-container">
          <ContentTitle
            className="cade-margin-bottom-4 problem-with-audio__red"
            level="large"
          >
            {t('problemWithAudioWrapper.title')}
          </ContentTitle>
          <Paragraph className="cade-margin-bottom-6" level="large">
            {t('problemWithAudioWrapper.p')}
          </Paragraph>
        </div>
        <Box role="instruction" className="problem-with-audio__box">
          <div className="problem-with-audio__box-container">
            <div className="problem-with-audio__icon problem-with-audio__red">
              <MicrophoneIcon />
            </div>
            <div className="problem-with-audio__buttons">
              <TextButton onClick={() => setOpenModal(true)}>
                {t('problemWithAudioWrapper.help')}
              </TextButton>
              <PrimaryButton onClick={hardExit}>
                {t('problemWithAudioWrapper.exit')}
              </PrimaryButton>
            </div>
          </div>
        </Box>
      </div>
      <AudioHelpModal open={openModal} onClose={() => setOpenModal(false)} />
    </Template>
  );
}
