import { Box, Paragraph, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import {
  audioEndedEvent,
  CadeEvent,
  timerEndedEvent,
  useEndedEvent,
} from '@utils/events';
import { Listening } from '@components/Listening';
import {
  isListeningActive,
  ListeningStatus,
} from '@components/Listening/commons';
import { useDelay } from '@utils/useDelay';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { Selection } from '@components/Selection';
import './styles.scss';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerStatus,
} from '@components/base/Timer';
import { useBeep } from '@utils/useBeep';
import classNames from 'classnames';
import { useVolume } from '@context/Volume.context';
import { VoiceLevelIndicator } from '@src/components/VoiceLevelIndicator';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

type Props = {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: any;
  leftLabel: string;
  rightLabel: string;
  options: string[];
  timeLeft: number;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  instruction?: string;
};

export function ResponseSelectionExercise({
  subTitle,
  count,
  total,
  leftLabel,
  rightLabel,
  audioSrc,
  options,
  timeLeft,
  onEvent,
  forceEnd = false,
  instruction,
}: Props) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const [enableSelection, setEnableSelection] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState('');
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );
  const [leftHeaderHeight, setLeftHeaderHeight] = useState<number>(0);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const activePart: 'LISTENING' | 'SELECTION' | 'NONE' = isListeningActive(
    listeningStatus
  )
    ? 'LISTENING'
    : enableSelection
    ? 'SELECTION'
    : 'NONE';
  const delay = useDelay();
  const beep = useBeep();
  const { sendEndEvent } = useEndedEvent<string>(onEvent);

  const { state } = useVolume();
  const voiceLevel = state.microphoneOutputValue;

  useEffect(() => {
    delay.set(playAudio, EXERCISE_AUTO_PLAY_TIMEOUT);
  }, []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent(currentOption);
    }
  }, [forceEnd]);

  function playAudio() {
    delay.clear();
    setListeningStatus('PLAYING');
    setTimerStatus(TIMER_STATUS.INIT);
  }

  async function onAudioEnded() {
    setListeningStatus('COMPLETED');
    setEnableSelection(true);
    await beep.playInteractionBeginTone();
    setTimerStatus(TIMER_STATUS.IN_PROGRESS);
    onEvent(audioEndedEvent());
  }

  function onChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    setCurrentOption(event.target.value);
  }

  const onChangeStatus = useCallback(
    (status: TimerStatus) => {
      setTimerStatus(status);
      if (isTimerFinished(status)) {
        onEvent(timerEndedEvent());
        delay.set(() => {
          sendEndEvent(currentOption);
        });
      }
    },
    [currentOption]
  );

  const selectionOptions = options.map((option) => ({
    value: option,
    label: option.toLocaleUpperCase(),
  }));

  const rightHeaderDivRef = useCallback((node: HTMLDivElement | null) => {
    setLeftHeaderHeight(node?.clientHeight ?? 0);
  }, []);

  return (
    <>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level="small">
          {instruction ?? t('responseSelection.exercise.instruction')}
        </Instruction>
      </InstructionContainer>
      <div className="response-selection-exercise__upper-section">
        <QuestionCounter
          count={count}
          total={total}
          title={t('responseSelection.exercise.questionCountLabel')}
        />
        <div className="response-selection-exercise__timer">
          {timerStatus === TIMER_STATUS.FINISHED && (
            <Paragraph className={'response-selection-exercise__red-msg'}>
              {t('timer.timeIsOver')}
            </Paragraph>
          )}
          <Timer
            time={timeLeft}
            onChangeStatus={onChangeStatus}
            timerStatus={timerStatus}
            variant={TIMER_VARIANT.PRIMARY}
          />
        </div>
      </div>
      <Box role="exercise">
        <DualPaneLayout
          leftPane={
            <>
              <Paragraph
                className={classNames(
                  'response-selection-exercise__listen-paragraph'
                )}
                level={'large'}
                weight={activePart === 'SELECTION' ? 'normal' : 'bold'}
                style={{ minHeight: leftHeaderHeight }}
              >
                {leftLabel}
              </Paragraph>
              <Listening
                audioSrc={audioSrc}
                role="exercise"
                onClickPlay={playAudio}
                status={listeningStatus}
                onAudioEnded={() => onAudioEnded()}
              />
            </>
          }
          rightPane={
            <>
              <div
                ref={rightHeaderDivRef}
                className="response-selection-exercise__selection-header"
              >
                <Paragraph
                  level={'large'}
                  weight={activePart === 'SELECTION' ? 'bold' : 'normal'}
                >
                  {rightLabel}
                </Paragraph>
              </div>
              <Box
                className="response-selection-exercise__selection-box"
                role="instruction"
                withoutShadow={true}
              >
                <Selection
                  readonly={activePart === 'NONE' || timerStatus === 'FINISHED'}
                  onChangeValue={onChangeValue}
                  name="responseselection"
                  options={selectionOptions}
                  state={activePart === 'NONE' ? 'DISABLED' : 'ACTIVE'}
                />
              </Box>
            </>
          }
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          weCantHearYou={false}
          numOfCircles={6}
          listenMode={true}
          currentValue={voiceLevel}
        />
      </div>
    </>
  );
}
