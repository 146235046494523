export function PlayIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19562 2.16869L22.0592 10.4487C22.9164 10.8961 23.2484 11.9533 22.8007 12.81C22.6351 13.1269 22.3763 13.3856 22.0592 13.5511L6.1956 21.8308C5.46087 22.2143 4.55419 21.9299 4.17049 21.1956C4.05849 20.9813 4 20.743 4 20.5012L4 3.49829C4 2.66986 4.67195 1.99829 5.50085 1.99829C5.7428 1.99829 5.98116 2.05675 6.19562 2.16869Z"
        fill="#676767"
      />
    </svg>
  );
}
