import React, { useState, useRef, FC, PropsWithChildren } from 'react';
import './styles.scss';

export interface AccordionProps {
  id: string;
  label: string;
  expanded: boolean;
  className?: string;
  maxHeight?: number;
  onChange?: (id: string) => void;
}

export const ACCORDION_TEST_ID = 'cade-accordion';
export const ACCORDION_HEADER_TEST_ID = 'cade-accordion-header';
export const ACCORDION_CONTENT_TEST_ID = 'cade-accordion-content';

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
  id,
  label,
  className = '',
  maxHeight,
  expanded = false,
  onChange,
  children,
}) => {
  const accordionRef = useRef<HTMLDivElement | null>(null);

  const toggleAccordion = () => {
    onChange?.(id);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.key === 'Enter' || event.key === ' ') && accordionRef.current) {
      toggleAccordion();
    } else if (event.key === 'ArrowDown' && accordionRef.current) {
      const nextSibling =
        accordionRef.current?.nextElementSibling?.querySelector(
          '.cade-accordion__header'
        ) as HTMLElement | null;
      if (nextSibling) {
        nextSibling.focus();
      }
    } else if (event.key === 'ArrowUp' && accordionRef.current) {
      const prevSibling =
        accordionRef.current?.previousElementSibling?.querySelector(
          '.cade-accordion__header'
        ) as HTMLElement | null;
      if (prevSibling) {
        prevSibling.focus();
      }
    } else if (event.key === 'Home') {
      const firstAccordion = document.querySelector(
        '.cade-accordion:first-child .cade-accordion__header'
      ) as HTMLElement | null;
      if (firstAccordion) {
        firstAccordion.focus();
      }
    } else if (event.key === 'End') {
      const lastAccordion = document.querySelector(
        '.cade-accordion:last-child .cade-accordion__header'
      ) as HTMLElement | null;
      if (lastAccordion) {
        lastAccordion.focus();
      }
    }
  };

  const accordionId = `cade-accordion-${label
    .replace(/\s+/g, '-')
    .toLowerCase()}`;
  const contentId = `content-${accordionId}`;

  return (
    <div
      data-testid={ACCORDION_TEST_ID}
      ref={accordionRef}
      className={`cade-accordion ${className}`}
      aria-expanded={expanded}
    >
      <div
        data-testid={ACCORDION_HEADER_TEST_ID}
        className="cade-accordion__header"
        tabIndex={0}
        role="button"
        onClick={toggleAccordion}
        aria-controls={contentId}
        onKeyDown={
          handleKeyDown as (event: React.KeyboardEvent<HTMLDivElement>) => void
        }
      >
        <div className="cade-accordion__header__label">{label}</div>
        <div
          className={`cade-accordion__icon ${
            expanded ? 'cade-accordion__icon--expanded' : ''
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>
      <div
        data-testid={ACCORDION_CONTENT_TEST_ID}
        style={{ maxHeight: expanded ? maxHeight || 300 : 0 }}
        aria-hidden={!expanded}
        id={contentId}
        role={'region'}
        aria-labelledby={accordionId}
        className={`cade-accordion__content`}
      >
        {children}
      </div>
    </div>
  );
};
