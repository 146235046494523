import { useEffect, useRef, useState } from 'react';
import { formatMinutes, formatSeconds } from './utils';

const useTimerHandler = (initTime: number, onTimeIsUp: () => void) => {
  const [timeCounter, setTimeCounter] = useState<number>(initTime);
  const intervalId = useRef<NodeJS.Timer>();

  const startCountdown = () => {
    intervalId.current = setInterval(() => {
      setTimeCounter((prevTimer) => {
        let nextTimer = prevTimer - 1;
        if (nextTimer < 0) {
          nextTimer = 0;
        }
        return nextTimer;
      });
    }, 1000);
  };

  // use effect is needed as doing onTimeIsUp() inside interval
  // creates an React error about setState made during rendering cycle of another component
  useEffect(() => {
    if (timeCounter === 0) {
      clearInterval(intervalId.current);
      onTimeIsUp();
    }
  }, [timeCounter]);

  useEffect(() => {
    return () => clearInterval(intervalId.current);
  }, []);

  const stopTimer = () => {
    clearInterval(intervalId.current);
  };

  const startTimer = (newTime?: number) => {
    setTimeCounter(newTime ?? initTime);
    clearInterval(intervalId.current);
    startCountdown();
  };

  return {
    timeCounter,
    time: formatMinutes(timeCounter) + ' : ' + formatSeconds(timeCounter),
    startTimer,
    stopTimer,
  };
};

export default useTimerHandler;
