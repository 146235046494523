import { useContext, useEffect } from 'react';
import { MicrophoneContext } from '../context/Microphone.context';

export function useMicrophoneStream() {
  const { microphoneId, stream, setStream } = useContext(MicrophoneContext);

  useEffect(() => {
    if (stream == null) {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: { exact: microphoneId } } })
        .then(setStream);
    }
  }, []);

  return stream;
}
