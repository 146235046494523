import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  checkTOCArray,
  extractAudioResourcesFromResponse,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';

interface CadeTocModel {
  toc?: Toc[];
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
}
export function transformJSONToTOC(json: ApiModel | any): CadeTocModel {
  const keysToCheckInItems = [
    'items.actions',
    'items.actions.playParams.url',
    'items.toc',
    'items.toc.titles',
    'items.toc.itemCount',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
    () => checkTOCArray(json.items[0].toc as Toc[]),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const toc = item.toc;
  const audioSrc = extractAudioResourcesFromResponse(json);
  return {
    toc,
    audioSrc,
    onEvent: (event: CadeEvent) => {},
  };
}
