import { ApiModel, Toc } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForExercise,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';

type SpeakingSituationsStep = {
  label: string;
  description?: string;
  time?: number;
};

interface CadeSpeakingSituationsExerciseModel {
  title: string;
  subTitle: string;
  currentStep: number;
  totalSteps: number;
  onEvent: (event: CadeEvent) => void;
  count: number;
  total: number;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  responseId?: string;
  steps: {
    first: SpeakingSituationsStep;
    second: SpeakingSituationsStep;
    third: SpeakingSituationsStep;
  };
  instruction?: string;
}
export function transformJSONToSpeakingSituations(
  json: ApiModel,
  tocArray: Toc[]
): CadeSpeakingSituationsExerciseModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);

  if (!validateResult) {
    throw new Error('Validation failed');
  }

  const [firstLabel, secondLabel, thirdLabel] = json.items[0].microInstructions;
  const description = json.items[0]?.actions[0]?.displayParams?.display[0][0];
  const time = json.items[0].actions[2].actionDuration;

  const steps = {
    first: {
      label: firstLabel,
      description,
    },
    second: {
      label: secondLabel,
      time: 10,
    },
    third: {
      label: thirdLabel,
      description,
      time,
    },
  };
  const instruction = json.items[0].cueInstructions?.at(0);

  return {
    ...extractCommonItemsForExercise(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    steps,
    timeouts: {
      speaking: time,
      ending: time,
      initial: time,
    },
    instruction,
  };
}
