import React, { ChangeEvent, useEffect, useState } from 'react';
import './styles.scss';
import {
  FEATURE_FLAG_STORAGE_NAME,
  Input,
  NextButton,
  Paragraph,
  Title,
} from '@versant-monorepo/cade';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { vfwRoutes } from '../../vfw-routes';
import { VfwStorage } from '../../utils/Storage';
import { useApi } from '../../hooks/useApi';
import { Header, HEADER_VERSANT_LOGO_ID } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { useFocus } from '../../hooks/useFocus';
import { CookieBanner } from '../../components/CookieBanner';
import { LoginFooter } from '../../components/LoginFooter/LoginFooter';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useTranslation } from 'react-i18next';
import { generateChecks } from '../../utils/prechecks';
import { useLogger } from 'hooks/useLogger';
import { LogLevel } from 'api/Logger';
import { serializeError } from '../../api/api.utils';

const isQuirksEnabled = process.env.REACT_APP_DEV_QUIRKS === 'true';
const isRecaptchaEnabled = !!process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function initRecaptcha(callback: () => void = () => {}) {
  if (!isRecaptchaEnabled) {
    return;
  }
  grecaptcha.enterprise.ready(function () {
    grecaptcha.enterprise.render('recaptcha', {
      sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      badge: 'inline',
      size: 'invisible', // yes this is crazy, but without this it does not work
      /**
       * Bigger explanation: by default enterprise recaptcha is using V3 version of normal recaptcha
       * which is invisible, showing badge for it is not std flow and it is not included in any documentation (sick)
       * so if we want to show a badge we need to make a hack and put size="invisible" as this indicated we use score based
       * recaptcha which by default is not accessible by the user
       * Solution can be found here - https://stackoverflow.com/a/53620039
       */
    });
    callback();
  });
}

function generateToken() {
  if (!isRecaptchaEnabled) {
    return;
  }
  return grecaptcha.enterprise.execute(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    { action: 'submit' }
  );
}

export const LOGIN_TEST_IDS = {
  TITLE: 'vfw-login-title',
  RECAPTCHA: 'vfw-login-recaptcha',
  CONTAINER: 'vfw-login-container',
  INPUT: 'vfw-login-input',
  NEXT_BUTTON: 'vfw-login-next-button',
  ERROR_MESSAGE: 'vfw-login-error-message',
};

export const Login = () => {
  const adapterWrapper = useApi();
  const { getSessionInfo } = useApi();
  const [urlParams] = useSearchParams();
  const [tin, setTin] = useState(urlParams.get('tin') ?? '');
  const [isRecaptchaReady, setRecaptchaReady] = useState<boolean>(false);
  const { pushEvent } = useLogger();

  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useSetDocumentTitle('Login');

  useEffect(() => {
    initRecaptcha(() => {
      setRecaptchaReady(true);
    });
    VfwStorage.clearAll(
      isQuirksEnabled ? { exclude: [FEATURE_FLAG_STORAGE_NAME] } : undefined
    );

    sessionStorage.clear();
  }, []);

  useFocus(HEADER_VERSANT_LOGO_ID, []);

  const login = async () => {
    try {
      if (!tin) {
        setError(t('login.tinError'));
        return;
      }
      const recaptchaToken = await generateToken();
      if (!recaptchaToken && isRecaptchaEnabled) {
        setError(t('login.recaptchaError'));
        return;
      }
      setError('');
      const data = await adapterWrapper.login(
        +tin,
        recaptchaToken ?? undefined
      );
      if (data.resume) {
        if (data.isProctored) {
          try {
            await getSessionInfo();
            VfwStorage.setItem('isTestResumed', true);
          } catch (e) {
            pushEvent({
              level: LogLevel.INFO,
              message: `Proctored test without proctoring session resumed, application will treat test as non resumed: ${serializeError(
                e
              )}`,
              item: 'Login',
              eventType: 'INFO',
            });
            VfwStorage.setItem('isTestResumed', false);
          }
        } else {
          VfwStorage.setItem('isTestResumed', true);
        }
        navigate(vfwRoutes.readyToContinue);
      } else {
        navigate(vfwRoutes.landingPage);
      }
      generateChecks();
    } catch (e: any) {
      const errorType = e?.error ?? 'InvalidTin';
      switch (errorType) {
        case 'UsedTin':
          setError(t('login.tinUsedError'));
          break;
        case 'InvalidRecaptcha':
          setError(t('login.invalidRecaptchaError'));
          break;
        default:
        case 'InvalidTin':
          setError(t('login.invalidTinError'));
          break;
      }
    }
  };

  const onChangeHandler = (value: string) => {
    setError(undefined);
    setTin(value);
  };

  return (
    <Template
      footer={<LoginFooter nextButtonVisible={false} onNext={() => {}} />}
      header={
        <Header
          role={'instruction'}
          closeButtonVisible={false}
          title={''}
          onExit={() => {}}
        />
      }
    >
      <div className="login">
        {error && (
          <div
            className={'sr-only'}
            role={'alert'}
            aria-live={'polite'}
            aria-atomic={true}
          >
            {error}
          </div>
        )}
        <div className="login__title-container">
          <Title
            centered={true}
            level={'large'}
            data-testid={LOGIN_TEST_IDS.TITLE}
          >
            {t('login.welcome')}
          </Title>
        </div>
        <div
          className="login__content-container"
          data-testid={LOGIN_TEST_IDS.CONTAINER}
        >
          <div className="login__content-container--left">
            <Paragraph weight={'bold'}>{t('login.p')}</Paragraph>
            <Paragraph>{t('login.p2')}</Paragraph>
            {isRecaptchaEnabled && <div id="recaptcha"></div>}
          </div>
          <div className="login__content-container--right">
            <div className={'login__input-wrapper'}>
              <label htmlFor="{'tinInput'}">{t('login.enterTin')}</label>
              <Input.Text
                data-testid={LOGIN_TEST_IDS.INPUT}
                strictMode={false}
                className={error && 'error-state'}
                id={'tinInput'}
                value={tin}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChangeHandler(event.target.value)
                }
                onKeyDown={(evt: any) => evt.key === 'Enter' && login()}
                placeholder={t('login.inputPlaceholder')}
              />
              {error && (
                <Paragraph
                  className={'error'}
                  data-testid={LOGIN_TEST_IDS.ERROR_MESSAGE}
                >
                  {error}
                </Paragraph>
              )}
            </div>
            <NextButton
              data-testid={LOGIN_TEST_IDS.NEXT_BUTTON}
              onClick={login}
              disabled={isRecaptchaEnabled && !isRecaptchaReady}
            >
              {t('login.validateTin')}
            </NextButton>
          </div>
        </div>
      </div>
      <CookieBanner />
    </Template>
  );
};
