import './styles.scss';
import { useContext, useEffect, useState } from 'react';
import { Box, Paragraph, Title } from '@src/components';
import { Typeahead } from '@components/Typeahead';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Space } from '@components/Space';
import { PlayAudio } from '@components/PlayAudio';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Props = {
  subTitle: string;
  radioOptions?: string[];
  radioQuestion?: string;
  radioQuestionResponseId?: string;
  inputQuestion?: string;
  inputQuestionResponseId?: string;
  options: { label: string; value: string }[];
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  cueInstructions?: string[];
  audioSrc: string[];
};

type SurveyFormEndEventValue = {
  radioResponse: string;
  radioResponseId: string;
  inputResponse: string;
  inputQuestionResponseId: string;
};

export function SurveyForm({
  radioQuestion,
  subTitle,
  inputQuestion,
  radioOptions,
  radioQuestionResponseId,
  inputQuestionResponseId,
  cueInstructions,
  options,
  onEvent,
  forceEnd = false,
  audioSrc,
}: Props) {
  const [option, setOption] = useState('');
  const [text, setText] = useState('');
  const { sendEndEvent } = useEndedEvent<SurveyFormEndEventValue>(onEvent);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (forceEnd) {
      const response = {
        radioResponse: option,
        radioResponseId: radioQuestionResponseId as string,
        inputResponse: text,
        inputQuestionResponseId: inputQuestionResponseId as string,
      };
      sendEndEvent(response);
    }
  }, [forceEnd]);

  const generateRadioOptions = () => {
    if (!radioOptions) {
      return;
    }

    return radioOptions.map((el, index) => {
      return (
        <div className={'cade-optional-questions__radio-wrapper'}>
          <input
            onChange={() => setOption(el)}
            id={`radio-${el}`}
            name={'survey-radio'}
            type="radio"
            aria-label={el}
            value={el}
            key={el}
            className={'cade-optional-questions__radio'}
          />
          <label
            htmlFor={`radio-${el}`}
            aria-hidden={true}
            className={
              'cade-optional-questions__radio-label cade-typography-text__normal'
            }
          >
            {el}
          </label>
        </div>
      );
    });
  };

  const onOptionSelected = (option: { label: string; value: string }) => {
    setText(option.value);
  };

  let instructions = [
    t('surveyForm.p'),
    t('surveyForm.p2'),
    t('surveyForm.p3'),
    t('surveyForm.p4'),
  ];
  if (cueInstructions && cueInstructions?.length > 1) {
    instructions = [
      cueInstructions[0],
      ...cueInstructions[1].split('.').map((e) => `${e}.`),
    ];
  }

  return (
    <div className="cade-optional-questions-wrapper">
      <Title centered id={CADE_CONTENT_TITLE_ID} level={'large'}>
        {subTitle}
      </Title>
      <Paragraph aria-hidden={true} level={'large'}>
        {instructions[0]}
      </Paragraph>
      <Paragraph aria-hidden={true} level={'large'}>
        {instructions[1]}
      </Paragraph>
      <Paragraph aria-hidden={true} level={'large'}>
        {instructions[2]}
      </Paragraph>
      <Box
        role={'instruction'}
        className={'cade-optional-questions__content-container'}
      >
        <div className="cade-optional-questions__content-container--left">
          <fieldset className={'cade-optional-questions__fieldset'}>
            <legend className={'cade-optional-questions__legend'}>
              {radioQuestion}
            </legend>
            <Box role={'instruction'} withoutShadow={true}>
              <Space className="cade-optional-questions__radio-section-options">
                {generateRadioOptions()}
              </Space>
            </Box>
          </fieldset>
        </div>
        <Space className="cade-optional-questions__content-container--right">
          <label
            htmlFor={'typeahead-input'}
            className={'cade-optional-questions__typeahead-label'}
          >
            {inputQuestion}
          </label>
          <Typeahead
            inputId={'typeahead-input'}
            options={options}
            onOptionSelected={onOptionSelected}
            minimumLetterCount={0}
          />
        </Space>
      </Box>
      <Paragraph level={'large'} aria-hidden={true}>
        {instructions[3]}
      </Paragraph>
      <PlayAudio audioSrc={audioSrc} autoPlay={true} />
    </div>
  );
}
