import { useCallback, useEffect, useRef, useState } from 'react';
import { getAudioContext } from '../../utils';

export type TimeoutKind =
  | 'initial_silence_timeout'
  | 'max_time_timeout'
  | 'ending_silence_timeout'
  | 'cant_hear_you_silence_timeout'
  | 'cant_hear_you_silence_timeout_canceled';

export const useTimeBasedVolumeAnalyzer = (
  timeouts: {
    initialSilence: number;
    endingSilence: number;
    cantHearYouSilence?: number;
    max: number;
  },
  onTimeout: (kind: TimeoutKind) => void,
  stream?: MediaStream,
  minWorkingTimeS = 0,
  frequencyMs = 100,
  volumeThreshold = 30
) => {
  const [audioAnalyser, setAudioAnalyser] = useState<AnalyserNode>();
  const [running, setRunning] = useState(false);
  const uintArrayRef = useRef<Uint8Array>();
  const internalTimer = useRef(0);
  const silenceTimer = useRef(0);
  const [hasUserSpoken, setUserSpoken] = useState<boolean>(false);
  const [analyzerBuffer, setAnalyzerBuffer] = useState<Float32Array>(
    new Float32Array(512)
  );
  const [isWarningShown, setWarningShown] = useState<boolean>(false);
  const startAnalysis = useCallback(() => setRunning(true), [setRunning]);
  const stopAnalysis = useCallback(() => setRunning(false), [setRunning]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (!running) {
      internalTimer.current = 0;
      silenceTimer.current = 0;
      setWarningShown(false);
    }

    if (running) {
      interval = setInterval(() => {
        if (
          silenceTimer.current === 0 &&
          internalTimer.current > minWorkingTimeS * 1000
        ) {
          //Some time passed and user is speaking
          setUserSpoken(true);

          if (isWarningShown) {
            onTimeout('cant_hear_you_silence_timeout_canceled');
            setWarningShown(false);
          }
        }

        if (
          internalTimer.current > minWorkingTimeS * 1000 &&
          silenceTimer.current >= timeouts.endingSilence * 1000 &&
          hasUserSpoken
        ) {
          onTimeout('ending_silence_timeout');
          stopAnalysis();
        }

        if (
          internalTimer.current > minWorkingTimeS * 1000 &&
          silenceTimer.current >= (timeouts.cantHearYouSilence ?? 4) * 1000 &&
          !hasUserSpoken &&
          !isWarningShown
        ) {
          onTimeout('cant_hear_you_silence_timeout');
          setWarningShown(true);
        }

        if (
          internalTimer.current > minWorkingTimeS * 1000 &&
          silenceTimer.current >= timeouts.initialSilence * 1000 &&
          !hasUserSpoken
        ) {
          stopAnalysis();
          onTimeout('initial_silence_timeout');
        }

        if (internalTimer.current >= timeouts.max * 1000) {
          stopAnalysis();
          onTimeout('max_time_timeout');
        }

        if (uintArrayRef.current && audioAnalyser) {
          audioAnalyser.getFloatTimeDomainData(analyzerBuffer);

          let sumSquares = 0.0;
          // @ts-ignore
          for (const amplitude of analyzerBuffer) {
            sumSquares += amplitude * amplitude;
          }

          const average =
            6.7 /* value to be adjusted */ *
            (100 * Math.sqrt(sumSquares / analyzerBuffer.length));
          let averageVolumeDB = Math.min(average, 100);

          internalTimer.current += frequencyMs;
          if (averageVolumeDB <= volumeThreshold) {
            silenceTimer.current += frequencyMs;
          } else {
            silenceTimer.current = 0;
          }
        }
      }, frequencyMs);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [audioAnalyser, running, hasUserSpoken, isWarningShown]);

  useEffect(() => {
    let microphone: MediaStreamAudioSourceNode;
    let analyser: AnalyserNode;
    if (stream) {
      const audioContext = getAudioContext();
      microphone = audioContext.createMediaStreamSource(stream);
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 512; // power of 2, bins
      setAnalyzerBuffer(new Float32Array(analyser.fftSize));
      const bufferLength = analyser.frequencyBinCount; // number of frequency bins, fftSize/2
      uintArrayRef.current = new Uint8Array(bufferLength);
      microphone.connect(analyser);
      setAudioAnalyser(analyser);
    }

    return () => {
      microphone?.disconnect();
      analyser?.disconnect();
    };
  }, [stream]);

  return [startAnalysis, stopAnalysis];
};
