import React, { useContext } from 'react';
import './styles.scss';
import { Paragraph } from '@components/typography/Paragraph';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Props = {
  count?: number;
  total?: number;
  title?: string;
};

export const QUESTION_COUNTER_TEST_ID = 'cade-question-counter';
export const CADE_QUESTION_COUNTER_ID = 'cade-question-counter';

export function QuestionCounter({ count, total, title = 'Question' }: Props) {
  const {
    i18n: { TranslationComponent },
  } = useContext(ConfigContext);

  return (
    <>
      {count != null && total != null ? (
        <Paragraph
          data-testid={QUESTION_COUNTER_TEST_ID}
          level="normal"
          id={CADE_QUESTION_COUNTER_ID}
          tabIndex={-1}
          className="cade-question-counter"
        >
          <TranslationComponent
            i18nKey="questionCounter.content"
            values={{ title, count, total }}
          />
        </Paragraph>
      ) : null}
    </>
  );
}
