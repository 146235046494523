import React, { useEffect } from 'react';

export const CONTENT_TITLE_ID = 'content-title';

export const useFocus = (id: string, deps: React.DependencyList = []) => {
  useEffect(() => {
    document.getElementById(id)?.focus();
  }, deps);

  return {
    focus: (id: string) =>
      setTimeout(() => document.getElementById(id)?.focus()),
  };
};
