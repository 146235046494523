import { Paragraph, Input, Space } from '@src/components';
import './styles.scss';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { ListeningStatus } from '@components/Listening/commons';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  audioEndedEvent,
  CadeEvent,
  cadeInputFocusEvent,
  useEndedEvent,
} from '@utils/events';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import { useDelay } from '@utils/useDelay';
import { Listening } from '@components/Listening';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';

const INPUT_ID = 'cade-text-input';

export type Props = {
  onEvent: (event: CadeEvent) => void;
  audioSrc: string;
  setListeningStatus: Dispatch<SetStateAction<ListeningStatus>>;
  listeningStatus: ListeningStatus;
  onTextChanged: (text: string) => void;
  actionDurationInSeconds: number;
  leftLabel: string;
  rightLabel: string;
  timeLeft: number;
  forceEnd?: boolean;
  timerStatus: TimerStatus;
  setTimerStatus: Dispatch<SetStateAction<TimerStatus>>;
};

export const LISTEN_WRITE_EXERCISE_CONTENT_TEST_IDS = {
  LEFT_LABEL: 'cade-listen-write-exercise-content-left-label',
  RIGHT_LABEL: 'cade-listen-write-exercise-content-right-label',
};

export const ListenWriteExerciseContent = ({
  audioSrc,
  listeningStatus,
  setListeningStatus,
  onTextChanged,
  leftLabel,
  rightLabel,
  onEvent,
  timeLeft,
  forceEnd = false,
  timerStatus,
  setTimerStatus,
}: Props) => {
  const [leftHeaderHeight, setLeftHeaderHeight] = useState<number>(0);
  const [text, onTextChange] = useState('');
  const startPlayingAudio = useCallback(() => {
    delay.clear();
    setListeningStatus('PLAYING');
  }, [setListeningStatus]);
  const { sendEndEvent } = useEndedEvent<string>(onEvent);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent(text);
    }
  }, [forceEnd]);

  const delay = useDelay();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => delay.set(startPlayingAudio, EXERCISE_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (listeningStatus === 'COMPLETED') {
      inputRef?.current?.focus();
    }
  }, [listeningStatus]);

  const onAudioEnded = useCallback(() => {
    setListeningStatus('COMPLETED');
    onEvent(audioEndedEvent());
    setTimerStatus(TIMER_STATUS.IN_PROGRESS);
  }, []);

  const rightHeaderDivRef = useCallback(
    (node: HTMLDivElement | null) => {
      setTimeout(() => {
        setLeftHeaderHeight(node?.clientHeight ?? 0);
      });
    },
    [timerStatus, listeningStatus]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onTextChanged(value);
      onTextChange(value);
    },
    []
  );

  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (isTimerFinished(timerStatus)) {
      delay.set(() => {
        sendEndEvent(text);
      });
    }
  }, [text, timerStatus]);

  return (
    <>
      <DualPaneLayout
        leftPane={
          <div>
            <Paragraph
              style={{ minHeight: leftHeaderHeight }}
              level={'large'}
              weight={listeningStatus === 'COMPLETED' ? 'normal' : 'bold'}
              data-testid={LISTEN_WRITE_EXERCISE_CONTENT_TEST_IDS.LEFT_LABEL}
            >
              {leftLabel}
            </Paragraph>
            <Space margin={{ top: 2 }} />
            <Listening
              role="exercise"
              onClickPlay={startPlayingAudio}
              status={listeningStatus}
              audioSrc={Array.isArray(audioSrc) ? audioSrc : [audioSrc]}
              onAudioEnded={onAudioEnded}
            />
          </div>
        }
        rightPane={
          <div>
            <Space
              style={{ alignItems: 'flex-start' }}
              margin={{ right: 4 }}
              className="listen-writing-example-box__label"
            >
              <div ref={rightHeaderDivRef}>
                <Paragraph
                  level={'large'}
                  weight={listeningStatus === 'COMPLETED' ? 'bold' : 'normal'}
                  data-testid={
                    LISTEN_WRITE_EXERCISE_CONTENT_TEST_IDS.RIGHT_LABEL
                  }
                >
                  {rightLabel}
                </Paragraph>
              </div>
            </Space>
            <Space
              padding={5}
              margin={{ top: 4 }}
              className="listen-writing-example-box"
            >
              <label className="sr-only" htmlFor={INPUT_ID}>
                {t('dictationExercise.listenWrite.placeholder')}
              </label>
              <Input.Text
                ref={inputRef}
                id={INPUT_ID}
                disabled={
                  listeningStatus === 'INACTIVE' ||
                  listeningStatus === 'PLAYING' ||
                  timerStatus === 'FINISHED'
                }
                onChange={onInputChange}
                onFocus={() => {
                  onEvent(cadeInputFocusEvent());
                }}
                className={'listen-writing-example-box__input'}
                aria-label={rightLabel}
              />
            </Space>
          </div>
        }
      />
    </>
  );
};
