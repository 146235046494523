import { ShowModalType } from '../../context/App.context';
import {
  ErrorModal,
  Modal,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@versant-monorepo/cade';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onConfirmExit: () => {};
  open: boolean;
  onCloseModal: () => void;
};

export const ExitModal = ({
  showModalType,
  ...modalProps
}: { showModalType: ShowModalType } & Omit<Props, 'open'>) => {
  if (!showModalType.open) {
    return null;
  }
  switch (showModalType.type) {
    case 'POSTPONE_TEST':
      return <TakeItLaterModal open={true} {...modalProps} />;
    case 'PRE_TEST':
      return <ExitBeforeTestModal open={true} {...modalProps} />;
    case 'TEST':
      return <ExitTestModal open={true} {...modalProps} />;
    default:
      return null;
  }
};

export const TakeItLaterModal = ({
  onConfirmExit,
  open,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      titleType="h2"
      title={t('takeItLaterModal.title')}
      footer={
        <div className="exit-test-modal-footer">
          <SecondaryButton size="large" onClick={onConfirmExit}>
            {t('takeItLaterModal.confirm')}
          </SecondaryButton>
        </div>
      }
      open={open}
      onClose={onCloseModal}
    >
      <Paragraph>{t('takeItLaterModal.p1')}</Paragraph>
      <Paragraph>{t('takeItLaterModal.p2')}</Paragraph>
      <Paragraph>{t('takeItLaterModal.p3')}</Paragraph>
    </Modal>
  );
};

export const ExitTestModal = ({ onConfirmExit, open, onCloseModal }: Props) => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      footer={
        <div className="exit-test-modal-footer">
          <SecondaryButton size="large" onClick={onConfirmExit}>
            {t('exitTestModal.confirm')}
          </SecondaryButton>
          <PrimaryButton onClick={onCloseModal}>
            {t('exitTestModal.cancel')}
          </PrimaryButton>
        </div>
      }
      title={t('exitTestModal.title')}
      open={open}
      onClose={onCloseModal}
    >
      <p>{t('exitTestModal.p')}</p>
    </ErrorModal>
  );
};

export const ExitBeforeTestModal = ({
  onConfirmExit,
  open,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      footer={
        <div className="exit-test-modal-footer">
          <SecondaryButton size="large" onClick={onConfirmExit}>
            {t('exitBeforeTestModal.confirm')}
          </SecondaryButton>
          <PrimaryButton onClick={onCloseModal}>
            {t('exitBeforeTestModal.cancel')}
          </PrimaryButton>
        </div>
      }
      title={t('exitBeforeTestModal.title')}
      open={open}
      onClose={onCloseModal}
    >
      <p>{t('exitBeforeTestModal.p')}</p>
    </ErrorModal>
  );
};
