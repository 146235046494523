import { Action, TextInputParam, Toc, ApiModel } from '../../api.interfaces';
import { validateWDTResponse } from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeEmailModel {
  subTitle: string;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  leftLabel: string;
  rightLabel: string;
  count: number;
  total: number;
  timeLeft: number;
  information: string[];
  responseId: string;
  currentStep: number;
  totalSteps: number;
  instruction?: string;
}
export function transformJSONToEmail(
  json: ApiModel,
  tocArray: Toc[]
): CadeEmailModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, []);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const textInput = item.actions.find((el) => el.actionType === 'textinput');
  const [letter, sectionName] = item.titles;
  const responseId = (textInput?.textInputParams as TextInputParam[])[0]
    ?.responseId;
  const title = i18n.t('part', { letter, sectionName });
  const { count, total } = item.itemSequence;
  const totalSteps = tocArray.length;
  const currentStep =
    tocArray.findIndex((el) => el.itemType === item.itemType) + 1;
  const speaking = (textInput as unknown as Action).actionDuration;
  const timeLeft = speaking;
  const leftLabel = item.microInstructions[0];
  const rightLabel = item.microInstructions[1];
  const information = (textInput?.textInputParams as TextInputParam[])[0]
    .prompt as string[];
  const instruction = item.cueInstructions?.at(0);

  return {
    responseId,
    subTitle: title,
    count,
    total,
    timeLeft,
    leftLabel,
    rightLabel,
    currentStep,
    totalSteps,
    onEvent: (event: CadeEvent) => {},
    information,
    instruction,
  };
}
