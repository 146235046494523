import React, {
  AriaAttributes,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import './styles.scss';
import classNames from 'classnames';
import icon_active from './assets/icon_active.svg';
import icon_expired from './assets/icon_expired.svg';
import icon_disabled from './assets/icon_disabled.svg';

import useTimerHandler from './useTimerHandler';
import { formatTimeWithSpaces } from './utils';
import { Paragraph } from '../../typography/Paragraph';
import { useBeep } from '../../../utils/useBeep';
import { Text } from '../../typography/Text';
import { TIME_IS_OVER_TEXT } from '../../../utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export const TIMER_TEXT_TEST_ID = 'cade-timer-text';
export const TIMER_TEXT_FINISHED_TEST_ID = 'cade-timer-text-finished';
export const TIMER_VALUE_TEST_ID = 'cade-timer-value';

export const TIMER_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export const TIMER_STATUS = {
  INIT: 'INIT',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  DISABLED: 'DISABLED',
} as const;

type TimerStatusKeys = keyof typeof TIMER_STATUS;
export type TimerStatus = (typeof TIMER_STATUS)[TimerStatusKeys];

type TimerVariantKeys = keyof typeof TIMER_VARIANT;
export type TimerVariant = (typeof TIMER_VARIANT)[TimerVariantKeys];

export type Props = {
  time: number;
  label?: string;
  icon?: {
    active?: string;
    expired?: string;
    disabled?: string;
  };
  timerStatus?: TimerStatus;
  onChangeStatus?: (status: TimerStatus) => void;
  /** Defining messages that should be read out by a screen reader. When a timer runs and counts down. */
  timeMessagesForReader?: (time: number) => string | undefined | null;
  /**
   * The Primary variant sets a grey colour for a timer when it's disabled.
   * The Secondary variant sets a black colour for a timer when it's disabled.
   */
  variant?: TimerVariant;
  /** Defining a custom message when a timer is up for a screen reader. */
  timeIsUpMessageForReader?: string;
};

const readRemainingTime = (time: number) => {
  switch (time) {
    case 180: {
      return '3 minutes left';
    }

    case 120: {
      return '2 minutes left';
    }

    case 60: {
      return '1 minute left';
    }

    case 30: {
      return '30 seconds left';
    }

    case 15: {
      return '15 seconds left';
    }

    case 5: {
      return '5 seconds left';
    }
  }
};

export function isTimerFinished(status: TimerStatus) {
  return status === TIMER_STATUS.FINISHED;
}

export type TimerMessageProps = PropsWithChildren<
  {
    text?: string;
    show?: boolean;
    className?: string;
    insideClassName?: string;
  } & AriaAttributes
>;

export const TIMER_MESSAGE_INNER_CONTAINER_TEST_ID =
  'cade-timer-message-inner-container';

export function TimerMessage({
  children,
  text,
  show,
  className,
  insideClassName,
  ...ariaAttributes
}: TimerMessageProps) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <div {...ariaAttributes} className={className}>
      {show && (
        <div
          className={`cade-timer__message ${insideClassName}`}
          data-testid={TIMER_MESSAGE_INNER_CONTAINER_TEST_ID}
        >
          {children ?? (
            <Paragraph weight="bold">{text || t('timer.timeIsOver')}</Paragraph>
          )}
        </div>
      )}
    </div>
  );
}

export function Timer({
  time,
  label,
  icon: {
    active: iconActive = icon_active,
    expired: iconExpired = icon_expired,
    disabled: iconDisabled = icon_disabled,
  } = {},
  timerStatus = TIMER_STATUS.INIT,
  onChangeStatus = () => {},
  timeMessagesForReader = readRemainingTime,
  variant = TIMER_VARIANT.PRIMARY,
  timeIsUpMessageForReader,
}: Props) {
  const handleTimeIsUp = useCallback(() => {
    const playBeep = async () => {
      beep && (await beep.playInteractionEndTone());
    };
    playBeep();
    onChangeStatus(TIMER_STATUS.FINISHED);
  }, [onChangeStatus]);
  const { timeCounter, stopTimer, startTimer } = useTimerHandler(
    time,
    handleTimeIsUp
  );
  const beep = useBeep();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (timerStatus === TIMER_STATUS.DISABLED) {
      stopTimer();
      return;
    }
    if (timerStatus === TIMER_STATUS.IN_PROGRESS) {
      startTimer(time);
      return;
    }
  }, [timerStatus]);

  const iconUrl = useCallback(() => {
    if (timerStatus === TIMER_STATUS.FINISHED) {
      return iconExpired;
    }

    return iconActive;
  }, [timerStatus, variant]);

  const warningClass = classNames({
    'cade-timer__counter--warning': timerStatus === TIMER_STATUS.FINISHED,
  });

  const containerClass = classNames('cade-timer__container');

  return (
    <div className={containerClass} id="timer">
      <Text
        level="normal"
        data-testid={TIMER_TEXT_TEST_ID}
        className={`cade-timer__text ${warningClass}`}
      >
        <img src={iconUrl()} className="cade-timer__icon" alt="" />
        {label || t('timer.label')}
      </Text>
      <Text
        level="normal"
        weight="bold"
        className={warningClass}
        data-testid={TIMER_VALUE_TEST_ID}
      >
        {formatTimeWithSpaces(timeCounter)}
      </Text>
      <span
        className="sr-only"
        role="timer"
        aria-live="polite"
        aria-atomic="true"
      >
        {timeMessagesForReader(timeCounter)}
      </span>
      <span
        data-testid={TIMER_TEXT_FINISHED_TEST_ID}
        className="sr-only"
        role="status"
        aria-atomic="true"
        aria-live="polite"
      >
        {timerStatus === TIMER_STATUS.FINISHED &&
          (timeIsUpMessageForReader || t('timer.timeIsOver'))}
      </span>
    </div>
  );
}
