import { ApiModel } from '../../api/api.interfaces';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { useState } from 'react';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from '../../hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToReadAloudInstruction } from '../../api/parsers/ReadAloud/ReadAloudInstruction';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Wrapper } from '../Wrapper';
import { Template } from '../../components/Template/Template';
import {
  CADE_CONTENT_TITLE_ID,
  ReadAloudInstruction,
} from '@versant-monorepo/cade';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const ReadAloudInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState<boolean>(false);

  const { pushEvent } = useLogger();
  const { eventHandler, lastEvent } = useHandleAppEvent({
    'cade-ended-event': () => onEnded(),
  });
  useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);

  const readAloudInstructionProps = useParser(
    transformJSONToReadAloudInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(readAloudInstructionProps?.title);

  const onEnded = async () => {
    pushEvent({
      item: 'Read Aloud Instruction',
      level: LogLevel.INFO,
      message: 'User clicked next on Read Aloud Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
  };

  return (
    readAloudInstructionProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'instruction'}
          footer={
            <Footer
              nextButtonVisible={true}
              onNext={() => setForceEnd(true)}
              role={'instruction'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Read Aloud Instruction',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Read Aloud Instruction',
                });
                eventHandler(exitEvent());
              }}
              currentStep={readAloudInstructionProps.currentStep}
              totalSteps={readAloudInstructionProps.totalSteps}
              title={readAloudInstructionProps.title}
              role={'instruction'}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <ReadAloudInstruction
              key={networkDependentKey(onlineStatus)}
              {...readAloudInstructionProps}
              onEvent={eventHandler}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
