import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { CADE_CONTENT_TITLE_ID, SurveyForm } from '@versant-monorepo/cade';
import { Wrapper } from '../Wrapper';
import { countries } from '../../utils/dictionaries';
import { useApi } from '../../hooks/useApi';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { useState } from 'react';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToSurvey } from '../../api/parsers/Survey/Survey';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const SurveyWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  useSetDocumentTitle('Optional Questions');
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const [forceEnd, setForceEnd] = useState<boolean>(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onNext(event.value),
  });
  useFocus(CADE_CONTENT_TITLE_ID, [model]);
  const { pushEvent } = useLogger();

  const surveyProps = useParser(transformJSONToSurvey, model as ApiModel);

  const onNext = async (value: any) => {
    const responses = [
      {
        id: value.radioResponseId,
        response: value.radioResponse,
        type: 'TEXT',
      },
      {
        id: value.inputQuestionResponseId,
        response: value.inputResponse,
        type: 'TEXT',
      },
    ] as TextResponse[];

    pushEvent({
      item: 'Optional Questions',
      level: LogLevel.INFO,
      message: 'User go next on Optional Questions',
    });
    const response = await getNextStep(responses);
    switchView(CURRENT_ITEM_TYPE, response);
  };

  return (
    surveyProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'exercise'}
            footer={
              <Footer
                nextButtonVisible={true}
                onNext={() => setForceEnd(true)}
                role={'exercise'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'Optional Questions',
                    level: LogLevel.INFO,
                    message: 'User clicked exit on Optional Questions',
                  });
                  eventHandler(exitEvent());
                }}
                title={''}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <SurveyForm
                {...surveyProps}
                onEvent={eventHandler}
                options={countries}
                forceEnd={forceEnd}
                subTitle={surveyProps.title}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
