import { CONTENT_TITLE_ID, useFocus } from '../../hooks/useFocus';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { Template } from '../../components/Template/Template';
import { LogLevel } from '../../api/Logger';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { Header } from '../../components/Header/Header';
import { useLogger } from '../../hooks/useLogger';
import { Footer } from '../../components/Footer/Footer';
import {
  Box,
  Paragraph,
  PrimaryButton,
  Space,
  Title,
} from '@versant-monorepo/cade';
import './styles.scss';
import centered from './icons/centered.svg';
import lookAway from './icons/look_away.svg';
import notes from './icons/notes.svg';
import shareScreen from './icons/share_screen.svg';
import enoughTime from './icons/enough_time.svg';
import quietRoom from './icons/quiet_room.svg';
import notifications from './icons/notifications.svg';
import proctoringCorrect from './icons/proctoring_correct.png';
import proctoringIncorrect from './icons/proctoring_not-correct.png';
import success from './icons/success.svg';
import error from './icons/error.svg';
import { isSpeakingTest } from '../../utils/utils';
import { vfwRoutes } from '../../vfw-routes';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

export function ReviewRules() {
  useFocus(CONTENT_TITLE_ID, []);
  const { t } = useTranslation();
  useSetDocumentTitle(t('reviewRules.documentTitle'));
  const { eventHandler } = useHandleAppEvent();
  const { pushEvent } = useLogger();
  const navigate = useNavigate();
  const isUnderBreakpoint = useMediaQuery('(max-height: 985px)');

  const items: { icon: string; text: string }[] = [
    {
      icon: centered,
      text: t('reviewRules.listItem1'),
    },
    {
      icon: lookAway,
      text: t('reviewRules.listItem2'),
    },
    {
      icon: notes,
      text: t('reviewRules.listItem3'),
    },
    {
      icon: shareScreen,
      text: t('reviewRules.listItem4'),
    },
    {
      icon: enoughTime,
      text: t('reviewRules.listItem5'),
    },
    {
      icon: quietRoom,
      text: t('reviewRules.listItem6'),
    },
    {
      icon: notifications,
      text: t('reviewRules.listItem7'),
    },
  ];

  const onClick = () => {
    pushEvent({
      item: 'Review the Rules',
      level: LogLevel.INFO,
      message: 'User go next on Review the Rules',
    });
    if (isSpeakingTest()) {
      navigate(vfwRoutes.speakingTips);
    } else {
      navigate(vfwRoutes.test);
    }
  };

  return (
    <Template
      volumeSlider={true}
      header={
        <Header
          onExit={() => {
            pushEvent({
              item: 'Review the Rules',
              level: LogLevel.INFO,
              message: 'User clicked exit on Review the Rules',
            });
            eventHandler(exitEvent());
          }}
          title={''}
        />
      }
      footer={
        <Footer nextButtonVisible={false} onNext={() => {}} role={'check'} />
      }
    >
      <div className={'review-rules__container'}>
        <Space className={'review-rules__header'}>
          <Paragraph>{t('reviewRules.headerParagraph')}</Paragraph>
          <Title level={'large'}>{t('reviewRules.title')}</Title>
        </Space>
        <Box role={'instruction'} className={'review-rules__content'}>
          {!isUnderBreakpoint && (
            <Space className={'review-rules__proctoring'}>
              <div>
                <img src={proctoringCorrect} alt="Proctoring correct" />
                <img
                  className={'review-rules__proctoring-icon'}
                  src={success}
                  alt="Success icon"
                />
              </div>
              <div>
                <img src={proctoringIncorrect} alt="Proctoring incorrect" />
                <img
                  className={'review-rules__proctoring-icon'}
                  src={error}
                  alt="Error icon"
                />
              </div>
            </Space>
          )}
          {!isUnderBreakpoint && (
            <Space className={'review-rules__list'}>
              {items.map((el, index) => (
                <div key={index} className={'review-rules__list-item'}>
                  <img src={el.icon} alt="icon" />
                  <Paragraph
                    dangerouslySetInnerHTML={{ __html: el.text }}
                  ></Paragraph>
                </div>
              ))}
            </Space>
          )}
          {isUnderBreakpoint && (
            <Space className={'review-rules__list'}>
              <div className={'review-rules__wide-container'}>
                <Space className={'review-rules__list'}>
                  {items.slice(0, 4).map((el, index) => (
                    <div key={index} className={'review-rules__list-item'}>
                      <img src={el.icon} alt="icon" />
                      <Paragraph
                        dangerouslySetInnerHTML={{ __html: el.text }}
                      ></Paragraph>
                    </div>
                  ))}
                </Space>
                <Space className={'review-rules__list'}>
                  {items.slice(4, 7).map((el, index) => (
                    <div key={index} className={'review-rules__list-item'}>
                      <img src={el.icon} alt="icon" />
                      <Paragraph
                        dangerouslySetInnerHTML={{ __html: el.text }}
                      ></Paragraph>
                    </div>
                  ))}
                </Space>
                <Space className={'review-rules__proctoring'}>
                  <div>
                    <img src={proctoringCorrect} alt="Proctoring correct" />
                    <img
                      className={'review-rules__proctoring-icon'}
                      src={success}
                      alt="Success icon"
                    />
                  </div>
                  <div>
                    <img src={proctoringIncorrect} alt="Proctoring incorrect" />
                    <img
                      className={'review-rules__proctoring-icon'}
                      src={error}
                      alt="Error icon"
                      style={{ right: 30 }}
                    />
                  </div>
                </Space>
              </div>
            </Space>
          )}

          <div className={'review-rules__footer'}>
            <PrimaryButton onClick={onClick} data-testid={'startTest'}>
              {t('reviewRules.startText')}
            </PrimaryButton>
          </div>
        </Box>
      </div>
    </Template>
  );
}
