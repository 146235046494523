import { ApiModel, Toc } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractAudioResourcesFromResponse,
  extractResponseIdFromResponse,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import { VfwStorage } from '../../../utils/Storage';

interface CadeSelfIntroModel {
  title: string;
  content: string;
  onEvent: (event: CadeEvent) => void;
  question: string;
  audioSrc: string;
  instructionAudioSrc: string;
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  responseId?: string;
  instruction: string;
}
export function transformJSONToSelfIntro(
  json: ApiModel,
  tocArray?: Toc[],
  t?: (key: string) => string
): CadeSelfIntroModel {
  const keysToCheckInItems = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const display = item.actions.find((el) => el.actionType === 'display');
  const title = item.titles[1]
    ? item.titles[1]
    : t
    ? t('selfIntro.title')
    : 'Record a speech sample';
  const [question] = display?.displayParams?.display[0] as string[];
  const audioSrc = extractAudioResourcesFromResponse(json);
  const responseId = extractResponseIdFromResponse(json);

  const record = item.actions.find((el) => el.actionType === 'record');

  const speaking = record?.actionDuration as number;
  const initial = record?.recordParams?.initialTimeout as number;
  const ending = record?.recordParams?.endTimeout as number;

  const instruction = display?.displayParams?.cueInstructions?.[0]
    .instructions[0]
    ? display?.displayParams?.cueInstructions?.[0].instructions[0]
    : t
    ? t('selfIntro.instructions')
    : 'Before you start the test, we need to hear you speak, so we will give you a question. Listen to the question, then give your answer. You have 30 seconds to answer this question. Try to speak for as long as you can.';

  return {
    title,
    question,
    content: '',
    audioSrc: audioSrc[1],
    instructionAudioSrc: audioSrc[0],
    responseId,
    onEvent: (event: CadeEvent) => {},
    timeouts: {
      speaking,
      initial,
      ending,
    },
    instruction,
  };
}
