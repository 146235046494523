import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import './App.css';
import CircleLoader from './components/CircleLoader/CircleLoader';
import { BandwidthCheckWrapper } from './pages/BandwidthCheckWrapper';
import { SystemCheckWrapper } from './pages/SystemCheckWrapper';
import { MicrophoneCheckWrapper } from './pages/MicrophoneCheckWrapper/MicrophoneCheckWrapper';
import { SpeakerCheckWrapper } from './pages/SpeakerCheckWrapper/SpeakerCheckWrapper';
import { SpeakingTipsWrapper } from './pages/SpeakingTipsWrapper/SpeakingTipsWrapper';
import { BackgroundNoiseCheckWrapper } from './pages/BackgroundNoiseCheckWrapper';
import { ThingsYouNeedToKnowWrapper } from './pages/ThingsYouNeedToKnow/ThingsYouNeedToKnowWrapper';
import { SetupSuccessWrapper } from './pages/SetupSuccessWrapper';
import { TestWrapper } from './pages/TestWrapper';
import { vfwRoutes } from './vfw-routes';
import {
  AppContext,
  closedModal,
  ShowModalType,
  TestStatus,
} from './context/App.context';
import {
  CadeProvider,
  FeatureFlags,
  FeatureFlagsManager,
  parseFeatureFlagsFromEnv,
  useDelay,
} from '@versant-monorepo/cade';
import { VfwStorage } from './utils/Storage';
import { ConnectionModalWrapper } from './pages/ConnectionModalWrapper/ConnectionModalWrapper';
import { ProblemWithConnectionWrapper } from './pages/ProblemWithConnectionWrapper/ProblemWithConnectionWrapper';
import { BrowserNotSupported } from './pages/BrowserNotSupported/BrowserNotSupported';
import { WeCantHearYouWrapper } from './pages/WeCantHearYouWrapper';
import { ReadyToContinueWrapper } from './pages/ReadyToContinueWrapper/ReadyToContinueWrapper';
import { AccessDeniedWrapper } from './pages/ErrorWrappers/AccessDeniedWrapper';
import { GeneralErrorWrapper } from './pages/ErrorWrappers/GeneralErrorWrapper';
import { exitTest } from './utils/utils';
import { MicrophoneProblemWrapper } from './pages/MicrophoneProblemWrapper/MicrophoneProblemWrapper';
import { FEATURE_FLAG_ENV_PREFIX } from './constants';
import { FeatureFlagConfig } from './pages/FeatureFlagConfig';
import { ProblemWithAudioWrapper } from './pages/ProblemWithAudioWrapper/ProblemWithAudioWrapper';
import { LegacyDirect } from 'pages/LegacyDirect/LegacyDirect';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { ExitModal } from './components/ExitModal';
import { Direct } from './pages/Direct/Direct';
import './i18n/i18n';
import { Trans, useTranslation } from 'react-i18next';
import { ReviewRules } from './pages/ReviewRules/ReviewRules';
import { TakeASelfie } from './pages/TakeASelfie/TakeASelfie';
import { RecordAVideo } from 'pages/RecordAVideo/RecordAVideo';
import { SetupSummary } from './pages/SetupSummary/SetupSummary';
import { ProblemWithCameraWrapper } from './pages/ProblemWithCameraWrapper';
import { HelmetProvider } from 'react-helmet-async';
import { ConsentPageBiometricData } from 'pages/ConsentPageBiometricData/ConsentPageBiometricData';
import { useLogger } from 'hooks/useLogger';
import { LogLevel } from 'api/Logger';
import { newRelicInit } from './utils/newrelic';

const interactionBeginTone = require('./assets/audio/interaction_begin_tone.mp3');
const interactionEndTone = require('./assets/audio/interaction_end_tone.mp3');
const nextTone = require('./assets/audio/next_tone.mp3');
const errorTone = require('./assets/audio/error_tone.mp3');

window.React = React;

const isQuirksEnabled = process.env.REACT_APP_DEV_QUIRKS === 'true';

const flagsFromEnv = parseFeatureFlagsFromEnv(
  process.env,
  FEATURE_FLAG_ENV_PREFIX
);

const CLOSE_MODAL_TIMEOUT = 10_000;

isQuirksEnabled &&
  FeatureFlagsManager.setFlags(FeatureFlagsManager.getFlags() || flagsFromEnv);

if (
  process.env.REACT_APP_NEW_RELIC_AGENT_ID &&
  process.env.REACT_APP_NEW_RELIC_LICENSE_KEY
) {
  newRelicInit();
}

const useDetectMultipleWindows = () => {
  const { pushEvent } = useLogger();

  useEffect(() => {
    const startTimeStorage = 'vfw2StartTime';
    const currentTime = new Date().getTime();
    localStorage.setItem(startTimeStorage, currentTime.toString());

    const checkMultipleWindows = () => {
      const storedTime = localStorage.getItem(startTimeStorage);
      if (storedTime && storedTime !== currentTime.toString()) {
        if (VfwStorage.getItem('authtoken')) {
          pushEvent({
            level: LogLevel.INFO,
            message: `User is running the app in multiple tabs`,
            item: 'App',
          });
        }
      }
    };

    window.addEventListener('storage', checkMultipleWindows);

    return () => {
      window.removeEventListener('storage', checkMultipleWindows);
    };
  }, []);
};

function App() {
  const [loading, setLoading] = useState(false);
  const [testStatus, setTestStatus] = useState<TestStatus>(undefined);
  const [visibleModal, setVisibleModal] = useState<ShowModalType>(
    closedModal()
  );
  const [weCantHearYouCount, setWeCantHearYouCount] = useState(0);
  const [audioTroubleshootingCount, setAudioTroubleshootingCount] = useState(0);
  const [flags, setFlags] = useState(
    isQuirksEnabled ? FeatureFlagsManager.getFlags() : flagsFromEnv
  );
  const delay = useDelay();
  const { t } = useTranslation();

  useDetectMultipleWindows();

  useEffect(() => FeatureFlagsManager.getUpdates(setFlags), []);

  useEffect(() => {
    if (visibleModal.open && visibleModal.type === 'TEST') {
      delay.set(() => setVisibleModal(closedModal()), CLOSE_MODAL_TIMEOUT);
    } else {
      delay.clear();
    }
  }, [delay, visibleModal]);

  const errorModalClickHandler = async () => {
    VfwStorage.setItem('withoutSecondConfirmation', true);

    if (visibleModal.open && visibleModal.type === 'TEST') {
      if (VfwStorage.getItem('withoutSecondConfirmation')) {
        exitTest({ hard: true, testStatus });
        return;
      }
    }
    exitTest({ testStatus });
  };

  const closeModal = useCallback(() => setVisibleModal(closedModal()), []);

  return (
    <div style={{ height: '100vh', overflow: 'auto' }} translate={'no'}>
      <HelmetProvider>
        <FeatureFlags features={flags}>
          <AppContext.Provider
            value={{
              visibleModal,
              setVisibleModal,
              weCantHearYouCount,
              setWeCantHearYouCount,
              audioTroubleshootingCount,
              setAudioTroubleshootingCount,
              loading,
              setLoading,
              testStatus,
              setTestStatus,
            }}
          >
            <CadeProvider
              config={{
                rootElementId: '#root',
                interactionBeginTone: interactionBeginTone,
                interactionEndTone: interactionEndTone,
                nextTone: nextTone,
                errorTone: errorTone,
                i18n: {
                  t,
                  TranslationComponent: Trans,
                },
              }}
              microphoneId={VfwStorage.getItem('microphoneId')}
            >
              {loading && <CircleLoader />}
              <Router basename={'/'}>
                <ConnectionModalWrapper />
                <Routes>
                  <Route path={vfwRoutes.root} element={<Login />} />
                  {isQuirksEnabled && (
                    <Route
                      path={vfwRoutes.featureFlagsConfig}
                      element={
                        <ProtectedRoute>
                          <FeatureFlagConfig />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  <Route
                    path={vfwRoutes.thingsYouNeedToKnow}
                    element={
                      <ProtectedRoute>
                        <ThingsYouNeedToKnowWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.systemCheck}
                    element={
                      <ProtectedRoute>
                        <SystemCheckWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.bandwidthCheck}
                    element={
                      <ProtectedRoute>
                        <BandwidthCheckWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.microphoneCheck}
                    element={
                      <ProtectedRoute>
                        <MicrophoneCheckWrapper />
                      </ProtectedRoute>
                    }
                  />
                  {VfwStorage.getItem('isTestProctored') && (
                    <Route
                      path={vfwRoutes.problemWithCamera}
                      element={
                        <ProtectedRoute>
                          <ProblemWithCameraWrapper />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  <Route
                    path={vfwRoutes.speakerCheck}
                    element={
                      <ProtectedRoute>
                        <SpeakerCheckWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.backgroundNoiseCheck}
                    element={
                      <ProtectedRoute>
                        <BackgroundNoiseCheckWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.landingPage}
                    element={
                      <ProtectedRoute>
                        <LandingPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.setupSuccess}
                    element={
                      <ProtectedRoute>
                        <SetupSuccessWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.takeASelfie}
                    element={
                      <ProtectedRoute>
                        <TakeASelfie />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path={vfwRoutes.recordAVideo}
                    element={
                      <ProtectedRoute>
                        <RecordAVideo />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path={vfwRoutes.speakingTips}
                    element={
                      <ProtectedRoute>
                        <SpeakingTipsWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.test}
                    element={
                      <ProtectedRoute>
                        <TestWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.problemWithConnection}
                    element={
                      <ProtectedRoute>
                        <ProblemWithConnectionWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.problemWithAudio}
                    element={
                      <ProtectedRoute>
                        <ProblemWithAudioWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.weCantHearYou}
                    element={
                      <ProtectedRoute>
                        <WeCantHearYouWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.browserNotSupported}
                    element={
                      <ProtectedRoute>
                        <BrowserNotSupported />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.readyToContinue}
                    element={
                      <ProtectedRoute>
                        <ReadyToContinueWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.accessDenied}
                    element={
                      <ProtectedRoute>
                        <AccessDeniedWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.serverError}
                    element={
                      <ProtectedRoute>
                        <GeneralErrorWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.microphoneProblem}
                    element={
                      <ProtectedRoute>
                        <MicrophoneProblemWrapper />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.reviewRules}
                    element={
                      <ProtectedRoute>
                        <ReviewRules />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={vfwRoutes.setupSummary}
                    element={
                      <ProtectedRoute>
                        <SetupSummary />
                      </ProtectedRoute>
                    }
                  />
                  {VfwStorage.getItem('isTestProctored') && (
                    <Route
                      path={vfwRoutes.consentPageBiometricData}
                      element={
                        <ProtectedRoute>
                          <ConsentPageBiometricData />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  {/* LTI path left only for back compatibility should be removed and vfwRoutes.directLegacy should be used */}
                  <Route path={vfwRoutes.lti} element={<LegacyDirect />} />
                  <Route
                    path={vfwRoutes.directLegacy}
                    element={<LegacyDirect />}
                  />
                  <Route path={vfwRoutes.direct} element={<Direct />} />
                </Routes>
              </Router>
              <ExitModal
                showModalType={visibleModal}
                onCloseModal={closeModal}
                onConfirmExit={errorModalClickHandler}
              />
            </CadeProvider>
          </AppContext.Provider>
        </FeatureFlags>
      </HelmetProvider>
    </div>
  );
}

export default App;
