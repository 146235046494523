import { useEffect, useRef, useState } from 'react';
import {
  updateMicrophoneOutputValue,
  useVolume,
} from '../context/Volume.context';
import { getAudioContext } from './index';

const getNumbersArray = (noOfBars: number): number[] => {
  const size = noOfBars % 2 === 0 ? noOfBars / 2 : Math.floor(noOfBars / 2) + 1;
  return Array.from(new Array(size).keys());
};

// makes the center values "bigger" to look better for the user
export const getEqualizer = (numberOfBars: number): number[] => {
  const c = 0.15;
  const a = (1 - c) / (numberOfBars / 2);
  const numbers: number[] = getNumbersArray(numberOfBars);
  const firstHalf = numbers.map((n) => n * a + c);
  const secondHalf = numbers.map((n) => n * a + c).reverse();
  return firstHalf.concat(secondHalf);
};

// makes the bars not go over the height
export const getNormalizer = (height: number): number => height / 255;

const getStart = (noOfBars: number) => {
  const dividedBy2 = noOfBars / 2;
  if (dividedBy2 >= 66) {
    return 0;
  }

  return 66 - dividedBy2;
};

const getMedia = (
  audioSource: MediaStream | HTMLAudioElement,
  audioContext: AudioContext
) =>
  audioSource instanceof MediaStream
    ? audioContext.createMediaStreamSource(audioSource)
    : audioContext.createMediaElementSource(audioSource);

export const transform = (
  values: Uint8Array,
  noOfBars: number,
  boost: number
): number[] => {
  const start = getStart(noOfBars);
  const end = start + noOfBars;
  const usedEqualizer = getEqualizer(noOfBars);
  // @ts-ignore
  return [...values]
    .slice(start, end)
    .map((element, index) => element * usedEqualizer[index])
    .map((el) => el * boost);
};

export const useSoundFrequency = (
  noOfBars: number,
  frequencyCallback: (n: number[]) => void,
  volumeCallback: (n: number) => void,
  audioSource?: MediaStream | HTMLAudioElement,
  frequencyMs = 100,
  boost = 1
) => {
  const [audioAnalyser, setAudioAnalyser] = useState<AnalyserNode>();
  const [analyzerBuffer, setAnalyzerBuffer] = useState<Float32Array>(
    new Float32Array(512)
  );
  const uintArrayRef = useRef<Uint8Array>();
  const { dispatch } = useVolume();

  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const startAnalyzing = () => {
    setIsAnalyzing(true);
  };
  const stopAnalyzing = () => {
    setIsAnalyzing(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (uintArrayRef.current && audioAnalyser) {
        audioAnalyser.getFloatTimeDomainData(analyzerBuffer);

        let sumSquares = 0.0;
        // @ts-ignore
        for (const amplitude of analyzerBuffer) {
          sumSquares += amplitude * amplitude;
        }

        const average =
          6.7 /* value to be adjusted */ *
          (100 * Math.sqrt(sumSquares / analyzerBuffer.length));
        let averageResult = Math.min(average, 100);
        if (isAnalyzing) {
          volumeCallback(averageResult);
          dispatch(updateMicrophoneOutputValue(averageResult));
          handleFrequencyCallback();
        }
      }
    }, frequencyMs);
    return () => {
      clearInterval(interval);
    };
  }, [audioAnalyser, isAnalyzing]);

  useEffect(() => {
    if (!audioSource) {
      audioSource = document.getElementById('audio') as HTMLAudioElement;
    }

    let analyser: AnalyserNode;

    let media: MediaStreamAudioSourceNode | MediaElementAudioSourceNode;
    if (audioSource) {
      const audioContext = getAudioContext();
      try {
        media = getMedia(audioSource, audioContext);
      } catch (ex) {
        console.warn('Error while analyzing audio', ex);
        return;
      }
      analyser = audioContext.createAnalyser();
      if (audioSource instanceof HTMLAudioElement) {
        analyser.connect(audioContext.destination);
      }
      analyser.fftSize = 1024; // power of 2, bins
      setAnalyzerBuffer(new Float32Array(analyser.fftSize));
      const bufferLength = analyser.frequencyBinCount; // number of frequency bins, fftSize/2
      uintArrayRef.current = new Uint8Array(bufferLength);
      media?.connect(analyser);
      setAudioAnalyser(analyser);
    }
    return () => {
      media?.disconnect();
      analyser?.disconnect();
    };
  }, [audioSource]);

  function handleFrequencyCallback() {
    if (uintArrayRef.current && audioAnalyser) {
      const array = uintArrayRef.current;

      audioAnalyser.getByteFrequencyData(array);

      const barHeights = transform(array, noOfBars, boost);

      if (frequencyCallback) {
        frequencyCallback(barHeights);
      }
    }
  }

  return [startAnalyzing, stopAnalyzing] as const;
};
