import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type Status =
  | { status: 'LOADING' }
  | { status: 'ERROR'; message: string }
  | { status: 'SUCCESS'; data: MediaDeviceInfo[] };

const useCameras = () => {
  const [state, setState] = useState<Status>({ status: 'LOADING' });
  const { t } = useTranslation();
  const getCameras = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      setState({ status: 'SUCCESS', data: videoDevices });
      stream.getTracks().forEach((track) => track.stop());
    } catch (err: any) {
      if (
        err.name === 'NotAllowedError' ||
        err.name === 'PermissionDeniedError'
      ) {
        setState({
          status: 'ERROR',
          message: t('remoteMonitoring.takeASelfie.cameraAccessError'),
        });
      } else {
        setState({
          status: 'ERROR',
          message: t('remoteMonitoring.takeASelfie.genericCameraError'),
        });
      }
    }
  }, []);

  useEffect(() => {
    getCameras();
  }, []);

  return { cameraState: state };
};

export default useCameras;
