import { CadeEvent } from '@versant-monorepo/cade';
import { ApiModel, Toc } from '../../api.interfaces';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import {
  checkPlayParamsUrls,
  extractCommonItemsForExercise,
  validateWDTResponse,
} from '../../api.utils';
import i18n from 'i18next';

interface CadeOppositesModel {
  title: string;
  currentStep: number;
  totalSteps: number;
  count: number;
  total: number;
  subTitle: string;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  responseId?: string;
  leftLabel?: string;
  rightLabel?: string;
  onEvent: (event: CadeEvent) => void;
  instruction: string;
}

export function transformJSONToOpposites(
  json: ApiModel,
  tocArray: Toc[]
): CadeOppositesModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const instruction =
    json.items[0].cueInstructions?.[0] ??
    i18n.t('opposites.exercise.instruction');

  return {
    ...extractCommonItemsForExercise(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    leftLabel: json.items[0].microInstructions[0],
    rightLabel: json.items[0].microInstructions[1],
    instruction,
  };
}
