import versantLogo from '../../assets/versant-logo.svg';
import closeIcon from '../../assets/exit_button_icon.svg';
import { Stepper } from '../Stepper/Stepper';

type Props = {
  currentStep?: number;
  totalSteps?: number;
  title: string;
  role?: 'static' | 'instruction' | 'check' | 'exercise';
  onExit: () => void;
  closeButtonVisible?: boolean;
};
export const HEADER_TEST_ID = 'vfw-header';
export const HEADER_STEPPER_CONTAINER_TEST_ID = 'vfw-header-stepper-container';
export const HEADER_RIGHT_TEST_ID = 'vfw-header-right';
export const HEADER_CLOSE_BUTTON_TEST_ID = 'vfw-header-close-btn';
export const HEADER_TITLE_TEST_ID = 'vfw-header-title';
export const HEADER_VERSANT_LOGO_ID = 'vfw-pearson-logo';
export const Header = ({
  currentStep,
  totalSteps,
  title,
  role = 'static',
  onExit,
  closeButtonVisible = true,
}: Props) => {
  return (
    <header
      data-testid={HEADER_TEST_ID}
      className={`vfw-template__header vfw-template__header--${role}`}
    >
      <div className="vfw-template__header--left">
        <div className="vfw-template__header-logo">
          <img
            tabIndex={-1}
            id={HEADER_VERSANT_LOGO_ID}
            src={versantLogo}
            alt="Versant logo"
          />
        </div>
        <p
          data-testid={HEADER_TITLE_TEST_ID}
          className="vfw-template__header-title"
        >
          {title}
        </p>
        <div data-testid={HEADER_STEPPER_CONTAINER_TEST_ID}>
          {currentStep && totalSteps ? (
            <Stepper currentStep={currentStep} totalSteps={totalSteps} />
          ) : null}
        </div>
      </div>
      <div
        className="vfw-template__header--right"
        data-testid={HEADER_RIGHT_TEST_ID}
      >
        {closeButtonVisible && (
          <button
            aria-label={'Exit test'}
            data-testid={HEADER_CLOSE_BUTTON_TEST_ID}
            onClick={onExit}
            className="vfw-template__header-close"
          >
            <img src={closeIcon} alt={'Exit test'} />
          </button>
        )}
      </div>
    </header>
  );
};
