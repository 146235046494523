import { CadeEvent, timerEndedEvent, useEndedEvent } from '@utils/events';
import { Box, Paragraph, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerStatus,
} from '@components/base/Timer';
import './styles.scss';
import { TextArea } from '@components/base/TextArea';
import { useDelay } from '@utils/useDelay';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { PlayAudio } from '@components/PlayAudio';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  count: number;
  total: number;
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  rightLabel: string;
  timeLeft: number;
  forceEnd?: boolean;
  passage: string[];
  instruction?: string;
  audioSrc: string[];
};

export const TYPING_EXERCISE_TEST_IDS = {
  WRAPPER: 'cade-typing-exercise-wrapper',
  SUBTITLE: 'cade-typing-exercise-sub-title',
  INSTRUCTION: 'cade-typing-exercise-instruction',
  LEFT_LABEL: 'cade-typing-exercise-left-label',
  LEFT_TEXT: 'cade-typing-exercise-left-text',
  RIGHT_LABEL: 'cade-typing-exercise-right-label',
};

export function TypingExercise({
  rightLabel,
  leftLabel,
  timeLeft,
  forceEnd,
  onEvent,
  total,
  subTitle,
  count,
  passage,
  instruction,
  audioSrc,
}: Props) {
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );
  const [currentText, setCurrentText] = useState('');
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent<string>(onEvent);
  const [leftHeaderHeight, setLeftHeaderHeight] = useState<number>(0);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const _rightLabel = rightLabel ?? t('typing.exercise.rightLabel');
  const _leftLabel = leftLabel ?? t('typing.exercise.leftLabel');

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent(currentText);
    }
  }, [forceEnd]);

  const onChangeStatus = useCallback(
    (status: TimerStatus) => {
      setTimerStatus(status);
      if (isTimerFinished(status)) {
        onEvent(timerEndedEvent());
        delay.set(() => {
          sendEndEvent(currentText);
        });
      }
    },
    [currentText]
  );

  const rightHeaderDivRef = useCallback((node: HTMLDivElement | null) => {
    setLeftHeaderHeight(node?.clientHeight ?? 0);
  }, []);

  return (
    <div
      className={'typing-exercise'}
      data-testid={TYPING_EXERCISE_TEST_IDS.WRAPPER}
    >
      <InstructionContainer>
        <Title
          centered
          id={CADE_CONTENT_TITLE_ID}
          data-testid={TYPING_EXERCISE_TEST_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction
          level={'small'}
          data-testid={TYPING_EXERCISE_TEST_IDS.INSTRUCTION}
        >
          {instruction ?? t('typing.exercise.instruction')}
        </Instruction>
      </InstructionContainer>
      <div className="typing-exercise__upper-section">
        <QuestionCounter
          count={count}
          total={total}
          title={t('typing.exercise.counterTitle')}
        />
        <div className="typing-exercise__timer">
          {timerStatus === TIMER_STATUS.FINISHED && (
            <Paragraph className="typing-exercise__red-msg">
              {t('timer.timeIsOver')}
            </Paragraph>
          )}
          <Timer
            time={timeLeft}
            onChangeStatus={onChangeStatus}
            timerStatus={timerStatus}
            variant={TIMER_VARIANT.SECONDARY}
          />
        </div>
      </div>
      <Box role={'exercise'} className={'typing-exercise__box'}>
        <DualPaneLayout
          leftPane={
            <div className={'typing-exercise__box--left'}>
              <div className="typing-exercise__box-header">
                <Paragraph
                  style={{ minHeight: leftHeaderHeight }}
                  level={'large'}
                  weight={'bold'}
                  data-testid={TYPING_EXERCISE_TEST_IDS.LEFT_LABEL}
                >
                  {_leftLabel}
                </Paragraph>
              </div>
              <Box
                role={'instruction'}
                className={'typing-exercise__left-text'}
              >
                {passage.map((el, index) => (
                  <Paragraph key={`paragraph${index}`} level={'small'}>
                    {el}
                  </Paragraph>
                ))}
              </Box>
            </div>
          }
          rightPane={
            <div className={'typing-exercise__box--right'}>
              <div
                className="typing-exercise__box-header"
                style={{
                  alignItems: leftHeaderHeight > 30 ? 'flex-start' : 'center',
                }}
                ref={rightHeaderDivRef}
              >
                <Paragraph
                  style={{ wordWrap: 'break-word', maxWidth: '50%' }}
                  level={'large'}
                  weight={'bold'}
                  data-testid={TYPING_EXERCISE_TEST_IDS.RIGHT_LABEL}
                >
                  {_rightLabel}
                </Paragraph>
              </div>
              <TextArea.Field
                disabled={timerStatus === TIMER_STATUS.FINISHED}
                className="typing-exercise__textarea"
                ariaLabel={t('typing.exercise.passageAria')}
                onChange={(text) => {
                  setCurrentText(text!);
                  return '';
                }}
              />
            </div>
          }
        />
      </Box>
      <PlayAudio
        audioSrc={audioSrc}
        autoPlay={true}
        onEnded={() => setTimerStatus(TIMER_STATUS.IN_PROGRESS)}
      />
    </div>
  );
}
