import { useContext } from 'react';
import {
  AppContext,
  ModalType,
  closedModal,
  openedModal,
} from '../context/App.context';
import { VfwStorage } from '../utils/Storage';

export const useExitModal = () => {
  const { setVisibleModal = () => {} } = useContext(AppContext);
  const isTestStarted = !!VfwStorage.getItem('launchTestResponse');

  const show = (type?: ModalType) => {
    if (type) {
      setVisibleModal(openedModal(type));
    } else {
      setVisibleModal(openedModal(isTestStarted ? 'TEST' : 'PRE_TEST'));
    }
  };

  const hide = () => {
    setVisibleModal(closedModal());
  };

  return { show, hide };
};
