import './styles.scss';
import alertIcon from './warning-icon.svg';
import { VfwStorage } from '../../utils/Storage';
import { PrimaryButton } from '@versant-monorepo/cade';
import { useFocus } from 'hooks/useFocus';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { useExitTest } from '../../hooks/useExitTest';

export const GeneralErrorWrapper = () => {
  const exitTest = useExitTest();
  const titleId = 'vfw-error-title';
  const { pushEvent } = useLogger();
  useFocus(titleId, []);
  const { t } = useTranslation();
  useSetDocumentTitle(t('generalErrorWrapper.documentTitle'));
  useEffect(() => {
    pushEvent({
      level: LogLevel.ERROR,
      message: `User lands in the error page`,
      item: 'Error page',
      eventType: 'ACTION',
    });
  }, []);

  const onExit = useCallback(() => {
    pushEvent({
      level: LogLevel.INFO,
      message: `User exits from the error page`,
      item: 'Error page',
      eventType: 'ACTION',
    });
    exitTest();
  }, [pushEvent]);

  return (
    <>
      <div className="vfw-error">
        <div className="vfw-error__header"></div>
        <div className="vfw-error__content">
          <h1 id={titleId} tabIndex={-1} className="vfw-error__title">
            {t('generalErrorWrapper.h1')}
          </h1>
          <div className="vfw-error__description">
            <div className="vfw-error__description--left">
              <img src={alertIcon} alt="Alert icon" />
            </div>
            <div className="vfw-error__description--right">
              <p className="vfw-error--paragraph-bold">
                {t('generalErrorWrapper.p')}
              </p>
              <p>{t('generalErrorWrapper.p2')}</p>
            </div>
          </div>
        </div>
        <div className="vfw-error__blank"></div>
        <div className="vfw-error__footer">
          <p className="vfw-error--paragraph-bold">
            {VfwStorage.getItem('testName')}
          </p>
          <PrimaryButton error={true} onClick={onExit}>
            {t('generalErrorWrapper.exit')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
