import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForExercise,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeOpenQuestionModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  onEvent: (event: CadeEvent) => void;
  subTitle: string;
  count: number;
  total: number;
  audioSrc: string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  responseId?: string;
  leftLabel: string;
  rightLabel: string;
  instruction: string;
}
export function transformJSONToOpenQuestion(
  json: ApiModel,
  tocArray: Toc[]
): CadeOpenQuestionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.recordParams.responseId',
    'items.actions.recordParams.initialTimeout',
    'items.actions.recordParams.endTimeout',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  let leftLabel = i18n.t('openQuestions.labels.left');
  let rightLabel = i18n.t('openQuestions.labels.right');
  if (json.items[0].microInstructions[0]) {
    leftLabel = json.items[0].microInstructions[0];
  }
  if (json.items[0].microInstructions[1]) {
    rightLabel = json.items[0].microInstructions[1];
  }

  const instruction =
    json.items[0].cueInstructions?.[0] ??
    i18n.t('openQuestions.instruction.content');

  return {
    ...extractCommonItemsForExercise(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    leftLabel: leftLabel,
    rightLabel: rightLabel,
    instruction,
  };
}
