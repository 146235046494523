import './styles.scss';
import { FC } from 'react';

export interface CircularProgressProps {
  size?: number;
  thickness?: number;
  color?: string;
}

export const CADE_CIRCULAR_PROGRESS_TEST_ID = 'cade-circular-progress';

export const CircularProgress: FC<CircularProgressProps> = ({
  size = 40,
  thickness = 3.6,
  color = '#8096b3',
}) => {
  const radius = (size - thickness) / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference * circumference;

  return (
    <svg
      className="circular-progress"
      width={size}
      height={size}
      data-testid={CADE_CIRCULAR_PROGRESS_TEST_ID}
    >
      <circle
        className="circular-progress__background"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={thickness}
      />
      <circle
        className="circular-progress__indicator"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={thickness}
        stroke={color}
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
      />
    </svg>
  );
};
