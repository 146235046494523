import React, { useState, useContext, useCallback } from 'react';
import { Box, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { ListeningStatus } from '@components/Listening/commons';
import { ListenWriteExerciseContent } from './ListenWriteExerciseContent';
import { CadeEvent } from '@utils/events';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { useVolume } from '@context/Volume.context';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import {
  isTimerFinished,
  Timer,
  TimerMessage,
  TimerStatus,
  TIMER_STATUS,
  TIMER_VARIANT,
} from '@src/components/base/Timer';
import './styles.scss';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IDictationExerciseProps {
  count: number;
  total: number;
  subTitle: string;
  audioSrc: any;
  actionDurationInSeconds: number;
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  rightLabel: string;
  timeLeft: number;
  forceEnd?: boolean;
  instruction?: string;
}

export const DICTATION_EXERCISE_IDS = {
  CONTAINER: 'cade-dictation-exercise-container',
  INSTRUCTION: 'cade-dictation-exercise-instruction',
  SUBTITLE: 'cade-dictation-exercise-subtitle',
};

export function DictationExercise({
  count,
  total,
  subTitle,
  audioSrc,
  actionDurationInSeconds,
  onEvent,
  leftLabel,
  rightLabel,
  timeLeft,
  forceEnd = false,
  instruction,
}: IDictationExerciseProps) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );

  const { state } = useVolume();
  const voiceLevel = state.outputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const onChangeStatus = useCallback((status: TimerStatus) => {
    setTimerStatus(status);
  }, []);

  return (
    <>
      <div
        className="dictation-exercise-container"
        data-testid={DICTATION_EXERCISE_IDS.CONTAINER}
      >
        <InstructionContainer>
          <Title
            centered
            id={CADE_CONTENT_TITLE_ID}
            data-testid={DICTATION_EXERCISE_IDS.SUBTITLE}
          >
            {subTitle}
          </Title>
          <Instruction
            level="small"
            data-testid={DICTATION_EXERCISE_IDS.INSTRUCTION}
          >
            {instruction ?? t('dictationExercise.instruction')}
          </Instruction>
        </InstructionContainer>
        <div className="dictation-exercise__upper-section">
          <QuestionCounter
            count={count}
            total={total}
            title={t('dictationExercise.questionCountLabel')}
          />
          <div className="dictation-exercise__timer">
            <TimerMessage
              show={isTimerFinished(timerStatus)}
              className="cade-margin-right-3"
            />
            <Timer
              time={timeLeft}
              onChangeStatus={onChangeStatus}
              timerStatus={timerStatus}
              variant={TIMER_VARIANT.SECONDARY}
            />
          </div>
        </div>
        <Box role="exercise" className="example-box">
          <ListenWriteExerciseContent
            onEvent={onEvent}
            audioSrc={audioSrc}
            onTextChanged={() => {}} /* TODO remove this prop */
            listeningStatus={listeningStatus}
            setListeningStatus={setListeningStatus}
            actionDurationInSeconds={actionDurationInSeconds}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            timeLeft={timeLeft}
            forceEnd={forceEnd}
            timerStatus={timerStatus}
            setTimerStatus={setTimerStatus}
          />
        </Box>
        <div className={'voice-level-container'}>
          <VoiceLevelIndicator
            weCantHearYou={false}
            numOfCircles={6}
            listenMode={true}
            currentValue={voiceLevel}
          />
        </div>
      </div>
    </>
  );
}
