import { Action, TextInputParam, Toc, ApiModel } from '../../api.interfaces';
import { checkTextInputParams, validateWDTResponse } from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeSentenceCompletionModel {
  title: string;
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
  count: number;
  total: number;
  timeLeft: number;
  subTitle: string;
  text: string;
  responseId?: string;
  boxLabel: string;
  instruction?: string;
}
export function transformJSONToSentenceCompletion(
  json: ApiModel,
  tocArray: Toc[]
): CadeSentenceCompletionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () =>
      checkTextInputParams(
        json.items[0].actions.find(
          (el: Action) => el.actionType === 'textinput'
        )?.textInputParams
      ),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const textInput = item.actions.find((el) => el.actionType === 'textinput');
  const [letter, sectionName] = item.titles;
  const instructionText = (textInput?.textInputParams as TextInputParam[])[0]
    .prompt;
  const responseId = (textInput?.textInputParams as TextInputParam[])[0]
    .responseId;
  const title = i18n.t('part', { letter, sectionName });
  const { count, total } = item.itemSequence;
  const content = instructionText.join(' ');
  const totalSteps = tocArray.length;
  const currentStep =
    tocArray.findIndex((el) => el.itemType === item.itemType) + 1;
  const boxLabel = item.microInstructions[0];
  const instruction = json.items[0].cueInstructions?.at(0);

  return {
    title,
    subTitle: title,
    text: content,
    timeLeft: textInput?.actionDuration as number,
    count,
    total,
    totalSteps,
    currentStep,
    responseId,
    onEvent: (event: CadeEvent) => {},
    boxLabel,
    instruction,
  };
}
