import { FC, PropsWithChildren, ReactNode } from 'react';
import './Template.scss';
import { VolumeSlider } from '@versant-monorepo/cade';

interface ITemplateProps {
  footer: ReactNode;
  header: ReactNode;
  role?: 'instruction' | 'check' | 'exercise';
  volumeSlider?: boolean;
}

export const TEMPLATE_TEST_ID = 'vfw-template';
export const TEMPLATE_VOLUME_SLIDER_CONTAINER =
  'vfw-template-volume-slider-container';
export const TEMPLATE_CONTENT_TEST_ID = 'vfw-template-content';

export const Template: FC<PropsWithChildren<ITemplateProps>> = ({
  children,
  footer,
  header,
  role = 'check',
  volumeSlider = false,
}) => {
  return (
    <main data-testid={TEMPLATE_TEST_ID} className="vfw-template">
      {header}
      <div
        data-testid={TEMPLATE_CONTENT_TEST_ID}
        className={`vfw-template__content vfw-template__content--${role}`}
      >
        {volumeSlider && (
          <div
            data-testid={TEMPLATE_VOLUME_SLIDER_CONTAINER}
            className={'vfw-template__volume-slider-container'}
          >
            <VolumeSlider />
          </div>
        )}
        {children}
      </div>
      {footer}
    </main>
  );
};
