import {
  CueInstruction,
  DisplayParam,
  Toc,
  ApiModel,
} from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';

interface CadeTypingInstructionModel {
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  leftLabel?: string;
  rightLabel?: string;
  leftText: string;
  rightText: string;
  totalSteps: number;
  currentStep: number;
}

export function transformJSONToTypingInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeTypingInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
    'items.actions.displayParams.display',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const display = item.actions.find((el) => el.actionType === 'display');

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    leftLabel: (
      (display?.displayParams as DisplayParam)
        .cueInstructions as CueInstruction[]
    )[0].examples[0].prompt,
    leftText: (
      (display?.displayParams as DisplayParam)
        .cueInstructions as CueInstruction[]
    )[0].examples[1].promptText as string,
    rightLabel: (
      (display?.displayParams as DisplayParam)
        .cueInstructions as CueInstruction[]
    )[0].examples[2].response,
    rightText: (
      (display?.displayParams as DisplayParam)
        .cueInstructions as CueInstruction[]
    )[0].examples[3].responseText as string,
  };
}
