import React, { useContext } from 'react';
import {
  TroubleshootingTipsModal,
  Props as ModalProps,
} from '@components/Modal';
import { osName } from 'react-device-detect';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export function TroubleshotModal({ open, onClose }: ModalProps) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  return (
    <TroubleshootingTipsModal open={open} onClose={onClose}>
      <div className="cade-typography-text__normal">
        {osName.includes('Windows') && (
          <>
            <i>{t('systemCheck.modal.os.title')}</i>
            <br />
            {t('systemCheck.modal.os.win.subTitle')}
            <br />
            {t('systemCheck.modal.os.win.content')}
            <br />
            <br />
            <i>{t('systemCheck.modal.firewall.title')}</i>
            <br />
            {t('systemCheck.modal.firewall.content')}
            <br />
            <br />
            <i>{t('systemCheck.modal.ram.title')}</i>
            <br />
            {t('systemCheck.modal.ram.subTitle')}
            <br />
            {t('systemCheck.modal.ram.content')}
            <br />
            {t('systemCheck.modal.ram.content2')}
            <br />
            <br />
            <i>{t('systemCheck.modal.display.title')}</i>
            <br />
            {t('systemCheck.modal.display.subTitle')}
            <br />
            {t('systemCheck.modal.display.content')}
            <br />
          </>
        )}
        {osName.includes('Mac OS') && (
          <>
            <i>{t('systemCheck.modal.os.title')}</i>
            <br />
            {t('systemCheck.modal.os.macos.subTitle')}
            <br />
            {t('systemCheck.modal.os.macos.content')}
            <br />
            <br />
            <i>{t('systemCheck.modal.firewall.title')}</i>
            <br />
            {t('systemCheck.modal.firewall.content')}
            <br />
            <br />
            <i>{t('systemCheck.modal.ram.title')}</i>
            <br />
            {t('systemCheck.modal.ram.subTitle')}
            <br />
            {t('systemCheck.modal.ram.content')}
            <br />
            {t('systemCheck.modal.ram.content2')}
            <br />
            <br />
            <i>{t('systemCheck.modal.display.title')}</i>
            <br />
            {t('systemCheck.modal.display.subTitle')}
            <br />
            {t('systemCheck.modal.display.content')}
            <br />
          </>
        )}
      </div>
    </TroubleshootingTipsModal>
  );
}
