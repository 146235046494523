import { CadeEvent, timerEndedEvent, useEndedEvent } from '@utils/events';
import { Box, Paragraph, Title } from '@src/components';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Instruction } from '@components/Instruction';
import { QuestionCounter } from '@components/QuestionCounter';
import { useEffect, useState, useContext } from 'react';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import {
  isTimerFinished,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import './styles.scss';
import { useDelay } from '@utils/useDelay';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  subTitle: string;
  count: number;
  total: number;
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  timeLeft: number;
  forceEnd?: boolean;
  imageSrc: string;
  questions: { question: string; optionsText: string[]; id: string }[];
  instruction?: string;
};

export function ReadingComprehensionExercise({
  leftLabel = 'Please read the passage',
  imageSrc,
  timeLeft,
  forceEnd,
  onEvent,
  total,
  subTitle,
  count,
  questions,
  instruction,
}: Props) {
  const [timerStatus, setTimerStatus] = useState<TimerStatus>(
    TIMER_STATUS.DISABLED
  );
  const [radioValues, setRadioValues] = useState<Map<string, string>>(
    new Map()
  );
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent<
    {
      radioResponse: string;
      radioResponseId: string;
    }[]
  >(onEvent);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    questions.forEach((q) => {
      radioValues.set(q.id, '');
    });
    setTimerStatus(TIMER_STATUS.IN_PROGRESS);
  }, []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      prepareAndSendEndEvent();
    }
  }, [forceEnd]);

  const onChangeStatus = (status: TimerStatus) => {
    setTimerStatus(status);
    if (isTimerFinished(status)) {
      onEvent(timerEndedEvent());
      delay.set(() => {
        prepareAndSendEndEvent();
      });
    }
  };

  const prepareAndSendEndEvent = () => {
    const response: {
      radioResponse: string;
      radioResponseId: string;
    }[] = [];
    radioValues.forEach((value, id) => {
      response.push({ radioResponse: value, radioResponseId: id });
    });
    sendEndEvent(response);
  };

  const generateQuestions = () => {
    if (!questions) {
      return;
    }

    return questions.map((question, outerIndex) => {
      return (
        <fieldset
          className={'reading-comprehension-exercise__question-container'}
          key={question.id}
        >
          <Paragraph
            weight={'bold'}
            dangerouslySetInnerHTML={{ __html: question.question }}
          />
          {question.optionsText.map((option, innerIndex) => {
            const RADIO_ID = `cade-radio-select-id-${outerIndex}-${innerIndex}`;

            return (
              <div
                key={'option' + innerIndex}
                className={
                  'reading-comprehension-exercise__check-box-container'
                }
              >
                <input
                  type={'radio'}
                  name={question.id}
                  id={RADIO_ID}
                  value={option}
                  disabled={timerStatus == 'FINISHED'}
                  onChange={(ev) => {
                    radioValues.set(question.id, option);
                  }}
                />
                <label htmlFor={RADIO_ID}>
                  <Paragraph>{option}</Paragraph>
                </label>
              </div>
            );
          })}
        </fieldset>
      );
    });
  };

  return (
    <div className={'reading-comprehension-exercise'}>
      <InstructionContainer>
        <Title centered id={CADE_CONTENT_TITLE_ID}>
          {subTitle}
        </Title>
        <Instruction level={'small'}>
          {instruction ?? t('readingComprehension.exercise.instruction')}
        </Instruction>
      </InstructionContainer>
      <div className={'reading-comprehension-exercise__horizontal-container'}>
        <QuestionCounter
          count={count}
          total={total}
          title={t('readingComprehension.exercise.questionCountLabel')}
        />
        <TimerMessage
          className="reading-comprehension-exercise__horizontal-container__timer-message"
          show={timerStatus === TIMER_STATUS.FINISHED}
        />
        <Timer
          time={timeLeft}
          onChangeStatus={onChangeStatus}
          timerStatus={timerStatus}
          variant={TIMER_VARIANT.SECONDARY}
        />
      </div>

      <Box role={'exercise'} className={'typing-exercise__box'}>
        <DualPaneLayout
          leftPane={
            <div className={'reading-comprehension-exercise__box--left'}>
              <div className="reading-comprehension-exercise__box-header">
                <Paragraph level={'large'} weight={'bold'}>
                  {leftLabel}
                </Paragraph>
              </div>
              <Box
                role={'instruction'}
                className={'reading-comprehension-exercise__left-text'}
              >
                <img
                  src={imageSrc}
                  className={'reading-comprehension-exercise__image'}
                ></img>
              </Box>
            </div>
          }
          rightPane={
            <div className={'reading-comprehension-exercise_box--right'}>
              <div className="reading-comprehension-exercise_box-header">
                <Paragraph weight={'bold'}></Paragraph>
              </div>
              <Box role={'instruction'}>
                <div className={'reading-comprehension-exercise__box-limiter'}>
                  {generateQuestions()}
                </div>
              </Box>
            </div>
          }
        />
      </Box>
    </div>
  );
}
