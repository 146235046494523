import './styles.scss';
import { Paragraph, Space } from '@src/components';
import {
  isRecordingActive,
  RecordingState,
} from '@components/Recording/SpeakingMeter';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  audioEndedEvent,
  CadeEvent,
  recordingEndedEvent,
  useEndedEvent,
} from '@utils/events';
import {
  isTimerFinished,
  Props as TimerProps,
  Timer,
  TIMER_STATUS,
  TIMER_VARIANT,
  TimerMessage,
  TimerStatus,
} from '@components/base/Timer';
import { Optional } from '@utils/types';
import { useDelay } from '@utils/useDelay';
import { useBeep } from '@utils/useBeep';
import { Recording } from '@components/Recording/Recording';
import {
  isListeningActive,
  ListeningStatus,
} from '@components/Listening/commons';
import { Listening } from '@components/Listening';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ButtonSize } from '@components/base/Button/Button';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type LabelProps = PropsWithChildren<Optional<TimerProps, 'time'>>;

function LabelWithTimer({ children, time, ...props }: LabelProps) {
  return time ? (
    <Space
      margin={{ right: 5 }}
      className="cade-listen-record-exercise-content__label-container"
    >
      {children}
      <Timer {...props} time={time} variant={TIMER_VARIANT.SECONDARY} />
    </Space>
  ) : (
    <>{children}</>
  );
}

type Props = {
  audioSrc: string | string[];
  timeouts: {
    initial: number;
    ending: number;
    speaking: number;
  };
  forceEnd: boolean;
  listeningStatus: ListeningStatus;
  setListeningStatus: Dispatch<SetStateAction<ListeningStatus>>;
  setWarningVisible: Dispatch<SetStateAction<boolean>>;
  showRightPane?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  withoutSpeaking?: boolean;
  verticalMode?: boolean;
  recordingFinishStatus?: 'SUCCESS' | 'INACTIVE';
  onEvent: (event: CadeEvent) => void;
  playButtonText?: string;
  playButtonSize?: ButtonSize;
  timeLeft?: number;
  role?: 'instruction' | 'exercise';
  playBeepSound?: boolean;
  autoPlay?: boolean;
  setTimerStatus?: Dispatch<SetStateAction<TimerStatus>>;
  timerStatus?: TimerStatus;
};

export const ListenRecordExerciseContent = ({
  audioSrc,
  timeouts,
  forceEnd,
  listeningStatus,
  setListeningStatus,
  setWarningVisible,
  showRightPane = true,
  leftLabel = 'Listen.',
  rightLabel = 'Begin speaking.',
  withoutSpeaking = false,
  verticalMode = false,
  recordingFinishStatus = 'INACTIVE',
  onEvent,
  playButtonText,
  playButtonSize,
  timeLeft,
  role = 'exercise',
  playBeepSound = false,
  autoPlay = true,
  setTimerStatus,
  timerStatus,
}: Props) => {
  const [recordingStatus, setRecordingStatus] =
    useState<RecordingState>('EMPTY');
  const { sendEndEvent } = useEndedEvent<Blob>(onEvent);
  const [leftHeaderHeight, setLeftHeaderHeight] = useState<number>(0);
  const audioBlobRef = useRef<Blob | null>(null);
  const delay = useDelay();
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  function playAudio() {
    delay.clear();
    setListeningStatus('PLAYING');
  }

  const activePart: 'LISTENING' | 'RECORDING' | 'NONE' = isListeningActive(
    listeningStatus
  )
    ? 'LISTENING'
    : isRecordingActive(recordingStatus)
    ? 'RECORDING'
    : 'NONE';

  const beep = useBeep();

  const handleFinishRecording = (recordedData: Blob) => {
    setRecordingStatus(recordingFinishStatus);
    playBeepSound && !timeLeft && beep.playInteractionEndTone();
    audioBlobRef.current = recordedData;
    onEvent(recordingEndedEvent(recordedData));
    if (forceEnd) {
      sendEndEvent(recordedData);
      delay.clear();
    } else {
      delay.set(() => {
        sendEndEvent(recordedData);
      });
    }
  };

  useEffect(() => {
    if (autoPlay) {
      delay.set(playAudio, EXERCISE_AUTO_PLAY_TIMEOUT);
    }
  }, []);

  useEffect(() => {
    if (forceEnd) {
      // initiate finish of recording
      setRecordingStatus(recordingFinishStatus);
      if (recordingStatus === recordingFinishStatus) {
        delay.clear();
        sendEndEvent(audioBlobRef.current as Blob);
      }
    }
  }, [forceEnd]);

  const onAudioEnded = useCallback(async () => {
    setListeningStatus('COMPLETED');

    playBeepSound && (await beep.playInteractionBeginTone());

    if (withoutSpeaking) {
      sendEndEvent();
    }

    setRecordingStatus('RECORDING');
    onEvent(audioEndedEvent());
    timeLeft && setTimerStatus?.(TIMER_STATUS.IN_PROGRESS);
  }, [timeLeft, withoutSpeaking, playBeepSound, setTimerStatus]);

  useEffect(() => {
    if (timerStatus && isTimerFinished(timerStatus)) {
      setRecordingStatus('SUCCESS');
    }
  }, [timerStatus]);

  const rightHeaderDivRef = useCallback(
    (node: HTMLDivElement | null) => {
      setLeftHeaderHeight(node?.clientHeight ?? 0);
    },
    [recordingStatus]
  );

  return (
    <>
      <DualPaneLayout
        vertical={verticalMode}
        leftPane={
          <div>
            <Paragraph
              style={{ minHeight: leftHeaderHeight }}
              level={'large'}
              weight={
                activePart === 'LISTENING' ||
                (activePart === 'NONE' && listeningStatus !== 'COMPLETED')
                  ? 'bold'
                  : 'normal'
              }
            >
              {leftLabel}
            </Paragraph>
            <Listening
              audioSrc={Array.isArray(audioSrc) ? audioSrc : [audioSrc]}
              role={role}
              onClickPlay={playAudio}
              status={listeningStatus}
              playButtonText={playButtonText || t('buttons.play')}
              playButtonSize={playButtonSize}
              onAudioEnded={() => onAudioEnded()}
            />
          </div>
        }
        rightPane={
          <div>
            {showRightPane ? (
              <>
                <div ref={rightHeaderDivRef}>
                  <Paragraph
                    style={{ flex: 1 }}
                    level={'large'}
                    weight={activePart === 'RECORDING' ? 'bold' : 'normal'}
                  >
                    {rightLabel}
                  </Paragraph>
                </div>
                <Recording
                  state={recordingStatus}
                  width={431}
                  onEvent={onEvent}
                  timeouts={timeouts}
                  handleFinishRecording={handleFinishRecording}
                  setWarningVisible={setWarningVisible}
                />
              </>
            ) : null}
          </div>
        }
      />
    </>
  );
};
