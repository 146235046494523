import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { PassageReconstruction } from '@versant-monorepo/cade';
import { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Wrapper } from '../Wrapper';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToPassageReconstruction } from '../../api/parsers/PassageReconstruction/PassageReconstruction';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useBackgroundNoiseAudio } from '../../hooks/useBackgroundNoiseAudio';

export const PassageReconstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const [readingStatus, setReadingStatus] = useState(true);
  const [forceEnd, setForceEnd] = useState(false);

  const { pushEvent } = useLogger();
  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as string),
    'cade-timer-ended-event': () => setReadingStatus(false),
  });
  useFocus('passage-reconstruction-label', [readingStatus]);

  const passageReconstructionProps = useParser(
    transformJSONToPassageReconstruction,
    model as ApiModel
  );

  const { stopRecording, isVideoProctoringEnabled } = useBackgroundNoiseAudio(
    passageReconstructionProps?.responseId
  );

  useSetDocumentTitle(passageReconstructionProps?.title);

  const onEnded = async (value: string) => {
    if (isVideoProctoringEnabled) {
      stopRecording();
    }
    const responseItem = {
      id: passageReconstructionProps?.responseId,
      response: value || '',
      type: 'TEXT',
    } as TextResponse;
    pushEvent({
      item: 'Passage Reconstruction Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Passage Reconstruction Exercise',
    });
    const response = await getNextStep([responseItem]);
    switchView(CURRENT_ITEM_TYPE, response);
    setReadingStatus(true);
    setForceEnd(false);
  };

  return (
    passageReconstructionProps && (
      <>
        <Wrapper>
          <Template
            volumeSlider={true}
            role={'exercise'}
            footer={
              <Footer
                nextButtonEnabled={true}
                onNext={() => {
                  if (!readingStatus) {
                    setForceEnd(true);
                  } else {
                    setReadingStatus(false);
                  }
                }}
                role={'exercise'}
              />
            }
            header={
              <Header
                onExit={() => {
                  pushEvent({
                    item: 'Passage Reconstruction Exercise',
                    level: LogLevel.INFO,
                    message:
                      'User clicked exit on Passage Reconstruction Exercise',
                  });
                  eventHandler(exitEvent());
                }}
                currentStep={passageReconstructionProps.currentStep}
                totalSteps={passageReconstructionProps.totalSteps}
                title={passageReconstructionProps.subTitle}
              />
            }
          >
            <div className="vfw-template__content-wrapper">
              <PassageReconstruction
                key={passageReconstructionProps.responseId}
                {...passageReconstructionProps}
                onEvent={eventHandler}
                forceEnd={forceEnd}
                readingStatus={readingStatus}
              />
            </div>
          </Template>
        </Wrapper>
      </>
    )
  );
};
