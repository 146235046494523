import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeSentenceCompletionInstructionModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  subTitle: string;
  audioSrc: string[];
  instruction: string;
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  rightLabel: string;
  sentenceExample: string;
  inputValue: string;
}
export function transformJSONToSentenceCompletionInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeSentenceCompletionInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
    'items.actions.displayParams.display',
    'items.actions.playParams.url',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const display = json.items[0].actions.find(
    (el) => el.actionType === 'display'
  )?.displayParams?.display;

  let leftLabel = i18n.t('sentenceCompletion.instruction.labels.left');
  let rightLabel = i18n.t('sentenceCompletion.instruction.labels.right');
  let sentenceExample = i18n.t(
    'sentenceCompletion.instruction.sentenceExample'
  );
  let inputValue = i18n.t('sentenceCompletion.instruction.inputValue');

  if (display) {
    if (display[0] && display[0][1]) {
      leftLabel = display[0][1];
    }
    if (display[0] && display[0][2]) {
      sentenceExample = display[0][2];
    }
    if (display[0] && display[0][3]) {
      rightLabel = display[0][3];
    }
    if (display[0] && display[0][4]) {
      inputValue = display[0][4];
    }
  }

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    leftLabel,
    rightLabel,
    sentenceExample,
    inputValue,
  };
}
