import { ApiModel, AudioResponse } from '../../api/api.interfaces';
import { useRef, useState } from 'react';
import { Wrapper } from '../Wrapper';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Template } from '../../components/Template/Template';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import {
  CADE_CONTENT_TITLE_ID,
  getAudioType,
  SpeechSample,
  SpeechSampleInstruction,
} from '@versant-monorepo/cade';
import { useParser } from '../../hooks/useParser';
import { transformJSONToSelfIntro } from '../../api/parsers/SelfIntro/SelfIntro';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useTranslation } from 'react-i18next';

enum Status {
  INSTRUCTION,
  EXERCISE,
}

export const SpeechSampleWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { t } = useTranslation();
  useSetDocumentTitle(t('speechSampleWrapper.documentTitle'));
  const { switchView, model } = props;
  const [status, setStatus] = useState<Status>(Status.INSTRUCTION);
  const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);
  const { getNextStep } = useApi();
  const [forceEnd, setForceEnd] = useState(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => {
      onEnded();
    },
    'cade-recording-ended-event': (event) => {
      onRecordingEnded(event.value as Blob);
    },
  });
  useFocus(CADE_CONTENT_TITLE_ID, [status]);
  const { pushEvent } = useLogger();

  const blob = useRef<Blob | null>(null);

  const selfIntroProps = useParser(transformJSONToSelfIntro, model as ApiModel);

  const onlineStatus = useNetwork();

  const onEnded = async () => {
    const responseItem = {
      id: selfIntroProps?.responseId as string,
      response: blob.current,
      type: 'AUDIO',
    } as AudioResponse;
    const response = await getNextStep(responseItem);

    pushEvent({
      item: 'Speech Sample',
      level: LogLevel.INFO,
      message: `User go next on speech sample page`,
    });

    switchView(CURRENT_ITEM_TYPE, response);
    setNextButtonActive(false);
    setForceEnd(false);
  };

  const onRecordingEnded = async (audio: Blob | undefined) => {
    setNextButtonActive(true);
    if (audio) {
      if (!audio.size) {
        pushEvent({
          item: 'Speech Sample',
          level: LogLevel.WARN,
          message: `No audio size for Speech Sample`,
        });
      }
      blob.current = new File([audio], selfIntroProps?.responseId as string, {
        type: getAudioType().fileType,
      });
    } else {
      console.warn('No blob is available');
    }
  };

  const onInstructionEnded = () => {
    setNextButtonActive(false);
    setStatus(Status.EXERCISE);
  };

  return (
    selfIntroProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Speech Sample',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Speech Sample',
                });
                eventHandler(exitEvent());
              }}
              title={''}
            />
          }
          footer={
            <Footer
              nextButtonEnabled={nextButtonActive}
              nextButtonVisible={true}
              onNext={() => {
                if (status === Status.EXERCISE) {
                  setForceEnd(true);
                } else {
                  onInstructionEnded();
                }
              }}
              role={'check'}
            />
          }
        >
          {status === Status.INSTRUCTION ? (
            <SpeechSampleInstruction
              key={networkDependentKey(onlineStatus, selfIntroProps.responseId)}
              audioSrc={[selfIntroProps.instructionAudioSrc]}
              instruction={selfIntroProps.instruction}
              onEnded={onInstructionEnded}
              title={selfIntroProps.title}
            ></SpeechSampleInstruction>
          ) : (
            <SpeechSample
              key={networkDependentKey(onlineStatus, selfIntroProps.responseId)}
              onEvent={eventHandler}
              audioSrc={[selfIntroProps.audioSrc]}
              question={selfIntroProps.question}
              timeouts={selfIntroProps.timeouts}
              forceEnd={forceEnd}
              title={selfIntroProps.title}
            ></SpeechSample>
          )}
        </Template>
      </Wrapper>
    )
  );
};
