import './styles.scss';
import React, { useEffect, useState, useContext } from 'react';
import { Box, Paragraph, Title } from '@src/components';
import { Instruction } from '@components/Instruction';
import { Listening } from '@components/Listening';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useVolume } from '@context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export interface IReadingInstructionProps {
  subTitle: string;
  audioSrc: string[];
  instruction: string;
  sentences: string[];
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  leftLabel?: string;
  leftValue?: string;
  rightLabel?: string;
}

export const READING_INSTRUCTION_TEST_IDS = {
  CONTAINER: 'cade-reading-instruction-container',
  INSTRUCTION: 'cade-reading-instruction-element',
  SUBTITLE: 'cade-reading-instruction-subtitle',
};

export const ReadingInstruction = ({
  instruction,
  audioSrc,
  subTitle,
  sentences,
  onEvent,
  forceEnd = false,
  leftLabel = 'You hear:',
  leftValue = 'Please read sentence two.',
  rightLabel = 'You say:',
}: IReadingInstructionProps) => {
  const [instructionEnded, setInstructionEnded] = useState(false);
  const [bottomState, setBottomState] = useState<'ACTIVE' | 'INACTIVE'>(
    'INACTIVE'
  );
  const { sendEndEvent } = useEndedEvent(onEvent);
  const delay = useDelay();

  const { state } = useVolume();
  const voiceLevel = state.outputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const textPlay = t('buttons.play');
  const textReplay = t('buttons.replay');

  useEffect(() => delay.set(onClickPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  function onClickPlay() {
    setBottomState('ACTIVE');
  }

  const onAudioEnded = () => {
    delay.set(() => {
      sendEndEvent();
    });
    setInstructionEnded(true);
    setBottomState('INACTIVE');
  };

  return (
    <div
      className={'reading-instruction'}
      data-testid={READING_INSTRUCTION_TEST_IDS.CONTAINER}
    >
      <InstructionContainer>
        <Title
          centered
          level={'large'}
          id={CADE_CONTENT_TITLE_ID}
          data-testid={READING_INSTRUCTION_TEST_IDS.SUBTITLE}
        >
          {subTitle}
        </Title>
        <Instruction data-testid={READING_INSTRUCTION_TEST_IDS.INSTRUCTION}>
          {instruction}
        </Instruction>
      </InstructionContainer>
      <Box role="instruction" className="reading-instruction__top">
        <Paragraph level={'large'} weight={'bold'}>
          {t('reading.instruction.p')}
        </Paragraph>
        <div className={'reading-instruction__listening-container'}>
          <Listening
            playButtonText={instructionEnded ? textReplay : textPlay}
            status={bottomState === 'ACTIVE' ? 'PLAYING' : 'INACTIVE'}
            onClickPlay={() => {
              delay.clear();
              onClickPlay();
            }}
            audioSrc={audioSrc}
            onAudioEnded={onAudioEnded}
            role={'instruction'}
          />
        </div>
      </Box>
      <Box role={'instruction'} className="reading-instruction__bottom">
        {bottomState === 'INACTIVE' && <div className={'overlay'} />}
        <DualPaneLayout
          leftPane={
            <div className={'reading-instruction__bottom-left'}>
              <Paragraph level={'large'}>{leftLabel}</Paragraph>
              <Box withoutShadow={true} role={'instruction'}>
                {bottomState !== 'INACTIVE' && (
                  <Paragraph level={'normal'}>{leftValue}</Paragraph>
                )}
              </Box>
            </div>
          }
          rightPane={
            <div
              className={`reading-instruction__bottom-right ${
                bottomState === 'INACTIVE' &&
                'reading-instruction__bottom-right--inactive'
              }`}
            >
              <Paragraph
                level={'large'}
                weight={bottomState === 'ACTIVE' ? 'bold' : 'normal'}
              >
                {rightLabel}
              </Paragraph>
              <Box withoutShadow={true} role={'instruction'}>
                {bottomState !== 'INACTIVE' &&
                  sentences &&
                  sentences.map((el: string, index: number) => {
                    return (
                      <Paragraph
                        level={'normal'}
                        key={'paragraph' + index}
                        dangerouslySetInnerHTML={{ __html: el }}
                      />
                    );
                  })}
              </Box>
            </div>
          }
        />
      </Box>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
};
