import { Listening } from '@components/Listening';
import { Paragraph } from '@components/typography/Paragraph';
import { DualPaneLayout } from '@components/base/DualPaneLayout';
import { useEffect, useState, useContext } from 'react';
import { ListeningStatus } from '@components/Listening/commons';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { useDelay } from '@utils/useDelay';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { OnPlayCallback } from '@src/components/PlayAudio';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type ListenRecordInstructionStatus = 'INIT' | 'LISTENING' | 'DONE';

export const LISTEN_RECORD_INSTRUCTION_CONTENT_INSTRUCTION_TEST_ID =
  'cade-listen-record-instruction-content-instruction';
export const LISTEN_RECORD_INSTRUCTION_CONTENT_TEST_ID =
  'cade-listen-record-instruction-content';

export function ListenRecordInstructionContent({
  status = 'INIT',
  onStatusChange,
  audioSrc,
  onSingleAudioEnded,
  onEvent = () => {},
  forceEnd = false,
  onPlayAudio = () => {},
}: {
  status: ListenRecordInstructionStatus;
  onStatusChange: (status: ListenRecordInstructionStatus) => void;
  audioSrc: string[];
  listenPartDuration: number;
  onSingleAudioEnded?: (index: number) => void;
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  onPlayAudio?: OnPlayCallback;
}) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  useEffect(() => {
    delay.set(onPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT);
  }, []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  function onPlay() {
    onStatusChange('LISTENING');
  }

  function onAudioEnded() {
    delay.set(() => {
      sendEndEvent();
    });
    onStatusChange('DONE');
  }

  useEffect(() => {
    switch (status) {
      case 'INIT':
        setListeningStatus('INACTIVE');
        break;
      case 'LISTENING':
        setListeningStatus('PLAYING');
        break;
      case 'DONE':
        setListeningStatus('INACTIVE');
        break;
    }
  }, [status]);

  return (
    <>
      <DualPaneLayout
        leftPane={
          <div data-testid={LISTEN_RECORD_INSTRUCTION_CONTENT_TEST_ID}>
            <Paragraph
              level={'large'}
              weight="bold"
              data-testid={
                LISTEN_RECORD_INSTRUCTION_CONTENT_INSTRUCTION_TEST_ID
              }
            >
              {t('listenRecordIntructionContent.p')}
            </Paragraph>
            <Listening
              role={'instruction'}
              status={listeningStatus}
              onClickPlay={() => {
                delay.clear();
                onPlay();
              }}
              audioSrc={audioSrc}
              onPlay={onPlayAudio}
              onSingleAudioEnded={onSingleAudioEnded}
              onAudioEnded={() => onAudioEnded()}
              playButtonText={
                status === 'DONE' ? t('buttons.replay') : t('buttons.play')
              }
            />
          </div>
        }
        rightPane={<></>}
      />
    </>
  );
}
