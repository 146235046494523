import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { useState } from 'react';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { Wrapper } from '../Wrapper';
import { Template } from '../../components/Template/Template';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import {
  CADE_QUESTION_COUNTER_ID,
  TypingExercise,
} from '@versant-monorepo/cade';
import { useFocus } from '../../hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToTyping } from '../../api/parsers/Typing/Typing';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';
import { useBackgroundNoiseAudio } from '../../hooks/useBackgroundNoiseAudio';

export const TypingWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  useFocus(CADE_QUESTION_COUNTER_ID);
  const { pushEvent } = useLogger();

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as string),
    'cade-timer-ended-event': () => setNextEnabled(true),
  });

  const typingProps = useParser(transformJSONToTyping, model as ApiModel);

  const { stopRecording, isVideoProctoringEnabled } = useBackgroundNoiseAudio(
    typingProps?.responseId
  );

  useSetDocumentTitle(typingProps?.subTitle);

  const onEnded = async (value: string) => {
    if (isVideoProctoringEnabled) {
      stopRecording();
    }
    const responseItem = {
      id: typingProps?.responseId as string,
      response: value || '',
      type: 'TEXT',
    } as TextResponse;
    pushEvent({
      item: 'Typing Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Typing Exercise',
    });
    const response = await getNextStep([responseItem]);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
    setNextEnabled(false);
  };

  return (
    typingProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'exercise'}
          footer={
            <Footer
              nextButtonEnabled={nextEnabled}
              onNext={() => {
                setForceEnd(true);
              }}
              role={'exercise'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Typing Exercise',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Typing Exercise',
                });
                eventHandler(exitEvent());
              }}
              currentStep={typingProps.currentStep}
              totalSteps={typingProps.totalSteps}
              title={typingProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <TypingExercise
              key={networkDependentKey(onlineStatus, typingProps.responseId)}
              {...typingProps}
              onEvent={eventHandler}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
