import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { vfwRoutes } from '../../vfw-routes';
import CircleLoader from '../../components/CircleLoader/CircleLoader';
import { useApi } from '../../hooks/useApi';
import { launchTest } from '../../api/api';
import { VfwStorage } from 'utils/Storage';
import { generateChecks } from '../../utils/prechecks';

export const Direct = () => {
  const { directLogin } = useApi();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const token = hash.split('=')[1];

  useEffect(() => {
    if (!token) {
      navigate(vfwRoutes.login);
      return;
    }

    (async () => {
      try {
        const reservation = await directLogin(token);
        VfwStorage.setItem('isTestResumed', !!reservation?.resume);
        generateChecks();
        if (reservation.resume) {
          await launchTest();
          navigate(vfwRoutes.readyToContinue);
        } else {
          navigate(vfwRoutes.landingPage);
        }
      } catch (e: any) {
        navigate(vfwRoutes.login);
      }
    })();
  }, [token]);

  return <CircleLoader />;
};
