import {
  CADE_CONTENT_TITLE_ID,
  CADE_MICROPHONE_CHECK_TROUBLESHOOTING_LINK_ID,
  CADE_NEXT_BUTTON_ID,
  MicrophoneCheck,
} from '@versant-monorepo/cade';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { VfwStorage } from '../../utils/Storage';
import { useCheckProgress } from '../../hooks/useCheckProgress';
import { vfwRoutes } from '../../vfw-routes';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from 'hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import { passCheck } from '../../utils/prechecks';
import { serializeError } from '../../api/api.utils';

export const MicrophoneCheckWrapper = () => {
  const navigate = useNavigate();
  const { pushEvent } = useLogger();
  const { t } = useTranslation();
  useSetDocumentTitle(t('microphoneCheckWrapper.documentTitle'));
  useCheckProgress('microphone');
  const { lastEvent, eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => {
      VfwStorage.setItem('microphoneId', event.value as string);
      onClickNext();
    },
    'cade-success-event': () => focus(CADE_NEXT_BUTTON_ID),
    'cade-failure-event': () => {
      pushEvent({
        level: LogLevel.INFO,
        item: 'Microphone Check',
        message: 'Microphone check failed',
        eventType: 'ERROR',
      });
      focus(CADE_MICROPHONE_CHECK_TROUBLESHOOTING_LINK_ID);
    },
  });
  const { focus } = useFocus(CADE_CONTENT_TITLE_ID, [lastEvent]);

  const onClickNext = async () => {
    try {
      passCheck('microphone');
      pushEvent({
        level: LogLevel.INFO,
        item: 'Microphone Check',
        message: 'User clicked next on microphone check',
      });
      navigate(vfwRoutes.speakerCheck);
    } catch (e) {
      console.error(e);
      pushEvent({
        level: LogLevel.INFO,
        message: `Microphone Check error: ${serializeError(e)}`,
        item: 'Microphone Check',
        eventType: 'ERROR',
      });
      navigate(vfwRoutes.serverError);
    }
  };

  const microphoneCheckProps = {
    currentCheckingStep: 3,
    totalCheckingSteps: 5,
    onEvent: eventHandler,
  };

  return (
    <>
      <Template
        volumeSlider={true}
        header={
          <Header
            onExit={() => {
              pushEvent({
                level: LogLevel.INFO,
                item: 'Microphone Check',
                message: 'User clicked exit test on microphone check',
              });
              eventHandler(exitEvent());
            }}
            title={''}
          />
        }
        footer={
          <Footer
            nextButtonVisible={false}
            onNext={onClickNext}
            role={'check'}
          />
        }
      >
        <MicrophoneCheck {...microphoneCheckProps} />
      </Template>
    </>
  );
};
