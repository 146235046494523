import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeReadingInstructionModel {
  currentStep: number;
  totalSteps: number;
  title: string;
  onEvent: (event: CadeEvent) => void;
  subTitle: string;
  instruction: string;
  audioSrc: string[];
  sentences: string[];
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
}
export function transformJSONToReadingInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeReadingInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.displayParams.cueInstructions',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const displayAction = json.items[0].actions.find(
    (el) => el.actionType === 'display'
  );

  const leftLabel =
    displayAction?.displayParams?.display[0][1] ??
    i18n.t('reading.instruction.labels.left');
  const leftValue =
    displayAction?.displayParams?.display[0][2] ??
    i18n.t('reading.instruction.values.left');
  const rightLabel =
    displayAction?.displayParams?.display[0][3] ??
    i18n.t('reading.instruction.labels.right');

  const sentences = displayAction?.displayParams?.display[0]
    .slice(4, 8)
    .map((el) => el.replaceAll('/n', '').replaceAll('"', '')) as string[];

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    sentences,
    leftLabel,
    leftValue,
    rightLabel,
  };
}
