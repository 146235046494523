import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { ConfigContext } from '@context/CadeConfigProvider';

export interface Option {
  label: string;
  value: string;
}

export interface TypeaheadProps {
  options: Option[];
  onOptionSelected: (option: Option) => void;
  /** Specifies the required number of letters for initiating a search */
  minimumLetterCount?: number;
  /** Displays a number of results */
  resultsCount?: number;
  inputId: string;
  /** Displays scrollbar w/ results if results exceed resultsCount */
  isScrollable?: boolean;
}

export const Typeahead: React.FC<TypeaheadProps> = ({
  options,
  onOptionSelected,
  minimumLetterCount = 3,
  resultsCount = 6,
  inputId,
  isScrollable = false,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [optionSelected, setOptionSelected] = useState<Option | null>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const typeaheadContainerRef = useRef<HTMLDivElement>(null);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const debounce = (func: Function, delay: number) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      func();
    }, delay);
  };

  useEffect(() => {
    if (optionSelected) {
      onOptionSelected(optionSelected);
    } else {
      onOptionSelected({ label: '', value: '' });
    }
  }, [optionSelected]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!filteredOptions || !filteredOptions.length) {
        return;
      }

      switch (event.key) {
        case 'ArrowUp': {
          event.preventDefault();
          highlightPreviousOption();
          return;
        }
        case 'ArrowDown': {
          event.preventDefault();
          highlightNextOption();
          return;
        }
        case 'Enter': {
          event.preventDefault();
          selectHighlightedOption();
          return;
        }
      }
    };

    if (typeaheadContainerRef.current) {
      typeaheadContainerRef.current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (typeaheadContainerRef.current) {
        typeaheadContainerRef.current.removeEventListener(
          'keydown',
          handleKeyDown
        );
      }
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [filteredOptions, highlightedIndex]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    debounce(() => {
      if (value.length >= minimumLetterCount) {
        const filtered = options.filter((option) =>
          option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(
          filtered.slice(0, isScrollable ? filtered.length : resultsCount)
        );
      } else {
        setFilteredOptions([]);
      }

      setHighlightedIndex(-1);
      setOptionSelected(null);
    }, 300);
  };

  const handleInputFocus = () => {
    if (minimumLetterCount === 0) {
      setFilteredOptions(
        options.slice(0, isScrollable ? options.length : resultsCount)
      );
    }
  };

  const handleOptionSelected = (option: Option) => {
    setInputValue(option.label);
    setFilteredOptions([]);
    setHighlightedIndex(-1);
    setOptionSelected(option);
    setTimeout(() => {
      inputRef.current?.blur();
    }, 100);
  };

  const highlightPreviousOption = () => {
    setHighlightedIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex >= 0 ? newIndex : filteredOptions.length - 1;
    });
  };

  const highlightNextOption = () => {
    setHighlightedIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= filteredOptions.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  };

  const selectHighlightedOption = () => {
    if (highlightedIndex !== -1) {
      handleOptionSelected(filteredOptions[highlightedIndex]);
    }
  };

  useEffect(() => {
    if (inputRef.current && highlightedIndex !== -1) {
      inputRef.current.setAttribute(
        'aria-activedescendant',
        `option-${highlightedIndex}`
      );
    }
  }, [highlightedIndex]);

  const handleInputBlur = () => {
    setFilteredOptions([]);
    setHighlightedIndex(-1);
    if (!optionSelected) {
      setInputValue('');
    }
  };

  return (
    <div className="typeahead" id="typeahead" ref={typeaheadContainerRef}>
      <div className={'sr-only'}>
        <div role={'status'} aria-atomic={true} aria-live={'polite'}>
          {filteredOptions.length > 0 &&
            `${filteredOptions.length} results available.`}
          {highlightedIndex > -1 &&
            `${filteredOptions[highlightedIndex].value} highlighted.`}
        </div>
      </div>
      <input
        id={inputId}
        type="text"
        className={'typeahead__input'}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={t(`surveyForm.language`)}
        ref={inputRef}
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={filteredOptions.length > 0}
        aria-owns="typeahead-options"
        autoComplete={'off'}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <ul
        id="typeahead-options"
        className={`typeahead__options ${
          filteredOptions.length > 0 && 'typeahead__options--visible'
        }
        
        ${
          filteredOptions.length > 0 &&
          isScrollable &&
          'typeahead__options--scrollable'
        }
        `}
        role="listbox"
        style={
          isScrollable
            ? { height: `${(resultsCount + 1) * 30}px` }
            : { height: 'auto' }
        }
      >
        {filteredOptions.map((option, index) => (
          <li
            key={option.value}
            id={`option-${index}`}
            role="option"
            className={index === highlightedIndex ? 'highlighted' : ''}
            onClick={() => handleOptionSelected(option)}
            onMouseDown={(e) => e.preventDefault()}
            onMouseOver={() => setHighlightedIndex(index)}
            aria-selected={index === highlightedIndex}
            aria-posinset={index + 1}
            aria-setsize={filteredOptions.length}
            tabIndex={-1}
          >
            {option.label}
          </li>
        ))}
      </ul>
      <span id="autocomplete-default__assistiveHint" className={'sr-only'}>
        When autocomplete results are available use up and down arrows to review
        and enter to select. Touch device users, explore by touch or with swipe
        gestures.
      </span>
    </div>
  );
};
