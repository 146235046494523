import { ApiModel, AudioResponse } from '../../api/api.interfaces';
import { useState } from 'react';
import { Wrapper } from '../Wrapper';
import {
  CADE_QUESTION_COUNTER_ID,
  getAudioType,
  OpenQuestionsExercise,
} from '@versant-monorepo/cade';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToOpenQuestion } from '../../api/parsers/OpenQuestion/OpenQuestion';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { useNavigate } from 'react-router-dom';
import { useForceEndTrigger } from '../../hooks/useForceEndTrigger';
import { vfwRoutes } from '../../vfw-routes';

export const OpenQuestionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const navigate = useNavigate();
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const { forceEndTrigger, setForceEnd } = useForceEndTrigger();

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as Blob),
    'cade-audio-ended-event': () => setNextEnabled(true),
    'navigate-we-cant-hear-you-event': () => {
      setForceEnd('we-cant-hear-you');
    },
    'navigate-problem-audio-event': () => {
      setForceEnd('problem-with-audio');
    },
  });
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);
  const { pushEvent } = useLogger();
  const openQuestionExerciseProps = useParser(
    transformJSONToOpenQuestion,
    model as ApiModel
  );

  useSetDocumentTitle(openQuestionExerciseProps?.title);

  const onNext = async (responseItem: AudioResponse) => {
    if (forceEndTrigger === 'we-cant-hear-you') {
      navigate(vfwRoutes.weCantHearYou);
      return;
    }

    if (forceEndTrigger === 'problem-with-audio') {
      navigate(vfwRoutes.problemWithAudio);
      return;
    }

    pushEvent({
      item: 'Open Question Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Open Question Exercise',
    });

    const response = await getNextStep(responseItem);
    switchView(CURRENT_ITEM_TYPE, response);
    setNextEnabled(false);
    setForceEnd(null);
  };

  const onEnded = async (audio: Blob = new Blob()) => {
    if (!audio.size) {
      pushEvent({
        item: 'Open Question Exercise',
        level: LogLevel.WARN,
        message: `No audio size for Open Question Exercise, question ${openQuestionExerciseProps?.count} of ${openQuestionExerciseProps?.total}`,
      });
    }
    const file = new File(
      [audio],
      openQuestionExerciseProps?.responseId as string,
      {
        type: getAudioType().fileType,
      }
    );

    const responseItem = {
      id: openQuestionExerciseProps?.responseId as string,
      response: file,
      type: 'AUDIO',
    } as AudioResponse;

    await onNext(responseItem);
  };

  return (
    openQuestionExerciseProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'exercise'}
          footer={
            <Footer
              nextButtonEnabled={nextEnabled}
              onNext={() => {
                setForceEnd('next-clicked');
              }}
              role={'exercise'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Open Question Exercise',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Open Question Exercise',
                });
                eventHandler(exitEvent());
              }}
              currentStep={openQuestionExerciseProps.currentStep}
              totalSteps={openQuestionExerciseProps.totalSteps}
              title={openQuestionExerciseProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <OpenQuestionsExercise
              key={networkDependentKey(
                onlineStatus,
                openQuestionExerciseProps.responseId
              )}
              {...openQuestionExerciseProps}
              onEvent={eventHandler}
              forceEnd={forceEndTrigger !== null}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
