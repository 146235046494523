export interface IIconProps {
  width?: string;
  height?: string;
}

export function BannerSuccessIcon({ width = '24', height = '24' }: IIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM11.2919 17.3527L17.7763 8.0893C18.1669 7.53128 18.0312 6.76226 17.4732 6.37165C16.9152 5.98103 16.1462 6.11674 15.7555 6.67476L10.1257 14.7174L7.87505 12.326C7.40821 11.83 6.62766 11.8064 6.13165 12.2732C5.63564 12.74 5.61198 13.5206 6.07882 14.0166L9.36857 17.5119C9.80054 17.9709 10.5011 18.0254 10.9963 17.6612C11.0817 17.6087 11.1666 17.5255 11.2544 17.4057C11.2674 17.3883 11.2799 17.3706 11.2919 17.3527Z"
      />
    </svg>
  );
}
