import React, { useCallback, useContext, useState } from 'react';
import { ChecksHeader } from '@components/ChecksHeader';
import {
  MicrophoneCheckComponent,
  MicrophoneCheckState,
} from '../components/MicrophoneCheck';
import { TroubleshotModal } from '../components/MicrophoneCheck/TroubleshotModal';
import {
  CadeEvent,
  exitEvent,
  troubleshootClickEvent,
  useEndedEvent,
} from '@utils/events';
import './styles.scss';
import { Space } from '@components/Space';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export { CADE_MICROPHONE_CHECK_TROUBLESHOOTING_LINK_ID } from '../components/MicrophoneCheck';

export interface IMicrophoneCheckProps {
  currentCheckingStep: number;
  totalCheckingSteps: number;
  onEvent: (event: CadeEvent) => void;
}

export function MicrophoneCheck({
  currentCheckingStep,
  totalCheckingSteps,
  onEvent,
}: IMicrophoneCheckProps) {
  const [state, setState] = useState<MicrophoneCheckState>('ALLOW');
  const [open, setOpen] = useState(false);
  const { sendEndEvent } = useEndedEvent<string>(onEvent);
  const onExit = useCallback(() => onEvent(exitEvent()), []);
  const onFinish = sendEndEvent;
  const onClose = useCallback(() => setOpen(false), []);
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const onTroubleshootClickCallback = useCallback(() => {
    onEvent(troubleshootClickEvent());
    setOpen(true);
  }, []);

  return (
    <div className="microphone-check__check-container">
      <Space
        className={'microphone-check__header'}
        margin={{ top: 8, bottom: 8 }}
      >
        <ChecksHeader
          headerText={t('microphoneCheck.header.text')}
          title={t('microphoneCheck.header.title')}
          currentStep={currentCheckingStep}
          totalSteps={totalCheckingSteps}
        />
      </Space>
      <MicrophoneCheckComponent
        state={state}
        onExit={onExit}
        onFinish={onFinish}
        onSetState={setState}
        onClickTroubleshooting={onTroubleshootClickCallback}
        successButtonText={t('microphoneCheck.button.success')}
        focusMicrophoneSelector={true}
        onEvent={onEvent}
      />
      <TroubleshotModal open={open} onClose={onClose} state={state} />
    </div>
  );
}
