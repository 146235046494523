import { Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractCommonItemsForInstruction,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';

interface CadeOpenQuestionInstructionModel {
  title: string;
  instruction: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  totalSteps: number;
  currentStep: number;
  audioDuration: number;
}

export function transformJSONToOpenQuestionInstruction(
  json: ApiModel,
  tocArray: Toc[]
): CadeOpenQuestionInstructionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.displayParams.display',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  let duration = json.items[0].actions[0].actionDuration * 1000;

  return {
    ...extractCommonItemsForInstruction(json, tocArray),
    onEvent: (event: CadeEvent) => {},
    audioDuration: duration,
  };
}
