import { Paragraph } from '@components/typography/Paragraph';
import { Box } from '@components/base/Box';
import React from 'react';
import classNames from 'classnames';
import { ScriptLine } from '@utils/types';

export type ReadCardSideProps = {
  script?: ScriptLine;
  status?: 'ONLY_HEADER_FREEZE' | 'ONLY_HEADER' | 'BOLDED' | 'NORMAL';
  regexForBolding?: string | RegExp;
};

export const READ_CARD_SIDE_HEADER_TEST_ID = 'cade-read-card-side-header';
export const READ_CARD_SIDE_CONTENT_TEST_ID = 'cade-read-card-side-content';

export function ReadCardSide({
  status,
  script,
  regexForBolding,
}: ReadCardSideProps) {
  const textClasses = classNames({
    'disabled-color': status === 'ONLY_HEADER_FREEZE',
  });

  function extractBoldedPart(scriptLine: string | undefined): string {
    if (!scriptLine) {
      return '';
    }
    const regex = new RegExp(regexForBolding!, 'g');
    return scriptLine.replace(regex, (s) => '<b>' + s + '</b>');
  }

  return (
    <>
      <Paragraph
        data-testid={READ_CARD_SIDE_HEADER_TEST_ID}
        className={`cade-margin-bottom-2 ${textClasses} read-card-side__header`}
        level="large"
        weight={status === 'BOLDED' ? 'bold' : 'normal'}
      >
        {script?.header}
      </Paragraph>
      <Box
        className="read-card-side__box"
        withoutShadow={true}
        role="instruction"
      >
        <Paragraph
          level={'normal'}
          className={textClasses}
          data-testid={READ_CARD_SIDE_CONTENT_TEST_ID}
          weight={status === 'BOLDED' && !regexForBolding ? 'bold' : 'normal'}
        >
          {status !== 'ONLY_HEADER' &&
            status !== 'ONLY_HEADER_FREEZE' &&
            !regexForBolding &&
            script?.content}
          {status !== 'ONLY_HEADER' &&
            status !== 'ONLY_HEADER_FREEZE' &&
            regexForBolding && (
              <span
                dangerouslySetInnerHTML={{
                  __html: extractBoldedPart(script?.content),
                }}
              />
            )}
        </Paragraph>
      </Box>
    </>
  );
}
