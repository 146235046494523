import { ApiModel, Toc } from '../api/api.interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vfwRoutes } from '../vfw-routes';
import { VfwStorage } from '../utils/Storage';
import { useTranslation } from 'react-i18next';
import { LogLevel } from '../api/Logger';
import { useLogger } from './useLogger';
import { serializeError } from '../api/api.utils';

type ParserFunction<T> = (
  model: ApiModel,
  tocArray: Toc[],
  t?: (key: string) => string
) => T;

export function useParser<T>(parser: ParserFunction<T>, model: ApiModel) {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pushEvent } = useLogger();

  useEffect(() => {
    if (shouldNavigate) {
      navigate(vfwRoutes.serverError);
    }
  }, [shouldNavigate]);

  const parsedItem = useMemo(() => {
    try {
      return parser(model, VfwStorage.getItem('toc') as Toc[], t);
    } catch (error) {
      pushEvent({
        level: LogLevel.ERROR,
        message: `useParser error: ${serializeError(error)}`,
        item: 'useParser',
        eventType: 'ERROR',
      });
      setShouldNavigate(true);
      return null;
    }
  }, [model]);

  return parsedItem;
}
