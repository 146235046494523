import { Link, useNavigate } from 'react-router-dom';
import { vfwRoutes } from '../../vfw-routes';
import { VfwStorage } from '../../utils/Storage';
import { Template } from '../../components/Template/Template';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import React, {
  HTMLProps,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import image from '../../assets/things-you-need-to-know.svg';
import computer from './icons/computer.svg';
import internet from './icons/internet.svg';
import camera from './icons/camera.svg';
import quiet_space from './icons/quiet-space.svg';
import time from './icons/time.svg';
import text_bubble from './icons/textBubble.svg';
import { Input, Paragraph, useFeature } from '@versant-monorepo/cade';
import './styles.scss';
import { CONTENT_TITLE_ID, useFocus } from 'hooks/useFocus';
import { ContentTitle } from 'components/ContentTitle/ContentTitle';
import { isSpeakingTest, parseMarkup } from '../../utils/utils';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useExitModal } from '../../hooks/useModal';
import { LogLevel } from '../../api/Logger';
import { useLogger } from '../../hooks/useLogger';
import { useTranslation } from 'react-i18next';

type HiddenCheckboxForY11AProps = PropsWithChildren<{
  inputProps: HTMLProps<HTMLInputElement>;
  labelProps: HTMLProps<HTMLLabelElement>;
}>;

const HIDDEN_CHECKBOX_ID = 'cade-hidden-checkbox-y11a';
const HiddenCheckboxForY11A = ({
  inputProps,
  labelProps,
  children = "Yes, I'm ready.",
}: HiddenCheckboxForY11AProps) => (
  <>
    <input
      {...inputProps}
      className="sr-only"
      id={HIDDEN_CHECKBOX_ID}
      type="checkbox"
    />
    <label
      {...labelProps}
      className="sr-only"
      htmlFor={HIDDEN_CHECKBOX_ID}
      tabIndex={-1}
    >
      {children}
    </label>
  </>
);

export const ThingsYouNeedToKnowWrapper = () => {
  const navigate = useNavigate();
  const { pushEvent } = useLogger();
  const useNewCheckbox = useFeature('USE_NEW_CHECKBOX');
  const exitModal = useExitModal();
  const [checkboxFocus, setCheckboxFocus] = useState(false);

  useFocus(CONTENT_TITLE_ID);
  const { t } = useTranslation();
  useSetDocumentTitle(t('thingsYouNeedToKnowWrapper.documentTitle'));

  const [checked, setChecked] = useState(false);
  type ListItem = {
    icon: string;
    title: string;
    description: string;
  };
  const listItems = [
    isSpeakingTest()
      ? {
          icon: computer,
          title: t('thingsYouNeedToKnowWrapper.items.computer.title'),
          description: t('thingsYouNeedToKnowWrapper.items.computer.desc'),
        }
      : null,
    VfwStorage.getItem('isTestProctored')
      ? {
          icon: camera,
          title: t('thingsYouNeedToKnowWrapper.items.camera.title'),
          description: t('thingsYouNeedToKnowWrapper.items.camera.desc'),
        }
      : null,
    {
      icon: internet,
      title: t('thingsYouNeedToKnowWrapper.items.internet.title'),
      description: t('thingsYouNeedToKnowWrapper.items.internet.desc'),
    },
    {
      icon: quiet_space,
      title: t('thingsYouNeedToKnowWrapper.items.quietSpace.title'),
      description: t('thingsYouNeedToKnowWrapper.items.quietSpace.desc'),
    },
    {
      icon: text_bubble,
      title: t('thingsYouNeedToKnowWrapper.items.textBubble.title'),
      description: t('thingsYouNeedToKnowWrapper.items.textBubble.desc'),
    },
    VfwStorage.getItem('reservationTinResponse')?.testTime ?? undefined
      ? {
          icon: time,
          title: t('thingsYouNeedToKnowWrapper.items.time.title'),
          description:
            VfwStorage.getItem('reservationTinResponse')?.testTime ?? undefined,
        }
      : null,
  ].filter((item) => item !== null) as ListItem[]; // remove optional elements;

  const onLabelKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.code === 'Space') {
      (event.currentTarget as HTMLLabelElement).click();
    }
    return;
  }, []);

  const onExit = useCallback(() => {
    pushEvent({
      level: LogLevel.INFO,
      item: 'Things you need to know page',
      message: 'Exit button clicked on things you need to know',
    });
    exitModal.show('PRE_TEST');
  }, [exitModal]);
  const onNext = useCallback(() => {
    pushEvent({
      level: LogLevel.INFO,
      item: 'Things you need to know page',
      message: 'Next clicked on things you need to know',
    });
    navigate(vfwRoutes.systemCheck);
  }, [navigate]);

  const checkboxOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setChecked(event.target.checked),
    []
  );
  const checkboxLabelOnKeyDown = useCallback(
    (event: any) => onLabelKeyDown(event),
    [onLabelKeyDown]
  );

  const postponeTestOnClick = useCallback(() => {
    pushEvent({
      level: LogLevel.INFO,
      item: 'Things you need to know page',
      message: `I'll take my test later clicked on things you need to know`,
    });
    exitModal.show('POSTPONE_TEST');
  }, [exitModal]);

  return (
    <>
      <Template
        header={<Header onExit={onExit} title={''} />}
        footer={
          <Footer nextButtonEnabled={checked} onNext={onNext} role={'check'} />
        }
      >
        <div className={'cade-things-you-need-to-know'}>
          <ContentTitle centered={true} level={'large'}>
            {t('thingsYouNeedToKnowWrapper.title')}
          </ContentTitle>
          <div className="cade-things-you-need-to-know__box-container">
            <div className="cade-things-you-need-to-know__box-container--left">
              <img src={image} alt="" aria-hidden="true" />
            </div>
            <div className="cade-things-you-need-to-know__box-container--right">
              <ul className="cade-things-you-need-to-know__list">
                {listItems.map((el, i) => {
                  return (
                    <li
                      key={i}
                      className={'cade-things-you-need-to-know__list-item'}
                    >
                      <div className="cade-things-you-need-to-know__list-item--left">
                        <img src={el.icon} alt="" aria-hidden={true} />
                      </div>
                      <div className="cade-things-you-need-to-know__list-item--right">
                        <Paragraph level={'normal'} className={'itemHeader'}>
                          {el.title}
                        </Paragraph>
                        <Paragraph
                          level={'small'}
                          dangerouslySetInnerHTML={{
                            __html: parseMarkup(el.description),
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div
                className={`cade-things-you-need-to-know__footer ${
                  useNewCheckbox ? '' : 'old-checkbox'
                }`}
              >
                <Paragraph level={'small'} tabIndex={-1}>
                  {t('thingsYouNeedToKnowWrapper.p')}
                </Paragraph>
                <div
                  className={`cade-things-you-need-to-know__check-container ${
                    checkboxFocus ? 'focused' : ''
                  }`}
                >
                  {useNewCheckbox ? (
                    <>
                      <HiddenCheckboxForY11A
                        inputProps={{
                          onChange: checkboxOnChange,
                          onBlur: () => setCheckboxFocus(false),
                          onFocus: () => {
                            setCheckboxFocus(true);
                          },
                        }}
                        labelProps={{
                          'aria-label':
                            "Are you ready to take this test? Yes, I'm ready",
                          onKeyDown: checkboxLabelOnKeyDown,
                        }}
                      />
                      <Input.Checkbox
                        role={'none'}
                        onChange={checkboxOnChange}
                        checked={checked}
                        tabIndex={-1}
                        labelProps={{
                          tabIndex: -1,
                          role: 'none',
                          onKeyDown: checkboxLabelOnKeyDown,
                        }}
                      >
                        {t('thingsYouNeedToKnowWrapper.confirm')}
                      </Input.Checkbox>
                    </>
                  ) : (
                    <>
                      <input
                        id={'check'}
                        className="sr-only"
                        type="checkbox"
                        tabIndex={0}
                        aria-label={"Yes, I'm ready."}
                        onChange={(event) => setChecked(event.target.checked)}
                      />
                      <label
                        onKeyDown={(event) => onLabelKeyDown(event as any)}
                        htmlFor={'check'}
                        aria-hidden={true}
                        tabIndex={-1}
                      >
                        {t('thingsYouNeedToKnowWrapper.confirm')}
                      </label>
                    </>
                  )}
                </div>
                <Link
                  to="#"
                  onClick={postponeTestOnClick}
                  className="vfw__blue-link"
                >
                  {t('thingsYouNeedToKnowWrapper.later')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
};
