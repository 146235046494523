import { ApiModel, TextResponse } from '../../api/api.interfaces';
import { useState } from 'react';
import {
  CADE_QUESTION_COUNTER_ID,
  ResponseSelectionExercise,
} from '@versant-monorepo/cade';
import { Wrapper } from '../Wrapper';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToResponseSelection } from '../../api/parsers/ResponseSelection/ResponseSelection';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const ResponseSelectionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': (event) => onEnded(event.value as string),
    'cade-audio-ended-event': () => setNextEnabled(true),
  });
  useFocus(CADE_QUESTION_COUNTER_ID, [model]);
  const { pushEvent } = useLogger();

  const { getNextStep } = useApi();
  const [forceEnd, setForceEnd] = useState<boolean>(false);

  const responseSelectionExerciseProps = useParser(
    transformJSONToResponseSelection,
    model as ApiModel
  );

  useSetDocumentTitle(responseSelectionExerciseProps?.subTitle);

  const onlineStatus = useNetwork();

  const onEnded = async (value: string) => {
    const responseItem = {
      id: responseSelectionExerciseProps?.responseId as string,
      response: value || '',
      type: 'TEXT',
    } as TextResponse;

    pushEvent({
      item: 'Response Selection Exercise',
      level: LogLevel.INFO,
      message: 'User go next on Response Selection Exercise',
    });
    const response = await getNextStep([responseItem]);
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
    setNextEnabled(false);
  };

  return (
    responseSelectionExerciseProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'exercise'}
          footer={
            <Footer
              nextButtonEnabled={nextEnabled}
              onNext={() => {
                setForceEnd(true);
              }}
              role={'exercise'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Response Selection Exercise',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Response Selection Exercise',
                });
                eventHandler(exitEvent());
              }}
              currentStep={responseSelectionExerciseProps.currentStep}
              totalSteps={responseSelectionExerciseProps.totalSteps}
              title={responseSelectionExerciseProps.subTitle}
            />
          }
        >
          <div className="vfw-template__content-wrapper">
            <ResponseSelectionExercise
              key={networkDependentKey(
                onlineStatus,
                responseSelectionExerciseProps.responseId
              )}
              {...responseSelectionExerciseProps}
              onEvent={eventHandler}
              leftLabel={responseSelectionExerciseProps.leftLabel!}
              rightLabel={responseSelectionExerciseProps.rightLabel!}
              forceEnd={forceEnd}
            />
          </div>
        </Template>
      </Wrapper>
    )
  );
};
