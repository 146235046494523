import { useContext, useEffect } from 'react';
import { AppContext } from '../context/App.context';
import {
  navigateProblemAudioEvent,
  navigateWeCantHearYouEvent,
} from './useHandleAppEvent';

const MAX_WE_CANT_HEAR_YOU_ALERT = 3;
const MAX_WE_CANT_HEAR_YOU_PAGE = 2;

export const useWeCantHearYou = (eventHandler?: Function) => {
  const {
    weCantHearYouCount,
    setWeCantHearYouCount,
    audioTroubleshootingCount,
  } = useContext(AppContext);

  useEffect(() => {
    if (weCantHearYouCount === MAX_WE_CANT_HEAR_YOU_ALERT) {
      if (audioTroubleshootingCount < MAX_WE_CANT_HEAR_YOU_PAGE) {
        eventHandler && eventHandler(navigateWeCantHearYouEvent());
      } else {
        eventHandler && eventHandler(navigateProblemAudioEvent());
      }
    }
  }, [weCantHearYouCount]);

  const canHearYou = () => setWeCantHearYouCount(0);

  const cannotHearYou = () =>
    setWeCantHearYouCount((weCantHearYouCount as number) + 1);

  return {
    canHearYou,
    cannotHearYou,
  };
};
