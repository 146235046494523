import { useContext, useEffect, useRef, useState } from 'react';
import { useSoundFrequency } from '../../utils/useSoundFrequency';
import { useMicrophoneStream } from '../../utils/useMicrophoneStream';
import { MicrophoneContext } from '../../context/Microphone.context';
import {
  TimeoutKind,
  useTimeBasedVolumeAnalyzer,
} from './useTimeBasedVolumeAnalyzer';
import { requestRecorder } from './requestRecorder';

export const useRecording = ({
  handleFinishRecording = () => {},
  timeouts = undefined,
  onTimeout = () => {},
}: {
  handleFinishRecording?: (recordedData: Blob) => void;
  timeouts?: {
    initialSilence: number;
    endingSilence: number;
    cantHearYouSilence?: number;
    max: number;
  };
  onTimeout?: (kind: TimeoutKind) => void;
}) => {
  const { microphoneId } = useContext(MicrophoneContext);
  const micStream = useMicrophoneStream();
  const audioChunks = useRef<Blob[]>([]);

  const [soundFrequency, setSoundFrequency] = useState<number[]>([]);
  // sound analysing for sound visualization
  const [startAnalyzing, stopAnalyzing] = useSoundFrequency(
    15,
    setSoundFrequency,
    () => {},
    micStream
  );
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [recordedData, setRecordedData] = useState<Blob | null>(null);

  const emptyTimeouts = { initialSilence: 0, endingSilence: 0, max: 0 };
  const [startTimeouts, stopTimeouts] = useTimeBasedVolumeAnalyzer(
    timeouts == null ? emptyTimeouts : timeouts,
    onTimeout,
    micStream
  );

  const onStopListener = () => {
    const audioBlob = new Blob(audioChunks.current);
    stopAnalyzing();
    stopTimeouts();
    setRecordedData(audioBlob);
    handleFinishRecording(audioBlob);
  };

  const onDataAvailableListener = (event: BlobEvent) => {
    audioChunks.current.push(event.data);
  };

  useEffect(() => {
    try {
      if (micStream) {
        if (recorder === null) {
          setRecorder(requestRecorder(micStream));
          return;
        }
        if (isRecording) {
          audioChunks.current = [];
          recorder.start();
        } else {
          recorder.stop();
        }

        recorder.addEventListener('dataavailable', onDataAvailableListener);
        recorder.addEventListener('stop', onStopListener);
      }
    } catch (e) {
      console.error(e);
    }
    return () => {
      recorder?.removeEventListener('dataavailable', onDataAvailableListener);
      recorder?.removeEventListener('stop', onStopListener);
    };
  }, [recorder, isRecording, micStream]);

  const startRecording = () => {
    startAnalyzing();
    startTimeouts();
    setIsRecording(true);
  };
  const stopRecording = () => {
    stopAnalyzing();
    stopTimeouts();
    setIsRecording(false);
  };

  return {
    startRecording,
    stopRecording,
    soundFrequency,
    recordedData,
    isRecording,
  } as const;
};
