import React, { ChangeEvent, forwardRef, useContext } from 'react';
import { MicrophoneOptions } from '@utils/microphone';
import './styles.scss';
import { ConfigContext } from '@src/context/CadeConfigProvider';

type Props = {
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  microphoneOptions: MicrophoneOptions;
  microphoneId?: string;
  disallowMicrophone: boolean;
  disabled: boolean;
};

export const MicrophoneSelector = forwardRef<HTMLSelectElement, Props>(
  function MicrophoneSelector(
    { onChange, microphoneOptions, microphoneId, disallowMicrophone, disabled },
    ref
  ) {
    const {
      i18n: { t },
    } = useContext(ConfigContext);
    return (
      <div className="microphone-selector__container">
        <select
          ref={ref}
          className={`microphone-selector__select ${
            (!microphoneOptions.microphones.length || disallowMicrophone) &&
            !disabled
              ? 'microphone-selector__error'
              : ''
          }`}
          aria-hidden={disabled}
          disabled={disabled}
          tabIndex={0}
          onChange={(e) => {
            onChange(e);
          }}
          value={
            microphoneId != null && microphoneId != ''
              ? microphoneId
              : t('microphoneCheck.selectMicrophone')
          }
        >
          {microphoneOptions.microphones.map((m, index: number) => (
            <option key={`${m.deviceId}_${index}`} value={m.deviceId}>
              {m.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
);
