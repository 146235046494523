import { ApiModel } from '../../api/api.interfaces';
import { useState } from 'react';
import {
  CADE_CONTENT_TITLE_ID,
  ReadingInstruction,
} from '@versant-monorepo/cade';
import './styles.scss';
import { useApi } from '../../hooks/useApi';
import { networkDependentKey, useNetwork } from '../../hooks/useNetwork';
import { Wrapper } from '../Wrapper';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Template } from '../../components/Template/Template';
import { exitEvent, useHandleAppEvent } from '../../hooks/useHandleAppEvent';
import { useFocus } from 'hooks/useFocus';
import { useParser } from '../../hooks/useParser';
import { transformJSONToReadingInstruction } from '../../api/parsers/Reading/ReadingInstruction';
import { CURRENT_ITEM_TYPE } from '../../api/api';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/Logger';

export const ReadingInstructionWrapper = (props: {
  switchView: Function;
  model?: ApiModel;
}) => {
  const { switchView, model } = props;
  const { getNextStep } = useApi();
  const onlineStatus = useNetwork();
  const [forceEnd, setForceEnd] = useState<boolean>(false);

  const { eventHandler } = useHandleAppEvent({
    'cade-ended-event': () => inEnded(),
  });
  const { pushEvent } = useLogger();
  useFocus(CADE_CONTENT_TITLE_ID, [model]);

  const readingInstructionProps = useParser(
    transformJSONToReadingInstruction,
    model as ApiModel
  );

  useSetDocumentTitle(readingInstructionProps?.title);

  const inEnded = async () => {
    pushEvent({
      item: 'Reading Instruction',
      level: LogLevel.INFO,
      message: 'User clicked next on Reading Instruction',
    });
    const response = await getNextStep();
    switchView(CURRENT_ITEM_TYPE, response);
    setForceEnd(false);
  };

  return (
    readingInstructionProps && (
      <Wrapper>
        <Template
          volumeSlider={true}
          role={'instruction'}
          footer={
            <Footer
              nextButtonVisible={true}
              onNext={() => setForceEnd(true)}
              role={'instruction'}
            />
          }
          header={
            <Header
              onExit={() => {
                pushEvent({
                  item: 'Reading Instruction',
                  level: LogLevel.INFO,
                  message: 'User clicked exit on Reading Instruction',
                });
                eventHandler(exitEvent());
              }}
              currentStep={readingInstructionProps.currentStep}
              totalSteps={readingInstructionProps.totalSteps}
              title={readingInstructionProps.subTitle}
              role={'instruction'}
            />
          }
        >
          <ReadingInstruction
            key={networkDependentKey(onlineStatus)}
            {...readingInstructionProps}
            onEvent={eventHandler}
            forceEnd={forceEnd}
          />
        </Template>
      </Wrapper>
    )
  );
};
