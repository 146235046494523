import './styles.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Paragraph, Title } from '@src/components';
import { Listening } from '@components/Listening';
import { ListeningStatus } from '@components/Listening/commons';
import { audioEndedEvent, CadeEvent, useEndedEvent } from '@utils/events';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { Space } from '@components/Space';
import { useDelay } from '@utils/useDelay';
import { EXERCISE_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';

export type Props = {
  title: string;
  audioSrc: string[];
  content: {
    title: string;
    intro: string;
    paragraphs: string[];
    ready?: string;
  };
  onEvent: (event: CadeEvent) => void;
};

export function SpeakingTips({ title, audioSrc, onEvent, content }: Props) {
  const [listeningStatus, setListeningStatus] =
    useState<ListeningStatus>('INACTIVE');
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);
  const {
    i18n: { t },
  } = useContext(ConfigContext);
  const [buttonLabel, setButtonLabel] = useState<string>(t('buttons.play'));

  useEffect(() => delay.set(playAudio, EXERCISE_AUTO_PLAY_TIMEOUT), []);

  function playAudio() {
    delay.clear();
    setListeningStatus('PLAYING');
  }

  const onClickInstructionPlay = useCallback(() => {
    delay.clear();
    setListeningStatus('PLAYING');
  }, []);

  const _onEvent = useCallback(
    (event: CadeEvent) => {
      if (event.name === 'cade-audio-ended-event') {
        delay.set(sendEndEvent);
      }
      onEvent(event);
    },
    [onEvent]
  );

  const onEnded = useCallback(() => {
    setListeningStatus('INACTIVE');
    setButtonLabel(t('buttons.replay'));
    _onEvent(audioEndedEvent());
  }, []);

  return (
    <div className="cade-speaking-tips-wrapper">
      <Title
        id={CADE_CONTENT_TITLE_ID}
        level="large"
        centered={true}
        className="cade-margin-bottom-6"
      >
        {title || t('speakingTips.title')}
      </Title>
      <Box role="instruction" className="cade-speaking-tips__box">
        <Paragraph
          className="cade-speaking-tips__paragraph--beginning cade-padding-left-7"
          level="large"
          aria-hidden={true}
          dangerouslySetInnerHTML={{ __html: content.title }}
        ></Paragraph>
        <div className="cade-speaking-tips__listening-container">
          <Listening
            role="instruction"
            status={listeningStatus}
            onClickPlay={onClickInstructionPlay}
            playButtonText={buttonLabel}
            audioSrc={audioSrc}
            onAudioEnded={onEnded}
          />
        </div>
        <Space
          padding={{ left: 7, right: 7 }}
          className="cade-speaking-tips__content"
        >
          <div aria-hidden={true}>
            <Paragraph
              className="mt-15"
              level="large"
              dangerouslySetInnerHTML={{ __html: content.intro }}
            ></Paragraph>
            <ul className={'cade-speaking-tips__list'}>
              {content.paragraphs.map((p, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: p }}></li>
              ))}
            </ul>
            {content.ready && (
              <Paragraph
                className="cade-speaking-tips__paragraph--ending"
                level="large"
              >
                {content.ready}
              </Paragraph>
            )}
          </div>
        </Space>
      </Box>
    </div>
  );
}
