import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  translationEnglish,
  translationGerman,
  translationSpanish,
  translationFrench,
  translationPortuguese,
} from '@versant-monorepo/translations';
import { getBrowserLanguage } from '../utils/utils';
import { VfwStorage } from '../utils/Storage';
import { vfwRoutes } from '../vfw-routes';

export const resources = {
  en: { translation: translationEnglish },
  de: { translation: translationGerman },
  fr: { translation: translationFrench },
  es: { translation: translationSpanish },
  pt: { translation: translationPortuguese },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng:
    process.env.REACT_APP_ENABLE_TRANSLATIONS === 'true'
      ? window.location.pathname === vfwRoutes.root
        ? getBrowserLanguage(Object.keys(resources))
        : VfwStorage.getItem('language') || 'en'
      : 'en',
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
