export function MicOnIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11C20.5523 11 21 11.4477 21 12V13C21 17.6326 17.5 21.4476 13 21.9451V23.5C13 23.7761 12.7761 24 12.5 24H11.5C11.2239 24 11 23.7761 11 23.5V21.9451C6.50005 21.4476 3 17.6326 3 13V12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12V13C5 16.866 8.13401 20 12 20C15.866 20 19 16.866 19 13V12C19 11.4477 19.4477 11 20 11ZM12 0C14.7614 0 17 2.23858 17 5V13C17 15.7614 14.7614 18 12 18C9.23858 18 7 15.7614 7 13V5C7 2.23858 9.23858 0 12 0Z"
      />
    </svg>
  );
}
