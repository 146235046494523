import {
  createElement,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';
import './styles.scss';
import classNames from 'classnames';

export type TagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type Props = PropsWithChildren<{
  centered?: boolean;
  level?: 'small' | 'normal' | 'large';
  tabIndex?: number;
  headingType?: TagType;
}> &
  HTMLAttributes<HTMLHeadingElement>;

export const Title = forwardRef<HTMLHeadingElement, Props>(
  (
    {
      centered = false,
      level = 'normal',
      className,
      children,
      tabIndex = -1,
      headingType = 'h1',
      ...attrs
    },
    ref
  ) =>
    createElement(headingType, {
      ...attrs,
      ref,
      children,
      tabIndex,
      className: classNames(className, 'cade-title', {
        [`cade-typography-title--${level}`]: level,
        'cade-title--centered': centered,
      }),
    })
);
