import { Instruction } from '@components/Instruction';
import { Box, Paragraph, Title } from '@src/components';
import { CadeEvent, useEndedEvent } from '@utils/events';
import { Listening } from '@components/Listening';
import React, { useEffect, useState, useContext } from 'react';
import { ListeningStatus } from '@components/Listening/commons';
import './styles.scss';
import { VoiceLevelIndicator } from '@components/VoiceLevelIndicator';
import { useDelay } from '@utils/useDelay';
import { useVolume } from '@context/Volume.context';
import { CADE_CONTENT_TITLE_ID } from '@itemTypes/constants';
import { INSTRUCTION_AUTO_PLAY_TIMEOUT } from '@utils/constants';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { InstructionContainer } from '@src/components/InstructionContainer';

export type Props = {
  title: string;
  instruction: string;
  onEvent: (event: CadeEvent) => void;
  audioSrc: string[];
  forceEnd?: boolean;
};

export function OpenQuestionsInstruction({
  title,
  instruction,
  onEvent,
  audioSrc,
  forceEnd = false,
}: Props) {
  const [status, setStatus] = useState<ListeningStatus>('INACTIVE');
  const delay = useDelay();
  const { sendEndEvent } = useEndedEvent(onEvent);

  const { state } = useVolume();
  const voiceLevel = state.outputValue;
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  const [buttonText, setButtonText] = useState(t('buttons.play'));

  useEffect(() => delay.set(onClickPlay, INSTRUCTION_AUTO_PLAY_TIMEOUT), []);

  useEffect(() => {
    if (forceEnd) {
      delay.clear();
      sendEndEvent();
    }
  }, [forceEnd]);

  function onClickPlay() {
    setStatus('PLAYING');
  }

  function onAudioEnded() {
    delay.set(() => {
      sendEndEvent();
    });
    setButtonText(t('buttons.replay'));
    setStatus('INACTIVE');
  }

  return (
    <div className={'open-questions-instruction'}>
      <div className={'open-questions-instruction-content'}>
        <InstructionContainer>
          <Title centered id={CADE_CONTENT_TITLE_ID} level="large">
            {title}
          </Title>
          <Instruction
            dangerouslySetInnerHTML={{ __html: instruction }}
          ></Instruction>
        </InstructionContainer>
        <Box role="instruction" className="example-box cade-padding-7">
          <Paragraph level={'large'} weight={'bold'}>
            {t('openQuestions.instruction.p')}
          </Paragraph>
          <Listening
            role={'instruction'}
            audioSrc={audioSrc}
            onAudioEnded={onAudioEnded}
            playButtonText={buttonText}
            status={status}
            onClickPlay={() => {
              delay.clear();
              onClickPlay();
            }}
          />
        </Box>
      </div>
      <div className={'voice-level-container'}>
        <VoiceLevelIndicator
          numOfCircles={6}
          currentValue={voiceLevel}
          listenMode={true}
        />
      </div>
    </div>
  );
}
