import { LogEvent, Logger } from '../api/Logger';

export const useLogger = () => {
  const pushEvent = (event: Omit<LogEvent, 'timestamp' | 'embedType'>) => {
    const l = Logger.getInstance();
    l.pushEvent(event);
  };

  const sendLogs = async () => {
    const l = Logger.getInstance();
    await l.sendLogs();
  };

  return {
    pushEvent,
    sendLogs,
  };
};
