import { Action, RadioInputParam, Toc, ApiModel } from '../../api.interfaces';
import {
  checkPlayParamsUrls,
  extractAudioResourcesFromResponse,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeResponseSelectionModel {
  subTitle: string;
  audioSrc: string[];
  onEvent: (event: CadeEvent) => void;
  forceEnd?: boolean;
  leftLabel: string;
  rightLabel: string;
  count: number;
  total: number;
  timeLeft: number;
  options: string[];
  responseId: string;
  currentStep: number;
  totalSteps: number;
  instruction?: string;
}
export function transformJSONToResponseSelection(
  json: ApiModel,
  tocArray: Toc[]
): CadeResponseSelectionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.microInstructions',
    'items.actions.actionDuration',
    'items.actions.playParams.url',
    'items.actions.actionDuration',
    'items.actions.radioInputParams.responseId',
    'items.actions.radioInputParams.options',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () => checkPlayParamsUrls(json.items[0].actions),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }

  const item = json.items[0];
  const formInput = item.actions.find((el) => el.actionType === 'forminput');
  const [letter, sectionName] = item.titles;
  const responseId = (formInput?.radioInputParams as RadioInputParam[])[0]
    ?.responseId;
  const title = i18n.t('part', { letter, sectionName });
  const { count, total } = item.itemSequence;
  const totalSteps = tocArray.length;
  const currentStep =
    tocArray.findIndex((el) => el.itemType === item.itemType) + 1;
  const timeLeft = (formInput as unknown as Action).actionDuration;
  const leftLabel = item.microInstructions[0];
  const rightLabel = item.microInstructions[1];
  const options = (formInput?.radioInputParams as RadioInputParam[])[0]
    .options as string[];
  const audio = extractAudioResourcesFromResponse(json);
  const instruction = item.cueInstructions?.at(0);

  return {
    responseId,
    subTitle: title,
    audioSrc: audio,
    count,
    total,
    timeLeft,
    leftLabel,
    rightLabel,
    currentStep,
    totalSteps,
    onEvent: (event: CadeEvent) => {},
    options,
    instruction,
  };
}
