import { Action, ApiModel, Toc } from '../../api.interfaces';
import {
  checkTextInputParams,
  extractPictureResourcesFromResponse,
  getSteps,
  validateWDTResponse,
} from '../../api.utils';
import { CadeEvent } from '@versant-monorepo/cade';
import { COMMON_KEYS_TO_VALIDATE } from '../../api';
import i18n from 'i18next';

interface CadeReadingComprehensionModel {
  title: string;
  subTitle: string;
  count: number;
  total: number;
  onEvent: (event: CadeEvent) => void;
  leftLabel: string;
  timeLeft: number;
  forceEnd?: boolean;
  imageSrc: string;
  questions: { question: string; optionsText: string[]; id: string }[];
  currentStep: number;
  totalSteps: number;
  instruction?: string;
}

export function transformJSONToReadingComprehension(
  json: ApiModel,
  tocArray: Toc[]
): CadeReadingComprehensionModel {
  const keysToCheckInItems: string[] = [
    ...COMMON_KEYS_TO_VALIDATE,
    'items.actions.actionDuration',
  ];
  const validateResult = validateWDTResponse(json, keysToCheckInItems, [
    () =>
      checkTextInputParams(
        json.items[0].actions.find(
          (el: Action) => el.actionType === 'forminput'
        )?.radioInputParams
      ),
  ]);
  if (!validateResult) {
    //TODO: switch somehow view to error page
    throw new Error('Validation failed');
  }
  const item = json.items[0];
  const [letter, sectionName] = item.titles;
  const title = i18n.t('part', { letter, sectionName });
  const { count, total } = item.itemSequence;
  const leftLabel = item.microInstructions[0];
  const imageSrc = extractPictureResourcesFromResponse(json);
  const instruction = item.cueInstructions?.at(0);

  const action = item.actions.find((el) => el.actionType === 'forminput');
  if (!action) {
    throw 'Cannot find required action - forminput';
  }

  const duration: number = action.actionDuration;

  if (!action.radioInputParams) {
    throw 'FormInput action has to have radioInputParams';
  }

  const questions = action.radioInputParams.map((param) => {
    return {
      id: param.responseId,
      question: param.prompt[0],
      optionsText: param.markedUpOptions.map((option) => option.value),
    };
  });

  return {
    ...getSteps(tocArray, item.itemType),
    title: title,
    subTitle: title,
    count: count,
    total: total,
    imageSrc: imageSrc[0],
    leftLabel: leftLabel,
    timeLeft: duration,
    questions: questions,
    onEvent: (event: CadeEvent) => {},
    instruction,
  };
}
