import React from 'react';
import './CircleLoader.scss';
import { CircularProgress } from '@versant-monorepo/cade';

type CircleLoaderProps = {
  size?: number;
  color?: string;
  loadingMessageForA11y?: string;
};

export const CIRCLE_LOADER_TEST_IDS = {
  CONTAINER: 'vfw-circle-loader-container',
  MESSAGE_A11Y: 'vfw-circle-loader-message-a11y',
};

const CircleLoader: React.FC<CircleLoaderProps> = ({
  size = 75,
  color = '#047a9c',
  loadingMessageForA11y = 'loading',
}) => {
  return (
    <div
      className="circle-loader-container"
      data-testid={CIRCLE_LOADER_TEST_IDS.CONTAINER}
    >
      <span
        className="sr-only"
        aria-busy={true}
        role="alert"
        data-testid={CIRCLE_LOADER_TEST_IDS.MESSAGE_A11Y}
      >
        {loadingMessageForA11y}
      </span>
      <CircularProgress size={size} />
    </div>
  );
};

export default CircleLoader;
