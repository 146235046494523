import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import './styles.scss';
import iconFull from './icons/icon_full.png';
import iconLevel0 from './icons/icon_level_0.png';
import iconLevel1 from './icons/icon_level_1.png';
import icon_finished from './icons/icon_finished.png';
import icon_error from './icons/icon_full_error.png';
import {
  CADE_NEXT_BUTTON_ID,
  Modal,
  NextButton,
  Paragraph,
  SecondaryButton,
  Space,
} from '@src/components';
import { Instruction } from '@components/Instruction';
import { ChecksHeader } from '@components/ChecksHeader';
import {
  CadeEvent,
  failureEvent,
  successEvent,
  useEndedEvent,
} from '@utils/events';
import { ChecksBox } from '../components/ChecksBox';
import { Link } from '@components/base/Link';
import { ConfigContext } from '@src/context/CadeConfigProvider';
import { connectionSpeed } from '@versant-monorepo/vfw2/src/api/api';

export interface IBandwidthCheckProps {
  checkState: CheckState;
  downloadSrc: string;
  downloadSizeMB: number;
  minAcceptableSpeedMbs: number;
  currentCheckingStep: number;
  totalCheckingSteps: number;
  onEvent: (event: CadeEvent) => void;
}

export enum CheckState {
  INITIAL = 'INITIAL',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

let interval: NodeJS.Timer | null = null;

export const CADE_BANDWIDTH_CHECK_TROUBLESHOOTING_LINK_ID =
  'cade-bandwidth-check-troubleshooting-link';

export function BandwidthCheck({
  checkState,
  downloadSizeMB,
  minAcceptableSpeedMbs,
  currentCheckingStep,
  totalCheckingSteps,
  onEvent,
}: IBandwidthCheckProps) {
  const [state, setState] = useState(checkState);
  const [currentImage, setCurrentImage] = useState(iconFull);
  const [openModal, setOpenModal] = useState(false);
  const { onClickSendEndEvent } = useEndedEvent(onEvent);
  const {
    i18n: { t, TranslationComponent },
  } = useContext(ConfigContext);

  function onCloseModal() {
    setOpenModal(false);
  }

  useEffect(() => {
    if (state === CheckState.INITIAL) {
      setCurrentImage(iconFull);
    }
    if (state === CheckState.STARTED) {
      if (interval === null) {
        let currentImageTemp = iconFull;
        let currentState: CheckState = state;
        interval = setInterval(() => {
          if (
            currentState === CheckState.FAILED ||
            currentState === CheckState.FINISHED
          ) {
            return;
          }
          if (currentImageTemp === iconLevel0) {
            setCurrentImage(iconLevel1);
            currentImageTemp = iconLevel1;
            return;
          }
          if (currentImageTemp === iconLevel1) {
            setCurrentImage(iconFull);
            currentImageTemp = iconFull;
            return;
          }
          if (currentImageTemp === iconFull) {
            setCurrentImage(iconLevel0);
            currentImageTemp = iconLevel0;
            return;
          }
        }, 1000);
      }

      window.setTimeout(MeasureConnectionSpeed, 1);
    }
    if (state === CheckState.FAILED || state === CheckState.FINISHED) {
      if (interval != null) {
        clearInterval(interval);
        interval = null;
      }
    }
    if (state === CheckState.FINISHED) {
      if (interval != null) {
        clearInterval(interval);
        interval = null;
      }
      setCurrentImage(icon_finished);
    }
  }, [state]);

  async function MeasureConnectionSpeed() {
    let startTime: number, endTime: number;

    startTime = new Date().getTime();
    const cacheBuster = '?nnn=' + startTime;

    try {
      const response = await connectionSpeed(startTime);
      await response!.blob();
      endTime = new Date().getTime();
      showResults();
    } catch (e) {
      setFailedState();
    }

    function showResults() {
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSizeMB * 8 * 1024 * 1024;
      const speedBps: number = bitsLoaded / duration;
      const speedKbps = speedBps / 1024;
      const speedMbps = speedKbps / 1024;
      if (speedMbps >= minAcceptableSpeedMbs) {
        setState(CheckState.FINISHED);
        setCurrentImage(icon_finished);
        onEvent(successEvent());
      } else {
        setFailedState();
      }
    }
  }

  function setFailedState() {
    setState(CheckState.FAILED);
    setCurrentImage(icon_error);
    onEvent(failureEvent());
  }

  const onClickLink = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpenModal(true);
  }, []);

  function getText() {
    switch (state) {
      case CheckState.STARTED:
      case CheckState.INITIAL:
        return <>{t('bandwidthCheck.instruction.started_initial')}</>;
      case CheckState.FINISHED:
        return (
          <span aria-atomic={true} role={'text'}>
            {t('bandwidthCheck.instruction.finished')}
          </span>
        );
      case CheckState.FAILED:
        return (
          <>
            {
              <TranslationComponent i18nKey="bandwidthCheck.instruction.failed" />
            }
            <Link
              id={CADE_BANDWIDTH_CHECK_TROUBLESHOOTING_LINK_ID}
              className="cade-bandwidth-check__troubleshooting-link"
              onClick={onClickLink}
            >
              {t('bandwidthCheck.instruction.failedLink')}
            </Link>
          </>
        );
    }
  }

  function getStatus() {
    switch (state) {
      case CheckState.FINISHED:
        return 'success';
      case CheckState.FAILED:
        return 'error';
    }
  }

  return (
    <div className="cade-bandwidth-check">
      <Space
        margin={{ top: 8, bottom: 8 }}
        className="cade-bandwidth-check__hgroup"
      >
        <ChecksHeader
          headerText={t('bandwidthCheck.header.text')}
          title={t('bandwidthCheck.header.title')}
          currentStep={currentCheckingStep}
          totalSteps={totalCheckingSteps}
        />
      </Space>
      <div className="cade-bandwidth-check__content-container">
        <ChecksBox>
          <div className="cade-bandwidth-check__content">
            <div>
              <div
                className={`cade-bandwidth-check__instruction cade-bandwidth-check__${getStatus()}`}
              >
                {(state === CheckState.INITIAL ||
                  state === CheckState.STARTED) && (
                  <span
                    role="status"
                    aria-live="polite"
                    aria-atomic={true}
                    aria-label={t('bandwidthCheck.messagesForReader.loading')}
                  />
                )}
                <Instruction role="status" level="large">
                  {getText()}
                </Instruction>
                {(state === CheckState.INITIAL ||
                  state === CheckState.STARTED) && (
                  <span
                    role="status"
                    aria-live="polite"
                    aria-atomic={true}
                    aria-label={t('bandwidthCheck.messagesForReader.updating')}
                  />
                )}
              </div>
              <div className="cade-bandwidth-check__success-panel">
                <img
                  className="cade-bandwidth-check__icon"
                  src={currentImage}
                  aria-hidden={true}
                  alt=""
                />
              </div>
            </div>
            <div role={'status'}>
              {state === CheckState.FINISHED && (
                <div className="cade-bandwidth-check__status-components cade-bandwidth-check__status-components--success">
                  <NextButton
                    id={CADE_NEXT_BUTTON_ID}
                    size="large"
                    onClick={onClickSendEndEvent}
                  />
                </div>
              )}

              {state === CheckState.FAILED && (
                <div className="cade-bandwidth-check__status-components cade-bandwidth-check__status-components--failure">
                  <SecondaryButton
                    size="large"
                    onClick={() => setState(CheckState.STARTED)}
                  >
                    {t('bandwidthCheck.button.retry')}
                  </SecondaryButton>
                </div>
              )}
            </div>
          </div>
        </ChecksBox>
      </div>
      <Modal
        title={t('bandwidthCheck.modal.title')}
        open={openModal}
        onClose={onCloseModal}
        footer={
          <SecondaryButton size="large" onClick={onCloseModal}>
            {t('bandwidthCheck.modal.confirmButton')}
          </SecondaryButton>
        }
      >
        <Paragraph>{t('bandwidthCheck.modal.content')}</Paragraph>
      </Modal>
    </div>
  );
}
