import React, {
  forwardRef,
  HTMLProps,
  MouseEvent,
  PropsWithChildren,
  useCallback,
} from 'react';

type Props = PropsWithChildren<HTMLProps<HTMLAnchorElement>>;

export const LINK_TEST_ID = 'cade-link';

export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ href, onClick = () => {}, children, ...props }, ref) => {
    const _onClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        if (!href) {
          event.preventDefault();
        }

        onClick(event);
      },
      [href, onClick]
    );

    return (
      <a
        {...props}
        data-testid={LINK_TEST_ID}
        onClick={_onClick}
        href={href ?? '#'}
        ref={ref}
      >
        {children}
      </a>
    );
  }
);
