import './VolumeSlider.scss';
import React, {
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import volumeIcon from './volume-up-24.png';
import { updateVolume, useVolume } from '@context/Volume.context';
import { Space } from '@components/Space';

export enum Size {
  Large = 'lg',
  Small = 'sm',
}

export class Props {
  size?: Size;
  tabIndex?: number;
}

type MouseDown =
  | {
      isMouseDown: true;
      offset: number;
    }
  | { isMouseDown: false };

export function VolumeSlider(props: Props) {
  const bar = useRef<HTMLDivElement>(null);
  const [mouseDown, setMouseDown] = useState<MouseDown>({ isMouseDown: false });

  const { state, dispatch } = useVolume();
  const volumePosition = 100 - state.value;

  useEffect(() => {
    const handleMouseUp = () => {
      setMouseDown({ isMouseDown: false });
    };
    const handleMouseMove = (e: MouseEvent) => {
      if (!mouseDown.isMouseDown) {
        return;
      }
      let offsetWidth = bar!.current!.offsetHeight;
      let newPosition =
        volumePosition +
        (e.clientY - mouseDown.offset) /
          (offsetWidth === 0 ? 1 : offsetWidth / 100);
      if (newPosition < 0) {
        newPosition = 0;
      } else if (newPosition > 100) {
        newPosition = 100;
      }
      dispatch(updateVolume(100 - newPosition));
    };
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown, dispatch]);

  const handleMouseDown = useCallback((e: ReactMouseEvent) => {
    setMouseDown({
      isMouseDown: true,
      offset: e.clientY,
    });
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (volumePosition <= 95) {
          dispatch(updateVolume(100 - volumePosition - 5));
        }
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (volumePosition >= 5) {
          dispatch(updateVolume(100 - volumePosition + 5));
        }
      }
    },
    [volumePosition, dispatch]
  );

  return (
    <Space
      padding={{ left: 4, right: 4 }}
      className="b-volume-slider-container"
      data-testid="slider-container"
    >
      {props.tabIndex}

      <div
        className={`volume-slider-bar ${props.size ? props.size : ''}`}
        ref={bar}
        role={'slider'}
        aria-orientation="vertical"
        aria-roledescription={'slider'}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={100 - volumePosition}
        aria-label="volume slider"
        onKeyDown={handleKeyDown}
        tabIndex={props.tabIndex === null ? props.tabIndex : 0}
        data-testid="slider-bar"
      >
        <div
          style={{ height: volumePosition + 4 + '%' }}
          className="volume-slider-fill"
          data-testid="slider-fill"
        />

        <span
          style={{ top: `calc(${volumePosition}% - 6.5px)` }}
          onMouseDown={handleMouseDown}
          className={`volume-slider-handle ${props.size ? props.size : ''}`}
          data-testid="slider-handler"
        />
      </div>

      <img
        src={volumeIcon}
        className="icon"
        aria-hidden="true"
        alt="volume icon"
      ></img>
    </Space>
  );
}
