import './ListeningLoader.styles.scss';

export const LISTENING_LOADER_TEST_ID = 'cade-listening-loader';

export const ListeningLoader = () => {
  return (
    <svg
      className="listening-loader__container"
      viewBox="0 0 40 40"
      height="40"
      width="40"
      x="213"
      y="50"
      data-testid={LISTENING_LOADER_TEST_ID}
    >
      <circle
        className="listening-loader__circle"
        cx="20"
        cy="20"
        r="17.5"
        pathLength="100"
        strokeWidth="6px"
        fill="none"
      />
    </svg>
  );
};
