import './styles.scss';
import { SystemCheckCell } from './SystemCheckCell';
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChecksHeader } from '@components/ChecksHeader';
import { browserName, browserVersion } from 'react-device-detect';
import { TroubleshotModal } from './TroubleshotModal';
import {
  CadeEvent,
  failureEvent,
  successEvent,
  troubleshootClickEvent,
  useEndedEvent,
} from '@utils/events';
import { ChecksBox } from '../components/ChecksBox';
import {
  CADE_NEXT_BUTTON_ID,
  NextButton,
  Paragraph,
  PrimaryButton,
} from '@src/components';
import { Space } from '@components/Space';
import { Link } from '@components/base/Link';
import { ConfigContext } from '@context/CadeConfigProvider';
import { delay } from '@src/utils';

export interface ISystemCheckProps {
  count: number;
  total: number;
  currentCheckingStep: number;
  totalCheckingSteps: number;
  onEvent: (event: CadeEvent) => void;
  networkCheck?: () => Promise<boolean>;
}

const CHECK_TIME = 1500;

const browsersAllowed = [
  {
    name: 'Chrome',
    version: 90,
  },
  {
    name: 'Firefox',
    version: 111,
  },
  {
    name: 'Edge',
    version: 100,
  },
  {
    name: 'Safari',
    version: 15,
  },
];

function checkBrowserCompatibility() {
  return browsersAllowed.some(({ name }) => browserName.includes(name));
}

function checkBrowserVersionCompatibility() {
  return browsersAllowed.some(({ version, name }) => {
    if (browserName.includes(name)) {
      return Number(browserVersion) >= version;
    }

    return false;
  });
}

export type SystemCheckElement = {
  id: SYSTEM_CHECK_ID;
  label: string;
  state: 'inactive' | 'error' | 'ok';
};

export enum SYSTEM_CHECK_ID {
  BROWSER_VERSION = 'BROWSER_VERSION',
  BROWSER_COMPATIBILITY = 'BROWSER_COMPATIBILITY',
  COOKIES = 'COOKIES',
  NETWORK = 'NETWORK',
}

const renderSystemChecks = (systemCheck: SystemCheckElement) => (
  <SystemCheckCell
    key={systemCheck.id.toString()}
    label={systemCheck.label}
    state={systemCheck.state}
  />
);

export const CADE_SYSTEM_CHECK_TROUBLESHOOTING_LINK_ID =
  'cade-system-check-troubleshooting-link';

function applyCheckResult(
  checks: SystemCheckElement[],
  id: SYSTEM_CHECK_ID,
  result: boolean
) {
  return checks.map((sc) =>
    sc.id === id
      ? ({ ...sc, state: result ? 'ok' : 'error' } as SystemCheckElement)
      : sc
  );
}

export function SystemCheck({
  currentCheckingStep,
  totalCheckingSteps,
  onEvent,
  networkCheck,
}: ISystemCheckProps) {
  const {
    i18n: { t },
  } = useContext(ConfigContext);

  // TODO: change structure from array to Map, do some cleanup in whole component
  const initSystemChecks: SystemCheckElement[] = [
    {
      id: SYSTEM_CHECK_ID.BROWSER_VERSION,
      label: t('systemCheck.browserVersion'),
      state: 'inactive',
    },
    {
      id: SYSTEM_CHECK_ID.BROWSER_COMPATIBILITY,
      label: t('systemCheck.browserCompatibility'),
      state: 'inactive',
    },
    {
      id: SYSTEM_CHECK_ID.COOKIES,
      label: t('systemCheck.cookies'),
      state: 'inactive',
    },
    {
      id: SYSTEM_CHECK_ID.NETWORK,
      label: t('systemCheck.network'),
      state: 'inactive',
    },
  ];

  const [open, setOpen] = useState(false);
  const [systemChecks, setSystemChecks] = useState(initSystemChecks);
  const [checkStatus, setCheckStatus] = useState<
    'IDLE' | 'IN_PROGRESS' | 'FINISHED'
  >('IDLE');
  const { onClickSendEndEvent } = useEndedEvent(onEvent);

  const allErrors = useMemo(
    () => systemChecks.filter(({ state }) => state === 'error'),
    [systemChecks]
  );
  const checkError = useMemo(() => {
    return allErrors.length > 0;
  }, [allErrors]);

  const browserErrors = useMemo(
    () =>
      allErrors.filter(
        ({ id }) =>
          id === SYSTEM_CHECK_ID.BROWSER_VERSION ||
          id === SYSTEM_CHECK_ID.BROWSER_COMPATIBILITY
      ),
    [allErrors]
  );

  const startSystemChecks = useCallback(async function startSystemChecks() {
    setCheckStatus('IN_PROGRESS');

    await delay((resolve) => {
      setSystemChecks((systemChecks) => {
        resolve();
        return applyCheckResult(
          systemChecks,
          SYSTEM_CHECK_ID.BROWSER_VERSION,
          checkBrowserVersionCompatibility()
        );
      });
    }, CHECK_TIME);

    await delay((resolve) => {
      setSystemChecks((systemChecks) => {
        resolve();
        return applyCheckResult(
          systemChecks,
          SYSTEM_CHECK_ID.BROWSER_COMPATIBILITY,
          checkBrowserCompatibility()
        );
      });
    }, CHECK_TIME);

    await delay((resolve) => {
      setSystemChecks((systemChecks) => {
        resolve();
        return applyCheckResult(
          systemChecks,
          SYSTEM_CHECK_ID.COOKIES,
          navigator.cookieEnabled
        );
      });
    }, CHECK_TIME);

    await delay(async (resolve) => {
      const result = (await networkCheck?.()) ?? false;
      setSystemChecks((systemChecks) => {
        resolve();
        return applyCheckResult(systemChecks, SYSTEM_CHECK_ID.NETWORK, result);
      });
    }, CHECK_TIME);

    setCheckStatus('FINISHED');
  }, []);

  const onClickTroubleshootCallback = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setOpen(true);
      onEvent(troubleshootClickEvent());
    },
    []
  );

  const onClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    if (checkStatus === 'FINISHED') {
      if (checkError) {
        onEvent(failureEvent<SystemCheckElement[]>({ details: allErrors }));
      } else {
        onEvent(successEvent());
      }
    }
  }, [checkStatus, checkError]);

  useEffect(() => {
    startSystemChecks();
  }, []);

  const systemCheckContent = (
    systemChecks: SystemCheckElement[],
    isComplete?: boolean,
    isError?: boolean
  ) => (
    <div className="cade-system-check__content">
      {!isComplete && (
        <>
          <span className="sr-only" id="messageLabel">
            {t('systemCheck.messageLabelForReader')}
          </span>
          <Paragraph
            data-testid="initial_message"
            level="large"
            className="cade-system-check__break-down"
            aria-labelledby="messageLabel"
          >
            {t('systemCheck.message.initial')}
          </Paragraph>
        </>
      )}
      {isComplete && !isError && (
        <Paragraph
          level="large"
          data-testid="finished_checks_message"
          className="cade-system-check__break-down cade-system-check__break-down--complete"
          aria-label={t('systemCheck.message.complete')}
          aria-hidden={true}
        >
          {t('systemCheck.message.complete')}
        </Paragraph>
      )}
      {isComplete && isError && (
        <p
          data-testid="error_message"
          className="cade-typography-text__large cade-system-check__break-down cade-system-check__break-down--error"
          aria-label={t('systemCheck.message.error.text')}
        >
          {t('systemCheck.message.error.title')}
          <br />
          <Link
            id={CADE_SYSTEM_CHECK_TROUBLESHOOTING_LINK_ID}
            onClick={onClickTroubleshootCallback}
            className="cade-system-check__troubleshoot-link"
          >
            {t('systemCheck.message.error.link')}
          </Link>
        </p>
      )}

      <div aria-live={'polite'}>
        <Space
          role={'list'}
          margin={{ bottom: 7 }}
          className="cade-system-check__checks-list"
        >
          {systemChecks.map(renderSystemChecks)}
        </Space>
      </div>
      <h1
        className={'sr-only'}
        aria-live={'polite'}
        aria-atomic={true}
        role={'status'}
      >
        {isComplete
          ? t('systemCheck.message.complete')
          : t('systemCheck.message.initial')}
      </h1>
      <div className="cade-system-check__buttons-container">
        {isComplete && !isError && (
          <NextButton
            data-testid="next_button"
            id={CADE_NEXT_BUTTON_ID}
            onClick={onClickSendEndEvent}
          />
        )}
        {isComplete && isError && (
          <PrimaryButton data-testid="retry_button" onClick={retry}>
            {browserErrors.length
              ? t('systemCheck.next')
              : t('systemCheck.retry')}
          </PrimaryButton>
        )}
      </div>
    </div>
  );

  const retry = useCallback(
    function retry() {
      onEvent(failureEvent<SystemCheckElement[]>({ details: allErrors }));
      if (browserErrors.length) {
        onClickSendEndEvent();
        return;
      }
      setSystemChecks(systemChecks.map((sc) => ({ ...sc, state: 'inactive' })));
      setCheckStatus('IDLE');
      startSystemChecks();
    },
    [browserErrors, allErrors, systemChecks]
  );

  return (
    <div className="cade-system-check__content">
      <div className="cade-system-check__hgroup">
        <Space
          margin={{ top: 5, bottom: 5 }}
          className="cade-speaker-check__hgroup"
        >
          <ChecksHeader
            headerText={t('systemCheck.header.text')}
            title={t('systemCheck.header.title')}
            currentStep={currentCheckingStep}
            totalSteps={totalCheckingSteps}
          />
        </Space>
      </div>
      <div className="cade-system-check__content-container">
        <ChecksBox>
          {systemCheckContent(
            systemChecks,
            checkStatus === 'FINISHED',
            checkError
          )}
        </ChecksBox>
      </div>
      <TroubleshotModal open={open} onClose={onClose} />
    </div>
  );
}
