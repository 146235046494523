import { useEffect } from 'react';
import { VfwStorage } from '../utils/Storage';

export const useSetDocumentTitle = (title?: string) => {
  useEffect(() => {
    const testName = VfwStorage.getItem('testName')
      ? ` - ${VfwStorage.getItem('testName')}`
      : '';
    const t = title ? ` - ${title}` : '';

    document.title = `Versant for Web ${testName} ${t}`;
  }, []);
};
